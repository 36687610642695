import { isEmpty } from 'lodash';
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';

/**
 * Axios call to post to Carton Service V2 to receive carton(s)
 * @param {object} body the request body contains receivingNumber, numbers, storeArea and storeId.
 *                      All requried except storeArea
 */
export const postReceiveCartons = async (body) => {
  if (!body || isEmpty(body)) return null;

  const url = `${baseUrl(routerUrls.DELEDGE)}${endpoints.RECEIVE_CARTONS.url}`;

  return generalAxiosRequest('POST', url, endpoints.RECEIVE_CARTONS, true, null, body)
    .then(resp => resp)
    .catch(error => { throw error; });
};
