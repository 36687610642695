import { useMemo, useContext } from 'react';
import { object, string, bool } from 'prop-types';
import classNames from 'classnames';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import ScanAccuracyWidgetUtils from '../dashboardWidgetUtils/scanAccuracyWidget.utils';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import LinkWithDisable from '../../LinkWithDisable';

import './ScanAccuracyWidget.css';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { tutorialContext } from '../../tutorial/Tutorial.provider';
import { getScanDuration } from '../../../containers/scans/details/scanReportDetails.utils';

import mockedScanAccuracyData from '../../tutorial/mockTutorialData/dashboard/scan-accuracy-data.json';

/**
  * Creates a widget with data visuals for the Scan Accuracy Compliance
  * @param {String} customClass additional string to cusomize styles
  * @param {String} Locale the locale for a given user
  * @param {Object} data calculated details to display in the widget
  * @param {Boolean} loading loading state
  * @return {Node} returns a new component with the correct data
*/
const ScanAccuracyWidget = ({
  customClass = '',
  locale = 'en-US',
  data = {},
  loading = false,
}) => {
  const {
    storeConfig, getMessage, storeInfo, SimDateTime,
  } = useContext(SimWebContext);
  const { activateTutorials } = useContext(tutorialContext);

  const isGreaterChinaDoor = storeInfo?.isGreaterChina;
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const accuracyThreshold = storeConfig?.['rfid.scanReportAccuracyThreshold']?.value;
  const lastFullStoreScan = activateTutorials ? mockedScanAccuracyData.lastFSScan : data && data?.lastFSScan;
  const lastCycleCountScan = activateTutorials ? mockedScanAccuracyData.lastCCScan : data && data?.lastCCScan;
  const lastSalesFloorScan = activateTutorials ? mockedScanAccuracyData.lastSFScan : data && data?.lastSFScan;

  const endDate = SimDateTime.endOfDay(SimDateTime.now()).toISO();

  const scanAccuracyDetails = useMemo(() => {
    const noLastScanData = errMessage => (
      <div className="scanAccuracyWidget_date-error" data-testid="errorContainer_emptyData">
        <ErrorOutline color="error" fontSize="large" />
        <br />
        <p>{getMessage(errMessage)}</p>
      </div>
    );

    if (!data || data === undefined || data === null || data.error) {
      return (
        <div className="scanAccuracyWidget_card-error" data-testid="errorContainer">
          <ErrorOutline color="error" fontSize="large" />
          <br />
          <p>{getMessage('fatalError')}</p>
        </div>
      );
    }

    const fsScanDate = lastFullStoreScan && lastFullStoreScan.scanStatus
      && lastFullStoreScan.scanStatus.statusHistory
      && lastFullStoreScan.scanStatus.statusHistory[lastFullStoreScan.scanStatus.statusHistory.length - 1].date;
    const ccScanDate = lastCycleCountScan && lastCycleCountScan.scanStatus
      && lastCycleCountScan.scanStatus.statusHistory
      && lastCycleCountScan.scanStatus.statusHistory[lastCycleCountScan.scanStatus.statusHistory.length - 1].date;
    const sfScanDate = lastSalesFloorScan && lastSalesFloorScan.scanStatus
      && lastSalesFloorScan.scanStatus.statusHistory
      && lastSalesFloorScan.scanStatus.statusHistory[lastSalesFloorScan.scanStatus.statusHistory.length - 1].date;

    const fsScanDuration = getScanDuration(lastFullStoreScan, getMessage);
    const ccScanDuration = getScanDuration(lastCycleCountScan, getMessage);
    const sfScanDuration = getScanDuration(lastSalesFloorScan, getMessage);

    const fsLoadingState = loading && !lastFullStoreScan ? <div className="scanAccuracyWidget_card-loading" data-testid="fs-scanAccuracy_loadingState"><LoadingIndicator width="65px" height="65px" fill="white" /></div> : null;
    const ccLoadingState = loading && !lastCycleCountScan ? <div className="scanAccuracyWidget_card-loading" data-testid="cc-scanAccuracy_loadingState"><LoadingIndicator width="65px" height="65px" fill="white" /></div> : null;
    const sfLoadingState = loading && !lastSalesFloorScan ? <div className="scanAccuracyWidget_card-loading" data-testid="sf-scanAccuracy_loadingState"><LoadingIndicator width="65px" height="65px" fill="white" /></div> : null;
    const lastFSScanState = !loading && lastFullStoreScan
      ? (
        <>
          <div data-testid="scanAccuracyWidgetLastFSScanDate" className="scanAccuracy-date">
            {fsScanDate ? `${getMessage('submitted')} ${new Date(fsScanDate).toLocaleString(locale)}` : null}
          </div>
          <div data-testid="scanAccuracyWidgetLastFSScanDuration" className="scanDuration">
            {fsScanDuration}
          </div>
          <div className="scanAccuracyWidget_date-aggregations">
            {(JSON.stringify(lastFullStoreScan) === '{}')
              ? <ScanAccuracyWidgetUtils typeofScan="fullStore" lastScanData={lastFullStoreScan} accuracyThreshold={accuracyThreshold} />
              : null}
          </div>
        </>
      ) : noLastScanData('noResultsFSLastScan');

    const lastCCScanState = !loading && lastCycleCountScan
      ? (
        <>
          <div data-testid="scanAccuracyWidgetLastccScanDate" className="scanAccuracy-date">
            {ccScanDate ? `${getMessage('submitted')} ${new Date(ccScanDate).toLocaleString(locale)}` : null}
          </div>
          <div data-testid="scanAccuracyWidgetLastCCScanDuration" className="scanDuration">
            {ccScanDuration}
          </div>
          <div className="scanAccuracyWidget_date-aggregations">
            {(JSON.stringify(lastCycleCountScan) === '{}')
              ? <ScanAccuracyWidgetUtils typeofScan="cycleCount" lastScanData={lastCycleCountScan} accuracyThreshold={accuracyThreshold} />
              : null}
          </div>
        </>
      ) : noLastScanData('noResultsCCLastScan');

    const lastSFScanState = !loading && lastSalesFloorScan
      ? (
        <>
          <div data-testid="scanAccuracyWidgetLastSFScanDate" className="scanAccuracy-date">
            {sfScanDate ? new Date(sfScanDate).toLocaleString(locale) : null}
          </div>
          <div data-testid="scanAccuracyWidgetLastSFScanDuration" className="scanDuration">
            {sfScanDuration}
          </div>
          <div className="scanAccuracyWidget_date-aggregations">
            {(JSON.stringify(lastSalesFloorScan) === '{}')
              ? <ScanAccuracyWidgetUtils typeofScan="salesFloor" lastScanData={lastSalesFloorScan} accuracyThreshold={accuracyThreshold} />
              : null}
          </div>
        </>
      ) : noLastScanData('noResultsSFLastScan');

    const lastFSScanContainer = (
      <div className="fs-scanAccuracy-container" data-testid="fs-scanAccuracy-container">
        <div className="fs-scanAccuracy">
          <div className="fs-scanAccuracy-text">
            <div className="fs-scanAccuracy-data">
              <div>
                <p className="widget-title">{getMessage('lastFSScan')}</p>
                {fsLoadingState || lastFSScanState}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const lastSFScanContainer = (
      <div className="sf-scanAccuracy-container" data-testid="sf-cc-scanAccuracy-container">
        <div className="sf-scanAccuracy">
          <div className="sf-scanAccuracy-text">
            <div className="sf-scanAccuracy-data">
              <div>
                <p className="widget-title">{getMessage('lastSFScan')}</p>
                {sfLoadingState || lastSFScanState}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const lastCCScanContainer = (
      <div className="cc-scanAccuracy-container" data-testid="sf-cc-scanAccuracy-container">
        <div className="cc-scanAccuracy">
          <div className="cc-scanAccuracy-text">
            <div className="cc-scanAccuracy-data">
              <div>
                <p className="widget-title">{getMessage('lastCCScan')}</p>
                {ccLoadingState || lastCCScanState}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="scanAccuracyWidget_card-details" data-testid="scanAccuracyDataContainer">
        {activateTutorials ? (
          <>
            {lastFSScanContainer}
          </>
        ) : (
          <LinkWithDisable disabled={!rfidEnabled || !lastFullStoreScan} to={`/scan/${lastFullStoreScan?.value}/${endDate}`} className="scanAccuracyWidget_dashboard-widget">
            {lastFSScanContainer}
          </LinkWithDisable>
        )}
        {isGreaterChinaDoor ? activateTutorials ? (
          <>
            {lastSFScanContainer}
          </>
        ) : (
          <LinkWithDisable disabled={!rfidEnabled || !lastSalesFloorScan} to={`/scan/${lastSalesFloorScan?.value}/${endDate}`} className="scanAccuracyWidget_dashboard-widget">
            {lastSFScanContainer}
          </LinkWithDisable>
        ) : activateTutorials ? (
          <>
            {lastCCScanContainer}
          </>
        ) : (
          <LinkWithDisable disabled={!rfidEnabled || !lastCycleCountScan} to={`/scan/${lastCycleCountScan?.value}/${endDate}`} className="scanAccuracyWidget_dashboard-widget">
            {lastCCScanContainer}
          </LinkWithDisable>
        )}
      </div>
    );
  }, [
    lastCycleCountScan,
    lastFullStoreScan,
    data,
    loading,
    accuracyThreshold,
    getMessage,
    locale,
  ]);

  return (
    <div className={classNames('scanAccuracyWidget_dashboard-card ', `${customClass}`, 'borderStyles')} data-testid="scanAccuracyWidget">
      {scanAccuracyDetails}
    </div>
  );
};

ScanAccuracyWidget.propTypes = {
  customClass: string,
  locale: string,
  data: object,
  loading: bool,
};

export default ScanAccuracyWidget;
