import {
  array, bool, func, object, string,
} from 'prop-types';
import { useContext, useState } from 'react';
import Select from 'react-select';

import Button from '../../components/Button';
import { TranslationsContext } from '../../context/Translations.provider';

const SubmitButton = ({
  auth,
  clear,
  daAdapter,
  disabled,
  devicesAssigned,
  devicesAvailable,
  devicesInSession,
  isLoading,
  selectedAthlete,
  setChangesSubmitted,
  setDevicesAvailable,
  setDevicesAssigned,
  setError,
  setNewChanges,
  setSubmitting,
  setSuccess,
  storeId,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const [assignmentDuration, setAssignmentDuration] = useState({ label: '10 hours', value: 10 });

  const postDeviceSession = (deviceId, employeeNumber, expirationDate) => {
    const postBody = {
      athleteId: selectedAthlete.id,
      athleteLogon: employeeNumber,
      athleteName: selectedAthlete.athleteName,
      deviceId,
      expirationDate,
      status: 'ASSIGNED',
      storeId,
    };
    return daAdapter.postSession({ auth, postBody });
  };

  const putDeviceSession = (sessionId, deviceId, employeeNumber, expirationDate) => {
    const putBody = {
      athleteId: selectedAthlete.id,
      athleteLogon: employeeNumber,
      athleteName: selectedAthlete.athleteName,
      deviceId,
      expirationDate,
      status: 'ASSIGNED',
      storeId,
    };
    return daAdapter.putSession({ auth, sessionId, putBody });
  };

  const deleteDeviceSession = (device) => {
    const { sessionId } = device;
    return daAdapter.deleteSession({ auth, sessionId });
  };

  const getEmployeeNumber = () => {
    const { id: athleteId } = selectedAthlete;
    return daAdapter.getEmployeeNumber({ auth, athleteId });
  };

  const onSubmit = async (assignedDevices, availableDevices, sessionDevices) => {
    clear();
    const assignments = assignedDevices.filter(device => device.isNewlyAssigned);
    const unAssignments = availableDevices.filter(device => device.isNewDeletion)
      .map(device => ({ ...device, sessionId: sessionDevices.find(session => session.deviceId === device.id).id }));

    const sessionDeviceIds = sessionDevices.map(session => session.deviceId);
    const newAssignments = [];
    const reAssignments = [];

    assignments.forEach(device => (sessionDeviceIds.includes(device.id)
      ? reAssignments.push({ ...device, sessionId: sessionDevices.find(s => s.deviceId === device.id).id })
      : newAssignments.push(device)
    ));

    if (newAssignments.length || reAssignments.length || unAssignments.length) {
      setSubmitting(true);

      const employeeNumber = await getEmployeeNumber().catch(() => {
        setError(getMessage('messageEmployeeNumberError'));
        setSubmitting(false);
      });
      if (!employeeNumber) { return null; }

      const submitAssignments = async () => {
        // Set the duration of an assignment to be the configured assignment duration.
        const expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() + assignmentDuration.value);

        return Promise.all([
          newAssignments.map(device => postDeviceSession(device.id, employeeNumber, expirationDate)),
          reAssignments.map(device => putDeviceSession(device.sessionId, device.id, employeeNumber, expirationDate)),
          unAssignments.map(device => deleteDeviceSession(device)),
        ].flat())
          .then(() => {
            setChangesSubmitted([newAssignments, reAssignments, unAssignments]);
            setDevicesAvailable(availableDevices
              .map(device => ({ ...device, isNewDeletion: false, isCurrentlyAssigned: false })));
            setDevicesAssigned(assignedDevices
              .map(device => ({ ...device, isNewlyAssigned: false, isCurrentlyAssigned: true })));
            setNewChanges(false);
            setSuccess(getMessage('assignedDevicesSuccess'));
            setSubmitting(false);
          })
          .catch(() => {
            setError(getMessage('assignedDevicesError'));
            setNewChanges(false);
            setSubmitting(false);
          });
      };
      submitAssignments();
    }
    return null;
  };

  return (
    <>
      {devicesAssigned.filter(device => device.isNewlyAssigned).length !== 0 && (
        <>
          <p data-testid="assignmentDurationLabel" style={{ fontSize: '18px', marginBottom: '-1px', marginTop: '12px' }}>{getMessage('assignmentDuration')}</p>
          <Select
            aria-label="Assignment Duration Select"
            id="assignmentDurationSelect"
            options={Array.from({ length: 24 }, (v, i) => i + 1).map(hour => ({ label: `${hour} hour${hour !== 1 ? 's' : ''}`, value: hour }))}
            styles={{
              container: styles => ({ ...styles, marginBottom: '10px', zIndex: 110 }),
              control: styles => ({ ...styles, width: 390 }),
            }}
            theme={theme => ({ ...theme, colors: { ...theme.colors, primary25: 'lightgray', primary: 'black' } })}
            value={assignmentDuration || null}
            onChange={newOption => setAssignmentDuration(newOption)}
          />
        </>
      )}
      <Button
        data-testid="submit-button"
        buttonText={getMessage('submitChanges')}
        disabled={disabled}
        isLoading={isLoading}
        onClick={() => onSubmit(devicesAssigned, devicesAvailable, devicesInSession)}
        style={{ marginTop: '16px' }}
      />
    </>
  );
};

SubmitButton.propTypes = {
  auth: string.isRequired,
  clear: func.isRequired,
  daAdapter: object.isRequired,
  disabled: bool.isRequired,
  devicesAssigned: array.isRequired,
  devicesAvailable: array.isRequired,
  devicesInSession: array.isRequired,
  isLoading: bool.isRequired,
  selectedAthlete: object.isRequired,
  setChangesSubmitted: func.isRequired,
  setDevicesAvailable: func.isRequired,
  setDevicesAssigned: func.isRequired,
  setError: func.isRequired,
  setNewChanges: func.isRequired,
  setSubmitting: func.isRequired,
  setSuccess: func.isRequired,
  storeId: string.isRequired,
};

export default SubmitButton;
