import classNames from 'classnames';
import { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useMedia } from 'use-media';

// utils
import useApplicationSetup from './hooks/useApplicationSetup';
import useAuth from './hooks/useAuth';

// Components
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import DeviceRotateIcon from './assets/Svgs/DeviceRotateIcon';
import StackedNotifications from './components/userNotifications/StackedNotifications.jsx';

// Pages
import ItemInquiry from './containers/itemInquiry/ItemInquiry';
import BrowserLogin from './containers/login/BrowserLogin.js';
import NavMenu from './containers/navigation/navMenu/NavMenu';
import Postman from './containers/postman/PostmanPage';
import SelfCheckoutDeviceAssignment from './containers/selfCheckoutDeviceAssignment/SelfCheckoutDevice';
import wrappedPages from './wrappedPages';

// contexts
import { SimWebContext } from './context/SimWeb.provider';
import { AdminContext } from './context/Admin.provider';
import ScanTimerProvider from './context/ScanTimer.provider';

import './App.css';
import { BROWSER, LOGIN_SOURCE } from './constants/storageKeys.constants';

export const App = () => {
  const location = useLocation();
  const { isAdmin } = useContext(AdminContext);
  const { getMessage, isIOS, isTrue, storeConfig } = useContext(SimWebContext);

  const { isLoggedIn } = useAuth();

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const isSelfcheckoutForTrue = isTrue && location.pathname === '/selfCheckoutDeviceAssignment';
  const userAgent = navigator?.userAgent;
  const isMobile = userAgent.includes('iPad') || userAgent.includes('iPhone');
  const loginSource = localStorage.getItem(LOGIN_SOURCE);
  const isBrowser = loginSource === BROWSER;

  useApplicationSetup();

  useEffect(() => {
    if (isMobile) document.body.classList.add('mobile');
  }, [isMobile]);

  // CSS Media Queries
  const landscape = useMedia('only screen and (orientation: landscape)');
  sessionStorage.setItem('deviceSize', landscape ? 'lg' : 'sm');

  const reducedAnimation = useMedia('only screen and (prefers-reduced-motion: reduce)');

  const containerClasses = classNames(
    'sim-container',
    { isIOS },
    { landscape },
    { portrait: !landscape },
    { reducedAnimation },
  ); 

  const app = (
    <>
      <StackedNotifications
        isAdmin={isAdmin}
        isScanTimerEnabled={rfidEnabled}
      />
      <div
        className={containerClasses}
        data-testid="sim-containter"
        style={{ paddingTop: '60px' }}
      >
        <div className="iPad-portrait-overlay">
          <DeviceRotateIcon />
          <span className="message">{getMessage('rotateDeviceMessage')}</span>
        </div>
        {isLoggedIn() && !isSelfcheckoutForTrue ? <NavMenu /> : null}
        <div
          className="sim-content"
          id="sim-content"
        >
          <ScrollToTop />
          <Switch>
            <PrivateRoute
              exact
              path="/Adjustments"
              render={() => wrappedPages('adjustments')}
            />
            <PrivateRoute
              exact
              path="/agedOrders"
              render={() => wrappedPages('agedOrders')}
            />
            <PrivateRoute
              exact
              path="/asnTracking"
              render={() => wrappedPages('asnTracking')}
            />
            <PrivateRoute
              exact
              path="/stockroomBin"
              render={() => wrappedPages('binAudit')}
            />
            <PrivateRoute
              exact
              path="/challan"
              render={() => wrappedPages('challan')}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              render={() => wrappedPages('dashboard')}
            />
            <PrivateRoute
              exact
              path="/inboundVisibility"
              render={() => wrappedPages('inboundVisibility')}
            />
            <PrivateRoute
              exact
              path="/itemActivity"
              render={() => wrappedPages('itemActivity')}
            />
            <PrivateRoute
              exact
              path="/RFIDMissingScan"
              render={() => wrappedPages('missingStyles')}
            />
            <PrivateRoute
              exact
              path="/OOSAudits"
              render={() => wrappedPages('oosAudit')}
            />
            <PrivateRoute
              exact
              path="/physicalInventoryUpload"
              render={() => wrappedPages('physicalInventoryUpload')}
            />
            <PrivateRoute
              exact
              path="/priceChanges"
              render={() => wrappedPages('priceChanges')}
            />
            <PrivateRoute
              exact
              path="/productMovement"
              render={() => wrappedPages('productMovement')}
            />
            <PrivateRoute
              exact
              path="/productRefill"
              render={() => wrappedPages('productRefill')}
            />
            <PrivateRoute
              exact
              path="/ReceivingLog/:searchDate?"
              render={() => wrappedPages('receivingLog')}
            />
            <PrivateRoute
              exact
              path="/scanSummary"
              render={() => wrappedPages('scanReportSummary')}
            />
            <PrivateRoute
              path="/displayScan/:sessionId"
              render={() => wrappedPages('displayScan')}
            />
            <PrivateRoute
              path="/scan/:sessionId/:endDate"
              render={() => wrappedPages('scan')}
            />
            {isAdmin && (
              <PrivateRoute
                path="/scanV2/:sessionId/:endDate"
                render={() => wrappedPages('scanDetails')}
              />
            )}
            <PrivateRoute
              exact
              path="/sizeCompliance"
              render={() => wrappedPages('sizeCompliance')}
            />
            <PrivateRoute
              exact
              path="/StockOnHand"
              render={() => wrappedPages('stockOnHand')}
            />
            <PrivateRoute
              exact
              path="/stockroomOrganization"
              render={() => wrappedPages('stockroomOrganization')}
            />
            <PrivateRoute
              exact
              path="/UPHReport"
              render={() => wrappedPages('storePerformance')}
            />
            <PrivateRoute
              exact
              path="/styleManagement"
              render={() => wrappedPages('styleManagement')}
            />
            <PrivateRoute
              exact
              path="/transfers"
              render={() => wrappedPages('transfers')}
            />

            {/* old format pages */}
            <PrivateRoute
              component={ItemInquiry}
              exact
              path="/itemInquiry/:searchValue?"
            />
            <PrivateRoute
              component={SelfCheckoutDeviceAssignment}
              exact
              path="/selfCheckoutDeviceAssignment"
            />
            {isAdmin && (
              <PrivateRoute
                component={Postman}
                exact
                path="/postman"
              />
            )}
            <Route
              component={BrowserLogin}
              exact
              path="/login"
            />
            {loginSource && (
              <Route
                path="/"
                render={() =>
                  !isLoggedIn() && isBrowser ? (
                    <Redirect to='/login' />
                  ) : (
                    <Redirect to='/dashboard' />
                  )
                }
              />
            )}
          </Switch>
        </div>
      </div>
    </>
  );

  return rfidEnabled ? <ScanTimerProvider>{app}</ScanTimerProvider> : app;
};

export default App;
