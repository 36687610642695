import {
  useContext, useMemo,
} from 'react';
import {
  func, string, array, number,
} from 'prop-types';

import { DialogContent } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import DialogHeader from '../../components/dialogHeader/DialogHeader';
import { baseDetailsTableOptions } from '../baseView/baseViewPage.utils';

import { SimWebContext } from '../../context/SimWeb.provider';

const AgedOrdersDialog = ({
  setOpenModal,
  rows = [],
  orderId = '',
  setIndex,
  index,
}) => {
  const { getMessage } = useContext(SimWebContext);

  const dialogTitle = useMemo(() => (
    <strong>{`${'Order ID'}: ${orderId}`}</strong> // getMessage('orderId')
  ), [orderId]);

  const data = useMemo(() => rows?.[index]?.items.map(item => ({
    bins: item?.bins?.map(bin => bin?.name)?.join(', '),
    gtin: item?.gtin,
    styleColor: `${item?.itemDetails?.style}-${item?.itemDetails?.color}`,
    description: item?.itemDetails?.name,
    size: item?.itemDetails?.size,
    quantity: item.quantityRequested,
  })), [rows, index]);

  const options = baseDetailsTableOptions(getMessage);
  options.rowsPerPage = 15;
  options.tableBodyMaxHeight = '310px';

  const columns = [{
    name: 'gtin',
    label: getMessage('upc'),
  },
  {
    name: 'bins',
    label: getMessage('bins'),
  },
  {
    name: 'styleColor',
    label: getMessage('styleColor'),
  },
  {
    name: 'description',
    label: getMessage('description'),
  },
  {
    name: 'size',
    label: getMessage('size'),
  },
  {
    name: 'quantity',
    label: getMessage('count'),
  },
  ];

  const content = useMemo(() => (
    <>
      <DialogHeader
        index={index}
        setIsOpen={setOpenModal}
        rowCount={rows?.length}
        setIndex={setIndex}
        nodeTitle={dialogTitle}
      />
      <DialogContent>
        <h3><strong className="">{`${getMessage('items')}`}</strong></h3>
        <MUIDataTable
          className="MUIDataTable"
          data={data}
          columns={columns}
          options={options}
        />
      </DialogContent>
    </>
  ), [index, rows, orderId]);

  return (
    <div data-testid="price-changes-dialog-data-table">
      {content}
    </div>
  );
};

AgedOrdersDialog.propTypes = {
  setOpenModal: func.isRequired,
  orderId: string,
  setIndex: func.isRequired,
  index: number.isRequired,
  rows: array,
};

export default AgedOrdersDialog;
