/**
 * A helper function to get the center text string for the donut chart
 * @param {array} data the chart dataset
 * @param {string} chartType the report type. e.g. 'compliance'
 * @param {string} unit the unit label. e.g. 'units' or 'styles'
 * @param {boolean} isEmptyChart is the chart has no data
 */
export const getCenterTextString = (data, chartType, unit, isEmptyChart) => {
  if (!data) return '';

  let centerTextString = '';

  if (chartType === 'compliance') {
    centerTextString = `${data?.[0] || 0}%`;
  } else {
    centerTextString = `${!isEmptyChart ? data?.reduce((acc, curr) => acc + curr, 0) : 0} ${unit}`;
  }

  return centerTextString;
};
