/* eslint-disable no-useless-catch */
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';

/**
   * Gets the style management report data
   * @param {String} storeId the id of the store being reported on
   * @param {String} store the store country and number - e.g. 'USA-128'
   * @param {string} region region for axios call
   * @param {Array} filters describes how the records needs to be filtered.
   * @param {String} merchGroup merch group
   * @param {boolean} isEMEA is store's geo location in EMEA
   * @param {boolean} eddEnabled is store has EDD enabled
*/
export const getStyleManagementData = async (payload) => {
  try {
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.STYLE_MANAGEMENT.url}`;
    const stylemnaagementData = await generalAxiosRequest('POST', url, endpoints.STYLE_MANAGEMENT, true, null, payload);
    return stylemnaagementData;
  } catch (error) {
    throw error;
  }
};
