import { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CustomSelect from './CustomSelect';
import { COUNTRY, STORE_NUMBER, SHOW_TOAST, LANGUAGE, STORE_ID } from '../../constants/storageKeys.constants';
import useSimStore from '../../store/simStore';
import useTranslator from '../../hooks/useTranslator';

import './CustomSelect.css';
import { fetchStoreInfo, fetchAllStores } from '../../axios/fetchStoreSettings';
import { fetchTranslations } from '../../axios/fetchTranslations';
import { createCountryOptions, filterStoresByCountry } from './storeAndCountryDropdown.utils';

/**
 * Gets the params from either local storage or url.  If neither exist, returns an empty array.
 * To get params from url, the function is expecting the following query format: ?store=usa-128
 *
 * @returns - array of the country and store number or an empty array
 */
const getDefaults = () => {
  // get store number from query parameter
  const params = window.location.search?.split('=').pop();
  const store = (params && params.split('-'))
    || [localStorage.getItem(COUNTRY), localStorage.getItem(STORE_NUMBER)];

  if (store.length === 2) {
    return { country: store[0]?.toUpperCase(), storeNumber: store[1] };
  }
  return { country: '', storeNumber: '' };
};

/**
 * Creates dropdowns for selecting country and store number.
 * @returns - dropdowns for selecting country and store number
 */
const StoreAndCountryDropdown = () => {
  const defaults = getDefaults();
  const setTranslations = useSimStore(state => state.setTranslations);
  const setStoreInfo = useSimStore(state => state.setStoreInfo);
  const addError = useSimStore(state => state.addError);

  const language = useSimStore(state => state.language);
  const storeInfoId = useSimStore(state => state.storeInfo?.id);

  const [storesList, setStoresList] = useState([]);
  const [country, setCountry] = useState(defaults.country);
  const [storeNumber, setStoreNumber] = useState(defaults.storeNumber);

  const location = useLocation();
  const { getMessage } = useTranslator();
  const onLoginPage = location.pathname === '/login';

  const fetchTranslationsData = async () => {
    setTranslations(await fetchTranslations());
  }

  const fetchStoreData = async () => {
    setStoreInfo(await fetchStoreInfo());
    if (onLoginPage) {
     fetchTranslationsData();
    }
  }
  
  // Call translations to be set on load
  useEffect(() => {
    if (!language) {
      fetchTranslationsData();
    }
  }, []);

  // Fetches all of the stores for the dropdown boxes on load
  useEffect(() => {
    const callFetchAllStores = async () => {
      const {storesList, error} = await fetchAllStores();
      setStoresList(storesList);

      if (error) {
        addError(error);
      }
    }
    if (storesList?.length <= 0) {
      callFetchAllStores();
    }
  }, []);

  // Fetches the store info when the store number changes if there is a country and if the id is different than what is stored.
  useEffect(() => {
    if (onLoginPage && country && storeNumber) {
      fetchStoreData();
    }
    if (!onLoginPage && storeNumber && storeInfoId !== localStorage.getItem(STORE_ID)) {
      fetchStoreData();
    }
  }, [storeNumber]);

  // Create the country dropdown
  const countriesSelect = useMemo(() => {
    const countriesList = createCountryOptions(storesList)
    if (!countriesList?.length > 0) return null;

    const defaultValue = countriesList.filter(option => option.value === country);

    return (
      <CustomSelect
        label={`${getMessage('country')}`}
        options={countriesList}
        defaultValue={defaultValue}
        customClass={!onLoginPage ? 'flex-row' : ''}
        customLabelClass="select-label"
        customSelectClass="country-select"
        onChange={(event) => {
          setStoreNumber('');
          setCountry(event.value);
          localStorage.setItem(COUNTRY, event.value.toUpperCase());
          localStorage.removeItem(STORE_NUMBER);
          localStorage.removeItem(STORE_ID);
        }}
      />
    );
  }, [storesList, language]);

  // Create the store number dropdown based on the selected country
  const storeNumberSelect = useMemo(() => {
    const storeNumbersList = filterStoresByCountry(storesList, country);

    if (!storeNumbersList?.length > 0) return null;
    const defaultValue = !country ? null : storeNumbersList.filter(option => option.label === storeNumber);

    return (
      <CustomSelect
        label={`${getMessage('storeNumber')}`}
        options={storeNumbersList}
        defaultValue={defaultValue}
        clearDefault={!storeNumber}
        customClass={!onLoginPage ? 'flex-row' : ''}
        customLabelClass="select-label"
        customSelectClass="store-number-select"
        onChange={async (event) => {
          localStorage.setItem(STORE_ID, event.storeId);
          localStorage.setItem(STORE_NUMBER, event.label);
          localStorage.setItem(LANGUAGE, event.language);
          setStoreNumber(event.value);

          if (!onLoginPage) {
            sessionStorage.setItem(SHOW_TOAST, true);
          }
        }}
      />
    );
  }, [storesList, country, storeNumber, language]);

  return (
    <>
      {countriesSelect}
      {storeNumberSelect}
    </>
  );
};

export default StoreAndCountryDropdown;
