import { parseAsInt } from '../../utils/sort';

const options = { month: 'short', day: 'numeric', year: 'numeric' };
/**
  * Given an [] of dates we find the dates and a date {} we check if the date value exists
  * @param {array} newArray The new array of scan dates
  * @param {object} dateObject The next date obj in the original array that we are compairing
  * @returns {boolean} checking if the date exists in the Array
*/
export const dateExists = (newArray, date) => newArray.some(dateValue => dateValue.value === date);

/**
   * Given an [] of dates we find the dates and a date {} we check if the date value exists and reassign it
   * @param {array} newArray The new array of scan dates
   * @param {object} dateObject The next date obj in the original array that we are compairing
   * @returns {array} An array of date objs storing the highest accuracyTotal & calculating the totalScansCompleted
*/
export const updateDateValueInArray = (newArray, dateObject) => {
  const foundIndex = newArray.findIndex(date => date.value === dateObject.value);
  dateObject.totalScansCompleted += 1;
  newArray[foundIndex] = dateObject;
  return newArray;
};

/**
   * If given an [] of dates we look for the dates with no scans completed & create a new date {} for that date
   * @param {array} arr The array of scan dates
   * @param {string} locale is the locale in which we use to modify the date value
   * @param {string} endDate The date value we are calling the service with and extracting here as well
   * @returns {array} The new array of date objects sorted
*/
export const getMissingDates = (arr, locale) => {
  const newDate = new Date();
  const lastDate = new Date(newDate.setDate(newDate.getDate()));
  let firstDate = new Date(new Date(lastDate).setDate(lastDate.getDate() - 27));

  const missingDates = [];
  let obj = {};
  const newArr = [...arr];

  // checking if the scan date exists
  const scanDateExists = lastScan => (
    newArr && newArr.some(scan => scan.value === new Date(lastScan).toLocaleDateString('en-us', options)));

  // mapping over data set to fill in the data {}'s with data for dates with no scan data
  while (firstDate <= lastDate) {
    if (!scanDateExists(firstDate)) {
      // creating a date record with zero completed scans
      obj.scanType = 'incomplete';
      obj.value = new Date(firstDate).toLocaleDateString('en-us', options);
      obj.aggregations = [];
      obj.accuracyTotal = '-';
      obj.dayOfWeek = new Date(firstDate.setDate(firstDate.getDate())).toLocaleDateString(locale, { weekday: 'short' });
      obj.totalScansCompleted = 0;
      obj.scanStatus = {};
      missingDates.push(obj);
      obj = {};
      firstDate = new Date(firstDate.setDate(firstDate.getDate() + 1));
    }
    if (scanDateExists(firstDate)) {
      firstDate = new Date(firstDate.setDate(firstDate.getDate() + 1));
    }
  }
  // @ts-ignore - sorting array once again
  const arrayOfDates = newArr.concat(missingDates).sort((a, b) => new Date(a.value) - new Date(b.value));
  return arrayOfDates;
};

/**
   * When given an array of scan data we want to remove duplicate scan dates only storing
   * the dates with the highest scan accuracy
   * @param {array} arr The array of scan dates
   * @param {string} locale we use this val to modify the date
   * @param {string} rfidFullStoreScanDate tells us the required day of the week to complete a full store scan
   * @returns {array} The sorted array with no duplicate values
*/
const removeDupes = (arr, locale, rfidFullStoreScanDate) => {
  const newArray = [];
  const arrayCopy = [];
  let completedArrayOfScanData = [];

  if (arr !== undefined && arr !== null) {
    // changing the date value and getting the day of the week
     
    arr.length && arr.map((singleDate) => {
      const originalDate = new Date(singleDate.eventDateTime).toLocaleDateString('en-us', options);
      const createDateValue = new Date(originalDate);
      const getDateValue = new Date(createDateValue.setDate(createDateValue.getDate()));
      const newISODate = new Date(new Date(getDateValue).setDate(getDateValue.getDate()));
      const dayOfWeek = new Date(newISODate.setDate(newISODate.getDate()))
        .toLocaleDateString(locale, { weekday: 'short' });
      arrayCopy.push({
        ...singleDate,
        dayOfWeek,
        totalScansCompleted: 1,
        value: originalDate,
      });
    });
    for (let a = 0; a <= arrayCopy.length - 1; a += 1) {
      for (let b = a + 1; b < arrayCopy.length; b += 1) {
        const firstAccuracyTotal = parseAsInt(arrayCopy[a].accuracyTotal);
        const secondAccuracyTotal = parseAsInt(arrayCopy[b].accuracyTotal);
        // value doesnt exist & 2 dates are different
        if (arrayCopy[a].value !== arrayCopy[b].value && !dateExists(newArray, arrayCopy[a].value)) {
          newArray.push(arrayCopy[a]);
        }
        // value doesnt exist & the two dates are the same
        if (arrayCopy[a].value === arrayCopy[b].value && !dateExists(newArray, arrayCopy[a].value)) {
          if (arrayCopy[a].scanType === 'FULL_STORE_SCAN' || arrayCopy[a].scanType === 'FILTERED_FULL_STORE_SCAN') {
            newArray.push(arrayCopy[a]);
          } else {
             
            (firstAccuracyTotal > secondAccuracyTotal) ? newArray.push(arrayCopy[a]) : newArray.push(arrayCopy[b]);
          }
        }
        // value exists & the dates are the same
        if (arrayCopy[a].value === arrayCopy[b].value && dateExists(newArray, arrayCopy[a].value)) {
          // we want to save the FS scan preformed on the rfidFullStoreScanDate date
          if (arrayCopy[b].dayOfWeek === rfidFullStoreScanDate) {
            if (arrayCopy[a].scanType !== 'FULL_STORE_SCAN' && arrayCopy[b].scanType === 'FULL_STORE_SCAN') {
              updateDateValueInArray(newArray, arrayCopy[b]);
            }
            // we want to save the FS Scan with the highest accuracy total
            if (arrayCopy[a].scanType === 'FULL_STORE_SCAN' && arrayCopy[b].scanType === 'FULL_STORE_SCAN'
                && secondAccuracyTotal > firstAccuracyTotal) {
              updateDateValueInArray(newArray, arrayCopy[b]);
            }
          }
          // generally saving the scan with the highest accuracy total
          if (arrayCopy[b].dayOfWeek !== rfidFullStoreScanDate) {
            if (arrayCopy[a].scanType === 'SALES_FLOOR_SCAN' && (arrayCopy[b].scanType === 'FILTERED_FULL_STORE_SCAN' || arrayCopy[b].scanType === 'FULL_STORE_SCAN')) {
              updateDateValueInArray(newArray, arrayCopy[b]);
            } else if (arrayCopy[a].scanType === 'FILTERED_FULL_STORE_SCAN' && arrayCopy[b].scanType === 'FULL_STORE_SCAN') {
              updateDateValueInArray(newArray, arrayCopy[b]);
            } else if (secondAccuracyTotal > firstAccuracyTotal) {
              updateDateValueInArray(newArray, arrayCopy[b]);
            }
          }
        }
      }
      // if single item in arr push the single scan
      if (arrayCopy.length === 1) {
        newArray.push(arrayCopy[a]);
      }
    }
    completedArrayOfScanData = getMissingDates(newArray, locale);
    // only return 28 days every time
    while (completedArrayOfScanData.length >= 29) {
      completedArrayOfScanData.shift();
    }
  }
  return completedArrayOfScanData;
};

export default removeDupes;
