import { func, object, string } from 'prop-types';
import { Grid2, Box, Typography } from '@mui/material';
import getFormattedPrice from '../../../../../utils/getFormattedPrice';

const EstimatedVariancesWidget = ({
  getMessage,
  variances = null,
  locale = 'en-US',
  currencyCode = 'USD'
}) => (
  <Grid2 container data-testid="estimated-variances-widget-container" direction="column" style={{ padding: '10px', margin: '20px 0' }}>
    <Grid2 container justifyContent="space-evenly">
      <Typography variant="h5"><strong>{getMessage('missing')}</strong></Typography>
      <Typography variant="h5"><strong>{getMessage('extra')}</strong></Typography>
    </Grid2>
    <Box className="widget" style={{ padding: '10px', margin: '0 20px 30px' }}>
      <Grid2 container justifyContent="space-evenly">
        <Grid2>
          <Grid2 container direction="column">
            <Typography>{getMessage('value')}</Typography>
            <Typography data-testid="estimated-variances-missing-value">
              {getFormattedPrice(variances?.missing?.value, locale, currencyCode)}
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2>
          <Grid2 container direction="column">
            <Typography>{getMessage('units')}</Typography>
            <Typography data-testid="estimated-variances-missing-units">{variances?.missing?.units || '-'}</Typography>
          </Grid2>
        </Grid2>
        <Box sx={{ borderLeft: 1, borderColor: 'white', height: 'auto' }} />
        <Grid2>
          <Grid2 container direction="column">
            <Typography>{getMessage('value')}</Typography>
            <Typography data-testid="estimated-variances-extra-value">
              {getFormattedPrice(variances?.extra?.value, locale, currencyCode)}
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2>
          <Grid2 container direction="column">
            <Typography>{getMessage('units')}</Typography>
            <Typography data-testid="estimated-variances-extra-units">{variances?.extra?.units || '-'}</Typography>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
    <Box component="p" sx={{ color: '#5f5f5f' }}>
      <Typography>{getMessage('estimatedVarianceNote')}</Typography>
    </Box>
  </Grid2>
);

EstimatedVariancesWidget.propTypes = {
  getMessage: func.isRequired,
  variances: object,
  currencySymbol: string,
  locale: string,
  currencyCode: string,
};

export default EstimatedVariancesWidget;
