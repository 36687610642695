import {
  string, bool, shape, func, number, instanceOf,
} from 'prop-types';
import DateRangeWithOptions from '../../../components/date-range/DateRangeWithOptions';

const DatePickerComponent = ({ id, loading, datepicker }) => {
  if (datepicker) {
    const {
      startDate, endDate, handleDateChange, onGoClick, isOutsideRange, tooManyDaysSelected, maxSearchableDays,
    } = datepicker;

    return (
      <div className="date-picker-block" data-testid={`${id}-datepicker`}>
        <DateRangeWithOptions
          optionsLabel="View"
          start={startDate}
          end={endDate}
          onClick={onGoClick}
          onDateChange={handleDateChange}
          isOutsideRange={isOutsideRange}
          disabled={loading}
          tooManyDaysSelected={tooManyDaysSelected}
          maxSearchableDays={maxSearchableDays}
        />
      </div>
    );
  }
  return null;
};

DatePickerComponent.propTypes = {
  id: string.isRequired,
  loading: bool,
  datepicker: shape({
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
    handleDateChange: func,
    onGoClick: func,
    isOutsideRange: func,
    tooManyDaysSelected: bool,
    maxSearchableDays: number,
  }),
};

export default DatePickerComponent;
