import {
  string, any, object, number,
} from 'prop-types';
import Alert from './Alert';

/**
 * Component for building alerts and error messages
 * @param {string} apiName - string for originating api call
 * @param {string} pageCode - string for originating page
 * @param {object} errorObject - object containing the errorCode and errorMessage
 * @param {object} jsonInfo - object containg code or a json object that cane be rendered in a code block
 * @param {string} customMessage - string custom string rendered if severity is an error
 * @param {string} iconSize -size of icon to render
 * @param {string} fontSize -size of icon to render
 */
const ErrorAlert = ({
  apiName,
  pageCode = null,
  errorObject = null,
  jsonInfo = null,
  customMessage = null,
  iconSize = null,
  fontSize = null,
}) => (
  <div>
    <Alert
      apiName={apiName}
      severity="error"
      pageCode={pageCode}
      errorObject={errorObject}
      additionalInfo={jsonInfo}
      customMessage={customMessage}
      iconSize={iconSize}
      fontSize={fontSize}
    />
  </div>
);

ErrorAlert.propTypes = {
  errorObject: any,
  apiName: string.isRequired,
  customMessage: string,
  jsonInfo: object,
  pageCode: any,
  iconSize: string || number,
  fontSize: string || number,
};

export default ErrorAlert;
