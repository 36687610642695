import { useMemo, useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { isEmpty } from 'lodash';
import ReceivingLogSummaryTable from '../../receivingLogSummaryTable/ReceivingLogSummaryTable';
import { Chevron, LoadingIndicator } from '../../../assets/Svgs/index';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { ReceivingLogContext } from '../../../containers/receivingLog/ReceivingLog.provider';

import mockedReceivingLogSummaryData from '../../tutorial/mockTutorialData/dashboard/receiving-log-data.json';

import './ReceivingLogWidget.css';
import { useTutorial } from '../../tutorial/useTutorial';

/**
  * Creates a widget with data visuals for the Receiving Log
*/
const ReceivingLogWidget = () => {
  const {
    summary, isFetchingSummary, error, onGoClick,
  } = useContext(ReceivingLogContext);
  const { getMessage, SimDateTime } = useContext(SimWebContext);
  const { tutorial, activateTutorials } = useTutorial('receivingAndRefill');

  const [daysBacked, setDaysBacked] = useState(0);
  const [date, setDate] = useState(SimDateTime.now());

  const handleDateChange = (direction) => {
    const newDaysBacked = daysBacked + direction;
    const startDate = SimDateTime.startOfDay().minus({ days: newDaysBacked });
    const endDate = SimDateTime.endOfDay().minus({ days: newDaysBacked });
    setDaysBacked(newDaysBacked);
    setDate(startDate);
    onGoClick({
      startDate,
      endDate,
      selectedOption: 'MISSING',
    });
  };

  const recevingLogWidgetDetails = useMemo(() => {
    const noData = (
      <div className="receivingLogWidget_card-error" data-testid="errorContainer_emptyData">
        <ErrorOutline color="error" fontSize="large" />
        <br />
        <p>{getMessage('noResults')}</p>
      </div>
    );

    if (!activateTutorials && isFetchingSummary) {
      return (<div className="receivingLogWidget_card-loading"><LoadingIndicator height="100px" width="100px" fill="#FFFFFF" /></div>);
    }

    if (!activateTutorials && (!summary || error)) {
      return (
        <div className="receivingLogWidget_card-error" data-testid="errorContainer">
          <ErrorOutline color="error" fontSize="large" />
          <br />
          <p>{getMessage('fatalError')}</p>
        </div>
      );
    }

    if (!activateTutorials && isEmpty(summary)) {
      return noData;
    }

    const data = activateTutorials ? mockedReceivingLogSummaryData : summary;

    return (
      <Link
        to={`/ReceivingLog/${date}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
        className="receivingLogWidget_dashboard-link"
      >
        <ReceivingLogSummaryTable data={data} />
      </Link>
    );
  }, [summary, getMessage, isFetchingSummary, activateTutorials]);

  const recievingLogCardData = (
    <div className="receiving-log-widget-container">
      <p className="receiving-log-widget-title">{`${getMessage('receiving')}`}</p>
      <div className="receiving-log-widget-dates-navigation" data-testid="receiving-log-widget-dates-navigation">
        <button
          className="receiving-log-widget-previous-date"
          data-testid="receiving-log-widget-previous-date"
          type="button"
          onClick={() => handleDateChange(1)}
          disabled={isFetchingSummary || daysBacked === 2}
          aria-label="Receiving Log Previous Date"
        >
          <Chevron fill={isFetchingSummary || daysBacked === 2 ? '#979698' : 'white'} />
        </button>
        <p>{date.toISODate()}</p>
        <button
          className="receiving-log-widget-next-date"
          data-testid="receiving-log-widget-next-date"
          type="button"
          onClick={() => handleDateChange(-1)}
          disabled={isFetchingSummary || daysBacked === 0}
          aria-label="Receiving Log Next Date"
        >
          <Chevron position="right" fill={isFetchingSummary || daysBacked === 0 ? '#979698' : 'white'} />
        </button>
      </div>
      <div className="receiving-log-widget-data-container" data-testid="receiving-log-widget-data-container">
        {recevingLogWidgetDetails}
      </div>
    </div>
  );

  return activateTutorials ? (
    <Card className="receivingLogWidget_dashboard-card" elevation={8} data-testid="receivingLogWidget-tutorial">
      <div style={{ color: 'white', paddingLeft: '20px', paddingTop: '10px' }}>
        {activateTutorials && tutorial}
      </div>
      <CardContent>
        {recievingLogCardData}
      </CardContent>
    </Card>
  ) : (
    <Card className="receivingLogWidget_dashboard-card" elevation={8} data-testid="receivingLogWidget">
      <CardContent>
        {recievingLogCardData}
      </CardContent>
    </Card>
  );
};

export default ReceivingLogWidget;
