import { isLoggedIn } from './legacy.auth';

export const logout = () => {
  window?.webkit?.messageHandlers?.logout?.postMessage('');
  sessionStorage.clear();
};

export const login = (history) => {
  window.webkit.messageHandlers.getAccessData.postMessage('');

  const maxRetries = 3;
  let attempts = 0;
  const queryString = window.location.search?.split('=').pop();

  if (queryString) {
    localStorage.setItem('IOS_SOURCE_QUERY', queryString);
  }
  
  if (!isLoggedIn() && attempts < maxRetries) {
    setTimeout(window.location.reload(), 3000);
    attempts++;
  }

  if (isLoggedIn()) {
    history.push('/dashboard');
  }
};
