import { bool } from 'prop-types';
import Stack from '@mui/material/Stack';

import SeamlessLoginNotification from './SeamlessLoginNotification';
import ScanNotification from './ScanNotification';
import ErrorQueue from './ErrorQueue';

const StackedNotifications = ({
  isScanTimerEnabled = false,
  isAdmin = false,
}) => {
  const page = window.location.href.split('/')[3];

  return (
    <Stack spacing={1} sx={{ maxWidth: '500px' }}>
      { isAdmin && <SeamlessLoginNotification />}
      { page !== 'login' && isScanTimerEnabled && <ScanNotification /> }
      <ErrorQueue />
    </Stack>
  );
}

StackedNotifications.propTypes = {
  isScanTimerEnabled: bool,
  isAdmin: bool,
};

export default StackedNotifications;
