export const physicalInventoryUploadSteps = [
  {
    content: 'piUploadStep1',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="dragDropFileUploader-dropzone"]',
    title: 'piUploadStep1Title',
  },
  {
    content: 'piUploadStep2',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="previewStatusContainer-0"]',
    title: 'piUploadStep2Title',
  },
  {
    content: 'piUploadStep3',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'left',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="success-message-Upload-Completed"]',
    title: 'piUploadStep3Title',
  },
  {
    content: 'piUploadStep4',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="physical-inventory-upload-summary-box"]',
    title: 'piUploadStep4Title',
  },
  {
    content: 'piUploadStep5',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="physical-inventory-upload-submit-button"]',
    title: 'piUploadStep5Title',
  },
  {
    content: 'piUploadStep6',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="physical-inventory-upload-cancel-button"]',
    title: 'piUploadStep6Title',
  },
  {
    content: 'piUploadStep7',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: false,
    spotlightPadding: 10,
    target: '[data-testid="pi-upload-view-button"]',
    title: 'piUploadStep7Title',
  },
  {
    content: 'piUploadStep8',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: 'table.MuiTable-root',
    title: 'piUploadStep8Title',
  },
  {
    content: 'inboundVisibilityS8',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'inboundVisibilityS8Title',
  },
  {
    content: 'inboundVisibilityS9',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="columnStep"]',
    title: 'stockRoomOrgS2Title',
  },
];
