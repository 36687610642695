import { isEmpty } from 'lodash';
import { getAggregationText } from '../../utils';
import { scanTypes } from '../../scanReportConstants';

/**
 * A helper function to map through each scan aggregation and return the list
 * @param {object} additionalText an object of aggregations values and represented text colors
 */
export const getAggregationComponents = (additionalText) => {
  const additionalTextList = additionalText.map(aggregate => (
    <span style={{ color: aggregate.color }} key={aggregate.color}>
      {aggregate.name}
    </span>
  ));

  return (
    <div className="facet-additional-text">
      {additionalTextList}
    </div>
  );
};

/**
 * A helper function to get the corresponding aggregation if the facet group is 'missing' or 'extra'
 * @param {string} facetGroup the name of the facet group
 * @param {array} aggregations the list of aggregations of the filter
 * @param {string} locale the lanuage tag
 */
export const getMissingOrExtraAggregation = (facetGroup, aggregations) => {
  const mapping = {
    missingCountRange: {
      name: 'missing',
      color: '#c05850',
    },
    extraCountRange: {
      name: 'extra',
      color: '#d0b487',
    },
  };
  const agg = aggregations?.find(_ => _.field === mapping[facetGroup].name);
  const value = agg?.value ? `(${agg.value})` : '-';
  return [{ name: value, color: mapping[facetGroup].color }];
};

/**
 * Get the aggregation values and return with color coldes
 * @param {array} aggregations a list of scan aggregations
 */
export const getAggregations = (aggregations) => {
  const scanned = aggregations?.find(_ => _.field === 'scanned');
  const expected = aggregations?.find(_ => _.field === 'expected');
  const missing = aggregations?.find(_ => _.field === 'missing');
  const extra = aggregations?.find(_ => _.field === 'extra');
  const accuracy = aggregations?.find(_ => _.field === 'accuracy');

  const values = {
    scanned: scanned?.value || 0,
    expected: expected?.value || 0,
    missing: missing?.value || 0,
    extra: extra?.value || 0,
    accuracy: accuracy ? `${accuracy?.value}%` : '-',
  };

  return getAggregationText(values);
};

/**
 * A helper function to get the aggregations by facet group
 * @param {string} facetGroup the name of the facet group
 * @param {array} aggregations the list of aggregations of the filter
 */
export const getFacetAdditionalText = (facetGroup, aggregations) => {
  if (facetGroup === 'division' || facetGroup === 'retailCategory') return getAggregations(aggregations);
  return getMissingOrExtraAggregation(facetGroup, aggregations);
};

/**
 * A helper function to replace a string from supportedFilters to a label that use on facets
 * @param {string} label the facet label
 */
export const replaceFacetsLabel = (label) => {
  if (!label) return '';
  return label.replace(scanTypes.CycleCount, 'CYCLE COUNT SCAN')
    .replace(scanTypes.FullStore, 'FULLSTORE SCAN')
    .replace(scanTypes.SalesFloor, 'SALESFLOOR SCAN')
    .replace(scanTypes.Offsite, 'OFFSITE SCAN')
    .replace('FOOTWEAR DIVISION', 'FOOTWEAR')
    .replace('1_TO_19', '1-19')
    .replace('20_TO_39', '20-39')
    .replace('40_TO_59', '40-59')
    .replace('60_TO_79', '60-79')
    .replace('80_TO_99', '80-99')
    .replace('100_TO_500000', '100+');
};

/**
 * A helper function to build an object from supportedFilters to used for facets
 * @param {array} supportedFilters the filtered object of the scan data
 */
export const buildFacetsData = (supportedFilters) => {
  const facets = {};
  const facetGroupLabels = {
    division: 'division',
    missingCountRange: 'missingCount',
    extraCountRange: 'extraCount',
    retailCategory: 'category',
  };

  supportedFilters?.forEach((filter) => {
    const facetGroup = filter.name;
    if (Object.keys(facetGroupLabels).includes(facetGroup)) {
      facets[facetGroup] = {
        name: facetGroup,
        label: facetGroupLabels[facetGroup], // map labels
        values: [],
      };
      filter?.values.forEach(value => {
        if (value) {
          const facetName = value.value;
          facets[facetGroup].values.push({
            name: facetName,
            label: replaceFacetsLabel(facetName),
            selected: value.selected || value.selected === 'true',
            additionalText: getFacetAdditionalText(facetGroup, value.aggregations),
          });
        }
      });
    }
  });

  return facets;
};

/**
 * A helper function to add or remove filter when a facet is selected
 * @param {object} facet the facet selected
 * @param {string} groupName the facet group name of the facet selected
 * @param {array} filters the filters used in scan report request
 */
export const updateFilters = (facet, groupName, filters) => {
  if (!facet || !groupName) return filters;

  const filtersCopy = [...filters];
  const isFacetChecked = facet.selected;

  if (!isFacetChecked) {
    filtersCopy.push({
      name: groupName,
      operator: 'EQUALS',
      value: facet.name,
    });
  } else {
    filtersCopy.splice(filtersCopy.findIndex(_ => _.value === facet.name && _.name === groupName), 1);
  }

  return filtersCopy;
};

/**
 * A helper function to return a list of facet groups to show based on the facets selected
 * @param {object} facetData the re-structured supportedFilters data used on facets
 */
export const getFacetGroupsToShow = (facetData) => {
  const selectedFacets = {};
  Object.keys(facetData).forEach(groupName => {
    const selected = facetData[groupName].values.filter(_ => _.selected === true);
    if (!isEmpty(selected)) selectedFacets[groupName] = selected;
  });

  if ('retailCategory' in selectedFacets || 'extraCountRange' in selectedFacets || 'missingCountRange' in selectedFacets) {
    return ['division', 'missingCountRange', 'extraCountRange', 'retailCategory'];
  }
  return ['division', 'missingCountRange', 'extraCountRange'];
};
