import { useContext } from 'react';
import { Box } from '@mui/material';
import Search from '../../../components/search/Search';
import searchTooltipContents from './search-tooltip-contents.json';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { InboundVisibilityContext } from '../InboundVisibility.provider';

import './InboundVisibilitySearch.css';

const proNumber = {
  name: 'proNumber',
  translate: true,
  value: 'PP01151492',
};
const docNumber = {
  name: 'docNumber',
  translate: true,
  value: 'PP01151492',
};
const poNumber = {
  name: 'poNumber',
  translate: true,
  value: '7556838687',
};
const stoPoNumber = {
  name: 'stoPoNumber',
  translate: true,
  value: '7556838687',
};
const deliveryNumber = {
  name: 'Delivery Number',
  translate: false,
  value: '1277167748',
};

const InboundVisibilitySearch = () => {
  const { country, getMessage } = useContext(SimWebContext);
  const {
    searchText, setSearchText, setPageSearchText, setFacets,
  } = useContext(InboundVisibilityContext);

  const updatedSearchTooltipContents = (country === 'USA')
    ? {
      proNumber, stoPoNumber, deliveryNumber, ...searchTooltipContents,
    }
    : { docNumber, poNumber, ...searchTooltipContents };

  return (
    <div className="activeFlex">
      <Box className="inbound-visibility-search" data-testid="inbound-visibility-search">
        <div style={{ padding: '20px' }}>
          <p style={{ marginBottom: '0px' }}>{getMessage('search')}</p>
          <Search
            type="inbound-visibility"
            searchTooltipContents={updatedSearchTooltipContents}
            searchText={searchText}
            setSearchTextValue={setSearchText}
            setPageSearchTextValue={setPageSearchText}
            resetFacets={setFacets}
            showTooltip
          />
        </div>
      </Box>
    </div>
  );
};

export default InboundVisibilitySearch;
