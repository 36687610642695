import { createContext } from 'react';
import { node, object } from 'prop-types';
import { isEmpty } from 'lodash';
import useSimStore from '../store/simStore';

export const TranslationsContext = createContext({});

const TranslationsProvider = ({ children = {}, mockedValue = null }) => {
  const { Provider } = TranslationsContext;

  const translations = useSimStore(state => state.translations);

  /**
   * Returns translated value of a simple string
   *
   * @param {string} key - string key name of value to be translated
   * @returns translated string.
   */
  const getMessage = key => {
    if (!key || key === '-') {
      return '-';
    }
    return translations[key]?.value ?? `<<${key}>>`;
  };

  /**
   * Translates a more complicated string that contains a dynamic value.
   *
   * @param {string} key - string key name of value to be translated
   * @param {object} params - object containing values to be replaced in the translated string.
   *    Ex: { date: '01/01/2020', name: 'John Doe' }
   * @returns translated string.
   */
  const formatMessage = (key, params) => {
    const message = getMessage(key);
    if (message) {
      const formatted = message.replace(/\{(.*?)\}/gu, (_, token) => params[token]);
      return formatted;
    }
    return message;
  };

  return (
    <Provider
      value={mockedValue ?? {
        translationsFetched: !isEmpty(translations), // used to trigger updates in the app when translations are fetched
        getMessage,
        formatMessage,
        translations,
      }}
    >
      {children}
    </Provider>
  );
};

TranslationsProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default TranslationsProvider;
