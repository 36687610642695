import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import { SimDateTime } from '../../utils/datetime';
import { ScanTimerContext } from '../../context/ScanTimer.provider';
import { TranslationsContext } from '../../context/Translations.provider';

const ScanNotification = () => {
  const { getMessage } = useContext(TranslationsContext);
  const { inReviewScanId, scanStatus: status, sendNotification, setShowScan } = useContext(ScanTimerContext);

  const [showToast, setShowToast] = useState(false);
  const [scanStatus, setScanStatus] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const endDate = SimDateTime.endOfDay(SimDateTime.now()).toISO();

  const scanToastMapping = {
    NEW_SCAN: {
      message: 'newScanReady',
      severity: 'success',
    },
    FIVE_HOUR_MARK: {
      message: 'newScanReady',
      severity: 'success',
    },
    ONE_HOUR_MARK: {
      message: 'scanTimeoutInHour',
      severity: 'warning',
    },
    HALF_HOUR_MARK: {
      message: 'scanTimeoutInThirtyMinutes',
      severity: 'warning',
    },
    TEN_MINUTES_MARK: {
      message: 'scanTimeoutInTenMinutes',
      severity: 'warning',
    },
    TIMEDOUT: {
      message: 'messageScanTimedOut',
      severity: 'error',
    },
  };

  useEffect(() => {
    if (status === 'IN_REVIEW' && sendNotification !== null) {
      setScanStatus(scanToastMapping[sendNotification]);
      setShowToast(true);
    }
  }, [status, sendNotification]);

  const closeToast = (snackbarId) => {
    closeSnackbar(snackbarId);
  };

  const viewScan = (snackbarId) => {
    setShowScan(true);
    closeSnackbar(snackbarId);
  };

  const action = snackbarId => (
    <div>
      <Link
        to={`/scan/${inReviewScanId}/${endDate}`}
        style={{
          textDecoration: 'none', color: 'inherit', margin: '0 20px 0 40px',
        }}
      >
        <Button
          data-testid="user-toast-notification-view-timer-button"
          color="inherit"
          size="small"
          onClick={() => viewScan(snackbarId)}
        >
          {getMessage('viewScan')}
        </Button>
      </Link>
      <Button
        data-testid="user-toast-notification-close-button"
        color="inherit"
        size="small"
        onClick={() => closeToast(snackbarId)}
      >
        {getMessage('close')}
      </Button>
    </div>
  );

  if (showToast) {
    enqueueSnackbar(getMessage(scanStatus?.message), { variant: scanStatus?.severity, persist: true, action });
  }
};

export default ScanNotification;
