import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setSessionValues } from "../utils/setSessionValues";
import { IOS_WRAPPER } from "../constants/storageKeys.constants";
import useAuth from "./useAuth";

/**
 * Hook to attach a window action function to handle access token retrieval from the iOS wrapper.
 * @param {*} loginSource 
 */
const useSetWrapperAction = () => {
  const { login, logout } = useAuth();
  const history = useHistory();
  const [haveRetried, setHaveRetried] = useState(false);

  useEffect(() => {
    document.body.classList.add('mobile');
    window.action = {
      returnAccessData: token => {
        const parsedToken = JSON.parse(token);
        const thirtySeconds = 30000;
        if (parsedToken.accessToken) {
          setSessionValues(parsedToken.accessToken, true);
          setHaveRetried(false);
        } else if (!haveRetried) {
          // retry fetching token after 30 seconds to give a chance for wrapper to refresh token
          setHaveRetried(true);
          setTimeout(login(IOS_WRAPPER, history), thirtySeconds);
        } else {
          // if we have attempted to grab token twice and failed, logout
          setHaveRetried(false);
          logout();
        }
      },
    };
  }, []);
};

export default useSetWrapperAction;