import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup, FormLabel, Slider, Typography,
} from '@mui/material';

const CustomDisplayComponent = ({
  getMessage, filterList, onChange, index, column, bins,
}) => {
  const [value, setValue] = useState([filterList[index][0] || 0, filterList[index][1] || bins.length - 1]);
  const handleChange = (event, newValue) => setValue(newValue);

  useEffect(() => {
    let valueToUse = value;
    if (value[0] === 0 && value[1] === bins.length - 1) {
      valueToUse = [];
    }

     
    filterList[index] = valueToUse;
    onChange(filterList[index], index, column);
  }, [value, onChange, bins.length]);  
  // we are intentionally not re-rendering on changes to filterList, index, or column, because that causes an infinite
  // loop.

  return (
    <>
      <FormLabel>{getMessage('bin')}</FormLabel>
      <FormGroup style={{ justifyContent: 'center' }} row>
        <Typography
          variant="body1"
          style={{ width: '20%', marginRight: '5%' }}
        >
          {bins[value[0]]}
        </Typography>
        <Slider
          value={value}
          onChange={handleChange}
          min={0}
          max={bins.length - 1}
          valueLabelDisplay="off"
          style={{ width: '50%' }}
        />
        <Typography
          variant="body1"
          style={{ width: '20%', marginLeft: '5%', textAlign: 'right' }}
        >
          {bins[value[1]]}
        </Typography>
      </FormGroup>
    </>
  );
};

CustomDisplayComponent.propTypes = {
  filterList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.any.isRequired,
  column: PropTypes.any.isRequired,
  bins: PropTypes.array.isRequired,
  getMessage: PropTypes.func.isRequired,
};

export default ({ getMessage, bins }) => ({
  customFilterListOptions: {
    render: (v) => {
      if (!v || !Array.isArray(v) || !v.length) { return false; }

      const startBin = bins[v[0]];
      const endBin = bins[v[1]];

      if (v[0] === 0 && v[1] === bins.length - 1) {
        return false;
      }

      if ((v[0] || v[0] === '0') && v[0] === v[1]) {
        return `${getMessage('bin')}: ${startBin}`;
      }

      return `${getMessage('bin')}: ${startBin} - ${endBin}`;
    },
    update: (filterList, filterPos, index) => {
      if (filterPos === 0) {
        filterList[index].splice(filterPos, filterList[index].length);
      } else if (filterPos === -1) {
         
        filterList[index] = [];
      }
      return filterList;
    },
  },
  filterOptions: {
    names: [],
    logic: (bin, filters) => {
      if (!filters.length) {
        return false;
      }

      const itemBins = bin.split(',');
      const filteredBins = bins.slice(filters[0] || 0, filters[1] ? filters[1] + 1 : undefined);
      return !itemBins.some(_ => filteredBins.includes(_));
    },
    display: (filterList, onChange, index, column) => (
      <CustomDisplayComponent
        filterList={filterList}
        onChange={onChange}
        index={index}
        column={column}
        bins={bins}
        getMessage={getMessage}
      />
    ),
  },
});
