import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { logErrorAction } from '../../utils/newRelicPageActions';

export const fetchOOSAuditsData = async (store, dateAfter, dateBefore, useAuditV2 = false) => {
  if (!store || !dateBefore || !dateAfter) return null;

  try {
    const filters = `?filter=store(${store})&&filter=creationDateBeforeEq(${dateBefore.replace('+', '%2B')})&filter=creationDateAfterEq(${dateAfter.replace('+', '%2B')})`;
    const oosAuditsEndpoint = useAuditV2 ? endpoints.OOS_AUDITS_V2 : endpoints.OOS_AUDITS;
    const url = `${baseUrl(routerUrls.DELEDGE)}${oosAuditsEndpoint.url}${filters}`;
    const oosAuditsData = await generalAxiosRequest('GET', url, oosAuditsEndpoint);

    return oosAuditsData?.objects;
  } catch (error) {
    logErrorAction('OOSAuditsError', error, 'error retrieving data', {
      message: error?.message, store, dateBefore, dateAfter,
    });
    throw error;
  }
};
