import { number, object, string } from 'prop-types';
import { useContext } from 'react';
import { TranslationsContext } from '../../../context/Translations.provider';

import './ScanAccuracyWidgetUtils.css';

// sorted format requested by product
const sortOrder = ['scanned', 'expected', 'missing', 'extra', 'accuracy'];

/**
 * With the last scan data provided we map over the aggregations values and dispaly accordingly
 * @param {object} aggregation the aggregation details from the scan
 * @param {string} translation The string translation for the title
*/
const aggreagationHTML = (aggregation, translation) => (
  <div className="scanAccuracyWidgetUtil_labels" key={aggregation.field}>
    <p style={{ color: 'white' }}>{translation}</p>
    <p>{`${aggregation.value.toLocaleString()}`}</p>
  </div>
);
/**
   * With the last scan data provided we map over the aggregations values and dispaly accordingly
   * @param {string} typeofScan the type of scan
   * @param {object} lastScanData The data of the last scan
   * @param {number} accuracyThreshold config value that handles the threshold
  */
 
const ScanAccuracyWidgetUtils = ({
  typeofScan, lastScanData, accuracyThreshold,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  let sortedArray = lastScanData?.aggregations ? [...lastScanData.aggregations] : [];
  sortedArray = sortedArray?.sort((a, b) => (
    sortOrder.indexOf(a.field) - sortOrder.indexOf(b.field)));

  const utilContainer = sortedArray && sortedArray.map((agg) => {
    let val = 0;

    if (agg.field === 'accuracy' && typeofScan === 'fullStore') {
      val = agg.value;
    }
    if (agg.field === 'accuracy' && typeofScan === 'salesFloor') {
      val = agg.value;
    }
    if (agg.field === 'accuracy' && typeofScan === 'cycleCount') {
      val = agg.value;
    }
    if (agg.field === 'scanned') {
      return aggreagationHTML(agg, getMessage('scan:'));
    }
    if (agg.field === 'expected') {
      return aggreagationHTML(agg, getMessage('exp:'));
    }
    if (agg.field === 'extra' && typeofScan === 'fullStore') {
      return aggreagationHTML(agg, getMessage('ext:'));
    }
    if (agg.field === 'missing' && typeofScan === 'fullStore') {
      return aggreagationHTML(agg, getMessage('miss:'));
    }
    if (agg.field === 'extra' && typeofScan === 'salesFloor') {
      return aggreagationHTML(agg, getMessage('foun:'));
    }
    if (agg.field === 'missing' && typeofScan === 'salesFloor') {
      return aggreagationHTML(agg, getMessage('lost:'));
    }
    if (agg.field === 'extra' && typeofScan === 'cycleCount') {
      return aggreagationHTML(agg, getMessage('foun:'));
    }
    if (agg.field === 'missing' && typeofScan === 'cycleCount') {
      return aggreagationHTML(agg, getMessage('lost:'));
    }
     
    const style = (val >= accuracyThreshold) ? 'positive-accuracy-total' : (val < accuracyThreshold) ? 'negative-accuracy-total' : null;

    return (
      <div className="scanAccuracyWidgetUtil_labels" key={agg.field}>
        <p style={{ color: 'white' }}>{getMessage('acc:')}</p>
        <p className={style}>
          {`${val}%`}
        </p>
      </div>
    );
  });

  return (
    <div className="scanAccuracyWidgetUtil_container" data-testid="scan-accuracy-widget-utils">{utilContainer}</div>
  );
};

ScanAccuracyWidgetUtils.propTypes = {
  typeofScan: string.isRequired,
  lastScanData: object.isRequired,
  accuracyThreshold: number.isRequired,
};

export default ScanAccuracyWidgetUtils;
