import { useContext, useMemo } from 'react';
import {
  array, bool, func, object, shape, string, oneOfType, number,
} from 'prop-types';
import DatePickerComponent from './components/BaseDatePicker';
import ReportDetails from './components/ReportDetails';
import TabPanel from '../../components/TabPanel';
import PageContent from './components/BasePageContent';
import { useTutorial } from '../../components/tutorial/useTutorial';

import './BaseViewWithFacets.css';
import { TranslationsContext } from '../../context/Translations.provider';

/**
  * BasePageView this base view has the structure to build most of the reports needed
  * @param {string} id the name of the pageView, ie, styleManagement
  * @param {string} title bodega sting title
  * @param {boolean} loading is the data still loading for the page
  * @param {object} datepicker object containing startDate and endDate states and handleDateChange function
  * @param {object} visuals object containg chart and legend objects
  * @param {object} facets object containing any facets used on the report
  * @param {object} pageContent object containing report page details to display on the dom
  * @param {object} details object containing MUI Table options, tableTitle, rows, columns, theme
  * @param {object} error object defining the current error state
  * @param {object} dialog datarow dialog modal
  * @param {object} tabs object containing MUI Tabs
  * @param {object | node} searchToggle search functionalality toggle for a given search
  * @param {boolean} tabValue the current tab value
  * @param {object} reportInfo additional information about the report
  */
const BaseFacetsViewPage = ({
  id,
  title,
  loading = false,
  datepicker = null,
  visuals = null,
  facets = null,
  pageContent = null,
  details = null,
  error = null,
  dialog = null,
  tabs = null,
  searchToggle = null,
  tabValue = null,
  reportInfo = null,
}) => {
  const { getMessage, translationsFetched } = useContext(TranslationsContext);
  const { getTutorial } = useTutorial(id);

  const report = (
    <div className="content-details">
      <div className="facets">
        {reportInfo}
        {tabs}
        <br />
        {searchToggle}
        {facets}
      </div>
      <div>
        {visuals}
        {pageContent || (
          <PageContent
            id={id}
            loading={loading}
            error={error}
            reportDetails={(
              <ReportDetails
                id={id}
                details={details}
                getMessage={getMessage}
                dialog={dialog}
              />
            )}
          />
        )}
      </div>
    </div>
  );

  const tutorialToggle = useMemo(() => getTutorial(!details?.rows?.length || loading), [details, loading]);

  if (!translationsFetched) return null;

  return (
    <div data-testid={id} className="report">
      <div className="title-block flex-row">
        <div className="feature-title">{getMessage(title)}</div>
        {datepicker && (
          <DatePickerComponent
            id={id}
            loading={loading}
            datepicker={datepicker}
          />
        )}
      </div>
      {tutorialToggle}
      <div>
        {tabs ? (
          <TabPanel value={tabValue} index={tabValue}>
            {report}
          </TabPanel>
        ) : (
          report
        )}
      </div>
    </div>
  );
};

BaseFacetsViewPage.propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  loading: bool,
  error: object,
  facets: object,
  visuals: shape({
    chart: object,
    legend: object,
  }),
  reportInfo: object,
  pageContent: object,
  details: shape({
    title: oneOfType([
      string,
      object,
    ]),
    options: object,
    columns: array,
    theme: object,
  }),
  datepicker: shape({
    startDate: oneOfType([
      string,
      object,
    ]),
    endDate: oneOfType([
      string,
      object,
    ]),
    handleDateChange: func,
  }),
  dialog: object,
  tabs: object,
  tabValue: number,
  searchToggle: object,
};

export default BaseFacetsViewPage;
