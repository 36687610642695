import { useContext } from 'react';
import { string, number, bool } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import { TranslationsContext } from '../../context/Translations.provider';

const ProgressBar = ({
  bgcolor = '#fa5400',
  completed = 0,
  dataTestId = 'progress-bar',
  showArrow = true,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  const containerStyles = {
    height: 20,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: (completed === 100) ? '#ABD648' : bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out',
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  const ProgressToolTip = withStyles({
    arrow: {
      color: '#292929',
    },
    tooltip: {
      backgroundColor: '#292929',
      color: '#DEDEDE',
      accentColor: '#292929',
      fontWeight: 500,
      fontSize: '12px',
      border: '1px solid black',
      padding: '10px',
    },
  })(Tooltip);

  const toolTipTitle = `${getMessage('scanProcessing')}: ${completed}%`;

  return (
    <div style={containerStyles} data-testid={dataTestId}>
      <ProgressToolTip data-testid="scan-progress-tooltip" title={toolTipTitle} placement="top" arrow={showArrow}>
        <div style={fillerStyles}>
          <span data-testid={`${dataTestId}-completed`} style={labelStyles}>
            {`${completed}%`}
          </span>
        </div>
      </ProgressToolTip>
    </div>
  );
};

ProgressBar.propTypes = {
  bgcolor: string,
  completed: number,
  dataTestId: string,
  showArrow: bool,
};

export default ProgressBar;
