import {
  useEffect, useRef, useContext,
} from 'react';
import { DateTime } from 'luxon';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { isEmpty } from 'lodash';
import 'chartjs-plugin-annotation';

import Error from '../../components/alerts/Error';
import { LoadingIndicator } from '../../assets/Svgs/index';
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import DropdownTable from './dropdownTable/DropdownTable';
import CustomTable from '../../components/customTable/CustomTable';
import NewNoContentMessage from '../../components/noContentMessage/NoContentMessage';
import useAuthorizedToView from '../../hooks/useAuthorizedToView';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import hasSameDate from '../../utils/sameDate';
import { reconstructRCPPerformanceData, reduceDataByTimeUnit } from './storePerformance.utils';

import { SimWebContext } from '../../context/SimWeb.provider';

import './StorePerformancePage.css';
import { StorePerformanceContext } from './StorePerformance.provider';

export const setMaxHeights = (detailsRef, summaryRef) => {
  if (!detailsRef.current || !summaryRef.current) { return; }
  const setHeight = (container) => {
    const detail = container.children[1];

    if (!detail || !detail.children[0]) { return; }

    const maxHeight = detail.children[0].clientHeight;

    // plus one because otherwise our bottom border gets cut off in some cases.
    detail.setAttribute('style', `max-height: ${maxHeight + 20}px;`);
  };

  Array.from(detailsRef.current.children).forEach(setHeight);
  Array.from(summaryRef.current.children).forEach(setHeight);
};

export const StorePerformancePage = () => {
  const { getMessage, storeConfig } = useContext(SimWebContext);
  const {
    storePerformance, onGoClick, startDate, endDate, error, isFetching, isOutsideRange,
  } = useContext(StorePerformanceContext);

  const title = getMessage('storePerformance');
  
  useAuthorizedToView(title, 'isStorePerformanceEnabled', 40);

  const uphBaseline = storeConfig?.['uph.benchmark']?.value;
  const detailsRef = useRef(null);
  const summaryRef = useRef(null);

  const isGreaterThanTwoDays = (endDate?.diff(startDate, 'days').toObject())?.days >= 2;

  const prepareChart = (summaryData) => {
    let chartDates;

    if (isGreaterThanTwoDays) {
      chartDates = summaryData.map(item => item.date.toLocaleString(DateTime.DATE_SHORT));
    } else {
      chartDates = summaryData.map(item => `${item.date.toLocaleString(DateTime.DATE_SHORT)} ${item.hour}`);
    }
    const chartData = summaryData.map(item => item.uphScore);

    return {
      labels: chartDates,
      datasets: [
        {
          label: getMessage('uphScore'),
          pointBackgroundColor: 'rgb(0,0,0)',
          data: chartData,
          showLine: true,
          fill: false,
          borderColor: 'rgb(220,220,220)',
          backgroundColor: 'rgb(0,0,0)',
        },
      ],
    };
  };

  const prepareDataForRender = (data) => {
    if (!data) { return null; }

    const timeTable = {};

    const athleteData = Object.keys(data).map((athleteKey) => {
      const athlete = data[athleteKey];

      const summaryValues = Object.values(athlete).reduce((a, b) => ({
        units: a.units + b.units,
        minutes: a.minutes + b.minutes,
      }));

      if (summaryValues.minutes === 0) {
        summaryValues.uph = 0;
      } else {
        summaryValues.uph = Math.round(60 * (summaryValues.units / summaryValues.minutes));
      }

      summaryValues.id = athleteKey;

      const tableData = Object.keys(athlete).map((dateKey) => {
        const date = dateKey;
        let hour;

        if (!isEmpty(startDate) && !isEmpty(endDate)) {
          hour = DateTime.fromISO(dateKey).toLocaleString(DateTime.TIME_SIMPLE);
        }

        const dateValue = athlete[dateKey];

        if (!timeTable[date]) {
          timeTable[date] = [];
        }

        timeTable[date].push(dateValue);

        if (dateValue.uph === 2147483647) {
          dateValue.uph = 0;
        }

        return {
          sortKey: dateKey,
          date: DateTime.fromISO(date).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS),
          hour,
          units: dateValue.units,
          time: dateValue.minutes,
          uphScore: dateValue.uph,
        };
      });

       
      tableData.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1));

      return {
        summary: summaryValues,
        data: tableData,
      };
    });

    const timeTableData = reduceDataByTimeUnit(timeTable, isGreaterThanTwoDays ? 'day' : 'hour');
    const summaryData = Object.keys(timeTableData).map((dateKey) => {
      const date = timeTableData[dateKey];
      const reduced = Object.values(date).reduce((acc, itt) => ({
        units: acc.units + itt.units,
        minutes: acc.minutes + itt.minutes,
      }));

      if (reduced.minutes === 0) {
        reduced.uph = 0;
      } else {
        reduced.uph = Math.round(60 * (reduced.units / reduced.minutes));
      }

      return {
        sortKey: dateKey,
        date: DateTime.fromISO(dateKey),
        hour: DateTime.fromISO(dateKey).toLocaleString(DateTime.TIME_SIMPLE),
        units: reduced.units,
        time: reduced.minutes,
        uphScore: reduced.uph,
      };
    });

     
    summaryData.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1));

    const chartData = prepareChart(summaryData);

    const summaryHeaderData = summaryData.reduce((acc, itt) => ({
      units: acc.units + itt.units,
      time: acc.time + itt.time,
    }), { units: 0, time: 0 });

    if (summaryHeaderData.time === 0) {
      summaryHeaderData.uph = 0;
    } else {
      summaryHeaderData.uph = Math.round(60 * (summaryHeaderData.units / summaryHeaderData.time));
    }

    return {
      athlete: athleteData,
      summary: {
        headerData: summaryHeaderData,
        data: summaryData,
      },
      chart: chartData,
    };
  };

  useEffect(() => {
    if (storePerformance) setMaxHeights(detailsRef, summaryRef);
  }, [storePerformance]);

  const data = prepareDataForRender(reconstructRCPPerformanceData(storePerformance));

  return (
    <div className="store-performance-page flex-column" data-testid="storePerformancePage">
      <div className="flex-row header">
        <div className="title-block">
          <div className="feature-title">
            {title}
          </div>
          <>
            {getMessage('storePerformanceHeaderDescription')}
          </>
        </div>
        <div className="page-controls">
          <div className="date-picker-block" data-testid="storePerformancePageDatePicker">
            <DateRangeWithOptions
              start={startDate?.toISO()}
              end={endDate?.toISO()}
              onClick={onGoClick}
              isOutsideRange={isOutsideRange}
              disabled={isFetching}
            />
          </div>
        </div>
      </div>
      <div>
        {error && (
          <Error
            apiName="Store Performance"
            errorObject={error}
            pageCode=""
          />
        )}
        {!isFetching && data && data.athlete && !error
            && data.athlete.length === 0
            && <NewNoContentMessage isOnlyOneDay={hasSameDate(startDate, endDate)} />}
      </div>
      {
        !isFetching && data && data.athlete.length > 0
        && (
          <div>
            <div ref={summaryRef} className="report-summary">
              <DropdownTable
                summaryCells={[
                  { key: getMessage('totalUnits'), value: data.summary.headerData.units },
                  { key: getMessage('totalTime'), value: data.summary.headerData.time },
                  { key: getMessage('uphScore'), value: data.summary.headerData.uph },
                ]}
              >
                <CustomTable
                  data={data?.summary?.data?.map(eachItem => ({
                    ...eachItem,
                    date: eachItem?.date?.toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS),
                  }))}
                  columns={[
                    {
                      key: 'date', class: 'left', dataType: 'string', label: getMessage('date'),
                    },
                    !isGreaterThanTwoDays ? { key: 'hour', dataType: 'string', label: getMessage('hour') } : null,
                    {
                      key: 'units', dataType: 'number', label: getMessage('units'),
                    },
                    {
                      key: 'time', dataType: 'string', label: getMessage('timeMins'),
                    },
                    {
                      key: 'uphScore', dataType: 'number', label: getMessage('uphScore'),
                    },
                  ]}
                />
              </DropdownTable>
            </div>
            <div className="lineChart" data-testid="storePerformancePageLineChart">
              <Line
                data={data.chart}
                width={1152}
                height={330}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [{
                      ticks: {
                        autoSkip: false,
                      },
                    }],
                  },
                  annotation: {
                    annotations: [{
                      type: 'line',
                      mode: 'horizontal',
                      scaleID: 'y-axis-0',
                      value: uphBaseline,
                      borderColor: '#fa5400',
                      borderWidth: 2,
                    }],
                  },
                }}
              />
            </div>
            <div className="feature-subtitle athlete">
              {getMessage('athletePerformance')}
            </div>
            <div className="details" ref={detailsRef} data-testid="storePerformancePageDetails">
              {data && data.athlete.map(athlete => (
                <DropdownTable
                  key={athlete.summary.id}
                  summaryCells={[
                    { key: getMessage('athlete'), value: athlete.summary.id.substring(athlete.summary.id.lastIndexOf(';') + 1) },
                    { key: getMessage('totalUnits'), value: athlete.summary.units },
                    { key: getMessage('totalTime'), value: athlete.summary.minutes },
                    { key: getMessage('uphScore'), value: athlete.summary.uph },
                  ]}
                >
                  <CustomTable
                    data={athlete.data}
                    columns={[
                      {
                        key: 'date', class: 'left', dataType: 'string', label: getMessage('date'),
                      },
                      {
                        key: 'hour', dataType: 'string', label: getMessage('hour'),
                      },
                      {
                        key: 'units', dataType: 'number', label: getMessage('units'),
                      },
                      {
                        key: 'time', dataType: 'string', label: getMessage('timeMins'),
                      },
                      {
                        key: 'uphScore', dataType: 'number', label: getMessage('uphScore'),
                      },
                    ]}
                  />
                </DropdownTable>
              ))}
            </div>
          </div>
        )
      }
      {(isFetching || !data) && <LoadingIndicator dataTestId="storePerformanceDataFetching" />}
    </div>
  );
};

export default StorePerformancePage;
