import { useContext } from 'react';
import { func, string } from 'prop-types';
import { Refresh } from '../../assets/Svgs/Refresh';
import { SimWebContext } from '../../context/SimWeb.provider';

const RefreshButton = ({
  id,
  classes,
  refetch,
  color = 'action',
}) => {
  const { formatMessage, SimDateTime } = useContext(SimWebContext);
  const lateUpdatedTimestamp = SimDateTime.toMoment().format('MMM DD, YYYY h:mm A');

  return (
    <div className={classes}>
      <span>{formatMessage('lastUpdated', { timestamp: lateUpdatedTimestamp })}</span>
      <Refresh
        testId={`${id}-refresh`}
        onClick={() => refetch()}
        color={color}
      />
    </div>
  );
};

RefreshButton.propTypes = {
  id: string.isRequired,
  classes: string.isRequired,
  refetch: func.isRequired,
  color: string,
};

export default RefreshButton;
