import { useContext, useMemo } from 'react';
import { SimWebContext } from '../../context/SimWeb.provider';
import { StockOnHandContext } from './StockOnHand.provider';
import BaseViewPage from '../baseView/BaseViewPage';
import classNames from 'classnames';
import { Typography } from '@mui/material';

import './StockOnHandPage.css';
/**
 * SOH Report that displays the SOH within the 4 walls of a store
 */
const StockOnHandPage = () => {
  const {
    data, loading, error, columns, tableOptions, id,
  } = useContext(StockOnHandContext);
  const { getMessage, storeConfig } = useContext(SimWebContext);
  const rfidEnabled = storeConfig?.rfidEnabled;

  const renderSummaryTable = summary => (
    <>
      <div className="stock-on-hand-summary-table">
        {rfidEnabled && (
          <div
            className="stock-on-hand-summary-table-row labels"
            style={{ display: 'flex', justifyContent: 'end', padding: '10px' }}
          >
            <Typography
              variant="subtitle1"
              style={{ marginRight: '12px' }}
            >
              {getMessage('soh-summary')}
            </Typography>
            <Typography variant="subtitle1">{getMessage('rfidSoh-summary')}</Typography>
          </div>
        )}
        <div className="stock-on-hand-summary-table-row labels">
          <div className="key" />
          <div className="value">{getMessage('positive')}</div>
          <div className="value">{getMessage('negative')}</div>
          {rfidEnabled && (
            <>
              <div className="value">{getMessage('positive')}</div>
              <div className="value">{getMessage('negative')}</div>
            </>
          )}
        </div>
        {Object.entries(summary?.sohSummary).map(([key, value], idx, arr) => {
          const rfidValue = summary?.rfidSohSummary[key] || { positive: 0, negative: 0 };
          return (
            <div
              className={classNames('stock-on-hand-summary-table-row', { 'last-child': idx === arr.length - 1 })}
              key={`${key}-${idx}`}
            >
              <div className="key">{getMessage(key)}</div>
              <div
                className={`value ${value.positive < 0 ? 'negative' : ''}`}
                key={`${Math.random()}-${value.positive}-${idx}`}
              >
                {value.positive < 0 ? -value.positive : value.positive}
              </div>
              <div
                className={`value ${value.negative < 0 ? 'negative' : ''}`}
                key={`${Math.random()}-${value.negative}-${idx}`}
              >
                {value.negative < 0 ? -value.negative : value.negative}
              </div>
              {rfidEnabled && (
                <>
                  <div
                    className={`value ${rfidValue.positive < 0 ? 'negative' : ''}`}
                    key={`${Math.random()}-${rfidValue.positive}-${idx}`}
                  >
                    {rfidValue.positive < 0 ? -rfidValue.positive : rfidValue.positive}
                  </div>
                  <div
                    className={`value ${rfidValue.negative < 0 ? 'negative' : ''}`}
                    key={`${Math.random()}-${rfidValue.negative}-${idx}`}
                  >
                    {rfidValue.negative < 0 ? -rfidValue.negative : rfidValue.negative}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );

  const visuals = useMemo(() => {
    return (
      <>
        {!loading && !error && (
          <div className="stock-on-hand-summary-container" data-testid="stockOnHandSummary">
            {data?.summary && renderSummaryTable(data.summary)}
          </div>
        )}
      </>
    );
  }, [data, loading, error]);

  return (
    <>
      <BaseViewPage
        id={id}
        title={id}
        storeConfigValue="sohEnabled"
        loading={loading}
        error={error}
        visuals={visuals}
        details={{
          options: tableOptions,
          columns,
          rows: data?.details,
        }}
      />
    </>
  );
};

export default StockOnHandPage;