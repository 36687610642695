import { useContext, Fragment, useEffect } from 'react';
import { Grid2 } from '@mui/material';
import Alert from '../../components/alerts/Alert';
import RfidWidget from './widgets/RfidWidget';
import { ProductRefill, ReceivingLog } from './widgets';

import {
  AgedOrdersWidgetContainer,
  // InboundVisibilityWidgetContainer,
  ItemInquiryWidgetContainer,
  MarkdownsWidgetContainer,
} from '../../components/dashboardWidgets/index';
import Error from '../../components/alerts/Error';
import { Location } from '../../assets/Svgs/index';
import { createNewRelicInteraction } from '../../utils/newRelicPageActions';

import { DashboardContext } from './Dashboard.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import TutorialProvider, { tutorialContext } from '../../components/tutorial/Tutorial.provider';

import './DashboardPage.css';
import useAuth from '../../hooks/useAuth';

export const getGreetingTime = (currentTime, getMessage) => {
  if (!currentTime) {
    return getMessage('hello');
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = currentTime?.getHours();

  if (splitAfternoon < currentHour && currentHour <= splitEvening) {
    return getMessage('goodAfternoon');
  }

  if (currentHour >= splitEvening) {
    return getMessage('goodEvening');
  }

  return getMessage('goodMorning');
};

const DashboardPage = () => {
  const interaction = createNewRelicInteraction('Dashboard');

  const { getMessage, storeInfo, storeConfig } = useContext(SimWebContext);
  const { scanErrors, scansLoading } = useContext(DashboardContext);
  const { activateTutorials } = useContext(tutorialContext);

  const { getUserInfo } = useAuth();

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const rfidScanAccuracyWidgetEnabled = storeConfig?.rfidscanAccuracyWidget?.value;
  const rfidScanComplianceWidgetEnabled = storeConfig?.rfidscanComplianceWidget?.value;
  const rfidFullStoreScanDate = storeConfig?.['rfid.rfidFullStoreScanDate']?.value;
  const rfidSizeComplianceWidgetEnabled = storeConfig?.sizeComplianceEnabled?.value;

  const isRfidDataEnabled =
    rfidEnabled &&
    (rfidScanComplianceWidgetEnabled || rfidScanAccuracyWidgetEnabled || rfidSizeComplianceWidgetEnabled);

  useEffect(() => {
    interaction.setAttribute('sim-web-wrapper', { action: 'dashboard' });
    return () => interaction.end();
  }, []);

  const receivingAndRefillContainer = (
    <Grid2
      key="receivingAndRefill"
      container
    >
      <Grid2 size={6}>
        <ReceivingLog />
      </Grid2>
      <Grid2 size={6}>
        <ProductRefill />
      </Grid2>
    </Grid2>
  );

  const singleWidgets = [
    <AgedOrdersWidgetContainer key="agedOrders" />,
    <ItemInquiryWidgetContainer key="itemInquiry" />,
    receivingAndRefillContainer,
    <MarkdownsWidgetContainer key="markdowns" />,
  ];

  return (
    <div
      className="dashboard-page flex-column"
      data-testid="dashboardPage"
    >
      {/* {redirectError && (<Error apiName={getMessage('unauthorized')} errorObject={redirectError} />)} */}
      {activateTutorials && (
        <Alert
          apiName="Mock data are used in tutorial mode."
          severity="info"
        />
      )}
      <div className="dashboard">
        {!scansLoading && scanErrors && (
          <Error
            apiName="Dashboard API"
            errorObject={scanErrors}
          />
        )}
        <div className="dashboard-widget-test">
          <div className="welcome-text">
            <h1>{`${getGreetingTime(new Date(), getMessage)} ${getUserInfo().operatorID || getMessage('nikeMember')},`}</h1>
            <span>
              <p>{`${getMessage('welcomeTo')} ${storeInfo?.name || getMessage('simWeb')}`}</p>
              <Location fill="black" />
            </span>
          </div>
          <Grid2
            container
            alignItems="flex-start"
            justifyContent="center"
            data-testid="dashboardPage-data"
          >
            <Grid2 style={{ margin: '0 7px' }}>
              {singleWidgets.map((widget, index) => (
                <Fragment key={index}>
                  <TutorialProvider>{widget}</TutorialProvider>
                </Fragment>
              ))}
            </Grid2>
            <Grid2 style={{ margin: '0 7px' }}>
              <RfidWidget
                isRfidDataEnabled={isRfidDataEnabled}
                rfidFullStoreScanDate={rfidFullStoreScanDate}
              />
            </Grid2>
          </Grid2>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
