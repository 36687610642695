import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { logErrorAction } from '../../utils/newRelicPageActions';
import { SERVER_REGION } from '../../constants/storageKeys.constants';

/**
 * Function that gets the item activity data for a given product UPC or Style
 * @param {string} storeId Unique identifier for a store
 * @param {string} searchValue exact product code for the given item searched
 * @param {string} searchType either a gtin or style
 * @param {string} originDateAfterEq start date of the search range
 * @param {string} originDateBeforeEq end date of the serach range
 */
export const getItemActivityData = async (storeId, searchValue, searchType, originDateAfterEq, originDateBeforeEq) => {
  if (!storeId || !searchValue || !searchType || !originDateAfterEq || !originDateBeforeEq) return null;

  try {
    const filtersString = `?storeId=${storeId}&searchValue=${searchValue}&searchType=${searchType}&originDateAfterEq=${encodeURIComponent(originDateAfterEq)}&originDateBeforeEq=${encodeURIComponent(originDateBeforeEq)}`;
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.ITEM_ACTIVITY.url}${filtersString}`;
    const region = localStorage.getItem(SERVER_REGION)
    const headers = { region };

    const itemActivityData = await generalAxiosRequest('GET', url, endpoints.ITEM_ACTIVITY, true, headers);

    return itemActivityData;
  } catch (error) {
    logErrorAction('ItemActivityError', error, 'error retrieving data', {
      message: error?.message, storeId, searchValue, searchType, originDateAfterEq, originDateBeforeEq,
    });
    throw error;
  }
};
