import axios from 'axios';
import { baseUrl, routerUrls, endpoints } from './endpoints';
import { logErrorAction, newRelicAction } from '../utils/newRelicPageActions';
import useAuth from '../hooks/useAuth';
import { APP_ID } from '../constants/storageKeys.constants';

// use interceptors to create start and end dates for duration
axios.interceptors.request.use((config) => {
  config.timeData = { startTime: new Date() };
  return config;
}, (error) => Promise.reject(error));

axios.interceptors.response.use((response) => {
  response.config.timeData.endTime = new Date();
  return response;
}, (error) => Promise.reject(error));

/**
 * A timeout function.
 * @param {Number} ms milliseconds to wait
 * @returns void
 */
export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * gets a random integer between 0 and size
 * @param {size} upperlimit of random number
 * @returns int
 */
export const getRandomInteger = (size = 10000) => Math.floor(Math.random() * size);

/**
   * Build the paramerter string used in an API request ex: ?storeId=123456&metricName='topSellers'
   * @param {Object} obj data object containing the fields and values to be parametrized
   * @param {Array} array of required fields and data
   */
export const buildParametersString = (obj, requiredFields = ['storeId', 'metricName']) => {
  const hasValue = (value) => (Array.isArray(value) ? value.length > 0 : value !== null && value !== undefined);

  const missingFields = requiredFields.filter(field => !Object.prototype.hasOwnProperty.call(obj, field));
  if (missingFields.length > 0) {
    console.error(`The following required fields are missing: ${missingFields.join(', ')}`);
    return '';
  }

  const params = new URLSearchParams();

   
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop) && hasValue(obj[prop])) {
      params.append(prop, obj[prop]);
    }
  }

  return `?${params.toString()}`;
};

/**
 *  * Sets the headers for axios, custom headers can be added if desired
 * @param {String} auth authorization token
 */
export const setDefaultHeaders = (auth) => {
  const appId = sessionStorage.getItem(APP_ID);

  if (auth) {
    axios.defaults.headers.common.Authorization = `Bearer ${auth}`;
  }

  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.appId = appId ? appId: 'nike.athlete.platform';
};

/**
 * If the auth token is expired, this will pull a new token for the calls to use.
 * @returns {String }fresh token to use for apis
 */
export const refreshToken = async () => {
  const userDetailsStr = sessionStorage.getItem('login');
  const userDetails = JSON.parse(userDetailsStr);

  if (!userDetails || !userDetails?.access_token || !userDetails?.refresh_token) {
    logErrorAction('[BERM error]', {}, 'error while fetching new token', {
      customMessage: '[BERM Error]: Message: unable to retrieve user login details.',
    });
    throw new Error({ name: 'Auth Error', message: 'Unable to retrieve user login details!' });
  }

  const payload = {
    grant_type: 'nike:retail:refresh',
    access_token: userDetails?.access_token,
    refresh_token: userDetails?.refresh_token,
  };

  const refreshToken = await axios.post(baseUrl(routerUrls.BERM) + endpoints.BERM.url, payload);

  return refreshToken && refreshToken?.data?.access_token;
};

/**
 * General purpose GET function where custom headers and url can be added.
 * @param {String} method string of what method to perform. Should be one of [GET, POST, PATCH, DELETE]
 * @param {String} url string of api to talk to
 * @param {Object} endpoint used for reporting errors for specific endpoints
 * @param {Boolean} setAuth bool to flag whether or not an auth token is needed
 * @param {Object} headers  objects of custom headers unique to a call (ex: { store: usa-128 })
 * @param {Object} data object required when performing a POST or PATCH
 * @param {Object} params any params to pass through for checking in the BFF layer
 * @param {Boolean} rawRequest determines if the entire request should be returned
 * @returns data from api
 */
export const generalAxiosRequest = async (method, url, endpoint, setAuth = true, headers = null, data, params, rawRequest) => {
  const { getAccessToken } = useAuth();

  let duration;

  if (setAuth) {
    const authTokenResponse = await getAccessToken();
    const authToken = authTokenResponse?.token;
    setDefaultHeaders(authToken);
  }

  const results = axios({
    method,
    url,
    ...(headers && { headers }),
    ...(data && { data }),
    ...(params && { params }),
  }).then(resp => {
    // time data come for interceptors
    const { timeData } = { ...resp?.config };
    duration = timeData ? timeData?.endTime - timeData?.startTime : 0;
    return rawRequest ? resp : resp?.data;
  })
    .catch(error => {
      logErrorAction(`[${endpoint.name} error]`, error, `error ${method}-ing data`, {
        customMessage: `[${endpoint.name} Error]: Message: ${error?.message}.`,
      });
      if (rawRequest) throw error;

      if (error?.name?.toLowerCase() === 'axioserror') {
         
        throw {
          name: `${endpoint?.name}`,
          message: error?.response?.data?.message || error?.message,
        };
      }
      throw error;
    }).finally(() => {
      if (!window) return;
      if (window?._location || window?.location) {
        const location = window.location ?? window._location;
        newRelicAction(
          'sim-backend-performance',
          {
            source: 'axios',
            queryName: `${endpoint?.name}-axios-${method?.toLowerCase() ?? ''}`,
            url: endpoint?.url ?? '',
            report: location?.href?.split('/')?.[3] ?? '',
            duration,
          },
        );
      }
    });

  return results;
};
