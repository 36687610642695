import { DateTime } from 'luxon';
import getFormattedPrice from '../../utils/getFormattedPrice';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
/**
 * Gets the operator ID from the athlete string
 * @param {string} athlete the ID of the athlete who initiated the transfer
 */
const getOperatorId = (athlete) => {
  if (!athlete) return '';
  if (!athlete.includes(';')) return athlete;
  return athlete.split(';').slice(-1)[0].replace(')', '');
};

/**
 * A helper function to formats price and gets info for table
 * @param {object} data the transfers data object
 * @param {string} locale unique value to help us get the accurate date val
 * @param {string} currencyCode unique value to help get the currency val
 */
// we replace purchaseOrderNumber w/ shipmentDocumentNumber per carton if GC door
export const constructTableData = (data, locale, currencyCode) => {
  let hasGovernmentNumber = false;
  let hasReturnAuthorizationNumber = false;
  const newData = data?.map(item => {
    if (item?.returnAuthorizationNumber) hasReturnAuthorizationNumber = true;
    if (item?.governmentNumbers) hasGovernmentNumber = true;
    return {
      ...item,
      status: item?.status || '-',
      purchaseOrderNumber: item?.purchaseOrderNumber || '-', // not populated from API if PO number is not exist.
      shipmentDocumentNumber: item?.shipmentDocumentNumber || '-',
      returnAuthorizationNumber: item?.returnAuthorizationNumber || '-',
      creationDate: DateTime.fromISO(item?.creationDate).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS),
      totalPrice: getFormattedPrice(item?.totalPrice, locale, currencyCode),
      opId: getOperatorId(item?.athlete),
    };
  });

  return { newData, hasGovernmentNumber, hasReturnAuthorizationNumber };
};

/**
 * a helper function to filter data by status
 * @param {object} data the transfers data object
 * @param {string} filter the filter option that used for filter the data
 */
export const filterData = (data, filter) => {
  if (!data) { return null; }
  if (!filter || filter === 'all') { return data; }

  return data.filter(obj => obj.status && obj.status.toLowerCase().includes(filter));
};

/**
 * A helper function to filter data by transfer type
 * @param {object} data the transfers data object
 * @param {string} transfersFilter the transfer option that used for filter the data
 */
export const filterTransfersData = (data, transfersFilter) => {
  if (!data) { return null; }
  if (!transfersFilter || transfersFilter === 'all') { return data; }
  if (typeof data === typeof [] && data.length > 0 && !('targetType' in data[0])) { return []; }

  return data.filter(obj => obj.targetType && obj.targetType.toLowerCase().includes(transfersFilter));
};

/**
 * Get the table column span if extran columns are needed
 * @param {boolean} displayGovernmentNumber option to add government number column
 * @param {boolean} displayReturnAuthorizationNumber option to add return authorization number column
 */
export const getTableColumnSpan = (displayGovernmentNumber, displayReturnAuthorizationNumber, isFiscalDownloadDoor) => {
  let columnSpan = 10;
  if (displayGovernmentNumber) columnSpan += 1;
  if (displayReturnAuthorizationNumber) columnSpan += 1;
  if (isFiscalDownloadDoor) columnSpan += 1;
  return columnSpan;
};

/**
 * Get a list of headers for CSV download
 * @param {boolean} displayGovernmentNumber option to add government number column
 * @param {boolean} displayReturnAuthorizationNumber option to add return authorization number column
 * @param {function} getMessage a function to get translated message
 * @param {boolean} isGreaterChinaDoor bool that toggles btwn shipment # and PO #
 * @param {boolean} isNorthAmerica bool that toggles btwn delivery/PO # and PO # label
 */
export const getHeaderForDownloadData = (
  displayGovernmentNumber, displayReturnAuthorizationNumber, getMessage, isGreaterChinaDoor, isNorthAmerica,
) => {
  const headers = [
    { value: getMessage('date'), fontWeight: 'bold' },
    { value: getMessage('transfersType'), fontWeight: 'bold'},
    { value: getMessage('shipTo'), fontWeight: 'bold' },
    isGreaterChinaDoor ? { value: getMessage('shipmentDocumentNumber'), fontWeight: 'bold' } : { value: isNorthAmerica ? 'STO/PO Number' : getMessage('poNumber'), fontWeight: 'bold'},
    { value: getMessage('cartons'), fontWeight: 'bold' },
    { value: getMessage('totalUnits'), fontWeight: 'bold' },
    { value: getMessage('totalPrice'), fontWeight: 'bold' },
    { value: getMessage('status'), fontWeight: 'bold' },
    { value: getMessage('opId'), fontWeight: 'bold' },
    { value: getMessage('cartonID'), fontWeight: 'bold' },
    { value: `${getMessage('style')}\xA0\xA0\xA0${getMessage('clr')}`, fontWeight: 'bold' },
    { value: getMessage('class'), fontWeight: 'bold' },
    { value: getMessage('description'), fontWeight: 'bold' },
    { value: getMessage('size'),  fontWeight: 'bold' },
    { value: getMessage('upc'), fontWeight: 'bold' },
    { value: getMessage('units'),  fontWeight: 'bold' },
    { value: getMessage('price'),  fontWeight: 'bold'},
  ];

  if (displayReturnAuthorizationNumber) headers.splice(8, 0, { value: getMessage('raNumber'), fontWeight: 'bold' });
  if (displayGovernmentNumber) headers.splice(8, 0, { value: getMessage('governmentNumber'), fontWeight: 'bold' });

  return headers;
};

/**
 * Build data for CSV download. return selected data if rows are selected, else return all data.
 * @param {boolean} isGreaterChinaDoor bool that toggles btwn shipment # and PO #
 * @param {array} data An array of data displays in the table
 * @param {string} locale unique value to help us get the accurate date val
 * @param {string} currencyCode unique value to help get the currency val
 */
export const buildDownloadData = (isGreaterChinaDoor, data, locale, currencyCode) => {
  const download = [];

  data.forEach(transfer => {
    transfer?.cartons?.forEach(carton => {
      carton.styleColors.forEach(styleColor => {
        styleColor.items.forEach(item => {
          const orderNumber = isGreaterChinaDoor
            ? { shipmentDocumentNumber: transfer?.shipmentDocumentNumber ? transfer?.shipmentDocumentNumber : '-' }
            : { purchaseOrderNumber: transfer?.purchaseOrderNumber ? transfer?.purchaseOrderNumber : '-' };

          download.push([
            { value: transfer.creationDate || '-', type: String },
            { value: transfer.targetType || '-', type: String },
            { value: transfer.targetLocation || '-', type: String },
            { value: orderNumber.shipmentDocumentNumber || orderNumber.purchaseOrderNumber || '-', type: String },
            { value: transfer.totalCartons || 0, type: Number },
            { value: transfer.totalItems || 0, type: Number },
            { value: transfer.totalPrice || '-', type: String },
            { value: transfer.status || '-', type: String },
            { value: transfer.opId || '-', type: String },
            { value: carton.cartonNumber || '-', type: String },
            { value: styleColor.styleColor || '-', type: String },
            { value: styleColor.class || '-', type: String },
            { value: styleColor.description || '-', type: String },
            { value: item.size || '-', type: String },
            { value: item.gtin || '-', type: String },
            { value: item.quantity || 0, type: Number },
            { value: getFormattedPrice(item.price, locale, currencyCode) || '-', type: String },
          ]);
        });
      });
    });
  });
  return download;
};

/**
 * Build data for CSV download. return selected data if rows are selected, else return all data.
 * @param {boolean} isGreaterChinaDoor bool that toggles btwn shipment # and PO #
 * @param {array} data An array of data displays in the table
 * @param {string} locale unique value to help us get the accurate date val
 * @param {string} currencyCode unique value to help get the currency val
 * @param {object} selectedRows an object with indexes of selected rows
 */
export const buildSelectedDownloadData = (isGreaterChinaDoor, data, locale, currencyCode, selectedRows) => {
 const dataToDownload = selectedRows ? selectedRows?.data?.map(index => data?.[index.dataIndex]) : data;
 const download = [];

  dataToDownload.forEach(transfer => {
    transfer?.cartons?.forEach(carton => {
      carton.styleColors.forEach(styleColor => {
        styleColor.items.forEach(item => {
          const orderNumber = isGreaterChinaDoor
            ? { 'Shipment Number': transfer?.shipmentDocumentNumber ? transfer?.shipmentDocumentNumber : '-' }
            : { 'Purchase Order Number': transfer?.purchaseOrderNumber ? transfer?.purchaseOrderNumber : '-' };

            download.push({
              'Date': transfer.creationDate,
              'Transfer Type': transfer.targetType,
              'Ship To': transfer.targetLocation,
              ...orderNumber,
              'Cartons': transfer.totalCartons,
              'Total Units': transfer.totalItems,
              'Total Price': transfer.totalPrice,
              'Status': transfer.status,
              'Op Id': transfer.opId,
              'Carton ID': carton.cartonNumber,
              'Style Color': styleColor.styleColor,
              'Class': styleColor.class,
              'Description': styleColor.description,
              'Size': item.size,
              'UPC': item.gtin,
              'Units': item.quantity,
              'Price': getFormattedPrice(item.price, locale, currencyCode),
            });

          });
        });
      });
    });
   return download;
};

/**
 * Finds the selected row and converts its innards into a material and quantity format suitable for
 * fiscal compliance downloads.
 * @param {*} selectedRowIndex index of the row the user has selected
 * @param {*} data all of the data in the table to use the index to find the appropriate row of data.
 * @returns
 */
export const buildFiscalDownloadData = (selectedRowIndex, data) => {
  const toDownload = [];

  const selectedItem = data?.find((item, idx) => idx === selectedRowIndex);

  selectedItem?.cartons?.map(carton => carton?.styleColors?.map(styleColor => {
    const materialQuantity = styleColor?.items?.reduce((a, b) => a + (b.quantity || 0), 0);
    const sameStyleColorIndex = toDownload.findIndex(_ => _.material === styleColor?.styleColor);
    if (sameStyleColorIndex !== -1) {
      toDownload[sameStyleColorIndex].quantity += materialQuantity;
    } else {
      toDownload.push({
        material: styleColor?.styleColor,
        quantity: materialQuantity,
      });
    }
  }));
  return toDownload;
};

/**
 * Utils that handles setting/changing steps in the tutorial whe clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 */
export const setTransfersTutorialSteps = (startTutorial, stepIndex, isbackClicked, restartTutorial) => {
  if (startTutorial) {
    if (stepIndex === 5 || (stepIndex === 4 && isbackClicked)) {
      document.querySelector('[data-testid="MUIDataTableBodyRow-0"] button')?.click();
    }
  }
  if (restartTutorial) {
    document.querySelector('[data-testid="MUIDataTableBodyRow-0"] button')?.click();
  }
};
