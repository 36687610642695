import { useContext } from 'react';
import PriceChangesProvider from '../../../containers/priceChanges/PriceChanges.provider';
import MarkdownsWidget from './MarkdownsWidget';
import { SimWebContext } from '../../../context/SimWeb.provider';

const MarkdownsWidgetContainer = () => {
  const { storeConfig } = useContext(SimWebContext);

  const isPriceChangesEnabled = storeConfig?.isPriceChangesEnabled?.value;
  return isPriceChangesEnabled && (
    <PriceChangesProvider>
      <MarkdownsWidget />
    </PriceChangesProvider>
  );
};

export default MarkdownsWidgetContainer;
