const buildMode = import.meta.env.MODE || 'test';

const getEnv = () => {
  if (buildMode === 'staging' || buildMode === 'prod') {
    return 'prod';
  }
  return 'test';
};

export const isLocalHost = () =>
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === '';
export const isProd = () => getEnv() === 'prod';

/**
 * Badge to be displayed on the navigation bar.
 * @returns {string} - Returns the badge to be displayed on the navigation bar.
 */
const getBadge = () => {
  if (isLocalHost()) {
    return 'Local';
  }
  if (buildMode === 'test') {
    return 'Test';
  }
  if (buildMode === 'staging') {
    return 'staging';
  }
  return 'Prod';
};

export const navigationBadge = getBadge();
export const environment = getEnv();

/**
 * These are the environment variables that are used in the application.
 */
const config = {
  test: {
    AUTH_V3_CLIENT_ID: import.meta.env.VITE_AUTH_V3_CLIENT_ID,
    AUTH_V3_CLIENT_SECRET: import.meta.env.VITE_AUTH_V3_CLIENT_SECRET_TEST,
    TEMP_AUTH_V3_CLIENT_ID: import.meta.env.VITE_TEMP_AUTH_V3_CLIENT_ID,
    TEMP_AUTH_V3_CLIENT_SECRET: import.meta.env.VITE_TEMP_AUTH_V3_CLIENT_SECRET,
    BFF_URL: import.meta.env.VITE_BFF_URL,
  },
  prod: {
    AUTH_V3_CLIENT_ID: import.meta.env.VITE_AUTH_V3_CLIENT_ID,
    AUTH_V3_CLIENT_SECRET: import.meta.env.VITE_AUTH_V3_CLIENT_SECRET_PROD,
    TEMP_AUTH_V3_CLIENT_ID: 'undefined', // This do not exist in prod
    TEMP_AUTH_V3_CLIENT_SECRET: 'undefined', // This do not exist in prod
    BFF_URL: import.meta.env.VITE_BFF_URL,
  },
};

export const getEnvConfig = () => {
  return config[import.meta.env.MODE] || config.test;
};
