import { object, string } from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import {
  CarouselProvider, Slider, Slide, ButtonBack, ButtonNext,
} from 'pure-react-carousel';
import Typography from '@mui/material/Typography';
import NavigateNext from '@mui/icons-material//NavigateNext';
import NavigateBefore from '@mui/icons-material//NavigateBefore';
import BackdropShoe from '../assets/Svgs/BackdropShoe';
import 'pure-react-carousel/dist/react-carousel.es.css';

const ProductImageCarousel = ({ imageSet = {}, noImageLabel }) => {
  if (!imageSet || !imageSet.images || !imageSet.images.length) {
    return (
      <div
        className="image"
        data-testid="no-images"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BackdropShoe style={{ filter: 'saturate(0%)' }} />
        <Typography variant="h5" gutterBottom>
          {noImageLabel}
        </Typography>
      </div>
    );
  }

  const { images } = imageSet;
  const domain = imageSet?.defaultDomains?.[0];

  return (
    <div className="image" data-testid="image-slider">
      <CarouselProvider
        naturalSlideHeight={300}
        naturalSlideWidth={300}
        totalSlides={images.length}
      >
        <Slider>
          {images.map((image, index) => (
            <Slide key={image?.view ? `${image.view}-${index}` : ''}>
              <VisibilitySensor>
                <img
                  src={image?.src ? `https://${domain}/${image.company}/${image.view}?scl=3&cropN=0,0,1,1` : image?.url}
                  onDragStart={e => e.preventDefault()}
                  width="100%"
                  height="100%"
                />
              </VisibilitySensor>
            </Slide>
          ))}
        </Slider>
        <ButtonBack className="back"><NavigateBefore /></ButtonBack>
        <ButtonNext className="next"><NavigateNext /></ButtonNext>
      </CarouselProvider>
    </div>
  );
};

ProductImageCarousel.propTypes = {
  imageSet: object,
  noImageLabel: string.isRequired,
};

export default ProductImageCarousel;
