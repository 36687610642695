import { newRelicAttribute } from './newRelicPageActions';
import * as constants from '../constants/storageKeys.constants';

/**
 * Allows us to check the device the user is using in order to save the login source
 * to properly parse the tokens and display features based off of the device used
 */
const setLoginSource = () => {
  const isIOS = typeof navigator.standalone === 'boolean';
  const userAgent = navigator?.userAgent?.toLowerCase();
  const isMobile =
    (userAgent.includes('ipad') || userAgent.includes('iphone') || navigator.maxTouchPoints > 0) &&
    userAgent.includes('mac os x');
  const loginSourceFromUrl = window.location.search?.split('=').pop() || localStorage.getItem('IOS_SOURCE_QUERY');

  if (window.location !== window.parent.location) {
    /**
     * User is using SIM on desktop via retail launchpad
     *
     * Required for:
     *   - special login functions (iFrame)
     *   - special logout functions (iFrame)
     *   - reporting purposes
     */
    localStorage.setItem(constants.LOGIN_SOURCE, constants.LAUNCHPAD);
  } else if (loginSourceFromUrl?.toUpperCase() === constants.IOS && isMobile) {
    /**
     * User is using SIM via iOS wrapper app
     * ?source=iOS is hardcoded in the wrapper, therefore
     * this is a reliabale way to determine if the user is using the wrapper.
     *
     * Required for:
     *   - special login functions
     *   - special logout functions
     *   - visual rendering effects (isIOS)
     *   - reporting purposes
     */
    localStorage.setItem(constants.LOGIN_SOURCE, constants.IOS_WRAPPER);
  } else if (isIOS && isMobile) {
    /**
     * User is using SIM via iOS device browser
     *
     * Required for:
     *   - visual rendering effects (isIOS)
     *   - reporting purposes
     */
    localStorage.setItem(constants.LOGIN_SOURCE, constants.IOS_BROWSER);
  } else {
    /**
     * User is using SIM via desktop browser.  Expect default behaviors.
     */
    localStorage.setItem(constants.LOGIN_SOURCE, constants.BROWSER);
  }

  const loginSource = localStorage.getItem(constants.LOGIN_SOURCE);
  newRelicAttribute('sim-web-source', loginSource);
};

export default setLoginSource;
