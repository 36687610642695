import { useContext } from 'react';
import { createTheme } from '@mui/material';
import BaseViewPage from '../baseView/BaseViewPage';
import { SimWebContext } from '../../context/SimWeb.provider';
import { ProductMovementContext } from './ProductMovement.provider';
import { baseMuiTheme } from '../baseView/baseViewPage.utils';

import './ProductMovementPage.css';
import ChevronButton from '../../components/ChevronButton';

const ProductMovementPage = () => {
  const { getMessage, locale } = useContext(SimWebContext);
  const {
    data, loading, error, summaryColumns, datepicker, tableOptions, detailsColumns,
    productModal, setIsOpen, setTableIndex, setModalStyleColor, setSelectedScan, selectedScan, id,
  } = useContext(ProductMovementContext);

  const theme = createTheme({
    ...baseMuiTheme,
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            '&:first-of-type': {
              paddingLeft: '70px',
            },
          },
        },
      },
    },
  });

  const summaryOptions = {
    ...tableOptions,
    onRowClick: (rowData, { dataIndex }) => {
      setSelectedScan(data[dataIndex]);
    },
  };

  const detailsOptions = {
    ...tableOptions,
    onRowClick: (rowData, { dataIndex }) => {
      setIsOpen(true);
      setTableIndex(dataIndex);
      setModalStyleColor(selectedScan.products[dataIndex].styleColor);
    },
  };

  const detailsTitle = (
    <>
      <div className="product-movement-table-header" style={{ marginTop: '20px' }}>{`${getMessage(id)} ${getMessage('scan')}`.toUpperCase()}</div>
      <>
        <span className="product-movement-title-label">{`${getMessage('date')}: `}</span>
        <span className="product-movement-title-data">{new Date(selectedScan?.date).toLocaleString(locale)}</span>
      </>
      <br />
      <>
        <span className="product-movement-title-label">{`${getMessage('unitsScanned')}: `}</span>
        <span className="product-movement-title-data">{selectedScan?.scanned}</span>
      </>
      <br />
      <>
        <span className="product-movement-title-label">{`${getMessage('destination')}: `}</span>
        <span className="product-movement-title-data">{getMessage(selectedScan?.destination)}</span>
      </>
    </>
  );

  return (
    <>
      {!selectedScan ? (
        <BaseViewPage
          id={id}
          title={id}
          loading={loading}
          storeConfigValue="rfidEnabled"
          error={error}
          theme={theme}
          datepicker={datepicker}
          details={{
            options: summaryOptions,
            columns: summaryColumns,
            rows: data,
          }}
        />
      ) : (
        <>
          <ChevronButton
            type="button"
            chevron="left"
            onClick={() => setSelectedScan(null)}
            data-testid="product-movement-back-button"
            extraClasses="product-movement-back-button"
            buttonText={getMessage('back')}
          />
          <BaseViewPage
            id={id}
            title={id}
            theme={theme}
            storeConfigValue="rfidEnabled"
            dialog={productModal}
            details={{
              title: detailsTitle,
              options: detailsOptions,
              columns: detailsColumns,
              rows: selectedScan?.products,
              theme,
            }}
          />
        </>
      )}
    </>
  );
};

export default ProductMovementPage;
