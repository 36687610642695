import {
  useState, createContext, useContext, useEffect, useMemo,
} from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { node, object } from 'prop-types';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import { scanTypesLabel, missingCountSort, defaultPagination } from '../scanReportConstants';
import {
  getScanAccuracy, getScanAggregations, getScanCategory, getScanDuration, getScanLocationAggregations, getScanDateRangeString,
} from './scanReportDetails.utils';
import { fetchScanReportData } from '../scanReport.axios';

import { SimWebContext } from '../../../context/SimWeb.provider';

export const ScanDetailsContext = createContext({});

const ScanDetailsProvider = ({ children = {}, mockedValue = null }) => {
  const { sessionId, endDate } = useParams();
  const { Provider } = ScanDetailsContext;
  const {
    storeId, getMessage, locale, region, isOffsiteEnabled, storeConfig, SimDateTime,
  } = useContext(SimWebContext);

  const defaultFilter = [
    {
      name: 'sessionId',
      operator: 'EQUALS',
      value: sessionId,
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [filters, setFilters] = useState(defaultFilter);
  const [searchText, setSearchText] = useState('');
  const [pageSearchText, setPageSearchText] = useState('');
  const [dataSort, setDataSort] = useState(missingCountSort);
  const [pagination, setPagination] = useState(defaultPagination);
  const [selectedScanPagesData, setSelectedScanPagesData] = useState(null);
  const [rfidScanStatus, setRfidScanStatus] = useState('');

  const id = 'scanReport';
  const accuracyThreshold = storeConfig?.['rfid.scanReportAccuracyThreshold']?.value;

  useEffect(() => {
    const fetchScan = async (storeId, filters, pageSearchText) => {
      setLoading(true);

      try {
        const response = await fetchScanReportData(region, {
          storeId,
          count: pagination.currentCount,
          pageNumber: pagination.currentPageNumber,
          textQuery: pageSearchText,
          filters,
          aggregateBy: 'MISSING,SCANNED,ACCURACY,EXTRA,EXPECTED',
          sortBy: dataSort,
          ...(endDate && { endDateInclusive: endDate }),
        });
        setData(response);
        setSelectedScanPagesData(response?.pages);
      } catch (error) {
        setError(error?.message);
      } finally {
        setLoading(false);
      }
    };

    if (!sessionId) return;
    fetchScan(storeId, filters, pageSearchText);
  }, [storeId, filters, pageSearchText, dataSort, pagination]);

  const scanData = useMemo(() => data?.supportedFilters?.find(_ => _.name === 'sessionId').values?.find(_ => _.value === sessionId), [data, sessionId]);
  const scans = useMemo(() => data?.scans, [data]);
  const scanLocation = useMemo(() => data?.supportedFilters?.find(_ => _.name === 'scannedArea')?.values, [data]);
  const scanType = useMemo(() => scanData?.scanType, [scanData]);

  const resumeCount = useMemo(() => scanData?.scanStatus?.totalResumeCount ?? 0, [scanData]);
  const lastStatusOfScan = useMemo(() => scanData?.scanStatus?.status, [scanData]);
  const accuracy = useMemo(() => scanData?.aggregations?.find(_ => _.field === 'accuracy')?.value || 0, [scanData]);

  useEffect(() => {
    if (!isEmpty(scanData)) {
      newRelicAction('single-scan-details-summary', {
        accuracy: scanData?.aggregations?.find(_ => _.field === 'accuracy')?.value,
        extra: scanData?.aggregations?.find(_ => _.field === 'extra')?.value,
        missing: scanData?.aggregations?.find(_ => _.field === 'missing')?.value,
        scanned: scanData?.aggregations?.find(_ => _.field === 'scanned')?.value,
        expected: scanData?.aggregations?.find(_ => _.field === 'expected')?.value,
        duration: scanData?.scanStatus?.scanDuration,
        numberOfScanners: data?.scans[0]?.numberOfScanners,
        scanId: scanData?.value,
      });
    }
  }, [scanData]);

  const reportInfo = useMemo(() => {
    if (!scanData) return null;

    return (
      <div>
        <p className="scan-report-total-calculation" data-testid="scan-report-total-average">
          {getScanAccuracy(scanData, getMessage)}
        </p>
        <p className="scan-report-scan-duration" data-testid="scan-report-scan-duration">
          {getScanDuration(scanData, getMessage)}
        </p>
        <p className="scan-report-total-calculation" data-testid="scan-report-scan-type">
          {getMessage(scanTypesLabel[scanType])}
        </p>
        <p className="scan-report-scan-dates" data-testid="scan-report-selected-date">
          {getScanDateRangeString(scanData, SimDateTime)}
        </p>
        <div className="scan-report-header-aggregations checkbox_additional-text" data-testid="scan-report-aggregations">
          {getScanAggregations(scanData, locale)}
        </div>
        <div className="scan-report-header-location-aggregations" data-testid="scan-report-location-aggregations">
          {`${getMessage('scannedUnitsByLocation')}: `}
          {getScanLocationAggregations(scanLocation, locale, isOffsiteEnabled)}
        </div>
        {getScanCategory(scanData)}
      </div>
    );
  }, [scanData, scanType, scanLocation, locale]);

  const clearFilters = () => {
    setSearchText('');
    setPageSearchText('');
    setPagination(defaultPagination);
    setFilters(filters.filter(_ => _.name === 'sessionPurpose' || _.name === 'sessionId'));
  };

  return (
    <Provider
      value={mockedValue ?? {
        id,
        title: id,
        accuracy,
        accuracyThreshold,
        dataSort,
        data,
        error,
        filters,
        lastStatusOfScan,
        loading,
        pagination,
        reportInfo,
        resumeCount,
        rfidScanStatus,
        scanData,
        scanLocation,
        scanType,
        scans,
        searchText,
        selectedScanPagesData,
        sessionId,
        clearFilters,
        setDataSort,
        setFilters,
        setRfidScanStatus,
        setPageSearchText,
        setPagination,
        setSearchText,
        setSelectedScanPagesData,
      }}
    >
      {children}
    </Provider>
  );
};

ScanDetailsProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default ScanDetailsProvider;
