import {
  useContext, useMemo, useRef, useState,
} from 'react';
import { array, string, func } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import ReactToPrint from 'react-to-print';
import writeXlsxFile from 'write-excel-file';
import PrintIcon from '@mui/icons-material/Print';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { filterSelectedData, buildDownloadData, buildDownloadHeaders } from './adjustments.utils';
import PrinterPage from './print/PrinterPage';
import ExpandableRows from './print/ExpandableRows';

import { SimWebContext } from '../../context/SimWeb.provider';

import './AdjustmentsPage.css';

const useStyles = makeStyles({
  printIcon: {
    fontSize: '37px',
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
});

const theme = createTheme(({
  components: {
    MUIDataTableToolbar: {
      styleOverrides: {
        actions: {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
    },
  },
}));

const AdjustmentsTable = ({
  data = [],
  handlePrintingRequest,
  selectedPrintType = '',
}) => {
  const {
    isIOS, getMessage, locale, currencyCode, SimDateTime,
  } = useContext(SimWebContext);
  const [selectedItems, setSelectedItems] = useState([]);
  const classes = useStyles();
  const printRef = useRef();

  const customToolbar = () => (
    <>
      {data?.length > 0 && (
        <div>
          {!isIOS && selectedPrintType !== 'dispatchNote' && (
            <>
              <ReactToPrint
                trigger={() => (
                  <PrintIcon
                    className={classes.printIcon}
                    data-testid="print-icon"
                    style={{ width: '25px' }}
                  />
                )}
                content={() => printRef.current}
              />
            </>
          )}
          {!isIOS && (selectedPrintType === 'fiscalDoc' || selectedPrintType === 'dispatchNote') && (
            <PrintIcon
              className={classes.printIcon}
              data-testid="print-icon-fiscal"
              style={{ width: '25px' }}
              onClick={() => handlePrintingRequest(filterSelectedData(data, selectedItems))}
            />
          )}
        </div>
      )}
    </>
  );

  const columns = [
    {
      name: 'creationDate',
      label: getMessage('date'),
      options: {
        customBodyRender: (value) => (
          <>{SimDateTime.toLocaleString(value, DATE_SHORT_WITH_APPENDED_ZEROS)}</>
        ),
      },
    },
    {
      name: 'creationDate',
      label: getMessage('time'),
      options: {
        customBodyRender: (value) => (
          <>{SimDateTime.toLocaleString(value, SimDateTime.TIME_SIMPLE)}</>
        ),
      },
    },
    {
      name: 'type',
      label: getMessage('type'),
      options: {
        customBodyRender: (value) => (
          <>{getMessage(value)?.toUpperCase() }</>
        ),
      },
    },
    {
      name: 'totalUnits',
      label: getMessage('units'),
      options: {
        setCellHeaderProps: () => ({
          'data-testid': 'columnStep',
        }),
      },
    },
    { name: 'opId', label: getMessage('opId') },
    { name: 'searchHelper', options: { display: 'excluded' } },
  ];

  const printTable = useMemo(() => (
    <div ref={printRef} data-testid="adjustment-table">
      <PrinterPage
        columns={columns}
        getMessage={getMessage}
        data={filterSelectedData(data, selectedItems)}
        locale={locale}
        currencyCode={currencyCode}
      />
    </div>
  ), [selectedItems]);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MUIDataTable
            data={data}
            columns={columns}
            options={{
              filter: false,
              viewColumns: false,
              search: data?.length > 0,
              customSearch: (searchQuery, currentRow) => {
                let isFound = false;
                currentRow.forEach(col => {
                  if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                    isFound = true;
                  }
                });
                return isFound;
              },
              pagination: false,
              selectableRows: data?.length > 0 ? 'multiple' : 'none',
              print: false,
              onDownload: () => {
                const headerRow = buildDownloadHeaders();
                const dataRows = buildDownloadData(data, selectedItems);
                try {
                  writeXlsxFile([headerRow, ...dataRows], { fileName: `Adjustments Table-${SimDateTime.toUtcISO()}.xlsx` });
                } catch (error) {
                  console.error(error);
                }
                return false; // cancels default datatable download logic
              },
              textLabels: {
                body: {
                  noMatch: getMessage('noData'),
                },
              },
              onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                setSelectedItems(rowsSelected);
              },
              rowsSelected: selectedItems,
              customToolbarSelect: () => customToolbar(),
              customToolbar: () => customToolbar(),
              expandableRows: true,
              expandableRowsOnClick: true,
              expandableRowsHeader: data?.length > 0,
              renderExpandableRow: (rowData, { dataIndex }) => (
                <tr>
                  <td colSpan={rowData.length + 1}>
                    <ExpandableRows
                      data={data[dataIndex]}
                      getMessage={getMessage}
                      locale={locale}
                      currencyCode={currencyCode}
                    />
                  </td>
                </tr>
              ),
            }}
          />
        </ThemeProvider>
      </StyledEngineProvider>
      {printTable}
    </>
  );
};

AdjustmentsTable.propTypes = {
  data: array,
  handlePrintingRequest: func.isRequired,
  selectedPrintType: string,
};

export default AdjustmentsTable;
