import { func, bool, string } from 'prop-types';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

import './ErrorModal.css';

/**
 * A modal which displays a given error message
 * @param {sytring} buttonLabel - the label of the error button
 * @param {func} onClose - handles close actions
 * @param {bool} open - if the modal is open
 * @param {string} errorMessage - the error message to display
 */
const ErrorModal = ({
  onClose, open, errorMessage, buttonLabel,
}) => (
  <div className="error-modal-container" data-testid="error-modal">
    <Modal
      aria-describedby="error-modal-description"
      open={open}
      onClose={onClose}
      className="error-modal"
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <div className="error-modal-body" data-test-id="error-modal">
          <div className="error-modal-description" id="error-modal-description" data-testid="error-modal-description">
            {errorMessage}
          </div>
          <button
            type="button"
            onClick={onClose}
            className="error-modal-button"
            data-testid="error-modal-button"
          >
            {buttonLabel}
          </button>
        </div>
      </Fade>
    </Modal>
  </div>
);

ErrorModal.propTypes = {
  buttonLabel: string.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
  errorMessage: string.isRequired,
};

export default ErrorModal;
