import { useEffect } from 'react';
import { Settings } from 'luxon';
import { Route, useHistory, Redirect } from 'react-router-dom';
import { setDefaultZone, CET_TIMEZONE } from '../utils/datetime';
import { LoadingIndicator } from '../assets/Svgs/index';
import useSimStore from '../store/simStore';
import useAuth from '../hooks/useAuth';
import { LOGIN_SOURCE } from '../constants/storageKeys.constants';
import { isEmpty } from 'lodash';

/**
 * Creates a private route that requires authentication to access.
 * If the user is not authenticated, they are redirected to the login page.  
 * 
 * Temporarily forces CET timezone for inbound visibility for EDD reasons.
 *
 * @param {*} param - route props
 * @returns
 */
export const PrivateRoute = ({ ...props }) => {
  const history = useHistory();
  const storeInfo = useSimStore(state => state.storeInfo);
  const storeConfig = useSimStore(state => state.storeConfig);
  const loginSource = localStorage.getItem(LOGIN_SOURCE);

  const { isLoggedIn } = useAuth();
  const attemptedRoute = history.location.pathname.split('/')?.[1];

  /**
   * Forces CET timezone for inbound visibility for EDD reasons.
   * TODO: Move this to a better place.
   */
  useEffect(() => {
    if (attemptedRoute.toLowerCase() === 'inboundvisibility' && storeInfo?.isEMEA) {
      setDefaultZone(CET_TIMEZONE);
    } else if (storeInfo?.timezone !== Settings.defaultZone) {
      setDefaultZone(storeInfo?.timezone);
    }
  }, [attemptedRoute]);

  if (isEmpty(storeInfo) && isEmpty(storeConfig)) {
    return <LoadingIndicator extraClass="login_loading" />;
  }

  return loginSource && (isLoggedIn() ? <Route {...props} /> : <Redirect to="/login" />);
};

export default PrivateRoute;
