import { useContext, useMemo } from 'react';
import { func, object } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { TextField, DialogContent } from '@mui/material';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import Button from '../../../components/Button';
import BarcodeScannerButton from '../../../components/barcodeScanner/BarcodeScannerButton';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import ErrorAlert from '../../../components/alerts/Error';
import { getTranslatedStoreAreaOptions } from './receiveShipmentModal.utils';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { ReceivingLogContext } from '../ReceivingLog.provider';

import './ReceiveShipmentModal.css';

const styles = ({
  textField: {
    margin: '20px',
    '& legend span': {
      fontSize: '1rem',
    },
  },
});

const ReceiveShipmentModal = ({ classes, setOpenModal, setIsBarcodeOpen }) => {
  const { getMessage, isOffsiteEnabled, storeConfig } = useContext(SimWebContext);
  const {
    receiveCartons, receiveCartonCompleted, receiveCartonError, receiveCartonLoading, receiveShipmentInput,
    setReceiveCartonArea, setReceiveShipmentInput,
  } = useContext(ReceivingLogContext);

  const isBarcodeScannerEnabled = storeConfig?.isItemInquiryBarcodeScannerEnabled?.value;

  const resetModal = () => {
    setOpenModal(false);
    setReceiveShipmentInput('');
  };

  const content = useMemo(() => {
    if (receiveCartonError.length > 0) {
      const errorAlerts = receiveCartonError.map((error, index) => (
        <ErrorAlert
          key={`error-${index}`}
          errorObject={error.message || error[0].message || ''}
          apiName="Receive Shipment"
          pageCode=""
        />
      ));
      return (
        <div
          className="receive-shipment-error-content"
          data-testid="receive-shipment-error-content"
        >
          {errorAlerts}
        </div>
      );
    }
    if (receiveCartonCompleted && receiveCartonError.length === 0) {
      return (
        <div
          className="receive-shipment-success-content"
          data-testid="receive-shipment-success-content"
        >
          <h2>{getMessage('shipmentsSubmitted')}</h2>
        </div>
      );
    }
    if (receiveCartonLoading) return <LoadingIndicator extraClass="receiving-log-receive-shipment-loading" />;

    let storeAreaOptions = [
      { label: 'STOCKROOM', value: 'STOCK_ROOM' },
    ];

    if (isOffsiteEnabled) storeAreaOptions = [...storeAreaOptions, { label: 'OFFSITE', value: 'OFFSITE' }];

    const translatedOptions = getTranslatedStoreAreaOptions(storeAreaOptions, getMessage);
    return (
      <div
        className="receive-shipment-input-content"
        data-testid="receive-shipment-input-content"
      >
        <CustomSelect
          label={getMessage('receivingDestination')}
          options={translatedOptions}
          defaultValue={translatedOptions[0]}
          onChange={e => setReceiveCartonArea(e.value)}
          customClass="receive-shipment-storeArea-dropdown"
          customSelectClass="receive-shipment-storeArea-customize-dropdown"
        />
        <TextField
          type="text"
          id="receive-shipment-input"
          className={classes.textField}
          data-testid="receive-shipment-input"
          label={getMessage('cartonOrDocNumber')}
          margin="normal"
          variant="outlined"
          autoFocus
          fullWidth
          onChange={(e) => { setReceiveShipmentInput(e.target.value); }}
          value={receiveShipmentInput}
        />
        { isBarcodeScannerEnabled && (
          <BarcodeScannerButton
            setIsBarcodeOpen={setIsBarcodeOpen}
          />
        )}
      </div>
    );
  }, [receiveCartonCompleted, receiveCartonError, receiveCartonLoading, receiveShipmentInput]);

  const buttons = useMemo(() => {
    if (receiveCartonLoading) return null;
    if (receiveCartonCompleted || receiveCartonError.length > 0) {
      return (
        <Button
          extraClasses="receive-shipment-ok-button"
          onClick={() => setOpenModal(false)}
          buttonText={getMessage('ok')}
          dataTestId="receive-shipment-ok-button"
        />
      );
    }
    return (
      <>
        <Button
          extraClasses="receive-shipment-submit-button"
          onClick={() => receiveCartons(receiveShipmentInput)}
          buttonText={getMessage('submit')}
          dataTestId="receive-shipment-submit-button"
        />
        <Button
          extraClasses="receive-shipment-cancel-button"
          onClick={() => resetModal()}
          buttonText={getMessage('cancel')}
          dataTestId="receive-shipment-cancel-button"
        />
      </>
    );
  }, [receiveCartonCompleted, receiveCartonError, receiveCartonLoading, receiveShipmentInput]);

  return (
    <div
      className="receiving-log-receive-shipment-modal"
      data-testid="receiving-log-receive-shipment-modal"
    >
      <DialogContent>
        {content}
      </DialogContent>
      <div className="receive-shipment-buttons">
        {buttons}
      </div>
    </div>
  );
};

ReceiveShipmentModal.propTypes = {
  classes: object.isRequired,
  setOpenModal: func.isRequired,
  setIsBarcodeOpen: func.isRequired,
};

export default withStyles(styles)(ReceiveShipmentModal);
