import { useRef, useContext } from 'react';
import {
  Dialog, DialogActions, DialogContent, Grid2,
} from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import ReactToPrint from 'react-to-print';
// import { newRelicAction } from '../../../utils/newRelicPageActions';
import Button from '../../components/Button';
import { ChallanContext } from './Challan.provider';
import { SimWebContext } from '../../context/SimWeb.provider';

const PrintChallanDialog = () => {
  const { challanData, printDialogOpen, setPrintDialogOpen } = useContext(ChallanContext);
  const { getMessage } = useContext(SimWebContext);
  const printRef = useRef();

  const theme = createTheme(({
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            overflowY: 'hidden',
          },
        },
      },
    },
  }));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog
          open={printDialogOpen}
          onClose={() => setPrintDialogOpen(false)}
          maxWidth="md"
          fullWidth
          data-testid="print-challan-dialog"
        >
          <DialogContent>
            {/* TODO: Add library to display PDF */}
            {/* {challanData?.map((item, index) => (
              <Grid2
                key={index}
                style={{ pageBreakAfter: 'always' }}
              >
                {item}
              </Grid2>
            ))} */}
            <Grid2 style={{ pageBreakAfter: 'always' }}>
              {challanData}
            </Grid2>
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => (
                <Button
                  id="sim-printDropZoneButton"
                  buttonText={getMessage('print')}
                />
              )}
              content={() => printRef.current}
            />
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default PrintChallanDialog;
