import { func } from 'prop-types';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import PickListIcon from '@mui/icons-material/PlaylistAddCheckSharp';

const PicklistToolTip = withStyles({
  tooltip: {
    backgroundColor: '#292929',
    color: '#DEDEDE',
    accentColor: '#292929',
    fontWeight: 500,
    fontSize: '12px',
    border: '1px solid black',
    padding: '10px',
  },
})(Tooltip);

// TODO: may need to move this out of here since its used in two places
/**
 * Custom datatable toolbar used on the Size Compliance page,
 * allows the user to create a picklist with selected rows in the table
 * @param {func} createPickList - the function to create a picklist with selected rows when clicked
 */
const PickListToolBar = ({
  createPickList,
}) => (
  <div className="size-compliance-picklist-toolbar-container" data-testid="size-compliance-picklist-toolbar-container">
    <PicklistToolTip className="scan-progress-tool-tip" data-testid="scan-progress-tooltip" title="Start Picklist" placement="top">
      <IconButton
        className="picklist-button"
        data-testid="size-compliance-picklist-button"
        onClick={createPickList}
        size="large"
      >
        <PickListIcon />
      </IconButton>
    </PicklistToolTip>
  </div>
);

PickListToolBar.propTypes = {
  createPickList: func.isRequired,
};

export default PickListToolBar;
