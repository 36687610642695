import { useContext } from 'react';
import { bool, func, string } from 'prop-types';
import { DialogContent, DialogActions } from '@mui/material';
import Button from '../Button';

import './confirmationModal.css';
import { TranslationsContext } from '../../context/Translations.provider';

const ConfirmationModal = ({
  dataTestId = 'confirmation-modal',
  isLoading = false,
  onClick,
  message,
  setIsOpen,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  return (
    <div data-testid={dataTestId} className="confirmation-modal">
      <DialogContent>
        <div className="confirmation-modal-content">
          <h2>{message}</h2>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="confirmation-btns">
          <Button
            extraClasses="confirmation-modal-submit-btn"
            onClick={onClick}
            isLoading={isLoading}
            buttonText={getMessage('submit')}
            dataTestId="confirmation-modal-submit-btn"
          />
          <Button
            extraClasses="confirmation-modal-cancel-btn"
            onClick={() => setIsOpen(false)}
            buttonText={getMessage('cancel')}
            dataTestId="confirmation-modal-cancel-btn"
          />
        </div>
      </DialogActions>
    </div>
  );
};

ConfirmationModal.propTypes = {
  dataTestId: string,
  isLoading: bool,
  onClick: func.isRequired,
  message: string.isRequired,
  setIsOpen: func.isRequired,
};

export default ConfirmationModal;
