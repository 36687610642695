/* eslint-disable no-useless-catch */
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { baseUrl, endpoints, routerUrls } from '../../axios/endpoints';
import { SERVER_REGION } from '../../constants/storageKeys.constants';

export const fetchMissingScanReportSessions = async (storeId, startDate, endDate, timezone, locale) => {
  if (!storeId || !startDate || !endDate || !locale) return null;

  const region = localStorage.getItem(SERVER_REGION);

  const payload = {
    region,
    start: startDate,
    end: endDate,
    storeId,
    locale,
    timezone,
  };

  try {
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.MISSING_STYLES.url}`;
    const response = await generalAxiosRequest('POST', url, endpoints.MISSING_STYLES, true, null, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchMissingStylesDetails = async (jobId, sessionId) => {
  if (!jobId || !sessionId) return null;

  const region = localStorage.getItem(SERVER_REGION);

  const payload = {
    region,
    jobId,
    sessionId,
    count: 6000,
  };

  try {
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.MISSING_STYLES_DETAILS.url}`;
    const response = await generalAxiosRequest('POST', url, endpoints.MISSING_STYLES_DETAILS, true, null, payload);
    return response;
  } catch (error) {
    throw error;
  }
};
