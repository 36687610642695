import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import BackdropShoe from '../assets/Svgs/BackdropShoe';

const ShoeboxFragment = ({ label = 'No data available' }) => (  
  <div className={classNames('itemInquiryWidget_card-stateLayout', 'default-shoebox-state')} data-testid="itemInquiryWidget_card_noData">
    <BackdropShoe />
    <Typography variant="h5" gutterBottom>
      {label}
    </Typography>
  </div>
);

ShoeboxFragment.propTypes = {
  label: PropTypes.string,
};

export default ShoeboxFragment;
