import { createContext } from 'react';
import { node } from 'prop-types';
import useSimStore from '../store/simStore';

export const AdminContext = createContext({});

/**
  * An Admin Provider that handles whether or not a user is an admin.
  * This is used to turn on and off admin features and exposes the admin status to the application.
  *
  * @param {node} - returns boolean value of whether or not a user is an admin and a function to set it.
*/
const AdminProvider = ({ children }) => {
  const { Provider } = AdminContext;

  const isAdmin = useSimStore((state) => state.isAdmin);
  const setIsAdmin = useSimStore((state) => state.setIsAdmin);

  return (
    <Provider
      value={{
        isAdmin,
        setIsAdmin, // This is used in the profile menu to allow users to switch between admin and user views.
      }}
    >
      {children}
    </Provider>
  );
};

AdminProvider.propTypes = {
  children: node,
};

export default AdminProvider;
