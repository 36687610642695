import { useContext, useEffect } from 'react';
import {
  array, bool, string, number, func,
} from 'prop-types';
import writeXlsxFile from 'write-excel-file';
import DialogContent from '@mui/material/DialogContent';
import MUIDataTable from 'mui-datatables';
import { sortBy } from 'lodash';
import { SimWebContext } from '../../context/SimWeb.provider';
import DialogHeader from '../../components/dialogHeader/DialogHeader';
import { SimDateTime } from '../../utils/datetime';

/**
 * Takes in asn tracking data and formats it to be show in the dialog that is shown on the
 * ASN tracking page when an order is clicked on.
 * @param {array} dialogData the data being shown in the dialog
 * @param {function} setDialogData upates the data in the dialog whenever clicking through the chevron buttons
 * @param {string} storeNum the currently logged into store
 * @param {array} rows - array of shipping details per selected date
 * @param {array} setPROIndex - index of the PO Number to control the data in the modal
 * @param {array} proIndex - index of the PO the user has selected for the modal
 * @param {Function} setOpen - func that opens/closes the modal
 */
const DialogDetails = ({
  dialogData,
  setDialogData,
  storeNum,
  rows,
  setPROIndex,
  proIndex,
  setOpen,
  loading = false,
}) => {
  const {
    getMessage, formatMessage, storeConfig,
  } = useContext(SimWebContext);
  const rfidEnabled = storeConfig?.rfidEnabled?.value;

  useEffect(() => {
    const newDialogData = [
      {
        ...rows[proIndex],
        documentNumber: (rows?.[proIndex]?.deliveryNumber || rows?.[proIndex]?.proNumber),
      },
    ];
    setDialogData(newDialogData);
  }, [proIndex, rows]);

  if (!dialogData || !dialogData?.length) {
    return null;
  }

  if (dialogData[0].purchaseOrders === undefined) {
    return null;
  }
  const columns = [
    {
      name: 'poNumber',
      label: getMessage('poNumber'),
    },
    {
      name: 'cartonNumber',
      label: getMessage('cartonID'),
    },
    {
      name: 'styleColor',
      label: getMessage('style-color'),
    },
    {
      name: 'description',
      label: getMessage('description'),
    },
    {
      name: 'class',
      label: getMessage('class'),
    },
    {
      name: 'unitCount',
      label: getMessage('unitsExpected'),
    },
    {
      name: 'soh',
       
      label: (rfidEnabled) ? getMessage('rfidSoh') : getMessage('soh'),
    },
  ];
  /**
   * Builds the header rows for xlsx download
   * @returns an array of objects with value of the header and fontWeight
   */
  const buildDownloadHeaders = () => {
  const headerRow = [
    { value: 'Carton Number', fontWeight: 'bold' },
    { value: 'Purchase Order Number', fontWeight: 'bold' },
    { value: 'Style Color', fontWeight: 'bold' },
    { value: 'Description', fontWeight: 'bold' },
    { value: 'Class', fontWeight: 'bold' },

    { value: 'Division', fontWeight: 'bold' },
    { value: 'Stock On Hand', fontWeight: 'bold' },
    { value: 'Unit Count', fontWeight: 'bold' },
    { value: 'Current Price', fontWeight: 'bold' },
    { value: 'Retail Price', fontWeight: 'bold' },
  ];
  return headerRow;
};
  /**
   * Builds the rows for xlsx download
   * @returns an array of objects with value of the row and type
   */
  const buildDownloadData = () => {
    return dialogData?.[0]?.purchaseOrders?.map(r => [
      { value: r?.cartonNumber || '-'},
      { value: r?.purchaseOrderNumber || '-' },
      { value: r?.styleColor || '-' },
      { value: r?.description || '-'},
      { value: r?.class || '-'},
      { value: r?.division || '-'},
      { value: r?.stockOnHand || '-' },
      { value: r?.unitCount || '-' },
      { value: r?.currentPrice || '-' },
      { value: r?.retailPrice || '-' },
    ]) || [];
  };

  const renderData = dialogData?.[0]?.purchaseOrders?.map(_ => ({
    poNumber: _.purchaseOrderNumber || '-',
    cartonNumber: _.cartonNumber || '-',
    styleColor: _.styleColor || '-',
    description: _.description || '-',
    class: _.class || '-',
    unitCount: _.unitCount || '-',
    soh: _.stockOnHand || '-',
  }));

  const sortedByStyleColor = sortBy(renderData, 'styleColor');
  const sortedByCartonNumber = sortBy(sortedByStyleColor, 'cartonNumber');
  const sortedByPONumber = sortBy(sortedByCartonNumber, 'poNumber');

  return (
    <div data-testid="dialog-muiDataTable">
      <DialogHeader
        loading={loading}
        index={proIndex}
        setIsOpen={setOpen}
        rowCount={rows?.length}
        setIndex={setPROIndex}
        nodeTitle={(
          <>
            <strong>{formatMessage('purchaseOrderListStore', { store: storeNum })}</strong>
            {` ${dialogData[0].documentNumber}`}
          </>
        )}
      />
      <DialogContent>
        <MUIDataTable
          columns={columns}
          data={sortedByPONumber}
          options={{
            selectableRows: 'none',
            rowsPerPage: 100,
            rowsPerPageOptions: [],
            print: false,
            filter: false,
            viewColumns: false,
            downloadOptions: {
              filename: `ASN-${SimDateTime.toUtcISO()}.csv`,
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
              },
            onDownload: () => {
              const headerRow = buildDownloadHeaders();
              const dataRows = buildDownloadData();
              try {
                writeXlsxFile([headerRow, ...dataRows], { fileName: `ASN-${SimDateTime.toUtcISO()}.xlsx` });
              } catch (error) {
                console.error(error);
              }
              return false; // cancels default datatable download logic
            },
          }}
        />
      </DialogContent>
    </div>
  );
};

DialogDetails.propTypes = {
  dialogData: array.isRequired,
  setDialogData: func.isRequired,
  storeNum: string.isRequired,
  rows: array.isRequired,
  setPROIndex: func.isRequired,
  proIndex: number.isRequired,
  setOpen: func.isRequired,
  loading: bool,
};

export default DialogDetails;
