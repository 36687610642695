import { string, node, object } from 'prop-types';

import './DashboardWidgetContainer.css';

const DashboardWidgetContainer = ({
  title = '',
  children = null,
  testid = null,
  style = {},
}) => (
  <div className="dashboardWidgetContainer" data-testid={testid} style={{ ...style }}>
    <p className="dashboardWidgetContainer_title">{title}</p>
    <div className="dashboardWidgetContainer_children">
      {children}
    </div>
  </div>
);

DashboardWidgetContainer.propTypes = {
  title: string,
  children: node,
  testid: string,
  style: object,
};

export default DashboardWidgetContainer;
