import { useState, useContext, useEffect } from 'react';
import { object, array, bool, number, func } from 'prop-types';
import classNames from 'classnames';
import {
  Grid2 as Grid,
  Typography,
  DialogContent,
  Tabs,
  Tab,
  Paper,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import SwipeableViews from 'react-swipeable-views';

import Button from '../Button';
import ChevronButton from '../ChevronButton';
import { LoadingIndicator } from '../../assets/Svgs/index';

import getFormattedPrice from '../../utils/getFormattedPrice';
import CustomSpinner from '../CustomSpinner';
import AdditionalProductDetails from './AdditionalProductDetails';
import ProductInformation from './ProductInformation';
import ShoeboxFragment from '../ShoeboxFragment';

import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../tutorial/Tutorial.provider';

import GtinTable from './GtinTable';

const ProductDetails = ({
  product, loading, error, picklistIndex, setPicklistIndex, setIsOpen, setFormOpen, picklistItems, setSelectedPicklistItems, 
  isCreatingPicklist, optionalColumns, isUnitCountEnabled, selectedStyles, quantityTotal, }) => {
  const {
    labelName, division, retailCategory, genderAge, content, prices,
  } = product || {};

  const { startTutorial, isbackClicked, setBackWasClicked } = useContext(tutorialContext);
  const { currencyCode, locale, isIOS,  getMessage, storeConfig } = useContext(SimWebContext);
  
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const [sohTypeSelected, setSohTypeSelected] = useState(rfidEnabled ? 'RFID SOH' : 'SOH');
  const isRfidSelected = sohTypeSelected === 'RFID SOH';
  const [tabValue, setTabValue] = useState(0);
  const [productPrice, setPrice] = useState(<CustomSpinner
    size="5px"
    color="#5CDAA8"
    loading
  />);
  
  const totalStyles = selectedStyles?.length;
  const index = picklistIndex + 1;

  useEffect(() => {
    setPrice(getFormattedPrice(prices?.currentPrice, locale, currencyCode, startTutorial));
  }, [prices, locale, currencyCode, startTutorial]);

  useEffect(() => {
    if (startTutorial && isbackClicked && tabValue === 1) {
      setTabValue(0);
      setBackWasClicked(false); // for tutorial
  }}, [startTutorial, isbackClicked, tabValue]);

  if (error || (!product && !loading)) {
    return <div data-testid="productDetail-noResult" className="productDetail-noResult"><ShoeboxFragment label={getMessage('noResults')} /></div>;
  }
  if ((loading && (!product && !startTutorial)) || loading) {
    return <div data-testid="productDetail-loading" className="productDetail-loading"><LoadingIndicator width="100px" height="100px" /></div>;
  }

  const nav = (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <ChevronButton
        type="button"
        shape="circle"
        onClick={() => setPicklistIndex(s => s - 1)}
        disabled={index <= 1}
        data-testid="scan-details-back-button"
        extraClasses="scan-details-back-button"
        chevron="left"
        darkMode
      />
      <Button
        buttonText={`${picklistIndex === 0 ? 1 : index} / ${totalStyles} (${quantityTotal}) ${index === totalStyles ? 'SAVE' : ''}`}
        dataTestId="picklist-save-button"
        disabled={index !== totalStyles}
        className={classNames('sim-button-darkMode-hoverIgnore', { 'sim-button-darkMode-hoverIgnore-disabled': index !== totalStyles })}
        onClick={index === totalStyles ? () => {
          setIsOpen(false);
          setFormOpen(true);
        } : undefined}
      />
      <ChevronButton
        type="button"
        shape="circle"
        onClick={() => setPicklistIndex(s => s + 1)}
        disabled={index === totalStyles}
        data-testid="scan-details-next-button"
        extraClasses="scan-details-next-button"
        chevron="right"
        darkMode
      />
    </div>
  );

  return (
    <DialogContent className={`productDetails flex-column ${isIOS ? 'iPad' : ''}`} data-testid="productDetails-content">
      <Grid container alignItems="flex-start">
        <Grid>
          <Grid container direction="column" className="details">
            <Typography variant="h5">
              {labelName}
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              {content?.colorDescription}
            </Typography>
            <Grid style={{ marginTop: 10 }}>
              <Paper square>
                <Tabs
                  value={tabValue}
                  onChange={(_, val) => setTabValue(val)}
                  variant="fullWidth"
                  TabIndicatorProps={{ style: { backgroundColor: '#68d385' } }}
                >
                  <Tab
                    sx={{ '&.Mui-selected': { color: '#666' } }}
                    icon={<DescriptionIcon />}
                    label={getMessage('details')}
                    data-testid="productDetailDetails"
                  />
                  <Tab
                    sx={{ '&.Mui-selected': { color: '#666' } }}
                    icon={<InfoIcon />}
                    label={getMessage('productInfo')}
                    data-testid="productDetailsInfo"
                  />
                </Tabs>
                <SwipeableViews
                  axis="x"
                  index={tabValue}
                  onChangeIndex={setTabValue}
                  style={{ marginTop: '30px' }}
                >
                  <Typography component="div" style={{ padding: '0 24px' }}>
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid size={4}>
                        <ProductInformation
                          rfidEnabled={rfidEnabled}
                          sohTypeSelected={sohTypeSelected}
                          product={product}
                          productPrice={productPrice}
                          locale={locale}
                          currencyCode={currencyCode}
                          startTutorial={startTutorial}
                          setSohTypeSelected={setSohTypeSelected}
                          getMessage={getMessage}
                        />
                      </Grid>
                      <Grid size={8}>
                        <GtinTable 
                          product={product} 
                          picklistItems={picklistItems} 
                          setSelectedPicklistItems={setSelectedPicklistItems} 
                          isRfidSelected={isRfidSelected}
                          optionalColumns={optionalColumns}
                          isCreatingPicklist ={isCreatingPicklist} 
                          isUnitCountEnabled={isUnitCountEnabled} 
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography component="div" className="flex-row" style={{ padding: '0 24px' }}>
                    <Grid container direction="row" alignItems="flex-start">
                      <div style={{ margin: 'auto', display: 'flex', alignItems: 'center' }}>
                        <AdditionalProductDetails
                          getMessage={getMessage}
                          genderAge={genderAge}
                          retailDivision={division}
                          retailCategory={retailCategory}
                        />
                      </div>
                      <Grid size={8}>
                        <div
                          dangerouslySetInnerHTML={
                            {
                              __html: content?.description
                              || getMessage('noDescription'),
                            }
                          }
                          style={{ maxHeight: 450, overflowY: 'auto', paddingRight: 10 }}
                          className="productDescription"
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </SwipeableViews>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isCreatingPicklist && nav}
    </DialogContent>
  );
};

export default ProductDetails;

ProductDetails.propTypes = {
  product: object,
  loading: bool,
  error: bool,
  picklistIndex: number,
  setPicklistIndex: func,
  setIsOpen: func,
  setFormOpen: func,
  picklistItems: array,
  setSelectedPicklistItems: func,
  isCreatingPicklist: bool,
  optionalColumns: array,
  isUnitCountEnabled: bool,
  selectedStyles: array,
  quantityTotal: number,
};