
import { string } from 'prop-types';

export const Flame = ({ extraClasses = '' }) => (
  <div className={`${extraClasses}`}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 11.75C12.923 11.75 12.284 7.43803 12.088 3.53903C12.048 2.75203 10.995 2.54103 10.662 3.25403C8.694 7.46403 5.25 12.821 5.25 15.5C5.25 19.228 8.272 22.25 12 22.25C15.728 22.25 18.75 19.228 18.75 15.5C18.75 11.75 15.25 8.75003 15.25 5.00003" stroke="currentColor" strokeWidth="1.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.876 19.1261C8.876 17.8491 10.569 15.9121 11.463 14.9801C11.759 14.6701 12.241 14.6701 12.537 14.9801C13.431 15.9121 15.124 17.8491 15.124 19.1261C15.124 20.8521 13.726 22.2501 12 22.2501C10.274 22.2501 8.876 20.8521 8.876 19.1261Z" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  </div>
);

Flame.propTypes = {
  extraClasses: string,
};
