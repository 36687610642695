import { useContext } from 'react';
import { TranslationsContext } from '../../../context/Translations.provider';

const TooltipContent = () => {
  const { getMessage } = useContext(TranslationsContext);
  return (
    <div data-testid="size-compliance-tooltip-content">
      <p>
        {`${getMessage('percentCompliant:')} ${getMessage('percentCompliantDescription')}`}
      </p>
      <p>
        {`${getMessage('units:')} ${getMessage('unitsDescriptionSizeCompliance')}`}
      </p>
    </div>
  );
};

export default TooltipContent;
