import { useContext } from 'react';
import { func, string } from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { CameraScan } from '../../assets/Svgs/index';

import { TranslationsContext } from '../../context/Translations.provider';

const BarcodeScannerButton = ({ stroke = 'black', setIsBarcodeOpen }) => {
  const { getMessage } = useContext(TranslationsContext);
  const userAgent = navigator?.userAgent;
  const isMobile = userAgent.includes('iPad') || userAgent.includes('iPhone');

  return isMobile ? (
    <div className="button scan-button">
      <Tooltip title={getMessage('barcodeScannerToolTip')}>
        <IconButton
          data-testid="barcodeScanButton"
          id="barcodeScanButton"
          onClick={() => setIsBarcodeOpen(true)}
          size="large"
        >
          <CameraScan stroke={stroke} />
        </IconButton>
      </Tooltip>
    </div>
  ) : null;
};

BarcodeScannerButton.propTypes = {
  stroke: string,
  setIsBarcodeOpen: func.isRequired,
};

export default BarcodeScannerButton;
