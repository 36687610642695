import { isEmpty } from 'lodash';
import { SimDateTime } from '../../utils/datetime';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';

/**
 * A helper function to detailed audit data for each audit observed
 * @param {array} auditObserved a list of auditObserved object
 */
export const buildAuditsDetailsData = (auditObserved) => {
  let auditData = [];
  let numPassed = 0;

  if (!auditObserved && isEmpty(auditObserved)) return { auditData, numPassed };

  auditData = auditObserved?.map(product => {
    let key = '';
    const [style, color] = (product?.product?.styleColor || '').split('-');
    let auditPassed = true;
    const skus = product?.skus?.map((sku, index) => {
      if (!sku?.scanned) {
        auditPassed = false;
      }
      key += sku?.gtin;

      return {
        key: sku?.id,
        style: index === 0 ? style : '',
        color: index === 0 ? color : '',
        size: sku?.size,
        stockOnHand: sku?.stockOnHand,
        pulled: sku?.refillCount,
        passFail: sku?.scanned,
      };
    });

    if (auditPassed) {
      numPassed += 1;
    }

    return {
      key,
      skus,
      sortKey: `${style}${color}`,
    };
  });

  return { auditData, numPassed };
};

/**
 * A helper functiont to get and build data object from OOS Audit data for the datatable
 * @param {array} data the OOS Audits data
 */
export const buildOOSAuditsTableData = (data) => {
  if (!data || isEmpty(data)) return null;

  const audits = data.map(audit => {
    const { auditData, numPassed } = buildAuditsDetailsData(audit?.auditObserved);

     
    auditData.sort((a, b) => (a.sortKey === b.sortKey ? 0 : (a.sortKey > b.sortKey ? 1 : -1)));

    return {
      auditData,
      numPassed,
      key: audit?.id,
      date: audit?.creationDate,
      athlete: audit?.athlete.includes(';') ? audit?.athlete.split(';')[2] : audit?.athlete,
      auditNumber: audit?.auditNumber,
      auditScore: audit?.auditScore,
      numAudits: auditData.length,
    };
  });

  let numPassedTotal = 0;
  let numAuditsTotal = 0;
  let totalAverage = 0;

  audits.forEach(audit => {
    numPassedTotal += audit.numPassed;
    numAuditsTotal += audit.numAudits;
  });

  totalAverage = Math.round((numPassedTotal / numAuditsTotal) * 100);

  return {
    totalAverage,
    audits,
  };
};

/**
 * A helper function to build the OOS Audits dialog data for CSV download
 * @param {array} dialogData the OOS Audits dialog data
 */
export const buildDownloadData = (dialogData) => {
  const toDownload = [];

  dialogData?.map(
    data => data?.auditData?.map(
      audit => audit?.skus?.map(
        sku => {
          toDownload.push({
            STYLE: sku?.style,
            CLR: sku?.color,
            SIZES: sku?.size,
            SOH: sku?.stockOnHand,
            'PASS / FAIL': sku?.passFail,
            PULLED: sku?.pulled,
          });
        },
      ),
    ),
  );

  return toDownload;
};

/**
 * A helper function to build OOS Tracking table rows data
 * @param {array} data the OOS Audit summary data
 */
export const buildOOSTrackingTableRowsData = (data) => {
  if (!data || isEmpty(data)) return [];

  const rowData = data?.audits?.map(item => [
    SimDateTime.toLocaleString(item?.date, DATE_SHORT_WITH_APPENDED_ZEROS),
    `${item?.auditScore}%`,
    `${item?.numPassed} / ${item?.numAudits}`,
    item?.auditNumber,
    item?.athlete,
  ]);
  return rowData;
};

/**
 * Util that handles setting/changing steps in the tutorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {func} setStepIndex - a function that handles setting the step index
 * @param {boolean} isOpen - a value that represents the table row/shipdate was selected - meaning the modal was mounted
 * @param {func} setIsOpen - a function that opens/closes the modal
 */
export const setOOSAuditTutorialSteps = (startTutorial, stepIndex, isbackClicked, setStepIndex, isOpen, setOpen) => {
  if (startTutorial) {
    if (stepIndex === 4) {
      document.querySelector('[data-testid="MUIDataTableBodyRow-0"]')?.click();
      setTimeout(() => {
        setStepIndex(4);
      }, 50);
    }
    if (stepIndex === 3 && isbackClicked) {
      setOpen(false);
    }
    if (stepIndex === 5) { // unique use case - close modal
      if (isOpen) setOpen(false);
    }
  }
};
