
import { func, string } from 'prop-types';

import './svgs.css';

export const svg = (position, onClick, fill = 'black') => (
  <svg
    height="12px"
    version="1.1"
    width="9px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={position === 'right' ? 'rightChevron' : 'defaultChevron'}
    onClick={onClick}
    strokeWidth=".1"
  >
    <g fill={fill} fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        fill={fill}
        id="Core"
        transform="translate(-218.000000, -90.000000)"
      >
        <g id="chevron-left" transform="translate(218.500000, 90.000000)">
          <path
            d="M7.4,1.4 L6,0 L-8.8817842e-16,6 L6,12 L7.4,10.6 L2.8,6 L7.4,1.4 Z"
            id="Shape"
            stroke="black"
            strokeWidth=".02"
          />
        </g>
      </g>
    </g>
  </svg>
);
 
export const Chevron = ({
  onClick = null,
  position = '',
  fill = 'black',
}) => (
  <div className="chevron-container">
    {svg(position, onClick, fill)}
  </div>
);

Chevron.propTypes = {
  onClick: func,
  position: string,
  fill: string,
};
