import { useMemo, useState } from 'react';
import {
  bool, func, number, object, string,
} from 'prop-types';
import { Dialog } from '@mui/material';
import { LoadingIndicator } from '../../../../assets/Svgs';
import ConfirmationModal from './confirmationModal/ConfirmationModal';
import ScanInfo from './info/ScanInfo';
import ManagerApproval from './managerApproval/ManagerApproval';
import { scanTypesLabel } from '../../scanReportConstants';
import { useTutorial } from '../../../../components/tutorial/useTutorial';
import { mockScanDetails } from '../../../../components/tutorial/mockTutorialData/scanReport/mockTutorialProductData';
import { handleMockScanData } from '../../../../components/tutorial/stepConstants/scanReport';

import './DetailsWidgetContainer.css';

const DetailsWidgetContainer = ({
  accuracySubmissionThreshold = 0,
  scanInfoAggregations = null,
  currencyCode = 'USD',
  data = null,
  formatMessage,
  getMessage,
  isOffsiteEnabled = false,
  isScanNotProccessing = false,
  loading = false,
  locale = 'en-US',
  nikeStoreRoles,
  scanProgress,
  SimDateTime,
}) => {
  const { startTutorial, stepIndex, isbackClicked } = useTutorial('scanReport');
  const [openModal, setOpenModal] = useState(false);
  const [approvalAction, setApprovalAction] = useState('');

  const widget = useMemo(() => {
    let widgetData = data;
    if (startTutorial) {
      const updatedWidgetData = handleMockScanData(stepIndex, isbackClicked, setOpenModal, mockScanDetails);
      widgetData = updatedWidgetData;
    }

    if (loading) {
      return (
        <div className="details-widget-loading" data-testid="details-widget-loading">
          <LoadingIndicator width="65px" height="65px" fill="#FFFFFF" svgStyle={{ left: '45%' }} />
        </div>
      );
    }

    const tutorialManagerApproval = startTutorial && [5, 6, 7, 8].includes(stepIndex);

    return (
      <>
        <h4 className="details-widget-title">
          {!widgetData?.scanType ? '' : `${getMessage(scanTypesLabel[widgetData.scanType])} ${getMessage('scanDetailsStatus')}`}
        </h4>

        <ManagerApproval
          accuracySubmissionThreshold={accuracySubmissionThreshold}
          data={widgetData ?? {}}
          getMessage={getMessage}
          formatMessage={formatMessage}
          isScanNotProccessing={tutorialManagerApproval ?? isScanNotProccessing}
          nikeStoreRoles={nikeStoreRoles}
          scanProgress={scanProgress}
          setApprovalAction={setApprovalAction}
          setOpenModal={setOpenModal}
        />
        <ScanInfo
          data={widgetData}
          scanInfoAggregations={scanInfoAggregations}
          getMessage={getMessage}
          isOffsiteEnabled={isOffsiteEnabled}
          locale={locale}
          currencyCode={currencyCode}
          SimDateTime={SimDateTime}
        />
      </>
    );
  }, [
    accuracySubmissionThreshold, data, getMessage, isOffsiteEnabled, isScanNotProccessing, locale, nikeStoreRoles, scanProgress,
    scanInfoAggregations, startTutorial, stepIndex,
  ]);

  return (
    <div className="details-widget-container widget" data-testid="details-widget-container">
      <div className="details-widget">
        {widget}
      </div>
      <Dialog
        open={openModal}
        fullScreen={false}
        maxWidth="md"
        fullWidth
      >
        <ConfirmationModal
          approvalAction={approvalAction}
          setOpenModal={setOpenModal}
          setApprovalAction={setApprovalAction}
        />
      </Dialog>
    </div>
  );
};

DetailsWidgetContainer.propTypes = {
  accuracySubmissionThreshold: number,
  scanInfoAggregations: object,
  currencyCode: string,
  data: object,
  formatMessage: func.isRequired,
  getMessage: func.isRequired,
  isOffsiteEnabled: bool,
  isScanNotProccessing: bool,
  loading: bool,
  locale: string,
  nikeStoreRoles: object.isRequired,
  scanProgress: number.isRequired,
  SimDateTime: object.isRequired,
};

export default DetailsWidgetContainer;
