import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { bool, node, object } from 'prop-types';
import { SimWebContext } from '../../context/SimWeb.provider';
import { routerUrls, endpoints } from '../../axios/endpoints';
import { createAndMonitorJob, retrieveJobData } from '../../axios/jobPollAndDataFetchAxiosFunctions';
import { postReceiveCartons } from './receivingLog.axios';
import { getErrorMessage } from './receivingLog.utils';

import { isOutsideDateRange } from '../../utils/isOutsideDateRange';

export const ReceivingLogContext = createContext({});

const ReceivingLogProvider = ({
  children = {},
  mockedValue = null,
  fromDashboard = false,
}) => {
  const { Provider } = ReceivingLogContext;
  const { searchDate } = useParams();
  const { getMessage, store, storeId, SimDateTime } = useContext(SimWebContext);

  const searchedStartDate = searchDate && SimDateTime.startOfDay(searchDate);
  const searchedEndDate = searchDate && SimDateTime.endOfDay(searchDate);


  const [startDate, setStartDate] = useState(searchedStartDate || SimDateTime.startOfDay());
  const [endDate, setEndDate] = useState(searchedEndDate || SimDateTime.endOfDay());
  const [reportType, setReportType] = useState('MISSING');
  const [detailsData, setDetailsData] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [error, setError] = useState(null);
  const [isFetchingJobId, setFetchingJobId] = useState(false);
  const [isFetchingSummary, setIsFetchingSummary] = useState(false);
  const [receiveCartonArea, setReceiveCartonArea] = useState('STOCK_ROOM');
  const [receiveCartonError, setReceiveCartonError] = useState([]);
  const [receiveCartonCompleted, setReceiveCartonCompleted] = useState(false);
  const [receiveCartonLoading, setReceiveCartonLoading] = useState(false);
  const [receiveShipmentInput, setReceiveShipmentInput] = useState('');

  // Fetch details data
  useEffect(() => {
    const detailsPayload = {
      creationDateAfterEq: startDate,
      creationDateBeforeEq: endDate,
      report: reportType,
      wantMultiLevelReporting: true,
      store,
    };

    const fetchDetailsData = async () => {
      setFetchingJobId(true);
      setDetailsData([]);
      setError(null);
      try {
        const jobId = await createAndMonitorJob(routerUrls.DELEDGE,
          endpoints.RECEIVING_LOG_DETAILS, detailsPayload);
        const detailsResponse = await retrieveJobData(jobId, routerUrls.DELEDGE,
          endpoints.RECEIVING_LOG_DETAILS);
        setDetailsData(detailsResponse);
      } catch (error) {
        setError(error);
      } finally {
        setFetchingJobId(false);
      }
    };

    if (startDate && endDate && store && !fromDashboard) {
      fetchDetailsData();
    }
  }, [startDate, endDate, store, fromDashboard]);

  // Fetch summary data
  useEffect(() => {
    const summaryPayload = {
      creationDateAfterEq: startDate,
      creationDateBeforeEq: endDate,
      store,
    };

    const fetchSummaryData = async () => {
      setIsFetchingSummary(true);
      setError(null);
      setSummaryData({});
      try {
        const jobId = await createAndMonitorJob(routerUrls.DELEDGE,
          endpoints.RECEIVING_LOG_SUMMARY, summaryPayload);
        const summaryResponse = await retrieveJobData(jobId, routerUrls.DELEDGE,
          endpoints.RECEIVING_LOG_SUMMARY);
        setSummaryData(summaryResponse);
      } catch (error) {
        setError(error);
      } finally {
        setIsFetchingSummary(false);
      }
    };

    if (startDate && endDate && store) {
      fetchSummaryData();
    }
  }, [startDate, endDate, store]);

  const refreshData = (options) => {
    const { startDate: currentStartDate, endDate: currentEndDate, selectedOption } = options;

    setStartDate(SimDateTime.toDateTime(currentStartDate));
    setEndDate(SimDateTime.toDateTime(currentEndDate));
    setReportType(selectedOption);
  };

  const onGoClick = (options) => {
    refreshData(options);
  };

  // Post receive carton
  const receiveCartons = async (cartons) => {
    setReceiveCartonError([]);
    setReceiveCartonLoading(true);

    const errors = [];
    const cartonsList = cartons.split(',').map(_ => _.trim());

    await Promise.all(cartonsList.map(async carton => {
      const body = {
        receivingNumber: 'CARTON',
        numbers: [carton],
        storeArea: receiveCartonArea,
        storeId,
      };

      try {
        await postReceiveCartons(body);
      } catch (error) {
        const errorMessage = getErrorMessage(error, getMessage);
        const errorObject = { message: `Carton ${carton} - ${errorMessage}` };
        errors.push(errorObject);
      }
    }))
      .finally(() => {
        setReceiveCartonError(errors);
        setReceiveCartonCompleted(true);
        setReceiveCartonLoading(false);
      });
  };

  const resetModal = () => {
    setReceiveCartonArea('STOCK_ROOM');
    setReceiveCartonError([]);
    setReceiveCartonCompleted(false);
  };

  const minDate = SimDateTime.startOfDay().minus({ months: 12 });
  const maxDate = SimDateTime.endOfDay();
  const isOutsideRange = date => isOutsideDateRange(date, minDate, maxDate);

  return (
    <Provider
      value={mockedValue ?? {
        details: detailsData,
        summary: summaryData,
        error,
        reportType,
        startDate,
        endDate,
        isFetchingJobId,
        isFetchingSummary,
        receiveCartonCompleted,
        receiveCartonError,
        receiveCartonLoading,
        receiveShipmentInput,
        isOutsideRange,
        onGoClick,
        receiveCartons,
        resetModal,
        setReportType,
        setReceiveCartonArea,
        setReceiveShipmentInput,
      }}
    >
      {children}
    </Provider>
  );
};

ReceivingLogProvider.propTypes = {
  children: node,
  mockedValue: object,
  fromDashboard: bool,
};

export default ReceivingLogProvider;
