import { isEmpty } from 'lodash';

const isDeepEmpty = (array) => {
  if (array.length === 0) return true;
  let isArrayDeepEmpty = true;
  array.map(item => {
    if (!isEmpty(item)) {
      isArrayDeepEmpty = false;
    }
  });

  return isArrayDeepEmpty;
};

export const filterRowData = (rows, filterList) => {
  let filteredData = [];

  if (!rows || isEmpty(rows)) return filteredData;
  if (isDeepEmpty(filterList)) return rows;

  filteredData = rows.filter(row => filterList.every(filter => {
    if (filter.name === 'fiscalSoh' || filter.name === 'rfidSoh') {
      const dataIndex = filter.columnIndex;
      const [min, max] = filter.value;
      const value = row?.data?.[dataIndex] !== '-'
        ? filter.name === 'rfidSoh'
          ? +(row?.data?.[dataIndex]?.split('/').map(Number).reduce((acc, num) => acc + num, 0))
          : +row?.data?.[dataIndex]
        : 0;
      if (min && max) return value >= +min && value <= +max;
      if (min) return value >= +min;
      if (max) return value <= +max;
      return false;
    }
    if (filter.name === 'simpleColorHex') return row;

    return filter.value.some(value => row.data.includes(value));
  }));

  return filteredData;
};

export const filterData = (rows, tableFilters) => {
  let filteredData = [];

  if (!rows || isEmpty(rows)) return { filteredData, newTableFilters: tableFilters };
  if (isDeepEmpty(tableFilters)) return { filteredData, tableFilters };

  filteredData = rows.filter(row => tableFilters.every(filter => {
    if (filter.name === 'fiscalSoh' || filter.name === 'rfidSoh') {
      const name = filter.name === 'rfidSoh' ? 'rfidSohSum' : filter.name;
      const [min, max] = filter.value;
      if (min && max) return +row[name] >= +min && +row[name] <= +max;
      if (min) return +row[name] >= +min;
      if (max) return +row[name] <= +max;
      return false;
    }
    return filter.value.includes(row[filter.name]);
  }));

  return { filteredData, tableFilters };
};

export default (rows, changedColumn, filterList, changedColumnIndex, tableFilters) => {
  let newTableFilters = [];
  let filteredData = [];

  if (isDeepEmpty(filterList)) return { filteredData, newTableFilters };
  if (!changedColumn || changedColumnIndex === null || changedColumnIndex === undefined) return { filteredData: rows, newTableFilters: tableFilters };

  const changedValue = (changedColumn === 'soh' || changedColumn === 'rfidSoh') ? [filterList[changedColumnIndex][0], filterList[changedColumnIndex][1]] : filterList[changedColumnIndex];

  if (!isEmpty(changedValue)) {
    const filteredTableFilters = tableFilters.filter(item => item.name !== changedColumn);
    newTableFilters = [
      ...filteredTableFilters,
      {
        name: changedColumn,
        value: changedValue,
        columnIndex: changedColumnIndex,
      },
    ];
  } else {
    newTableFilters = tableFilters.filter(item => item.name !== changedColumn);
  }

  const { filteredData: newFilteredData } = filterData(rows, newTableFilters);
  filteredData = newFilteredData;

  return { filteredData, newTableFilters };
};
