import { useContext } from 'react';
import { Card, CardContent, Grid2 } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import LinkWithDisable from '../../LinkWithDisable';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import { ProductRefillContext } from '../../../containers/productRefill/ProductRefill.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { useTutorial } from '../../tutorial/useTutorial';

import mockedProductRefillSummaryData from '../../tutorial/mockTutorialData/dashboard/product-refill-data.json';

import './ProductRefillWidget.css';

/**
  * Creates a widget with data visuals for the Product Refill
*/
const ProductRefillWidget = () => {
  const { getMessage } = useContext(SimWebContext);
  const { activateTutorials } = useTutorial('productRefill');
  const { summary: productRefillSummary, isFetching, error } = useContext(ProductRefillContext);
  const summary = activateTutorials ? mockedProductRefillSummaryData : productRefillSummary;

  return (
    <LinkWithDisable disabled={activateTutorials} to="/productRefill" style={{ textDecoration: 'none', color: 'inherit' }} className="productRefillWidget_dashboard-link">
      <Card className="productRefillWidget_dashboard-card widget" elevation={8} data-testid={`productRefillWidget${activateTutorials ? '-tutorial' : ''}`}>
        <CardContent>
          <Grid2 container direction="column" justifyContent="center">
            <Grid2 style={{ paddingBottom: '50px' }}>
              <p className="productRefillWidget_title">{getMessage('refills')}</p>
            </Grid2>
            { (!activateTutorials && isFetching) && (<Grid2><div className="productRefill_card-loading"><LoadingIndicator width="100px" height="100px" fill="#FFFFFF" /></div></Grid2>)}
            { (!activateTutorials && (!productRefillSummary || error))
              && (
                <Grid2>
                  <div className="productRefill_error" data-testid="errorContainer">
                    <ErrorOutline color="error" fontSize="large" />
                    <br />
                    <p>{getMessage('fatalError')}</p>
                  </div>
                </Grid2>
              )}
            <Grid2>
              <Grid2 container direction="column" className="productRefillWidget_dashboard-card-details" data-testid="productRefillWidgetData">
                <Grid2>
                  <Grid2 container justifyContent="space-between">
                    <Grid2>
                      <p>{`${getMessage('unitsInQueue')}: `}</p>
                    </Grid2>
                    <Grid2>
                      <p>{summary?.unitsInQueue}</p>
                    </Grid2>
                  </Grid2>
                </Grid2>
                <Grid2>
                  <Grid2 container justifyContent="space-between">
                    <Grid2>
                      <p>{`${getMessage('openRefills')}: `}</p>
                    </Grid2>
                    <Grid2>
                      <p>{summary?.refills}</p>
                    </Grid2>
                  </Grid2>
                </Grid2>
                <Grid2>
                  <Grid2 container justifyContent="space-between">
                    <Grid2>
                      <p>{`${getMessage('openPicklists')}: `}</p>
                    </Grid2>
                    <Grid2>
                      <p>{summary?.pickList}</p>
                    </Grid2>
                  </Grid2>
                </Grid2>
                <Grid2>
                  <Grid2 container justifyContent="space-between">
                    <Grid2>
                      <p>{`${getMessage('openUnbinned')}: `}</p>
                    </Grid2>
                    <Grid2>
                      <p>{summary?.unbinned && summary.unbinned.toLocaleString()}</p>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </LinkWithDisable>
  );
};

export default ProductRefillWidget;