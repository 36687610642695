 import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { node, object } from 'prop-types';
import { getDashboardData } from './dashboard.axios';
import removeDupes from './dashboard.utils';
import { StoreViewContext } from '../../context/StoreView.provider';

export const DashboardContext = createContext({});

const DashboardProvider = ({ children = {}, mockedContext = null }) => {
  const [scansLoading, setScansLoading] = useState(false);
  const [scans, setScans] = useState({});
  const [scanError, setError] = useState(null);

  const { Provider } = DashboardContext;
  const { storeId, country, storeInfo } = useContext(StoreViewContext);

  const fetchScans = async () => {
    setScansLoading(true);
    try {
      const scanData = await getDashboardData(storeId, storeInfo.serverRegion);
      setScans(scanData);
      setError(scanData?.error);
    } catch (error) {
      setError(error?.message);
    }

    setScansLoading(false);
  };

  useEffect(() => {
    if (!storeId || !country) return;
    fetchScans();
  }, [storeId, country, storeInfo?.region]);

  const scanReportDashboardData = scans?.scanReportDashboardData;

  return (
    <Provider
      value={mockedContext ?? {
        storeId,
        country,
        refetch: fetchScans,
        scansLoading,
        scans,
        scanError,
        data: {
          styleCompliance: scans?.styleComplianceData,
          sizeCompliance: scans?.sizeComplianceData,
          scanReport: scanReportDashboardData,
          getScanCompliance: (locale, rfidFullStoreScanDate) => removeDupes(scanReportDashboardData?.twentyEightDaysOfScans, locale, rfidFullStoreScanDate),
        },
      }}
    >
      {children}
    </Provider>
  );
};

DashboardProvider.propTypes = {
  children: node,
  mockedContext: object,
};

export default DashboardProvider;
