  /**
   * Builds the header rows for xlsx download
   * @param {*} columns - visible columns of the table
   * @returns an array of objects with value of the header and fontWeight
   */
export const buildDownloadHeaders = (columns) => {
  const headerRow = [];
  columns?.map(column => {
    headerRow.push({ value: column.label.toUpperCase(), fontWeight: 'bold' });
  });
  return headerRow;
};

  /**
   * Builds the rows for xlsx download
   * @param {*} rows - visible rows of the table (should only change if filters are applied)
   * @returns an array of objects with value of the row and type
   */
export const buildDownloadData = (rows) => {
  const toDownload = [];
  rows?.map(item => {
    const row = item.data.map((value, idx) => {
      // The first 7 columns are strings, the last 3 are numbers (SOH, RFID Stock, and Variance)
      if (idx >= 7) {
        return { value: parseInt(value, 10) ?? 0, type: Number };
      }
      return { value: value?.toString() ?? '', type: String };
    });
    toDownload.push(row);
  });
  return toDownload;
};