import { SimDateTime } from '../../utils/datetime';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { startCase } from 'lodash';

/**
 * A helper function to filter the adjustment data by indexes
 * @param {array} data the adjustment detail data
 * @param {array} selectedItems a list of index of selected detail data
 */
export const filterSelectedData = (data, selectedItems) => {
  if (selectedItems.length === 0) return data;
  return data?.filter((item, i) => selectedItems.includes(i));
};

/**
 * A helper function to build filtered adjustment data for print or CSV download
 * @param {array} data the adjustment detail data
 * @param {array} selectedItems a list of index of selected detail data
 */

/**
 * A helper function to build filtered adjustment data for print or CSV download
 * @param {array} data the adjustment detail data
 * @param {array} selectedItems a list of index of selected detail data
 * @returns an array of objects with value of the row and type
 */
export const buildDownloadData = (data, selectedItems) => {
  const downloadData = filterSelectedData(data, selectedItems);
  const toDownload = [];

  downloadData?.forEach(item => {
    item?.tableData?.forEach(adjustment => {
      const array = [
        { value: SimDateTime.toLocaleString(adjustment?.creationDate, DATE_SHORT_WITH_APPENDED_ZEROS), type: String },
        { value: item?.opId || '-', type: String },
        { value: startCase(item?.type).toUpperCase() || '-', type: String },
        { value: adjustment?.styleColor || '-', type: String },
        { value: adjustment?.class || '-', type: String },
        { value: adjustment?.description || '-', type: String },
        { value: adjustment?.size || '-', type: String },
        { value: adjustment?.upc || '-', type: String },
        { value: adjustment?.units || 0, type: Number },
        { value: adjustment?.price || 0, type: Number },
      ];
      toDownload.push(array);
    });

    if (item?.totalPrice || item?.totalUnits) {
      const totalsArray = [
        { value: '', type: String },
        { value: '', type: String },
        { value: '', type: String },
        { value: '', type: String },
        { value: '', type: String },
        { value: '', type: String },
        { value: '', type: String },
        { value: 'TOTALS', type: String, fontWeight: 'bold' },
        { value: item?.totalUnits ?? 0, type: Number },
        { value: item?.totalPrice ?? 0, type: Number },
      ];
      toDownload.push(totalsArray);
    }
  });

  return toDownload;
};

/**
 * Builds the header rows for xlsx download
 * @returns an array of objects with value of the header and fontWeight
 */
export const buildDownloadHeaders = () => {
  const headerRow = [
    { value: 'Date', fontWeight: 'bold' },
    { value: 'Op Id', fontWeight: 'bold' },
    { value: 'Type', fontWeight: 'bold' },
    { value: 'Style Color', fontWeight: 'bold' },
    { value: 'Class', fontWeight: 'bold' },
    { value: 'Description', fontWeight: 'bold' },      
    { value: 'Size', fontWeight: 'bold' },
    { value: 'UPC', fontWeight: 'bold' },
    { value: 'Units', fontWeight: 'bold' },
    { value: 'Price', fontWeight: 'bold' },
  ];
  return headerRow;
};
