import { useContext } from 'react';
import {
  string, shape, oneOfType, object, array,
} from 'prop-types';
import {
  createTheme, ThemeProvider, StyledEngineProvider,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { baseDetailsTableOptions, baseMuiTheme } from '../baseViewPage.utils';
import { SimWebContext } from '../../../context/SimWeb.provider';

const ReportDetails = ({
  id,
  details = null,
  dialog = null,
}) => {
  const {
    options: tableOptions, title, rows, columns, theme,
  } = { ...details };
  const { getMessage } = useContext(SimWebContext);

  const options = tableOptions ?? baseDetailsTableOptions(getMessage);
  const theming = theme ?? createTheme(baseMuiTheme);

  if (columns && Array.isArray(rows)) {
    return (
      <div data-testid={`${id}-table`}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theming}>
            <MUIDataTable
              title={title}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </StyledEngineProvider>
        {dialog}
      </div>
    );
  }

  return null;
};

export default ReportDetails;

ReportDetails.propTypes = {
  id: string.isRequired,
  details: shape({
    title: oneOfType([
      string,
      object,
    ]),
    options: object,
    columns: array,
    theme: object,
  }),
  dialog: object,
};
