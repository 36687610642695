import { useContext, useMemo } from 'react';
import { TablePagination } from '@mui/material';

import { ScanDetailsContext } from '../ScanDetails.provider';

const ScanReportProductsPagination = () => {
  const {
    pagination, selectedScanPagesData, setPagination, setSelectedScanPagesData,
  } = useContext(ScanDetailsContext);

  const noPages = {
    totalCount: 0,
    currentPageNumber: 1,
    currentCount: 10,
  };

  const { totalCount } = selectedScanPagesData || noPages;

  const handlePaginationOffset = (paginationDetails, newOffset) => {
    setPagination({
      ...paginationDetails,
      offset: newOffset,
      starting: newOffset,
      currentPageNumber: newOffset + 1,
    });
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  const handleNewRowsPerPage = (event) => {
    setSelectedScanPagesData({
      ...selectedScanPagesData,
      currentCount: event?.target?.value,
    });
    setPagination({
      starting: 0,
      offset: 0,
      currentPageNumber: 1,
      currentCount: event?.target?.value,
    });
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  const scanReportPagination = useMemo(() => (
    <TablePagination
      page={pagination?.starting}
      count={totalCount}
      rowsPerPage={pagination?.currentCount}
      component="div"
      onPageChange={(event, newOffset) => handlePaginationOffset(pagination, newOffset)}
      onRowsPerPageChange={(event) => handleNewRowsPerPage(event)}
      rowsPerPageOptions={[10, 20, 30]}
      className="custom-pagination"
      data-testid="custom-pagination"
      SelectProps={{
        inputProps: {
          label: pagination?.currentCount,
          'data-testid': 'paginationInput',
        },
      }}
    />
  ), [pagination, totalCount, handlePaginationOffset]);

  return scanReportPagination;
};

export default ScanReportProductsPagination;
