import { useContext } from 'react';
import { func, bool, string } from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Button from '../../../components/Button';
import { Alert, Checkmark } from '../../../assets/Svgs/index';

import { StyleManagementContext } from '../../styleManagement/StyleManagement.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';

import './PicklistModal.css';
/**
 * UI to display the success or failure of the picklist creation
 * @param {boolean} isOpen - indicator to display modal
 * @param {function} setIsOpen - function to update whether the modal should be displayed
 * @param {boolean} isCreated - indicator for status of picklist creation status
 * @param {string} report - type of report component is rendered in
 */
const PicklistModal = ({
  isOpen = false,
  setIsOpen,
  isCreated = false,
  report = 'size-compliance',
}) => {
  const { getMessage } = useContext(SimWebContext);
  const { setPicklistName, setPicklistComplete, setCreated } = useContext(StyleManagementContext);

  const createdPicklistTitle = getMessage('pickListSuccessTitle');
  const createdPicklistFailedTitle = getMessage('pickListFailureTitle');
  const createdPicklistSuccess = getMessage('pickListSuccessMessage');
  const createPicklistFailed = getMessage('pleaseTryAgain');

  const getIcon = () => {
    let svg = <Alert width="50px" height="50px" fill="#BB1426" />;
    if (isCreated) svg = <Checkmark fill="#5CDAA8" width="50px" height="50px" />;
    return (
      <div data-testid="size-compliance-picklist-modal-svg">
        {svg}
      </div>
    );
  };

  const getTitle = () => (isCreated ? createdPicklistTitle : createdPicklistFailedTitle);
  const getStringMessage = () => (isCreated ? createdPicklistSuccess : createPicklistFailed);

  const className = `${report}-picklist-modal`;

  const handleClick = () => {
    setIsOpen(false);
    setPicklistName('');
    setPicklistComplete(true);
    setCreated(false);
  };

  const sizeCompliancePicklist = (
    <>
      {getIcon()}
      <DialogTitle>
        <div className={`${className}-title`} data-testid={`${className}-title`}>
          {getTitle()}
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={`${className}-content`} data-testid={`${className}-content`}>
          {getStringMessage()}
        </div>
        <br />
      </DialogContent>
    </>
  );

  const styleManagementPicklist = (
    <>
      <DialogTitle>
        <div className={`${className}-title`} data-testid={`${className}-title`} style={{ padding: '15px 0' }}>
          <h1>{getMessage('successPicklistCreation')}</h1>
          <h1>{getMessage('picklistLocation')}</h1>
        </div>
      </DialogTitle>
    </>
  );

  return (
    <Dialog
      open={isOpen}
      fullScreen={false}
      maxWidth="sm"
    >
      <div
        className={className}
        data-testid={className}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '70px 15px',
        }}
      >
        {report === 'size-compliance' ? sizeCompliancePicklist : styleManagementPicklist}
        <Button
          dataTestId={`${className}-ok`}
          buttonText={getMessage('ok')}
          onClick={() => (report === 'style-management' ? handleClick() : setIsOpen(false))}
        />
      </div>
    </Dialog>
  );
};

PicklistModal.propTypes = {
  setIsOpen: func.isRequired,
  isOpen: bool,
  isCreated: bool,
  report: string,
};

export default PicklistModal;
