import { baseUrl, endpoints, routerUrls } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { logErrorAction } from '../../utils/newRelicPageActions';

/**
* gets the scans for a particular store
* @param {string} storeId storeid value
* @param {string} region current region
* @returns {object} object dashboard data
*/
export const getDashboardData = async (storeId, region) => {
  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DASHBOARD_SCANS.url}?storeId=${storeId}&region=${region}`;
  return generalAxiosRequest('GET', url, endpoints.DASHBOARD_SCANS, true, { accept: 'application/json' })
    .catch(async (error) => {
      logErrorAction('Dashboard Data error', error, 'error getting dashboard scan data', { api: endpoints.DASHBOARD_SCANS.url, message: error?.message });
      return {
        scanReportDashboardData: null,
        styleComplianceData: null,
        sizeComplianceData: null,
        error,
      };
    });
};
