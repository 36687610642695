import {
  isEmpty, find, findIndex, remove, includes,
} from 'lodash';

/**
 * A helper function to find and return the digit value
 * based on the aggregate value passed in
 * @param {array} aggregationList list of aggregates names and values
 * @param {string} aggregationType string value of desired aggregate
 */
export const getAggregationValue = (aggregationList, aggregationType) => {
  const aggregation = find(aggregationList, { field: aggregationType });
  return aggregation?.value || 0;
};

/**
 * Returns aggregation component displayed within facets
 * @param {Number} accuracy
 * @param {Number} unrepresented
 */
export const getAggregationComponent = (accuracy, unrepresented) => {
  if ((accuracy === 100 && unrepresented === 0) || (!accuracy && !unrepresented)) return null;
  return (
    <div className="size-compliance-aggregation">
      {`${accuracy}%`}
      <span className="aggregation-seperator" />
      {`(${unrepresented})`}
    </div>
  );
};

/**
 * A helper function to build an object from Size Compliance service
 * to build aggregate values
 * @param {object} aggregations
 */
export const getFacetAggregations = (aggregations) => {
  const count = aggregations?.find(_ => _?.field === 'unrepresented');
  const accuracy = aggregations?.find(_ => _?.field === 'accuracy');

  return {
    count: {
      field: 'unrepresented',
      value: count?.value || 0,
    },
    accuracy: {
      field: 'accuracy',
      value: accuracy?.value || 0,
    },
  };
};

/**
 * A helper function to build the data used to populate facets
 * @param {array} supportedFilters the filters supported by the Size Compliance service
 */
export const buildFacetsData = (supportedFilters) => {
  const facets = {};
  const facetGroupLabels = {
    division: 'division',
    genderAge: 'gender',
    retailCategory: 'category',
    price: 'price',
  };

  supportedFilters?.forEach((filterGroup) => {
    const facetGroup = filterGroup?.name;
    if (Object.keys(facetGroupLabels).includes(facetGroup)) {
      facets[facetGroup] = {
        name: facetGroup,
        label: facetGroupLabels[facetGroup],
        values: [],
      };
    }
    filterGroup?.values?.forEach(filter => {
      const aggregations = getFacetAggregations(filter?.aggregations);
      if (filter && (aggregations?.count?.value)) {
        const facetName = filter?.value;
        facets[facetGroup]?.values?.push({
          name: facetName,
          label: facetName,
          selected: filter?.selected === true || false,
          aggregations,
        });
      }
    });
  });

  return facets;
};

/**
 * A helper function to build object for the grouping of facets
 * @param {object} facetData data used to build out facets
 */
export const getFacetGroups = (facetData) => {
  const selectedFacets = {};
  if (facetData) {
    Object.keys(facetData).forEach(groupName => {
      const selected = facetData[groupName].values.filter(_ => _.selected === true);
      if (!isEmpty(selected)) selectedFacets[groupName] = selected;
    });
  }

  if ('division' in selectedFacets) {
    return ['division', 'genderAge', 'retailCategory', 'price'];
  }
  return ['division'];
};

/**
 * Helper function to add an unselected facet to filter data from size
 * compliance request
 * @param {array} filters list of values to filter size compliance data by
 * @param {string} groupName string value of name facet group selected
 * @param {object} facet selected facet dataa
 */
const addFacetToGroup = (filters, groupName, facet) => {
  const filtersCopy = [...filters];
  const facetGroupIndex = findIndex(filters, { name: groupName });
  if (facetGroupIndex && facetGroupIndex < 0) {
    filtersCopy.push({
      name: groupName,
      operator: 'EQUALS',
      values: [facet.name],
    });
  } else {
    const facetGroup = filtersCopy[facetGroupIndex];
    facetGroup?.values.push(facet.name);
    filtersCopy[facetGroupIndex] = facetGroup;
  }
  return filtersCopy;
};

/**
 * Helper function to remove selected facet value from filter for size compliance request
 * @param {array} filters list of values to filter size compliance data by
 * @param {string} groupName string value of name facet group selected
 * @param {object} facet selected facet dataa
 */
const removeFacetFromGroup = (filters, groupName, facet) => {
  const filtersCopy = [...filters];
  const facetGroupIndex = findIndex(filters, { name: groupName });
  const facetGroup = filtersCopy[facetGroupIndex];

  if (facetGroup?.values?.length > 1) {
    const filterValues = remove(facetGroup?.values, (filterType) => filterType !== facet.name);
    facetGroup.values = filterValues;
    filtersCopy[facetGroupIndex] = facetGroup;
  } else if (facetGroup?.values.length === 1 && facet?.selected && groupName === 'division') {
    filtersCopy.splice(1);
  } else {
    const removalIndex = filtersCopy.findIndex(_ => _.name === groupName && includes(_.values, facet.name));
    filtersCopy.splice(removalIndex, 1);
  }
  return filtersCopy;
};

/**
 * A helper function to add or remove filter when a facet is selected
 * @param {object} facet the facet selected
 * @param {string} groupName the facet group name of the facet selected
 * @param {array} filters the filters used in scan report request
 */
export const updateFilters = (facet, groupName, filters) => {
  if (!facet || !groupName) return filters;

  if (!facet?.selected) {
    return addFacetToGroup(filters, groupName, facet);
  }
  return removeFacetFromGroup(filters, groupName, facet);
};
