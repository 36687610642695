import { array, func, string } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import ExpandableRows from './ExpandableRows';

import '../AdjustmentsPage.css';

const PrinterPage = ({
  columns = [],
  currencyCode = 'USD',
  data = [],
  locale = 'en-US',
  getMessage,
}) => (
  <div className="print-source" data-testid="print-source">
    <MUIDataTable
      data={data}
      columns={columns}
      options={{
        expandableRows: true,
        pagination: false,
        responsive: 'standard',
        rowsExpanded: Array.from(Array(data?.length).keys()),
        expandableRowsOnClick: true,
        expandableRowsHeader: data?.length > 0,
        renderExpandableRow: (rowData, { dataIndex }) => (
          <tr>
            <td colSpan={rowData.length + 1}>
              <ExpandableRows
                data={data[dataIndex]}
                getMessage={getMessage}
                locale={locale}
                currencyCode={currencyCode}
              />
            </td>
          </tr>
        ),
      }}
    />
  </div>
);

PrinterPage.propTypes = {
  columns: array,
  currencyCode: string,
  data: array,
  getMessage: func.isRequired,
  locale: string,
};

export default PrinterPage;
