import { isEmpty, cloneDeep } from 'lodash';
import { sortFacets } from '../../../utils/sort';
import { SimDateTime } from '../../../utils/datetime';
/**
 * Get the PO number, carton and unit aggregations for the facet
 * @param {array} aggregations a list of inbound shipment aggregations
 */
export const getFacetAggregations = (aggregations) => {
  const poNumber = aggregations.find(_ => _.field === 'poNumberCount')?.value || '-';
  const carton = aggregations.find(_ => _.field === 'cartonCount')?.value || '-';
  const unit = aggregations.find(_ => _.field === 'unitCount')?.value || '-';

  return { poNumber, carton, unit };
};

/**
 * A helper function to set which facet group to show the tooltip
 * @param {object} facets the facets data object
 * @param {boolean} showEDD the indicator to show or hide EDD facet group
 */
export const getFacetGroupToShowTooltip = (facets, showEDD) => {
  if (isEmpty(facets)) return facets;

  const copyOfFacets = cloneDeep(facets);

  const hasEDD = !isEmpty(copyOfFacets.edd?.values);
  if (showEDD && hasEDD) {
    copyOfFacets.edd.showTooltip = true;
  } else {
    copyOfFacets.shipDate.showTooltip = true;
  }

  return copyOfFacets;
};

/**
 * A helper function to change the all facet's show property to false if it is not selected
 * or not in the same facet group as last selected facet
 * @param {object} facets the facets data object
 * @param {string} lastSelectedGroup the last selected facet group name
 */
export const resetFacetsToShow = (facets, lastSelectedGroup) => {
  if (isEmpty(facets) || !lastSelectedGroup) return facets;

  const facetCopy = cloneDeep(facets);
  Object.values(facets).forEach(group => {
    group?.values.forEach((facet, index) => {
      if (facetCopy[group.name].values[index].selected === true) {
        facetCopy[group.name].values[index].selected = false;
        return;
      }
      if ((group.name === 'edd' && lastSelectedGroup !== 'edd')
      || (group.name === 'shipDate' && lastSelectedGroup !== 'shipDate')
      || (group.name === 'carrier' && lastSelectedGroup !== 'carrier')
      || (group.name === 'docNumber' && lastSelectedGroup !== 'docNumber')
      || (group.name === 'poNumber' && lastSelectedGroup !== 'poNumber')) {
        facetCopy[group.name].values[index].show = false;
      }
    });
  });

  return facetCopy;
};

/**
 * A helper function to sum the count by provided field name
 * @param {object} filter the supported filter item
 * @param {string} field the field name of the value to get - ex. unitCount, cartonCount and poNumberCount
 */
export const sumSameAggregationValues = (filter, field) => {
  if (isEmpty(filter) || !field) return 0;

  const values = filter?.map(value => value?.aggregations?.find(item => item.field === field)?.value ?? 0);
  return values && values.length > 0 ? values?.reduce((a, b) => a + b) : 0;
};

/**
 * A helper function to build an object from supportedFilters to used for facets
 * @param {object} facets the facets data object
 * @param {array} supportedFilters the filtered object of the inbound shipment data
 * @param {string} lastSelectedGroup the last selected facet group name
 * @param {boolean} showEDD the indicator to show or hide EDD facet group
 * @param {string} selectedEdd this is used to force the selected value returned from the platform team to true
 */
export const buildFacetsData = (facets, supportedFilters, lastSelectedGroup, showEDD, selectedEdd, eddDates) => {
  const facetGroupLabels = {
    edd: 'eddDate',
    shipDate: 'shipDate',
    carrier: 'carrier',
    docNumber: 'docNumber',
    poNumber: 'poNumber',
    deliveryNumber: 'deliveryNumber',
  };

  const newFacets = resetFacetsToShow(cloneDeep(facets), lastSelectedGroup);

  supportedFilters?.map(filter => {
    const facetGroup = filter.name;
    if (Object.keys(facetGroupLabels).includes(facetGroup)) {
      if (!(facetGroup in newFacets)) {
        newFacets[facetGroup] = {
          name: facetGroup,
          label: facetGroupLabels[facetGroup],
          showTooltip: false,
          values: [],
        };
      }
      filter?.values.map(value => {
        const facetName = value.value;
        const facetDataList = filter?.values?.filter(_ => _.value === value.value);
        const aggregatedData = [
          {
            field: 'unitCount',
            value: sumSameAggregationValues(facetDataList, 'unitCount'),
          },
          {
            field: 'cartonCount',
            value: sumSameAggregationValues(facetDataList, 'cartonCount'),
          },
          {
            field: 'poNumberCount',
            value: sumSameAggregationValues(facetDataList, 'poNumberCount'),
          },
        ];

        const index = newFacets[facetGroup].values.findIndex(_ => _.name === facetName);
        if (index === -1) {
          newFacets[facetGroup].values.push({
            name: facetName,
            label: facetName,
            selected: false,
            show: true,
            aggregation: getFacetAggregations(aggregatedData),
          });
        } else {
          newFacets[facetGroup].values[index] = {
            ...newFacets[facetGroup].values[index],
            selected: value.selected === true,
            show: true,
            aggregation: getFacetAggregations(aggregatedData),
          };
        }

        if (facetGroup === 'edd') {
          newFacets[facetGroup].values = eddDates.map(date => ({
            selected: selectedEdd === date,
            name: date,
            label: date,
            show: true,
            aggregtion: [],
          }));
        }
      });
    }
  });

  return getFacetGroupToShowTooltip(newFacets, showEDD);
};

/**
 * A helper function to sort shipDate or EDD facets
 * @param {object} facets the facets value of a facet group
 * @param {string} groupName the facet group name
 */
export const sortFacetsByDate = (facets, groupName) => {
  if (isEmpty(facets) || !groupName || (groupName !== 'shipDate' && groupName !== 'edd')) return facets;

  const sortedFacets = [];
  const facetsInGroup = facets.map(_ => _.name);
  const sorted = sortFacets(facetsInGroup, groupName, facets);

  sorted.forEach(facetName => {
    const facet = facets.find(_ => _.name === facetName);
    if (facet) sortedFacets.push(facet);
  });

  return sortedFacets;
};

/**
 * Add the facet name in the filter values or create a new filter object if facet group is not found
 * @param {array} filter the filter object used in inbound visibility report request
 * @param {object} facet the facet selected
 * @param {string} groupName the facet group name of the facet selected
 */
export const addFilter = (filter, facet, groupName) => {
  if (!facet || !groupName) return filter;
  if (groupName === 'edd') {
    return [
      {
        name: groupName,
        operator: 'GTE',
        values: [SimDateTime.startOfDay(facet.name).toUTC()],
      },
      {
        name: groupName,
        operator: 'LTE',
        values: [SimDateTime.endOfDay(facet.name).toUTC()],
      },
    ];
  }
  const filterCopy = cloneDeep(filter);
  if (!filterCopy) {
    return {
      name: groupName,
      operator: 'EQUALS',
      values: [facet.name],
    };
  }

  const newValues = (!filterCopy.values.includes(facet.name)) ? [...filterCopy.values, facet.name] : filterCopy.values;
  return {
    ...filterCopy,
    values: newValues,
  };
};

/**
 * Remove the face name in the filter values or remove the filter object if it is the last value in the filter
 * @param {array} filter the filter object used in inbound visibility report request
 * @param {object} facet the facet deselected
 */
export const removeFilter = (filter, facet) => {
  if (!filter || !facet) return filter;

  const filterCopy = cloneDeep(filter);

  const index = filterCopy.values.indexOf(facet.name);
  if (index !== -1) filterCopy.values.splice(index, 1);

  if (filterCopy.values.length === 0) return null;
  return filterCopy;
};

/**
 * Update the filter list when facet is selected or deselected
 * @param {object} facet the facet selected or deselected
 * @param {string} groupName the facet group name of the facet
 * @param {array} filters the filter object used in inbound visibility report request
 * @param {function} setSelectedEdd sets the selected edd if store is edd enabled
 */
export const updateFilters = (facet, groupName, filters, setSelectedEdd) => {
  if (!facet || !groupName) return filters;

  let filtersCopy = cloneDeep(filters);
  const isFacetChecked = facet.selected;
  const index = filtersCopy.findIndex(_ => _.name === groupName);
  const filter = (index !== -1) ? filtersCopy.splice(index, 1)[0] : null;

  // Since only one edd can be selected, remove all other filters, this shouldn't cause an issue.
  if (groupName === 'edd') {
    filtersCopy = [];
  }

  const newFilterItem = (isFacetChecked) ? removeFilter(filter, facet) : addFilter(filter, facet, groupName);
  if (newFilterItem) {
    if (Array.isArray(newFilterItem)) {
      newFilterItem.map(filterItem => filtersCopy.push(filterItem));
    } else {
      filtersCopy.push(newFilterItem);
    }
  }

  // Helps manage edd enabled stores.
  if (groupName === 'edd') {
    if (!facet.selected) {
      setSelectedEdd(facet.name);
    }
  }

  return filtersCopy;
};

/**
 * Gets and translates facet group title
 * @param {string} label the label of the facet group
 * @param {boolean} isPRO the indicator to use PRO or DOC Number label
 * @param {function} getMessage translation helper function
 */
export const getFacetGroupTitle = (label, isPRO, getMessage) => {
  if (label === 'docNumber') {
    if (isPRO) return getMessage('proNumber');
    return 'DOC Number';
  }
  if (label === 'deliveryNumber') return 'Delivery Number';
  return getMessage(label);
};
