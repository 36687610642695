import { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField, Paper } from '@mui/material';

import Button from '../../components/Button';
import StoreAndCountryDropdown from '../../components/customSelect/StoreAndCountryDropdown';
import { LoadingIndicator } from '../../assets/Svgs';
import useAuth from '../../hooks/useAuth';
import * as constants from '../../constants/storageKeys.constants';
import { isProd } from '../../configs/environment';
import { version } from '../../../package.json';

import './LoginPage.css';
import useTranslator from '../../hooks/useTranslator';
import useSimStore from '../../store/simStore';

/**
 * Login page users see when accessing the application from a browser.
 */
const BrowserLogin = () => {
  // Third party hooks
  const history = useHistory();
  const location = useLocation();

  // Team made hooks
  const { login, isLoggedIn, isLoginResponsePresentOnURL } = useAuth();
  const { getMessage } = useTranslator();

  // Local state
  const [employeeId, setEmployeeId] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Getters and setters for Zustand store
  const addError = useSimStore(state => state.addError);
  const storeInfo = useSimStore(state => state.storeInfo);
  const language = useSimStore(state => state.language);
  const versionIsCurrent = useSimStore(state => state.versionIsCurrent);

  const authV3Countries = ['KOR', 'IND'];

  /**
   * This is needed to handle the redirect back to the login page from Auth V3 to complete the flow.
   */
  useEffect(() => {
    const hashParams = isLoginResponsePresentOnURL() && new URLSearchParams(location.hash);
    const isErrorOnUrl = () => hashParams.has('#error');

    if (isLoginResponsePresentOnURL() && !isLoggedIn()) {
      if (isErrorOnUrl()) {
        addError(getMessage('invalidEmployeeNumberPassword'));
      } else {
        login(constants.RETAIL, history);
      }
    }
  }, [isLoggedIn]);

  /**
   * Handle form submission for either Auth V3 or Legacy login.
   * Auth V3 login is only for KOR and IND and test environment only.
   *
   * @param {object} event - javascript event object
   */
  const handleSubmit = async event => {
    setIsLoading(true);
    event.preventDefault();
    if (storeInfo?.country && authV3Countries.includes(storeInfo?.country) && !isProd()) {
      await login(constants.RETAIL, history);
    } else {
      const assertion = btoa(`${storeInfo?.country};${storeInfo?.storeNumber};${employeeId}:${password}`);
      const error = await login(constants.LEGACY, history, assertion);
      if (error) {
        addError(getMessage('invalidEmployeeNumberPassword'));
      }
    }
    setIsLoading(false)
  };

  const userAndPassword = useMemo(
    () => (
      <div style={{ display: 'flex', flexDirection: 'inherit' }}>
        <TextField
          type="text"
          name="employeeId"
          autoComplete="username"
          id="retail-login-form-employeeId"
          data-testid="retail-login-form-employeeId"
          label={getMessage('employeeId')}
          variant="outlined"
          required
          onChange={event => {
            setEmployeeId(event.target.value);
          }}
          value={employeeId}
          sx={{ marginBottom: '40px' }}
          inputlabelprops={{
            shrink: !!employeeId,
          }}
        />
        <TextField
          type="password"
          name="password"
          id="retail-login-form-password"
          data-testid="reatil-login-form-password"
          label={getMessage('password')}
          variant="outlined"
          required
          onChange={event => {
            setPassword(event.target.value);
          }}
          value={password}
          sx={{ marginBottom: '20px' }}
          inputlabelprops={{
            shrink: !!password,
          }}
        />
      </div>
    ),
    [password, employeeId, language],
  );

  return (
    <div className="sim-login-container">
      <Paper
        elevation={3}
        className="login-paper"
        square={false}
      >
        <div className="header">
          <span className="g72-swoosh image-swoosh" />
          <div className="text">{getMessage('retailWebTitle')}</div>
          <p className={versionIsCurrent ? 'version-header' : 'version-header-error'}>{version}</p>
        </div>
        <form
          data-testid="retail-login-form"
          onSubmit={handleSubmit}
        >
          {!isLoading ? (
            <>
              <div style={{ zIndex: 1000, display: 'flex', flexDirection: 'row' }}>
                <StoreAndCountryDropdown />
              </div>
              <br />
              {((storeInfo?.country && !authV3Countries.includes(storeInfo?.country)) || isProd()) && (
                <>{userAndPassword}</>
              )}
              <Button
                data-testid="retail-login-form-submit"
                type="submit"
                buttonText={getMessage('signInLabel')}
                onClick={handleSubmit}
                extraClasses="login-button"
              />
            </>
          ) : (
            <LoadingIndicator />
          )}
        </form>
      </Paper>
    </div>
  );
};

export default BrowserLogin;
