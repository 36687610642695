import { useContext, useMemo, useState } from 'react';
import FadeIn from 'react-fade-in';
import CustomSelect from '../../../../components/customSelect/CustomSelect';
import ScanReportProductsPagination from './ScanReportProductsPagination';
import ScanReportProductCards from './ScanReportProductCards';

import { getTranslatedSortByOptions } from './scanReportProducts.utils';
import { sortByOptions, missingCountTableSort } from '../../scanReportConstants';

import CPAProvider from '../../../../context/cpa/CPA.provider';
import { SimWebContext } from '../../../../context/SimWeb.provider';
import { ScanDetailsContext } from '../ScanDetails.provider';

import './ScanReportProducts.css';

const ScanReportProducts = () => {
  const { getMessage } = useContext(SimWebContext);
  const {
    loading, scans, scanType, setDataSort,
  } = useContext(ScanDetailsContext);
  const [...defaultSortBy] = getTranslatedSortByOptions([sortByOptions[0]], getMessage);
  const [sortBy, setSortBy] = useState(missingCountTableSort);

  const handleSortOption = (event) => {
    setDataSort({
      field: event.value.split('_')[0].replace(' ', '_'),
      order: event.order,
    });

    setSortBy(event);
  };

  const productCards = useMemo(() => (
    !loading ? scans?.map(scan => (
      <FadeIn transitionDuration={600} key={`${scan.productCode} + ${Math.random()}`}>
        <CPAProvider>
          <ScanReportProductCards scan={scan} scanType={scanType} sortByValue={sortBy} />
        </CPAProvider>
      </FadeIn>
    )) : null
  ), [loading, scans, sortBy]);

  return (
    <>
      <div className="scan-report-paging-wrapper" data-testid="scan-report-products-page">
        <div className="scan-report-sort-options" data-testid="scan-report-sort-options">
          <CustomSelect
            label={getMessage('sort')}
            options={getTranslatedSortByOptions(sortByOptions, getMessage)}
            onChange={e => handleSortOption(e)}
            defaultValue={defaultSortBy}
            customClass="scan-report-sort-by-dropdown"
            customSelectClass="scan-report-sort-by-customize-dropdown"
          />
        </div>
      </div>
      <div className="scan-report-data-container" data-testid="scan-report-product-cards">
        { productCards }
      </div>
      <div className="scan-report-pagination">
        <ScanReportProductsPagination />
      </div>
    </>
  );
};

export default ScanReportProducts;
