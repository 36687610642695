export const setScanTimerWidgetTutorialSteps = (
  isbackClicked, restartTutorial, startTutorial, stepIndex, dashboardWidget, setTutorialScanStartDate,
  setTutorialScanStatus, setTutorialScanTimer, setTutorialScanTimeout, setTutorialScanType,
) => {
  if (startTutorial && dashboardWidget === 'scanTimer') {
    if (stepIndex === 1 && isbackClicked) {
      setTutorialScanTimer({ hours: 3, minutes: 12, seconds: 59 });
    }
    if (stepIndex === 2) {
      setTutorialScanTimer({ hours: 0, minutes: 58, seconds: 59 });
    }
    if (stepIndex === 2 && isbackClicked) {
      setTutorialScanStatus('IN_REVIEW');
      setTutorialScanTimeout(false);
    }
    if (stepIndex === 3) {
      setTutorialScanStatus('TIMED_OUT');
      setTutorialScanTimeout(true);
    }
    if ((stepIndex === 3 && isbackClicked)) {
      setTutorialScanStatus('TIMED_OUT');
      setTutorialScanTimeout(true);
    }
    if (stepIndex === 4) {
      setTutorialScanStatus('SUBMITTED');
      setTutorialScanTimeout(false);
    }
  }
  if (restartTutorial) {
    setTutorialScanStartDate('2023-01-01T00:00:00.000Z');
    setTutorialScanStatus('IN_REVIEW');
    setTutorialScanTimer({ hours: 3, minutes: 12, seconds: 59 });
    setTutorialScanTimeout(false);
    setTutorialScanType('FULL_STORE_SCAN');
  }
};
