import { parseAsInt } from '../../utils/sort';

/**
 * Sorts the data in tables by date and time stamp
 * @param {array} data an array of date with dates and time stamps
 * @param {number} index location of the obj in the given array
 * @param {string} direction sort directions such as desc/asc
 */
export const sortByFirstValue = (data, index, direction) => {
  if (!data) return [];

  const sortedData = [...data];
  sortedData.sort((a, b) => {
    const first = a?.[index];
    const second = b?.[index];
    if (first > second) return direction === 'desc' ? -1 : 1;
    if (second > first) return direction === 'desc' ? 1 : -1;
    return 0;
  });
  return sortedData;
};

/**
 * Sorts the data in tables by a given area
 * @param {array} data an array of date with dates and time stamps
 * @param {number} index location of the obj in the given array
 * @param {string} direction sort directions such as desc/asc
*/
export const sortRowsByFirstValue = (data, index, direction) => {
  if (!data) return [];

  const sortedValue = [...data];
  sortedValue.sort((a, b) => {
    const first = parseAsInt(a?.data?.[index]?.split(' ')[0]);
    const second = parseAsInt(b?.data?.[index]?.split(' ')[0]);

    if (first > second) return direction === 'desc' ? -1 : 1;
    if (second > first) return direction === 'desc' ? 1 : -1;
    return 0;
  });
  return sortedValue;
};

/**
 * A util that creates the bin audit data with pass/fail, calculations, audit #'s, score etc...
 * @param {array} binResponse - the full response from calling /store/bin_audits/v1
*/
export const buildBinAuditData = (binResponse) => {
  let totalAverage = Math.round(binResponse.map(audit => audit.score)
    .reduce((accumulator, score) => accumulator + score, 0) / binResponse.length);

  if (Number.isNaN(totalAverage)) {
    totalAverage = 0;
  }

  const buildAudits = binResponse.map((audit) => {
    let numPassed = 0;
    let numFailed = 0;
    let numUpdated = 0;
    const binNameMap = {};

    audit.auditExpected.forEach((bin) => {
      binNameMap[bin?.binId] = bin?.binName;
    });

    const binsData = audit.auditActions.map((bin) => {
      const binName = binNameMap[bin?.binId];

      let binPassed = true;
      let binUpdated = false;

      let styleColors = bin.styleColors.map((product) => {
        let updated = false;
        const pass = product.expected === product.found;

        if (!pass && !['NONE', 'KEPT', 'RELOCATED'].includes(product?.action)) {
          updated = true;
        }

        if (pass) {
          return null;
        }

        // if any one styleColor fails, the whole bin fails. Same with updated status.
        const firstFailure = binPassed;
        binPassed = false;
        if (updated) { binUpdated = true; }

        return {
          updated,
          key: `${bin?.binId}${product?.styleColor}`,
          bin: firstFailure ? binName : '',
          passFail: pass,
          styleColor: product?.styleColor,
          buffer: '',
        };
      })
        // filter out any null values using ES6 magic
        .filter((items) => items);

      if (!binPassed) {
        numFailed += 1;
      } else {
        numPassed += 1;

        // if the bin passed, we don't care about anything inside of it.
        styleColors = [{
          bin: binName,
          passFail: true,
          buffer: '',
        }];
      }

      if (binUpdated) { numUpdated += 1; }

      return {
        styleColors,
        sortKey: `${binPassed ? 1000 : 0}${binName}`,
        key: bin.binId,
      };
    });

    binsData?.sort((a, b) => (a.sortKey > b.sortKey ? 1 : -1));

    return {
      binsData,
      numPassed,
      numFailed,
      numUpdated,
      key: audit?.id,
      date: audit?.creationDate,
      athlete: audit?.athlete,
      auditNumber: audit?.auditNumber,
      score: audit?.score,
      numAudits: audit?.auditExpected?.length,
    };
  });
  return { totalAverage, audits: buildAudits };
};

/**
 * A helper function to build the Bin Audits dialog data for CSV download
 * @param {array} dialogData the Bin Audits dialog data
 */
export const buildDownloadData = (dialogData) => {
  const toDownload = [];

  dialogData?.map(
    data => data?.binsData?.map(
      bin => bin?.styleColors?.map(
        styleColor => {
          toDownload.push({
            BIN: styleColor?.bin,
            'PASS / FAIL': styleColor?.passFail,
            'FAILED STYLE': styleColor?.styleColor,
            UPDATED: styleColor?.updated,
          });
        },
      ),
    ),
  );

  return toDownload;
};
