/* eslint-disable no-useless-catch */
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';

export const fetchStockroomOrganizationData = async (storeId, country, region) => {
  if (!storeId || !country) return null;

  try {
    const headers = { region };
    const urlParams = `?storeId=${storeId}&country=${country}`;
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.STOCKROOM_ORGANIZATION.url}${urlParams}`;

    return await generalAxiosRequest('GET', url, endpoints.STOCKROOM_ORGANIZATION, false, headers);
  } catch (error) {
    throw error;
  }
};
