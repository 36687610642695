import { useContext, useMemo } from 'react';
import Options from '../../components/Options';
import BaseViewPage from '../baseView/BaseViewPage';
import { getTableOptions, getTransfersTableColumns, getAdjustmentsTableColumns } from './challan.utils';
import { SimWebContext } from '../../context/SimWeb.provider';
import { ChallanContext } from './Challan.provider';
import { SimDateTime } from '../../utils/datetime';
import PrintChallanDialog from './PrintChallanDialog';

const ChallanPage = () => {
  const { getMessage } = useContext(SimWebContext);
  const {
    data, displayGovernmentNumber, displayReturnAuthorizationNumber, endData, error, id, loading, startData, reportType,
    isOutsideRange, onGoClick, setIdList, setPrintDialogOpen, setReportType,
  } = useContext(ChallanContext);
  const pageTitle = 'Challan';

  const dropdown = useMemo(() => (
    <div className="dropdown-block" data-testid="challan-type-dropdown">
      <Options
        onOptionChange={(newValue) => {
          setReportType(newValue);
          setIdList([]);
        }}
        options={[
          { label: getMessage('adjustments'), value: 'adjustments' },
          { label: getMessage('transfers'), value: 'transfers' },
        ]}
        optionsLabel="Filter"
        disabled={loading}
      />
    </div>
  ), [loading, getMessage]);

  const transferTableColumns = useMemo(() => getTransfersTableColumns(displayGovernmentNumber, displayReturnAuthorizationNumber, getMessage),
    [displayGovernmentNumber, displayReturnAuthorizationNumber]);

  const adjustmentsTableColumns = useMemo(() => getAdjustmentsTableColumns(SimDateTime, getMessage), []);

  const tableConfigs = useMemo(() => {
    let title = '';
    let columns = [];
    let rows = [];

    if (reportType === 'transfers') {
      title = getMessage('transfers');
      columns = transferTableColumns;
      rows = data;
    }
    if (reportType === 'adjustments') {
      title = getMessage('adjustments');
      columns = adjustmentsTableColumns;
      rows = data;
    }
    return {
      title,
      columns,
      options: getTableOptions(reportType, getMessage, setIdList, setPrintDialogOpen),
      rows,
    };
  }, [reportType, data]);

  return (
    <BaseViewPage
      id={id}
      title={pageTitle}
      dropdown={dropdown}
      storeConfigValue="isChallanEnabled"
      datepicker={{
        startData,
        endData,
        loading,
        isOutsideRange,
        onGoClick,
      }}
      details={tableConfigs}
      error={error}
      loading={loading}
      dialog={<PrintChallanDialog />}
    />
  );
};

export default ChallanPage;
