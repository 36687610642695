import { createContext, useState, useContext } from 'react';
import { node, object } from 'prop-types';
import { TranslationsContext } from './Translations.provider';
import { StoreViewContext } from './StoreView.provider';
import { StoreConfigContext } from './StoreConfig.provider';
import { AdminContext } from './Admin.provider';
import { SimDateTime } from '../utils/datetime';
import { LOGIN_SOURCE, SERVER_REGION } from '../constants/storageKeys.constants';
import useSimStore from '../store/simStore';

export const SimWebContext = createContext({});
export const isIOS = () => localStorage.getItem(LOGIN_SOURCE)?.toLowerCase()?.includes('ios');

const SimWebProvider = ({ children = {}, mockedValue = null }) => {
  const { Provider } = SimWebContext;

  const { storeConfig, configRetrieved } = useContext(StoreConfigContext);
  const { getMessage, formatMessage, translationsFetched } = useContext(TranslationsContext);
  const { storeId, storeInfo, store, storeName, flag, country, storeNumber } = useContext(StoreViewContext);
  const { isAdmin } = useContext(AdminContext);
  // this needs to be in this provider since the useTutorial hook does not have access to the tutorial provider
  const setActivateTutorials = useSimStore(state => state.setActiveTutorials);
  const activateTutorials = useSimStore(state => state.activateTutorials);

  const [isInitialCall, setInitialCall] = useState(true);
  const isTutorialEnabled = isAdmin || storeConfig?.isTutorialEnabled?.value;

  const userAgent = navigator?.userAgent;
  const isMobile = userAgent.includes('iPad') || userAgent.includes('iPhone');
  const offsiteType = storeConfig?.offsiteType?.value;
  const isOffsiteEnabled = storeConfig?.rfidEnabled?.value && offsiteType?.toLowerCase() !== 'none';
  const isOffsiteInactive = offsiteType === 'inactive';

  // Inbound Visibility
  const [inboundSearchDate, setInboundSearchDate] = useState(null);

  const getCurrencySymbol = (locale = 'en-US', currencyCode = 'USD') => {
    const nullPrice = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    }).format(0);

    const currencySymbol = nullPrice.replace(/[\d,.\s]/g, '');
    return currencySymbol;
  };
  // printing is always enabled - for NSP/KOR doors we're checking the config
  const isPrintingEnabled = true;// isIOS() ? storeConfig?.isIpadPrintEnabled?.value : true;

  return (
    <Provider
      value={mockedValue ?? {
        configRetrieved,
        country,
        currencyCode: storeInfo?.currencies?.[0],
        currencySymbol: getCurrencySymbol(storeInfo?.locale?.replace('_', '-') || 'en-US', storeInfo?.currencies?.[0]),
        flag,
        inboundSearchDate,
        isInitialCall,
        isIOS: isIOS(),
        isMobile,
        iso2Country: storeInfo?.address?.iso2Country,
        isOffsiteEnabled,
        isOffsiteInactive,
        isPrintingEnabled,
        isTrue: window.location !== window.parent.location,
        isTutorialEnabled,
        locale: storeInfo?.locale || 'en-US',
        region: localStorage.getItem(SERVER_REGION),
        storeInfo,
        storeId,
        storeNumber,
        store,
        storeName,
        storeConfig,
        translationsFetched,
        formatMessage,
        getMessage,
        setInitialCall,
        setInboundSearchDate,
        SimDateTime,
        activateTutorials,
        setActivateTutorials,
      }}
    >
      {children}
    </Provider>
  );
};

SimWebProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default SimWebProvider;
