import { useContext, useEffect } from 'react';
import {
  func, object, string, array, bool,
} from 'prop-types';
import { DateTime } from 'luxon';
import writeXlsxFile from 'write-excel-file';
import Card from '@mui/material/Card';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SimDateTime } from '../../utils/datetime';
import MUIDataTable from 'mui-datatables';
import DialogHeader from '../dialogHeader/DialogHeader';

import { SimWebContext } from '../../context/SimWeb.provider';
import { itemActivityContext } from '../../containers/itemActivity/ItemActivity.provider';

import './ItemActivityModal.css';

const ItemActivityModal = ({
  styleInput,
  startDate,
  endDate,
  productData = null,
  closeCall = null,
  hiddenColumns,
  loading = false,
  rows = [],
}) => {
  const {
    index, rowCount, setIndex, setModalStyles,
  } = useContext(itemActivityContext);

  const { getMessage, storeConfig } = useContext(SimWebContext);
  const rfidEnabled = storeConfig?.rfidEnabled?.value;

  useEffect(() => {
    setModalStyles(rows?.[index]?.styleColor);
  }, [index]);

  let columns = [{
    name: 'date',
    label: getMessage('date'),
  }, {
    name: 'gtin',
    label: getMessage('upc'),
  }, {
    name: 'size',
    label: getMessage('size'),
  }, {
    name: 'sale',
    label: getMessage('unitsSold'),
  }, {
    name: 'onlineSale',
    label: getMessage('onlineSales'),
  }, {
    name: 'shipFromStore',
    label: getMessage('shipFromStore'),
  }, {
    name: 'returned',
    label: getMessage('returns'),
  }, {
    name: 'received',
    label: getMessage('received'),
  }, {
    name: 'postVoidSale',
    label: getMessage('postVoidSales'),
  }, {
    name: 'postVoidReturn',
    label: getMessage('postVoidReturns'),
  }, {
    name: 'adjustment',
    label: getMessage('inventoryAdjustment'),
  }, {
    name: 'transfer',
    label: getMessage('transferOut'),
  }, {
    name: 'soh',
    label: getMessage('soh'),
  }];

  if (rfidEnabled) {
    columns = [...columns, {
      name: 'rfidSoh',
      label: getMessage('rfidSoh'),
    }];
  }

  const columnsToShow = columns.map((column) => {
    if (hiddenColumns[column.name]) {
      return {
        ...column,
        options: {
          ...column.options,
          display: false,
        },
      };
    }

    return column;
  });

  const aggRows = {};
  const event = productData?.find(product => product?.styleColor === styleInput);
  if (event && Array.isArray(event?.itemEvents)) {
    event?.itemEvents?.forEach((_) => {
      const date = DateTime.fromISO(_?.originDate).toString().split('T')[0];
      const gtin = _?.productId;
      if (!aggRows[date]) {
        aggRows[date] = {};
      }

      if (!aggRows[date][gtin]) {
        aggRows[date][gtin] = {
          SALE: 0,
          RETURN: 0,
          RECEIVED: 0,
          POST_VOID_SALE: 0,
          POST_VOID_RETURN: 0,
          ADJUSTMENT: 0,
          TRANSFER: 0,
          INITIAL_BALANCE: 0,
          PHYSICAL_INVENTORY: 0,
          ONLINE_SALE: 0,
          SHIP_FROM_STORE: 0,
          SIZE: (_?.product?.sizeName) || '-',
          SOH: (_?.soh?.fiscalStock?.quantity) || 0,
          RFIDSOH: (_?.soh?.physicalStock?.quantity) || 0,
        };
      }
      if (_?.originType === 'WECHAT_MOBILE_SALE') aggRows[date][gtin].SALE += Math.abs(Number(_?.delta));
      aggRows[date][gtin][_?.originType] += Math.abs(Number(_?.delta));
    });
  }
  const activityRows = [];

  Object.keys(aggRows).forEach((day) => {
    Object.keys(aggRows[day]).forEach((key) => {
      const row = aggRows[day][key];
      activityRows.push({
        date: day,
        gtin: key,
        size: row.SIZE,
        sale: row.SALE,
        onlineSale: row.ONLINE_SALE,
        shipFromStore: row.SHIP_FROM_STORE,
        returned: row.RETURN,
        received: row.RECEIVED,
        postVoidSale: row.POST_VOID_SALE,
        postVoidReturn: row.POST_VOID_RETURN,
        adjustment: row.ADJUSTMENT,
        transfer: row.TRANSFER,
        soh: row.SOH,
        rfidSoh: row.RFIDSOH,
      });
    });
  });

    /**
   * Builds the rows for xlsx download
   * @returns an array of objects with value of the row and type
   */
    const buildDownloadData = () => activityRows?.map(item => [
      { value: item?.date || '-', type: String  },
      { value: item?.gtin || '-', type: String },
      { value: item?.size || '-', type: String },
      { value: item?.sale || 0, type: Number  },
      { value: item?.onlineSale || 0, type: Number  },
      { value: item?.shipFromStore || 0, type: Number  },
      { value: item?.returned || 0, type: Number  },
      { value: item?.received || 0, type: Number  },
      { value: item?.postVoidSale || 0, type: Number  },
      { value: item?.postVoidReturn || 0, type: Number  },
      { value: item?.adjustment || 0, type: Number },
      { value: item?.transfer || 0, type: Number  },
      { value: item?.soh || 0, type: Number  },
      { value: item?.rfidSoh || 0, type: Number  },
    ]) || [];

  /**
   * Builds the header rows for xlsx download
   * @returns an array of objects with value of the header and fontWeight
   */
  const buildDownloadHeaders = () => {
    const headerRow = [
      { value: 'Date', fontWeight: 'bold' },
      { value: 'UPC', fontWeight: 'bold' },
      { value: 'Size', fontWeight: 'bold' },
      { value: 'Sale', fontWeight: 'bold' },
      { value: 'Online Sale', fontWeight: 'bold' },
      { value: 'Ship From Store', fontWeight: 'bold' },      
      { value: 'Return', fontWeight: 'bold' },
      { value: 'Received', fontWeight: 'bold' },
      { value: 'Post Void Sale', fontWeight: 'bold' },
      { value: 'Post Void Return', fontWeight: 'bold' },
      { value: 'Adjustment', fontWeight: 'bold' },
      { value: 'Transfer', fontWeight: 'bold' },
      { value: 'SOH', fontWeight: 'bold' },
      { value: 'RFID SOH', fontWeight: 'bold' },
    ];
    return headerRow;
  };

  return (
    <div className="item-activity-modal" data-testid="item-activity-modal">
      <DialogHeader
        loading={loading}
        index={index}
        setIsOpen={closeCall}
        rowCount={rowCount}
        setIndex={setIndex}
      />
      <div className="feature-title">
        <DialogTitle>{getMessage('detailedActivity')}</DialogTitle>
      </div>
      <DialogContent>
        <div className="feature-header">
          <Card className="summary-box">
            <div className="style-box">
              <span className="key">{`${getMessage('style')}: `}</span>
              <span className="value" data-testid="styleSummary">{styleInput}</span>
            </div>
            <div className="date-box">
              <span className="key">{`${getMessage('dates')}: `}</span>
              <span className="value" data-testid="dateRangeSummary">{`${startDate}  -  ${endDate}`}</span>
            </div>
          </Card>
        </div>
        <div className="table" data-testid="itemActivityModalTable">
          <MUIDataTable
            data={activityRows}
            columns={columnsToShow}
            options={{
              selectableRows: 'none',
              print: true,
              filter: false,
              elevation: 0,
              downloadOptions: {
                filename: `Item Activity Report Details-${SimDateTime.toUtcISO()}.csv`,
                filterOptions: {
                  useDisplayedColumnsOnly: true,
                  useDisplayedRowsOnly: true,
                },
                },
                onDownload: () => {
                  const headerRow = buildDownloadHeaders();
                  const dataRows = buildDownloadData();
                  try {
                    writeXlsxFile([headerRow, ...dataRows], { fileName: `Item Activity Report Details-${SimDateTime.toUtcISO()}.xlsx` });
                  } catch (error) {
                    console.error(error);
                  }
                  return false; // cancels default datatable download logic
                },
            }}
            className="MUIDataTable"
          />
        </div>
      </DialogContent>
    </div>
  );
};

ItemActivityModal.propTypes = {
  styleInput: string.isRequired,
  startDate: string.isRequired,
  endDate: string.isRequired,
  productData: array,
  closeCall: func,
  hiddenColumns: object.isRequired,
  loading: bool,
  rows: array,
};

export default ItemActivityModal;
