import { Link } from 'react-router-dom';
import {
  bool, func, object, string,
} from 'prop-types';

/**
 * A react router link that can be disabled, if it is disabled, just return a span instead of the Link
 * @param {object} children - the children to be rendered in the link
 * @param {boolean} disabled - if true will return a span instead of the link
 * @param {string} to - the page to route to
 * @param {string} className - the classes to apply to the tags
 * @param {func} onClick - the custom actions on link clicked
 */
const LinkWithDisable = ({
  children,
  className = '',
  disabled = false,
  to,
  onClick = null,
}) => {
  const onLinkClicked = () => {
    if (onClick) onClick();
  };

  if (disabled) {
    return (
      <span className={className} data-testid={`disabled-link-to-${to}`}>
        {children}
      </span>
    );
  }
  return (
    <Link to={to} className={className} data-testid={`enabled-link-to-${to}`} style={{ textDecoration: 'none', color: 'inherit' }} onClick={onLinkClicked}>
      {children}
    </Link>
  );
};

LinkWithDisable.propTypes = {
  children: object.isRequired,
  className: string,
  disabled: bool,
  to: string.isRequired,
  onClick: func,
};

export default LinkWithDisable;
