import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { newRelicAttribute } from './utils/newRelicPageActions';
import { environment } from './configs/environment';

import App from './App';
import TranslationsProvider from './context/Translations.provider';

import './reset.css';
import './index.css';
import './common.css';
// import "@nike/eds/dist/index.css";

import AdminProvider from './context/Admin.provider';
import StoreConfigProvider from './context/StoreConfig.provider';
import StoreViewProvider from './context/StoreView.provider';
import SimWebProvider from './context/SimWeb.provider';
import setLoginSource from './utils/setLoginSource';

newRelicAttribute('sim-environment', environment);
setLoginSource();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <StoreViewProvider>
      <TranslationsProvider>
        <AdminProvider>
          <StoreConfigProvider>
            <SimWebProvider>
              <SnackbarProvider
                dense
                preventDuplicate
                maxSnack={3}
                style={{ width: '500px' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <App />
              </SnackbarProvider>
            </SimWebProvider>
          </StoreConfigProvider>
        </AdminProvider>
      </TranslationsProvider>
    </StoreViewProvider>
  </Router>,
);
