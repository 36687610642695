import { useMemo } from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import {
  getScanAggregations, getScanLocationAggregations, getScanTimeRange, getScanDuration,
} from './scanInfo.utils';
import getLocalizedPercent from '../../../../../utils/getLocalizedPercent';
import getFormattedPrice from '../../../../../utils/getFormattedPrice';

import './ScanInfo.css';

const ScanInfo = ({
  currencyCode = 'USD',
  data = null,
  getMessage,
  isOffsiteEnabled = false,
  locale = 'en-US',
  scanInfoAggregations = null,
}) => {
  const reportInfo = useMemo(() => {
    if (!data) return null;

    return (
      <div className="scan-details-info-container" data-testid="scan-details-info-container">
        <div className="scan-details-info-scan-accuracy" data-testid="scan-details-info-scan-accuracy">
          <strong>{`${getMessage('scanAccuracy')}: `}</strong>
          {getLocalizedPercent(locale, data.aggregations?.accuracy)}
        </div>
        <div className="scan-details-info-aggregations-descriptions" data-testid="scan-details-info-aggregations-descriptions">
          <strong>{`${getMessage('unitsScanned')} / ${getMessage('unitsExpected')}: `}</strong>
          <span className="scan-details-info-aggregations-descriptions-values">{getScanAggregations(scanInfoAggregations, locale)}</span>
        </div>
        <div className="scan-details-info-location-aggregations" data-testid="scan-report-location-aggregations">
          <strong>{`${getMessage('scannedUnitsByLocation')}: `}</strong>
          <span className="scan-details-info-location-aggregations-values">
            {getScanLocationAggregations(scanInfoAggregations, locale, isOffsiteEnabled)}
          </span>
        </div>
        <div className="scan-details-info-total-value-variance" data-testid="scan-details-info-total-value-variance">
          <strong>{`${getMessage('totalValueVariance')}: `}</strong>
          <span className={`scan-details-info-total-value-variance-values ${data.totalValueVariance >= 0 ? 'scan-total-value-variance-value-positive' : 'scan-total-value-variance-value-negative'}`}>
            {getFormattedPrice(data.totalValueVariance, locale, currencyCode)}
          </span>
        </div>
        <div className="scan-details-info-scan-date" data-testid="scan-details-info-scan-date">
          <strong>{`${getMessage('date')}: `}</strong>
          {data.scanDate}
        </div>
        <div className="scan-details-info-scan-time" data-testid="scan-details-info-scan-time">
          <strong>{`${getMessage('time')}: `}</strong>
          {getScanTimeRange(data.scanStartDate, data.scanEndDate)}
        </div>
        <div className="scan-details-scan-duration" data-testid="scan-details-scan-duration">
          <strong>{`${getMessage('scanDuration')}: `}</strong>
          {getScanDuration(data.scanDuration, getMessage)}
        </div>
        <div className="scan-details-scanners-number" data-testid="scan-details-scanners-number">
          <strong>{`${getMessage('numberOfScanners')}: `}</strong>
          {data.numberOfScanners}
        </div>
      </div>
    );
  }, [data, scanInfoAggregations, getMessage]);

  return reportInfo;
};

ScanInfo.propTypes = {
  currencyCode: string,
  data: object,
  getMessage: func.isRequired,
  isOffsiteEnabled: bool,
  locale: string,
  scanInfoAggregations: object,
};

export default ScanInfo;
