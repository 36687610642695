import { useContext, useMemo } from 'react';
import BaseViewPage from '../baseView/BaseViewPage';
import PicklistModal from '../sizeCompliance/picklistModal/PicklistModal';
import PicklistFormModal from '../sizeCompliance/picklistModal/PicklistFormModal';
import { StyleManagementContext } from './StyleManagement.provider';

import './StyleManagement.css';

const StyleManagementPage = () => {
  const {
    id, title, loading, buttons, visuals, dialog, detailsTitle, options, columns, rows, error,
    pageInfo, picklistItems, isOpen, setIsOpen, pickListLoading, createPicklist, setPicklistName,
    isNameSet, setNameSet, isFormOpen, setFormOpen,
  } = useContext(StyleManagementContext);

  const picklistSuccessModal = useMemo(() => (
    <>
      <PicklistFormModal
        createPicklist={createPicklist}
        isOpen={isFormOpen}
        loading={pickListLoading}
        setIsOpen={setFormOpen}
        units={picklistItems.length}
        report="style-management"
      />
      <PicklistModal
        isOpen={isNameSet}
        setIsOpen={setNameSet}
        report="style-management"
        setPicklistName={setPicklistName}
      />
    </>
  ), [setIsOpen, isNameSet, isOpen, createPicklist, isFormOpen, pickListLoading]);
  return (
    <>
      <BaseViewPage
        id={id}
        title={title}
        pageInfo={pageInfo}
        loading={loading}
        buttons={buttons}
        visuals={visuals}
        dialog={dialog}
        details={{
          title: detailsTitle, options, columns, rows,
        }}
        error={error}
        storeConfigValue="isStyleManagementEnabled"
      />
      {picklistSuccessModal}
    </>
  );
};

export default StyleManagementPage;
