
import { string } from 'prop-types';

export const ReadyForPickup = ({ extraClasses = '' }) => (
  <div className={`${extraClasses}`}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 21V9.75L5.36 3.75H18.64L20.25 9.75V18C20.25 19.24 19.24 20.25 18 20.25" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path d="M18 20.25H8" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path d="M20.25 9.75H3.75" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path d="M12 3.75V13.5" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path d="M12.775 15.15L8.002 19.923L5.35 17.272" stroke="#111111" strokeWidth="1.25" />
    </svg>
  </div>
);

ReadyForPickup.propTypes = {
  extraClasses: string,
};
