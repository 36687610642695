import { useContext, useState } from 'react';
import { func, string } from 'prop-types';

import {
  getValuesFromScanData, getTitle, getModalTitleStyles, getContent, getIcon,
} from './scanReportManagerApproval.utils';
import { newRelicAction } from '../../../../utils/newRelicPageActions';

import { SimWebContext } from '../../../../context/SimWeb.provider';
import { ScanDetailsContext } from '../ScanDetails.provider';

import './ScanConfirmationModal.css';
import { generalAxiosRequest } from '../../../../axios/axiosFunctions';
import { baseUrl, endpoints, routerUrls } from '../../../../axios/endpoints';

/**
 * @param {string} approvalAction the manger approval actions - SUBMITTED, SCANNING or CANCELLED
 * @param {function} setOpenModal the function to set confirmation modal open or close
 * @param {function} setApprovalAction the function to set the manager action
 */
const ScanReportConfirmationModal = ({
  approvalAction,
  setOpenModal,
  setApprovalAction,
}) => {
  const { getMessage, formatMessage, country } = useContext(SimWebContext);
  const {
    sessionId, scanData, accuracyThreshold, rfidScanStatus, setRfidScanStatus,
  } = useContext(ScanDetailsContext);

  const [statusUpdated, setStatusUpdated] = useState(false);
  const [error, setError] = useState(null);

  const { accuracy, status: lastStatusOfScan, totalResumeCount } = getValuesFromScanData(scanData);

  const showLowAccuracyWarning = country === 'USA' || country === 'CAN';

  const handleCloseModal = (resetApprovalAction = false) => {
    setOpenModal(false);
    setError(null);
    if (resetApprovalAction) {
      setApprovalAction('');
    }
  };

  const handleOnClick = async (rfidScanStatus) => {
    const url = `${baseUrl(routerUrls.RFID)}${endpoints.RFID_SESSIONS.url}/${sessionId}`;
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    const body = { status: rfidScanStatus };

    try {
      await generalAxiosRequest('PATCH', url, endpoints.RFID_SESSIONS, true, headers, body);
      setStatusUpdated(true);
      setRfidScanStatus(rfidScanStatus);
    } catch (error) {
      setError(error);
    }

    newRelicAction('rfid-scan-report-manager-approval', { action: rfidScanStatus, actionAppName: 'Simweb', duration: scanData?.scanStatus?.scanDuration });
  };

  const scanButton = (onClick, disabled = false, label, className = 'confirmation-modal-submit-button') => (
    <button
      type="button"
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );

  const okButton = (resetApprovalAction) => scanButton(() => handleCloseModal(resetApprovalAction), false, getMessage('ok'));
  const noButton = () => scanButton(() => handleCloseModal(true), false, getMessage('no'));
  const yesButton = (rfidScanStatus, disabled) => scanButton(() => handleOnClick(rfidScanStatus), disabled, getMessage('yes'));
  const submittedButton = (rfidScanStatus, disabled) => scanButton(() => handleOnClick(rfidScanStatus), disabled, getMessage('submit'));
  const cancelledButton = rfidScanStatus => scanButton(() => handleOnClick(rfidScanStatus), false, getMessage('cancelScan'), 'confirmation-modal-cancel-button');

  const getButtons = () => {
    if (statusUpdated) return okButton();
    if (error) return okButton(true);
    // submit accuracy below threshold, 2nd attempt
    if (lastStatusOfScan === 'IN_REVIEW') {
      if (approvalAction === 'SUBMITTED' && accuracy < accuracyThreshold && totalResumeCount > 1) {
        return (
          <>
            {submittedButton('SUBMITTED')}
            {cancelledButton('CANCELLED')}
          </>
        );
      }

      // submit accuracy below threshold
      if (approvalAction === 'SUBMITTED' && accuracy < accuracyThreshold) {
        return (
          <>
            {submittedButton('SUBMITTED', true)}
            {okButton(true)}
          </>
        );
      }

      // resume
      if (approvalAction === 'SCANNING') {
        return (
          <>
            {yesButton('SCANNING')}
            {noButton()}
          </>
        );
      }

      // cancel
      if (approvalAction === 'CANCELLED') {
        return (
          <>
            {yesButton('CANCELLED')}
            {noButton()}
          </>
        );
      }
    }

    // alert
    if (lastStatusOfScan !== 'IN_REVIEW') return okButton();

    // submit
    return (
      <>
        {yesButton(approvalAction)}
        {noButton()}
      </>
    );
  };

  return (
    <div className="scan-confirmation-modal" data-testid="scan-confirmation-modal-container">
      <div className="scan-confirmation-modal-content">
        <div className="scan-confirmation-modal-title" data-testid="scan-modal-title" style={getModalTitleStyles(statusUpdated, lastStatusOfScan)}>
          {getIcon(lastStatusOfScan, statusUpdated, approvalAction, error)}
          <h2>
            {getTitle(
              scanData, statusUpdated, approvalAction, rfidScanStatus,
              accuracyThreshold, getMessage, formatMessage, error,
            )}
          </h2>
        </div>
        <p className="scan-confirmation-modal-description" data-testid="scan-modal-description">
          {getContent(scanData, approvalAction, accuracyThreshold, getMessage, error, showLowAccuracyWarning)}
        </p>
        <div className="confirmation-buttons" data-testid="scan-modal-buttons">
          {getButtons()}
        </div>
      </div>
    </div>
  );
};

ScanReportConfirmationModal.propTypes = {
  approvalAction: string.isRequired,
  setOpenModal: func.isRequired,
  setApprovalAction: func.isRequired,
};

export default ScanReportConfirmationModal;
