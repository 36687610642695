import { useContext } from 'react';
import { array, bool, string } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import {
  Table, TableHead, TableContainer, Paper, TableRow, TableCell, TableBody,
} from '@mui/material';

import ReceivingLogTableDetailItem from './ReceivingLogTableDetailItem';
import { TranslationsContext } from '../../context/Translations.provider';
import './ReceivingLogPage.css';

const PrinterPage = ({
  data = [],
  reportType = 'MISSING',
  isNorthAmerica = false,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  const columns = () => {
    if (data?.length > 0) {
      const useProNumber = !!(data[0] && data[0].proNumber);
      return [
        {
          name: useProNumber ? 'proNumber' : 'deliveryNumber',
          label: useProNumber ? getMessage('proNumber') : getMessage('documentNumber'),
        },
      ];
    }
    return [];
  };

  return (
    <div className="print-source">
      <MUIDataTable
        data={data}
        columns={columns()}
        options={{
          search: true,
          pagination: false,
          expandableRows: true,
          rowsExpanded: Array.from(Array(data?.length).keys()),
          expandableRowsOnClick: true,
          expandableRowsHeader: data?.length > 0,
          renderExpandableRow: (rowData, { dataIndex }) => (
            <>
              <tr>
                <td colSpan={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{isNorthAmerica ? 'STO/PO Number' : getMessage('poNumber')}</TableCell>
                          <TableCell>{getMessage('carton')}</TableCell>
                          {reportType === 'FULL' ? <TableCell>{getMessage('recvDate')}</TableCell> : null}
                          <TableCell>{getMessage('upc')}</TableCell>
                          <TableCell>{getMessage('unitsExpctd')}</TableCell>
                          <TableCell>{getMessage('unitsRecd')}</TableCell>
                          <TableCell>{getMessage('variance')}</TableCell>
                          <TableCell>{getMessage('soh')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <ReceivingLogTableDetailItem data={data[dataIndex]} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </td>
              </tr>
            </>
          ),
        }}
      />
    </div>
  );
};

PrinterPage.propTypes = {
  data: array,
  reportType: string,
  isNorthAmerica: bool,
};

export default PrinterPage;
