import { useState, useContext } from 'react';
import { camelCase, isEmpty } from 'lodash';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import ErrorModal from '../../components/NewErrorModal';
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import { LoadingIndicator } from '../../assets/Svgs/index';
import Options from '../../components/Options';
import AddressModal from './addressModal/AddressModal';
import AdjustmentsTable from './AdjustmentsTable';
import SummaryTable from './SummaryTable';
import ErrorAlert from '../../components/alerts/Error';
import ChallanForm from './ChallanForm';
import Button from '../../components/Button';
import { isProd } from '../../configs/environment';

import { AdjustmentsContext } from './Adjustments.provider';
import { useTutorial } from '../../components/tutorial/useTutorial';
import './AdjustmentsPage.css';

export const AdjustmentsPage = () => {
  const {
    adjustmentId, currencyCode, details, dialogData, error, getMessage, locale, printOptions, startDate, endDate, summary, isOutsideRange,
    handlePrintingRequest, onGoClick, isAddressModalOpen, isLoading, isIOS, isErrorOpen, setAddressModalOpen, setDialogData, setErrorOpen, challanRef, isAdmin,
  } = useContext(AdjustmentsContext);

  const [selectedPrintType, setSelectedPrintType] = useState(printOptions ? printOptions[0] : null);
  const [showCallanMock, setShowCallanMock] = useState(false);

  const showMockChallan = () => setShowCallanMock(!showCallanMock);

  const { activateTutorials, tutorial } = useTutorial('adjustments', isLoading || isEmpty(details));

  const getPrintingOptions = () => {
    if (!printOptions) return null;

    return (
      <div className="print-option-block" data-testid="print-option-block">
        <Options
          onOptionChange={(value) => setSelectedPrintType(value)}
          options={printOptions.map(option => ({
            label: getMessage(camelCase(option)),
            value: option,
          }))}
          optionsLabel={getMessage('document')}
        />
      </div>
    );
  };

  return (
    <div className="adjustments-page flex-column" data-testid="adjustmentsPage">
      <div className="header flex-row">
        <div className="title-block">
          <div className="feature-title" id="adjustmentsPage-title">
            {getMessage('adjustments')}
          </div>
          {/* {tutorialToggle} */}
          {activateTutorials && tutorial}
        </div>
        <div className="page-controls flex-row">
          <div className="date-picker-block">
            <DateRangeWithOptions
              start={startDate?.toISO()}
              end={endDate?.toISO()}
              onClick={onGoClick}
              disabled={isLoading}
              isOutsideRange={isOutsideRange}
            />
          </div>
        </div>
      </div>
      {!isLoading && error
        && (
          <ErrorAlert
            errorObject={error.message}
            apiName="Adjustments Page API"
            pageCode=""
            data-testid="adjustments-page-error"
          />
        )}
      {!isLoading && (
        <>
          <div className="summary flex-row">
            <SummaryTable data={summary} currencyCode={currencyCode} locale={locale} />
          </div>
          {!isIOS && getPrintingOptions()}
          <div className="content">
            <AdjustmentsTable
              data={details}
              currencyCode={currencyCode}
              selectedPrintType={selectedPrintType}
              handlePrintingRequest={(selectedItems) => handlePrintingRequest(selectedItems, selectedPrintType)}
            />
          </div>
        </>
      )}
      {isLoading && !error && (<div className="content"><LoadingIndicator /></div>)}
      {isErrorOpen ? (
        <ErrorModal
          open={isErrorOpen}
          onClose={() => setErrorOpen(false)}
          buttonLabel={getMessage('ok')}
          errorMessage={dialogData}
        />
      ) : null}
      <AddressModal
        open={isAddressModalOpen}
        setAddressModalOpen={setAddressModalOpen}
        onClose={() => setAddressModalOpen(false)}
        adjustmentId={adjustmentId}
        setErrorOpen={setErrorOpen}
        setDialogData={setDialogData}
      />
      {' '}

      {(isAdmin && !isProd()) && <Button onClick={() => showMockChallan()} buttonText="mock challan" />}
      {showCallanMock && (
        <div>
          <ReactToPrint
            trigger={() => (
              <PrintIcon
                data-testid="print-icon"
                style={{ width: '25px' }}
              />
            )}
            content={() => challanRef.current}
          />
          <ChallanForm reference={challanRef} />
        </div>
      )}
    </div>
  );
};

export default AdjustmentsPage;
