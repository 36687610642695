/**
 * Create dropdown options for country
 * @param {array.<Object>} storesList - array of simplified store objects
 * @returns {Array.<Object>} - formatted array of country option objects
 */
export const createCountryOptions = (storesList) => {
  if (!storesList?.length > 0) return [];

  const optionsList = [...new Set(storesList.map(store => store.address?.country))];

  return optionsList.map(country => ({ label: country?.toUpperCase(), value: country?.toUpperCase() }));
};

/**
 * Create dropdown options for store numbers based on the country selected
 * @param {array.<Object>} storesList - array of simplified store objects
 * @param {string} country - country to filter by
 * @returns {array.<Object>} - formatted array of store number options with language and storeId
 */
export const filterStoresByCountry = (storesList, country) => {
  if (!storesList?.length > 0) return [];

  const filteredStores = storesList.filter(store => store?.address?.country === country);

  return filteredStores.map(store => ({ 
    label: store.storeNumber,
    value: store.storeNumber,
    storeId: store.id,
    language: store.defaultLanguage 
  }));
};