import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setSessionValues } from '../utils/setSessionValues';
import { newRelicAction } from '../utils/newRelicPageActions';
import useAuth from './useAuth';
import { BROWSER, LOGIN_SOURCE } from '../constants/storageKeys.constants';

/**
 * This hook handles the logic for sharing credentials between tabs.
 */
export const useNewTab = () => {
  const isBrowser = localStorage.getItem(LOGIN_SOURCE) === BROWSER;
  const history = useHistory();
  const attemptedRoute = history.location.pathname.split('/')[1];

  const { getAccessToken } = useAuth();

  useEffect(() => {
    if (!isBrowser) return;

    window.addEventListener('storage', event => {
      const tokenResponse = getAccessToken();
      const token = tokenResponse?.token;

      if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && token) {
        const store = sessionStorage.getItem('sim-store');

        localStorage.setItem('CREDENTIALS_SHARING', JSON.stringify({ store, token }));
        localStorage.removeItem('CREDENTIALS_SHARING');
      }

      if (event.key === 'CREDENTIALS_SHARING' && !token) {
        const tokens = JSON.parse(event.newValue);
        setSessionValues(tokens.token);
        sessionStorage.setItem('sim-store', tokens.store);
        newRelicAction('simweb-credential-sharing', { isShared: true });

        // forces a refresh to the attempted route which is needed
        // to pull all of the newly set session variables in useEffects below.
        window.location = attemptedRoute;
      }
    });
  }, []);

  useEffect(() => {
    if (!isBrowser) return;

    localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString());
    localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
  }, []);
};

export default useNewTab;