import { useContext, useMemo } from 'react';
import { object, array, bool, func } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { isEmpty } from 'lodash';

import { getRfidTableHeader } from '../RFIDTableHeader';
import getStockOnHand from '../../utils/getStockOnHand';
import { sortSizes } from '../../utils/sort/sortSizes';
import { sortByStockOnHand } from '../../utils/sort';
import { defaultSort } from '../../utils/sort/defaultSort';

import { TextField } from '@mui/material';
import { SimWebContext } from '../../context/SimWeb.provider';

const GtinTable = ({ product, picklistItems, setSelectedPicklistItems, isRfidSelected, optionalColumns, isCreatingPicklist, isUnitCountEnabled }) => {
  const { sizes, content } = product || {};
  const {
    isIOS, isOffsiteEnabled, isOffsiteInactive, getMessage, storeConfig, iso2Country,
  } = useContext(SimWebContext);
  const rfidEnabled = storeConfig?.rfidEnabled?.value;

  const getQuantityLabel = () => (isRfidSelected ? getMessage('rfidStock') : getMessage('soh').toUpperCase());
  const rfidColumn = getRfidTableHeader('sohQuantity', getQuantityLabel(), 'productCard-summary', rfidEnabled, isOffsiteEnabled, isOffsiteInactive, !rfidEnabled);

  const rows =  useMemo(() => sizes?.map(_ => ({
    ..._,
    gtin: _?.gtin || '-',
    sizeName: _?.label || '-',
    sohQuantity: getStockOnHand(_?.quantity, isRfidSelected, isOffsiteEnabled),
    dotcomAvailability: _?.available ? 'YES' : 'NO',
    inboundUnits: _?.inboundUnits || '-',
    quantity: '0',
  })), [sizes]);

  const columns = useMemo(() =>{
    if (isEmpty(sizes)) return null;

    return [{
    name: 'gtin',
    label: getMessage('upc'),
  },
  {
    name: 'sizeName',
    label: getMessage('size'),
  },
  rfidColumn,
  {
    name: 'dotcomAvailability',
    label: 'Nike.com',
    options: {
      display: ((!!iso2Country && optionalColumns.includes('dotcomAvailability')) && !isCreatingPicklist) || 'excluded', // iso2Country for merchGroup
    },
  },
  {
    name: 'inboundUnits',
    label: getMessage('inboundUnits'),
    options: {
      display: (isUnitCountEnabled && !isCreatingPicklist) || 'excluded',
    },
  },
  {
    name: 'quantity',
    label: getMessage('quantity'),
    options: {
      filter: false,
      sort: true,
      display: isCreatingPicklist || 'excluded',
      customBodyRender: (value, tableMeta) => {
        const gtin = tableMeta.rowData[0];
        const soh = tableMeta.rowData[2];
         
        const quantity = picklistItems?.find(item => (item.gtin === gtin))?.quantityRequested;
        const { rowIndex } = tableMeta; // Get the row index
        let currentValue = quantity ?? 0;

        if (soh === '-' || soh === '0 / 0') {
          return ('-');
        }

        return (
          <TextField
            data-testid={`quantity-input-${rowIndex}`}
            defaultValue={currentValue}
            value={currentValue}
            type="number"
            onChange={event => setSelectedPicklistItems(gtin, content?.imageSet?.images?.map(image => image.url), event.target.value)}
            InputProps={{
              readOnly: false,
              inputMode: 'numeric',
              style: {
                width: '70px',
              },
              min: 0,
            }}
            onKeyDown={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
              if (event?.code === 'ArrowDown' || event?.code === 'ArrowUp') {
                event.preventDefault();
              }
            }}
            onInput={(e) => {
              const numericValue = parseFloat(e.target.value);
              // Check if the numeric value exceeds the max value (100)
              if (!Number.isNaN(numericValue) && numericValue > parseInt(soh, 10) && !soh?.includes('/')) {
                e.target.value = parseInt(soh, 10);
                currentValue = parseInt(soh, 10);
              } else {
                const sohArray = soh.split('/');
                const fullStoreSOH = sohArray?.map(Number).reduce((a, b) => a + b, 0);
                if (!Number.isNaN(numericValue) && numericValue > parseInt(fullStoreSOH, 10)) {
                  e.target.value = parseInt(fullStoreSOH, 10);
                  currentValue = parseInt(fullStoreSOH, 10);
                }
              }
            }}
            size="small"
          />
        );
      },
    },
  }]}, [picklistItems]);

  if (isEmpty(sizes)) return null;
  
  return (
    <div data-testid="detailsGtinTable">  
      <MUIDataTable
        data={rows}
        columns={columns}
        options={{
          selectableRows: 'none',
          rowsPerPage: isIOS ? 4 : 8,
          rowsPerPageOptions: [],
          filter: false,
          print: false,
          download: false,
          search: false,
          viewColumns: true,
          elevation: 0,
          sortOrder: {
            name: 'sizeName',
            direction: 'asc',
          },
          customSort: (data, dataIndex, rowIndex) => {
            if (dataIndex === 1) {
              
              return data && sortSizes(data, rowIndex, dataIndex);
            }
            if (dataIndex === 3) {
              
              return data && sortByStockOnHand(data, rowIndex, dataIndex, isRfidSelected);
            }
            return defaultSort(data, dataIndex, rowIndex);
          },
        }}
        className="MUIDataTable"
      />
    </div>
  );
};

export default GtinTable;

GtinTable.propTypes = {
  product: object.isRequired,
  picklistItems: array.isRequired,
  setSelectedPicklistItems: func.isRequired,
  isRfidSelected: bool.isRequired,
  optionalColumns: array.isRequired,
  isCreatingPicklist: bool.isRequired,
  isUnitCountEnabled: bool.isRequired,
};