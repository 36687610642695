import MUIDataTable from 'mui-datatables';
import {
  array, bool, func, string,
} from 'prop-types';
import { getRfidTableHeader } from '../../../../components/RFIDTableHeader';
import { sortSizes, defaultSort } from '../../../../utils/sort';

const GtinTable = ({
  scanAreaData = [],
  scanArea = 'fullStore',
  scanType = '',
  rfidEnabled = false,
  sohTypeSelected,
  isOffsiteEnabled = false,
  isOffsiteInactive = false,
  getMessage,
}) => {
  const sohColumn = (sohTypeSelected === 'RFID SOH')
    ? getRfidTableHeader('physicalStockQuantity', getMessage('rfidStock'), 'productCard-summary', rfidEnabled, isOffsiteEnabled, isOffsiteInactive, !rfidEnabled, true, null, null, null, scanArea)
    : { name: 'fiscalStockQuantity', label: getMessage('soh') };
  const columns = [
    {
      name: 'gtin',
      label: getMessage('upc'),
    },
    {
      name: 'size',
      label: getMessage('size'),
    },
    {
      name: 'scanned',
      label: getMessage('scanned'),
    },
    {
      name: 'expected',
      label: getMessage('expected'),
    },
    {
      name: 'missing',
      label: scanArea === 'fullStore' && scanType !== 'SALES_FLOOR_SCAN' ? getMessage('missing') : getMessage('lost'),
    },
    {
      name: 'extra',
      label: scanArea === 'fullStore' && scanType !== 'SALES_FLOOR_SCAN' ? getMessage('extra') : getMessage('found'),
    },
    {
      name: 'accuracy',
      label: getMessage('accuracy'),
    },
    sohColumn,
  ];

  const options = {
    selectableRows: 'none',
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50],
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    elevation: 0,
    customSort: (data, dataIndex, rowIndex) => {
      if (dataIndex === 1) {
        return data && sortSizes(data, rowIndex);
      }
      return defaultSort(data, dataIndex, rowIndex);
    },
  };

  return (
    <div
      className="scan-product-gtin-table"
      data-testid="scan-product-gtin-table"
    >
      <MUIDataTable
        data={scanAreaData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

GtinTable.propTypes = {
  scanAreaData: array,
  scanArea: string,
  scanType: string,
  rfidEnabled: bool,
  sohTypeSelected: string.isRequired,
  isOffsiteEnabled: bool,
  isOffsiteInactive: bool,
  getMessage: func.isRequired,
};

export default GtinTable;
