export const changeMockDataScanTypeByConfigs = (data, cycleCountEnabled, virtualWallEnabled, isOffsiteEnabled) => {
  if (!data || data.length === 0) return [];

  if (cycleCountEnabled) {
    data[25].scanType = 'FILTERED_FULL_STORE_SCAN';
  } else if (!cycleCountEnabled && !virtualWallEnabled) {
    data[25].scanType = 'SALES_FLOOR_SCAN';
  } else if (!cycleCountEnabled && virtualWallEnabled && isOffsiteEnabled) {
    data[25].scanType = 'OFFSITE_SCAN';
  } else {
    data[25] = {
      value: 'Jul 6, 2020',
      dayOfWeek: 'Monday',
      aggregations: [],
      scanType: 'incomplete',
      accuracyTotal: '-',
      totalScansCompleted: 0,
      scanStatus: {
        id: null,
        status: null,
        scannedTotalCount: null,
        statusHistory: null,
        totalResumeCount: null,
      },
    };
  }

  return data;
};
