import { useContext } from 'react';
import AgedOrdersProvider from '../../../containers/agedOrders/AgedOrders.provider';
import AgedOrdersWidget from './AgedOrdersWidget';
import { AdminContext } from '../../../context/Admin.provider';
import { StoreConfigContext } from '../../../context/StoreConfig.provider';

const AgedOrdersWidgetContainer = () => {
  const { storeConfig } = useContext(StoreConfigContext);
  const { isAdmin } = useContext(AdminContext);
  const isAgedOrdersEnabled = isAdmin || storeConfig?.isAgedOrdersEnabled?.value;
  return isAgedOrdersEnabled && (
    <AgedOrdersProvider>
      <AgedOrdersWidget />
    </AgedOrdersProvider>
  );
};

export default AgedOrdersWidgetContainer;
