import { isLocalHost, environment } from '../configs/environment';

export const messages = {
  REQUEST_ACCESS_TOKEN: 'requestAccessToken',
  SET_TITLE: 'setTitle',
  GET_URL: 'getUrl',
  ADDRESS_BAR: 'addressbar',
  CLOSE: 'close',
};

export const getTargetOrigin = () => {
  // run simweb first, then the retail launchpad
  // the url of the Retail Launchpad
  let targetOrigin = ['http://localhost:3000', 'http://localhost:3001'];

  const buildMode = environment || 'none';

  if (isLocalHost()) {
    return targetOrigin;
  }

  if (buildMode === 'test') {
    targetOrigin = [
      'https://true-test.nike.com',
      'https://rtl-lchpad-2.rbh-retail-launchpad-test.nikecloud.com',
      'https://rlp-test.nike.com',
    ];
  }

  if (buildMode === 'prod') {
    targetOrigin = [
      'https://true.nike.com',
      'https://rtl-lchpad-2.rbh-retail-launchpad-prod.nikecloud.com',
      'https://rlp.nike.com',
    ];
  }

  return targetOrigin;
};
