import { get } from "lodash";

/**
 * A helper function to sort data by season and year.
 * @param {object} data the data to be sorted
 * @param {string} path the path to the season data
 * @param {string} direction the direction to sort by
 */
export const sortBySeasonAndYear = (data, path, direction) => {
  if (!data) return [];

  const seasonList = ['FA', 'HO', 'SP', 'SU'];

  const regexp = /(.+)(\d{4})/;

  const sortedValue = [...data];
  return sortedValue.sort((a, b) => {
    const aData = get(a, path);
    const bData = get(b, path);
    if (aData === '-' && bData === '-') return 0;
    if (aData === '-' || bData === '-') {
      if (direction === 'asc') return (aData !== '-' && bData === '-') ? -1 : 1;
      return (aData === '-' && bData !== '-') ? 1 : -1;
    }

    const [seasonFirst, yearFirst] = regexp.exec(aData).slice(1);
    const [seasonSecond, yearSecond] = regexp.exec(bData).slice(1);

    if (direction === 'asc') return +yearFirst - +yearSecond || seasonList.indexOf(seasonFirst) - seasonList.indexOf(seasonSecond);
    return +yearSecond - +yearFirst || seasonList.indexOf(seasonSecond) - seasonList.indexOf(seasonFirst);
  });
};

/**
 * A helper function to sort data by season.
 * @param {object} data the data to be sorted
 * @param {string} path the path to the season data
 * @param {string} direction the direction to sort by
 */
export const sortBySeason = (data, path, direction) => {
  if (!data) return [];

  const seasonOrder = ['FA', 'HO', 'SP', 'SU'];

  const sortedValue = [...data];
  return sortedValue.sort((a, b) => {
    const aData = get(a, path);
    const bData = get(b, path);
    if (aData === '-' && bData === '-') return 0;
    if (aData === '-' || bData === '-') {
      if (direction === 'asc') return (aData !== '-' && bData === '-') ? -1 : 1;
      return (aData === '-' && bData !== '-') ? 1 : -1;
    }

    if (direction === 'asc') return seasonOrder.indexOf(aData) - seasonOrder.indexOf(bData);
    return seasonOrder.indexOf(bData) - seasonOrder.indexOf(aData);
  });
};
