import { createStep } from '../tutorial.utils';

const steps = [
  {
    stepId: 1,
    title: 'styleManagementS1Title',
    content: 'styleManagementS1',
    target: '[data-testid="style-management-binnedButton"]',
    spotlightPadding: 15,
  },
  {
    stepId: 2,
    title: 'styleManagementS2Title',
    content: 'styleManagementS2',
    target: '[data-testid="style-management-unbinnedButton"]',
    spotlightPadding: 15,
  },
  {
    stepId: 3,
    title: 'styleManagementS3Title',
    content: 'styleManagementS3',
    target: '[data-testid="style-management-unbinnedButton"]',
    spotlightPadding: 15,
  },
  {
    stepId: 4,
    title: 'styleManagementS4Title',
    content: 'styleManagementS4',
    target: '[data-testid="style-management-genderAge-visual"]',
  },
  {
    stepId: 5,
    title: 'styleManagementS5Title',
    content: 'styleManagementS5',
    target: '.toggle-vertical',
    spotlightPadding: 10,
  },
  {
    stepId: 6,
    title: 'styleManagementS6Title',
    content: 'styleManagementS6',
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    placement: 'top',
  },
  {
    stepId: 7,
    title: 'styleManagementS7Title',
    content: 'styleManagementS7',
    target: '[data-testid="columnStep"]',
    placement: 'top',
  },
  {
    stepId: 8,
    title: 'styleManagementS8Title',
    content: 'styleManagementS8',
    target: '[data-testid="MUIDataTableBodyRow-0"]',
  },
  {
    stepId: 9,
    title: 'styleManagementS13Title',
    content: 'styleManagementS13',
    target: '[data-testid="style-management-refresh"]',
  },
  {
    stepId: 10,
    title: 'Selecting a Single Row Item',
    content: 'Click on a single checkbox for hand selecting row items from the list below.',
    target: '.MuiTableBody-root > tr',
  },
  {
    stepId: 11,
    title: 'Create a Picklist',
    content: 'Click on the list icon to create a picklist for all the selected items in the size compliance data table.',
    placement: 'top',
    target: '[data-testid="size-compliance-picklist-button"]',
    spotlightPadding: 10,
  },
  {
    stepId: 12,
    title: 'Select a Quantity',
    content: 'Select a quantity for each of the sizes in the picklist.',
    target: '[data-testid="quantity-input-2"]',
    spotlightClicks: true,
    spotlightPadding: 10,
  },
  {
    stepId: 13,
    title: 'Save the Selected Quantities',
    content: 'After entering the quantities, click the save button.',
    target: '[data-testid="picklist-save-button"]',
    spotlightClicks: true,
    spotlightPadding: 10,
  },
  {
    stepId: 14,
    title: 'Name the Picklist',
    placement: 'top',
    content: 'Enter the name of the picklist.',
    target: '[data-testid="style-management-picklist-form-input"]',
    spotlightClicks: false,
  },
  {
    stepId: 15,
    title: 'Create or Cancel the Picklist',
    content: 'Click the save button to create or cancel to discard.',
    target: '[data-testid="style-management-picklist-form-cancel"]',
    spotlightClicks: true,
  },
];

export const styleManagementStepsArray = steps.map(step => createStep(step));
export const styleManagementSteps = (rfidEnabled, translatedSteps) => {
  let newSteps = translatedSteps;

  if (!rfidEnabled) {
    newSteps = newSteps.filter(value => value.config !== true);
  }

  return newSteps;
};

/**
 * Util that takes state/utils/configs & handles setting/changing steps in the turorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} restartTutorial - a value used to cancel out any state since the tutorial is no longer active
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {func} setStepIndex - a function that handles setting the step index
 */
export const setStyleManagementTutorialSteps = (
  startTutorial, stepIndex, restartTutorial, isbackClicked, setStepIndex,
) => {
  const listOfCheckboxes = document.querySelectorAll('table td input');
  const checkbox = listOfCheckboxes?.[0];
  const picklistButton = document.querySelector('[data-testid="size-compliance-picklist-button"]');
  const productDetailCard = document.querySelector('[data-testid="productDetails-content"]');

  if (startTutorial) {
    // step 8-9 is handles in src/components/tutorial/customActions.js due to tool tip placement
    if (stepIndex === 10) {
      const listOfCheckboxes = document.querySelectorAll('table td input');
      const checkbox = listOfCheckboxes?.[0];
      if (!checkbox.checked) checkbox.click();
      
      if (isbackClicked) {
        const closeModal = document.querySelector('[data-testid="modalCloseButton"]');
        closeModal?.click();
      }
    }

    if (stepIndex === 11) {
      picklistButton?.click();

      setTimeout(() => { 
        setStepIndex(stepIndex);
       }, 300);

       if (productDetailCard) return;
    }

    if (stepIndex === 12 && !isbackClicked) {
      if (!productDetailCard) return;

      const quantity = document.querySelector('[data-testid="quantity-input-2"]');
      quantity?.click();
    }

    if (stepIndex === 12 && isbackClicked) {
      // Close picklist modal if the checkbox is checked
      const cancelPicklistButton = document.querySelector('[data-testid="style-management-picklist-form-cancel"]');
      if (checkbox.checked && cancelPicklistButton) {
        cancelPicklistButton.click();
      }
    
      setTimeout(() => {
        if (!checkbox.checked) {
          checkbox.click();

          setTimeout(() => {
            const picklistButton = document.querySelector('[data-testid="size-compliance-picklist-button"]');
            if (!picklistButton) {
              console.error('Picklist button not found');
              return;
            }
    
            if (picklistButton.disabled || picklistButton.offsetParent === null) {
              console.error('Picklist button is not interactable');
              return;
            }
    
            picklistButton.click();
          }, 500);
        }
      }, 100);
    }

    if (stepIndex === 13) {
      const save = document.querySelector('[data-testid="picklist-save-button"]');
      if (save) save?.click();
    }

    if (stepIndex === 14) {
      const picklistName = document.querySelector('[data-testid="style-management-picklist-form-input"]');
      picklistName?.focus();
    }

    if (stepIndex === 15) {
      const cancelPicklistButton = document.querySelector('[data-testid="style-management-picklist-form-cancel"]');
      cancelPicklistButton?.click();
    }
  }
  if (restartTutorial) {
    const closeModal = document.querySelector('[data-testid="modalCloseButton"]');
    const cancelPicklistButton = document.querySelector('[data-testid="style-management-picklist-form-cancel"]');

    if (checkbox.checked) checkbox.click();
    if (closeModal) closeModal.click();
    if (cancelPicklistButton) cancelPicklistButton.click();
  }
}
