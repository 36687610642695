import allFlags from '../assets/flags/index';
import noFlag from '../assets/flags/noFlag.png';

/**
 * The getFlag func takes the selected language and pulls the correct matching flag
  * @param {string} country - The language of the flag to be retrieved
  * @param {object} notFound - flag returned if not found by language
*/
export const getLanguageFlag = (country, notFound) => {
  const selectFlagForCountry = allFlags[country];
  return selectFlagForCountry && selectFlagForCountry.image ? selectFlagForCountry.image : notFound ?? noFlag;
};

export default getLanguageFlag;