import { useContext, useEffect, useState } from 'react';
import { array, bool, func } from 'prop-types';
import {
  Button,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Checkbox,
  Paper,
  TextField,
} from '@mui/material';
import {
  Clear, KeyboardArrowLeft, KeyboardArrowRight, Search,
} from '@mui/icons-material';
import { get as lodashGet } from 'lodash';

import { LoadingIndicator, NoDevice } from '../../assets/Svgs/index';
import { TranslationsContext } from '../../context/Translations.provider';

/* Section for helper functions that let us filter the left list */
const onlyWhitespace = /^(\s*)$/;
const repeatedWhitespace = /(\s+)/;
const leftSearchFields = ['id', 'description', 'deviceStatus', 'ownerId'];

 
const any = (predicate, list) => {
  let r = false;
  for (let i = 0; i < list.length && !r; i += 1) {
    r |= predicate(list[i]);
  }
  return r;
};

const all = (fn, list) => {
  let r = true;
  for (let i = 0; r && i < list.length; i += 1) {
    r &= fn(list[i]);
  }
  return r;
};
/* End of filtering helper functions section */

const not = (a, b) => a.filter(value => b.indexOf(value) === -1);

const intersection = (a, b) => a.filter(value => b.indexOf(value) !== -1);

const adornmentStyle = { minHeight: '20px', minWidth: '20px' };
const buttonStyle = { minHeight: '35px', minWidth: '60px' };
const iconStyle = { alignSelf: 'center', border: 'none', padding: '0' };

const TransferList = ({
  assigned,
  available,
  clear,
  devicesInSession,
  disabled,
  fetching,
  setDevicesAssigned,
  setDevicesAvailable,
  setNewChanges,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(available);
  const [right, setRight] = useState(assigned);

  const [leftSearch, setLeftSearch] = useState('');
  const [leftFiltered, setLeftFiltered] = useState(available);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  /* These effects are for updating the lists to refect the new flags of the device after submit */
  useEffect(() => {
    setRight(assigned);
  }, [assigned]);

  useEffect(() => {
    setLeft(available);
    setLeftFiltered(available);
  }, [available]);

  const assignmentsChanged = (assignedDevices, availableDevices, sessionDevices) => {
    const assignments = assignedDevices.filter(device => device.isNewlyAssigned);
    const unAssignments = availableDevices.filter(device => device.isNewDeletion)
      .map(device => ({ ...device, sessionId: sessionDevices.find(session => session.deviceId === device.id).id }));
    setNewChanges(assignments.length || unAssignments.length);
  };

  const handleDeviceAssignment = (r, l) => {
    clear();
    assignmentsChanged(r, l, devicesInSession);
    setDevicesAssigned(r);
    setDevicesAvailable(l);
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // Left to Right
  const handleCheckedRight = () => {
    const rightList = right.concat(leftChecked
      .map(device => ({ ...device, isNewlyAssigned: !device.isCurrentlyAssigned })));
    const leftList = not(left, leftChecked);
    const filteredLeftList = not(leftFiltered, leftChecked);
    setLeft(leftList);
    setLeftFiltered(filteredLeftList);
    setLeftSearch('');
    setRight(rightList);
    setChecked(not(checked, leftChecked));
    handleDeviceAssignment(rightList, leftList);
  };

  // Right to Left
  const handleCheckedLeft = () => {
    const leftList = left.concat(rightChecked
      .map(device => ({ ...device, isNewDeletion: device.isCurrentlyAssigned })));
    const filteredLeftList = leftFiltered.concat(rightChecked);
    const rightList = not(right, rightChecked);
    setLeft(leftList);
    setLeftFiltered(filteredLeftList);
    setLeftSearch('');
    setRight(rightList);
    setChecked(not(checked, rightChecked));
    handleDeviceAssignment(rightList, leftList);
  };

  const updateSearch = (searchString) => {
    if (searchString.length) {
      let searchFilter = () => true;
      setLeftSearch(searchString);
      const terms = searchString.split(repeatedWhitespace)
        .filter(s => !onlyWhitespace.test(s))
        .map(s => new RegExp(s, 'i'));
      searchFilter = device => all(
        term => any(
          field => term.test(lodashGet(device, field)), leftSearchFields,
        ),
        terms,
      );
      setLeftFiltered(left.filter(device => searchFilter(device)));
    } else {
      setLeftSearch(searchString);
      setLeftFiltered(left);
    }
  };

  const customList = (items, filterable) => (
    <Paper elevation={2} style={{ width: 380, height: 450, overflow: 'auto' }}>
      {fetching
        ? <LoadingIndicator height="420" />
        : (
          <List dense component="div" role="list">
            {filterable && (
              <ListSubheader>
                <TextField
                  data-testid="deviceFilter"
                  disabled={disabled}
                  id="deviceFilter"
                  InputProps={{
                    inputProps: {
                      'data-testid': 'deviceFilterInput',
                      style: { background: 'white', minHeight: 30, minWidth: 220 },
                    },
                    startAdornment: (
                      <IconButton onClick={null} style={adornmentStyle} size="large">
                        <Search style={{ ...adornmentStyle, ...iconStyle }} />
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton onClick={() => updateSearch('')} style={adornmentStyle} size="large">
                        <Clear style={{ ...adornmentStyle, ...iconStyle }} />
                      </IconButton>
                    ),
                  }}
                  onChange={e => updateSearch(e.target.value)}
                  style={{ width: 350, marginLeft: '-2px', background: 'white' }}
                  value={leftSearch}
                />
              </ListSubheader>
            )}
            {!items || items.length === 0
              ? <NoDevice height="420" />
              : items.map((item) => {
                const id = `transfer-list-item-${item.id}-label`;
                return (
                  <ListItem
                    key={item.id}
                    role="listitem"
                    button
                    onClick={handleToggle(item)}
                    disabled={disabled}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(item) !== -1}
                        color="default"
                        disabled={disabled}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': id }}
                      />
                    </ListItemIcon>
                    <ListItemText id={id} primary={`${item.description} - ${item.deviceStatus}`} />
                  </ListItem>
                );
              })}
            <ListItem />
          </List>
        )}
    </Paper>
  );

  return (
    <Grid2 container spacing={1} alignItems="center" direction="row" justifyContent="center">
      <Grid2 data-testid="available">
        <p style={{ fontSize: '18px', marginBottom: '-1px' }}>{getMessage('availableDevices')}</p>
        {customList(leftFiltered, true)}
      </Grid2>
      <Grid2>
        <Button
          data-testid="button-checked-right"
          disabled={leftChecked.length === 0}
          onClick={handleCheckedRight}
          style={buttonStyle}
          variant="outlined"
        >
          <KeyboardArrowRight style={{ ...buttonStyle, ...iconStyle }} />
        </Button>
        <br />
        <br />
        <Button
          data-testid="button-checked-left"
          disabled={rightChecked.length === 0}
          onClick={handleCheckedLeft}
          style={buttonStyle}
          variant="outlined"
        >
          <KeyboardArrowLeft style={{ ...buttonStyle, ...iconStyle }} />
        </Button>
      </Grid2>
      <Grid2 data-testid="assigned">
        <p style={{ fontSize: '18px', marginBottom: '-1px' }}>{getMessage('assignedDevices')}</p>
        {customList(right, false)}
      </Grid2>
    </Grid2>
  );
};

TransferList.propTypes = {
  assigned: array.isRequired,
  available: array.isRequired,
  clear: func.isRequired,
  devicesInSession: array.isRequired,
  disabled: bool.isRequired,
  fetching: bool.isRequired,
  setDevicesAssigned: func.isRequired,
  setDevicesAvailable: func.isRequired,
  setNewChanges: func.isRequired,
};

export default TransferList;
