import { DateTime } from 'luxon';
import { DATE_TIME_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { isProd } from '../../configs/environment';

const cartonErrorCodeMapping = {
  CARTON_ALREADY_RECEIVED: 'shipmentAlreadyReceived',
  INVALID_CARTON_NUMBER: 'invalidCartonNumber',
  CARTON_MISSING_ERROR: 'cartonNotFound',
};

/**
 * A helper function to get the error message from axios request and translate the message if needed.
 * @param {object} error the error object from axios request
 * @param {function} getMessage a function to get translated message
 */
export const getErrorMessage = (error, getMessage) => {
  let message;

  if (error?.response?.status === 409) {
    message = getMessage(cartonErrorCodeMapping.CARTON_ALREADY_RECEIVED);
  } else if (error?.response?.data?.errors[0]) {
    const errorCodeMessage = cartonErrorCodeMapping[error.response.data.errors[0].code];
    message = errorCodeMessage ? getMessage(errorCodeMessage) : error.response.data.errors[0].message;
  } else {
    message = error?.message || '';
  }

  return message;
};

/**
 * A helper function to filter data with indexes of selected items
 * @param {array} data the receiving log data
 * @param {array} selectedItems an array of indexes of selected data
 */
export const filterSelectedData = (data, selectedItems) => {
  if (selectedItems.length > 0) {
    return data?.filter((item, i) => selectedItems.includes(i));
  }
  return data;
};

export const buildDownloadData = (data, selectedItems, useProNumber, timeZone) => {
  const selectedArray = filterSelectedData(data, selectedItems);
  const toDownload = [];

  selectedArray?.map(selected => {
    selected.purchaseOrders.map(purchaseOrder => {
      purchaseOrder.cartons.map(carton => {
        carton.cartonItems.map(item => {
          toDownload.push({
            ...(useProNumber
              ? { 'PRO Number': selected.proNumber }
              : { 'Delivery Number': selected.deliveryNumber }),
            'PO Number': purchaseOrder.purchaseOrderNumber,
            ...!isProd && { 'Delivery Numbers': carton?.deliveryNumbers?.join(',') },
            'Carton': carton.cartonNumber,
            'Received Date': (carton.receiveDate && DateTime.fromISO(carton.receiveDate, { zone: timeZone }).toLocaleString(DATE_TIME_SHORT_WITH_APPENDED_ZEROS)) || '-',
            'UPC': item.gtin,
            'Units Expected': item.unitsExpected,
            'Units Received': item.unitsReceived,
            'Variance': Math.abs(item.variance),
            'SOH': item.stockOnHand,
          });
        });
      });
    });
  });
  return toDownload;
};
