import { Fragment, useContext } from 'react';
import { bool, string } from 'prop-types';
import { SimWebContext } from '../../../context/SimWeb.provider';
import DashboardWidgetContainer from '../../../components/dashboardWidgets/DashboardWidgetContainer';
import {
  ScanAcuracy, ScanTimer, SizeCompliance, StyleCompliance, ScanCompliance,
} from './index';
import TutorialProvider, { tutorialContext } from '../../../components/tutorial/Tutorial.provider';

const RfidWidget = ({ isRfidDataEnabled = false, rfidFullStoreScanDate = '' }) => {
  const { getMessage, } = useContext(SimWebContext);
  const { activateTutorials } = useContext(tutorialContext);


  const complianceWidget = (
    <div key="compliance-widget" className="rfid-flex-complianceWidgets">
      <StyleCompliance />
      <div style={{ paddingTop: activateTutorials ? '31px' : '' }}><SizeCompliance /></div>
    </div>
  );

  const widgets = [
    <ScanTimer key="scan-timer" />,
    <ScanAcuracy key="scan-accuracy" />,
    <ScanCompliance key="scan-compliance" rfidFullStoreScanDate={rfidFullStoreScanDate} />,
    complianceWidget,
  ];

  return (isRfidDataEnabled
      && (
        <DashboardWidgetContainer title={getMessage('rfid')} testid="dashboard_rfidWidget">
          { widgets.map((widget, index) => <Fragment key={index}><TutorialProvider>{widget}</TutorialProvider></Fragment>) }
        </DashboardWidgetContainer>
      ));
};

RfidWidget.propTypes = {
  isRfidDataEnabled: bool,
  rfidFullStoreScanDate: string,
};

export default RfidWidget;
