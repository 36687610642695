import useSimStore from "../store/simStore";

/**
 * Functions that will take a provided key string and find the corresponding translation
 * in the translations object.
 * 
 * @example <caption>Format Message Usage</caption> formatMessage('stringKey', { param: 'value' })
 * @returns {{
 *   getMessage: (key: string) => string,
 *  formatMessage: (key: string, params: object) => string 
 * }} A translated string.
 */
const useTranslator = () => {
  const translations = useSimStore((state) => state.translations);

  /**
   * Returns the translated message for the provided key.
   */
  const getMessage = (key) => {
    if (!key || key === '-') {
      return '-';
    }
    return translations[key]?.value ?? `<<${key}>>`;
  };

  /**
   * Returns the translated message for the provided key and adds the provided dynamic params.
   */
  const formatMessage = (key, params) => {
    const message = getMessage(key);
    if (message) {
      const formatted = message.replace(/\{(.*?)\}/gu, (_, token) => params[token]);
      return formatted;
    }
    return message;
  };

  return {
    getMessage,
    formatMessage
  };
};

export default useTranslator;