import {
  createContext, useState, useContext, useEffect,
} from 'react';
import { node, object } from 'prop-types';
import { SimWebContext } from '../../context/SimWeb.provider';
import { CPAContext } from '../../context/cpa/CPA.provider';
import { fetchStockroomOrganizationData } from './stockroomOrganization.axios';
import { isCacheExpired, getCache, setCache } from './stockroomOrganization.utils';

export const StockroomContext = createContext({});

const StockroomOrganizationProvider = ({ children = {}, mockedValue = null }) => {
  const {
    storeId, country, locale, region,
  } = useContext(SimWebContext);
  const { Provider } = StockroomContext;

  const { getProducts } = useContext(CPAContext);

  const [rows, setRows] = useState([]);
  const [modalStyleColor, setModalStyleColor] = useState(null);
  const [scIndex, setSCIndex] = useState(0);
  const [styleColors, setStyleColors] = useState(null);
  const [allBins, setAllBins] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setStyleColors(null);
    setLoading(true);

    try {
      const styleColors = await fetchStockroomOrganizationData(storeId, country, region);
      setStyleColors(styleColors);

      setCache(storeId, country, locale, styleColors);
    } catch (error) {
      setStyleColors(null);
      setError(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (storeId && country) {
      if (isCacheExpired(storeId, country)) {
        fetch();
      } else {
        const cachedData = getCache(storeId, country);
        setLoading(true);
        setStyleColors(cachedData.styleColors);
        setLoading(false);
      }
    }
  }, [storeId, country, isCacheExpired]);

  useEffect(() => {
    if (styleColors && !rows?.length) {
      const allBins = Array.from(new Set(styleColors.flatMap(styleColor => styleColor.bins)));
      setAllBins(allBins);

      setRows(styleColors?.filter(_ => _.id !== null)
        .map(_ => ({
          bin: _.binName,
          division: _.division,
          class: _.class,
          category: _.category,
          genderName: _.gender,
          description: _.description,
          color: _.color,
          styleColor: _.styleColor,
          key: _.key,
        })));
    }
  }, [styleColors, rows?.length]);

  useEffect(() => {
    const getProductDataForStyleColor = () => getProducts(rows[scIndex].styleColor);
    if (rows?.[scIndex]?.styleColor) {
      getProductDataForStyleColor();
    }
  }, [rows, scIndex]);

  return (
    <Provider value={mockedValue ?? {
      allBins,
      loading,
      error,
      refetch: fetch,
      rows,
      setRows,
      modalStyleColor,
      setModalStyleColor,
      scIndex,
      setSCIndex,
      styleColors,
    }}
    >
      {children}
    </Provider>
  );
};

StockroomOrganizationProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default StockroomOrganizationProvider;
