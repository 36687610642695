import { useEffect } from 'react';
import {
  string,
  object,
  any,
  number,
  oneOfType,
} from 'prop-types';
import MUIAlert from '@mui/material/Alert';
import MUIAlertTitle from '@mui/material/AlertTitle';
import { makeStyles } from '@mui/styles';

const useStyles = (iconSize, fontSize) => makeStyles({
  iconSize: {
    '& .MuiAlert-icon': {
      fontSize: iconSize ?? 25,
    },
  },
  titleSize: {
    fontSize: fontSize ?? 15,
  },
});
/**
 * Component for building alerts and error messages
 * @param {string} severity - string for MUI alert severity values
 * @param {string} pageCode - string for originating page
 * @param {string} apiName - string for originating api call
 * @param {object} errorObject - object containing the errorCode and errorMessage
 * @param {string} customMessage - string custom string rendered if severity is an error
 * @param {object} additionalInfo - object containg code or a json object that cane be rendered in a code block
 * @param {string} iconSize -size of icon to render
 * @param {string} fontSize -size of icon to render
 */
export const Alert = ({
  severity,
  pageCode,
  apiName = null,
  errorObject = null,
  additionalInfo = null,
  customMessage = null,
  iconSize = null,
  fontSize = null,
}) => {
  useEffect(() => {
    if (window?.newrelic?.addPageAction) {
      window.newrelic.addPageAction({
        name: `${severity}-${apiName?.replace(/\s/g, '-')}`,
        attributes: {
          pageCode,
          errorCode: errorObject?.code,
          message: errorObject?.message,
          additionalInfo,
        },
      });
    }
  }, []);
  const getAdditionalInfo = () => {
    if (!additionalInfo) return null;

    return (
      <>
        <br />
        { additionalInfo ? 'Additional Info: ' : null}
        <br />
        <pre>
          <code>
            { additionalInfo ? JSON.stringify(additionalInfo, null, 4) : null}
          </code>
        </pre>
      </>
    );
  };

  const getTitle = () => {
    if (severity === 'error') {
      return `${apiName}${errorObject ? `: ${ JSON.stringify(errorObject)}` : ''} ${customMessage ?? pageCode}`;
    }

    return apiName;
  };

  const classes = useStyles(iconSize, fontSize);
  return (
    <div data-testid={`${severity}-message-${apiName?.replace(/\s/g, '-')}`} style={{ padding: '2em' }}>
      <MUIAlert className={classes.iconSize} severity={severity} data-testid={`${severity}-message-content`}>
        <MUIAlertTitle className={classes.titleSize}>{getTitle()}</MUIAlertTitle>
        {getAdditionalInfo()}
      </MUIAlert>
    </div>
  );
};

Alert.propTypes = {
  errorObject: any,
  apiName: string,
  additionalInfo: object,
  customMessage: string,
  pageCode: any.isRequired,
  severity: string.isRequired,
  iconSize: oneOfType([string, number]),
  fontSize: oneOfType([string, number]),
};

export default Alert;
