import { useContext, useMemo } from 'react';
import {
  bool, func, array, string,
} from 'prop-types';
import { DateTime } from 'luxon';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { orderBy } from 'lodash';
import MUIDataTable from 'mui-datatables';
import { LoadingIndicator } from '../../assets/Svgs/index';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import Error from '../../components/alerts/Error';
import { handleModalOpen } from './asnTracking.utils';
import { SimWebContext } from '../../context/SimWeb.provider';

const theme = createTheme(({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  },
}));

/**
 * The Table of asn tracking data, will show a loading spinner if loading is true, and will show an error screen
 * if error is true or loading is false and the data is missing
 * @param {object} data - the asn tracking report
 * @param {boolean} error - if true show error screen
 * @param {function} refetch - graphql refetch
 * @param {boolean} loading - if true show loading spinner
 * @param {function} setDialogData - will set the data to be shown in the details dialog
 * @param {function} setOpen - when called will open the details dialog
 * @param {array} setPROIndex - index of the PO Number to control the data in the modal
 * @param {function} setRows - updates the data when sorted so that we get the new order for the dialog
 * @param {string} store - store to display on the title on the data table
 */
const ASNTrackingTable = ({
  data = null,
  error = null,
  loading,
  setDialogData,
  setOpen,
  setPROIndex,
  setRows,
  store,
}) => {
  const { getMessage, formatMessage, isPrintingEnabled } = useContext(SimWebContext);

  const dataTable = useMemo(() => {
    if (loading && !error) return <LoadingIndicator />;

    if (error || (!loading && !data)) {
      return (
        <Error
          apiName="ASN Tracking API"
          errorObject={error}
        />
      );
    }

    const rows = data?.length >= 1 ? data?.map(_ => ({
      documentNumber: (_?.deliveryNumber || _?.proNumber),
      shipDate: _?.shipDate ? DateTime.fromISO(_?.shipDate).toLocaleString(
        DATE_SHORT_WITH_APPENDED_ZEROS,
      ) : '-',
      poTotal: _?.purchaseOrderCount || '-',
      cartons: _?.cartonCount || '-',
      styleColorCount: _?.styleColorCount || '-',
      unitCount: _?.unitCount || '-',
      apparelCount: _?.apparelCount,
      footwearCount: _?.footwearCount,
      equipmentCount: _?.equipmentCount,
      purchaseOrders: JSON.stringify(_?.purchaseOrders),
    })) : [];

    const documentLabel = getMessage(FormData?.[0]?.deliveryNumber
      ? 'documentNumber' : 'proNumber');

    const columns = [
      {
        name: 'documentNumber',
        label: documentLabel,
      },
      {
        name: 'shipDate',
        label: getMessage('shipDate'),
      },
      {
        name: 'poTotal',
        label: getMessage('poTotal'),
      },
      {
        name: 'cartons',
        label: getMessage('cartons'),
      },
      {
        name: 'styleColorCount',
        label: getMessage('style-color'),
      },
      {
        name: 'unitCount',
        label: getMessage('unitsExpected'),
      },
      {
        name: 'apparelCount',
        label: getMessage('apparel'),
      },
      {
        name: 'footwearCount',
        label: getMessage('footwear'),
      },
      {
        name: 'equipmentCount',
        label: getMessage('equipment'),
      },
      {
        name: 'purchaseOrders',
        options: {
          display: 'excluded',
        },
      },
    ];

    return (
      <MUIDataTable
        title={formatMessage('asnListStore', { store })}
        data={rows}
        columns={columns}
        options={{
          search: data?.length > 0,
          customSearch: (searchQuery, currentRow) => {
            let isFound = false;
            currentRow.forEach(col => {
              if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                isFound = true;
              }
            });
            return isFound;
          },
          onColumnSortChange: (changedColumn, direction) => {
            if (changedColumn === 'documentNumber') {
               
              changedColumn = data?.[0]?.deliveryNumber ? 'documentNumber' : 'proNumber';
            }
            const sortBy = {
              cartons: 'cartonCount',
              documentNumber: 'documentNumber',
              proNumber: 'proNumber',
              poTotal: 'purchaseOrderCount',
              purchaseOrders: 'purchaseOrders',
              shipDate: 'shipDate',
              styleColorCount: 'styleColorCount',
              unitCount: 'unitCount',
              apparelCount: 'apparelCount',
              footwearCount: 'footwearCount',
              equipmentCount: 'equipmentCount',
            };
            const orderDataBy = sortBy[changedColumn];
            setRows(orderBy(data, orderDataBy, [`${direction}`]));
          },
          selectableRows: 'none',
          rowsPerPage: 100,
          rowsPerPageOptions: [15, 25, 50, 100],
          print: isPrintingEnabled,
          responsive: 'standard',
          filter: false,
          textLabels: {
            body: {
              noMatch: getMessage('noData'),
            },
          },
          onRowClick: (rowData, { dataIndex }) => {
            const newDialogData = [
              {
                ...data[dataIndex],
                documentNumber: rowData[0],
              },
            ];
            setDialogData(newDialogData);
            setPROIndex(dataIndex);
            handleModalOpen(setOpen);
          },
          downloadOptions: {
            filterOptions: {
              useDisplayedColumnsOnly: true,
              useDisplayedRowsOnly: true,
            },
          },
          // ignoring lint rule bc the data isn't rendering properly if i update to the perfered-template
          onDownload: (buildHead, buildBody, newColumns, newData) => '\uFEFF' + buildHead(newColumns) + buildBody(newData),
        }}
      />
    );
  }, [data, loading, error]);

  return (
    <div data-testid="asnTrackingReportTable">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {dataTable}
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

ASNTrackingTable.propTypes = {
  data: array,
  error: string,
  loading: bool.isRequired,
  setDialogData: func.isRequired,
  setOpen: func.isRequired,
  setPROIndex: func.isRequired,
  setRows: func.isRequired,
  store: string.isRequired,
};

export default ASNTrackingTable;
