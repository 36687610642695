/**
 * Reference Usages:
 * https://moment.github.io/luxon/api-docs/index.html
 */

import moment from 'moment-timezone';
import { DateTime, Settings } from 'luxon';

export const PACIFIC_TIMEZONE = 'America/Los_Angeles';
// using timezone for EMEA edd, since 'Europe/Paris' is in the CET timezone.  May have to set to `Europe/Amsterdam` if needed for summer offset times (CEST).
export const CET_TIMEZONE = 'Europe/Amsterdam';
export const UTC_TIMEZONE = 'UTC';

/**
 * sets the defaultZone across all instances of luxon DateTime
 * @param {string} timezone - current timezone
 * Examples: "Asia/Tokyo", 'utc', "system"
 */
export const setDefaultZone = (timezone) => { Settings.defaultZone = timezone; };

/**
 * converts a date value to a luxon DateTime object
 * @param {object} value - datetime value, can be a string
 * @returns {DateTime} returns a luxon DateTime object
 */
export const toDateTime = (value, zone) => {
  try {
    const date = value ? new Date(value).toISOString() : DateTime.now().toISO();
    return DateTime.fromISO(date, { zone: zone ?? Settings?.defaultZone.name });
  } catch (error) {
    console.error({ error });
    return null;
  }
};

/**
 * gets a start of week, ie Sunday,  datetime object
 * @param {object} value - a datetime iso value, optional
 * @returns {DateTime} returns a luxon DateTime object
 */
export const startOfWeek = (value, zone) => toDateTime(value, zone).startOf('week', { useLocaleWeeks: true });

/**
 * gets an end of week, ie Saturday, datetime object
 * @param {object} value - a datetime iso value, optional
 * @returns {DateTime} returns a luxon DateTime object
 */
export const endOfWeek = (value, zone) => toDateTime(value, zone).endOf('week', { useLocaleWeeks: true });

/**
 * gets a start of day datetime object
 * @param {object} value - a datetime iso value, optional
 * @returns {DateTime} returns a luxon DateTime object
 */
export const startOfDay = (value, zone) => toDateTime(value, zone).startOf('day');

/**
 * gets an end of day datetime object
 * @param {object} value - a datetime iso value, optional
 * @returns {DateTime} returns a luxon DateTime object
 */
export const endOfDay = (value, zone) => toDateTime(value, zone).endOf('day');

/**
 * converts a date value a UTC based ISO
 * @param {object} value - a datetime or moment value
 * @returns {string} returns an iso string
 */
export const toUtcISO = (value) => (moment.isMoment(value) ? moment.utc(value).toISOString() : toDateTime(value)?.toUTC()?.toISO());

/**
 * formats a value to a localized string value based on timezone
 * @param {object} value - a datetime iso value
 * @param {string} format - string format expression
 * @returns {string} returns a formatted string
 */
export const toLocaleString = (value, format, zone) => toDateTime(value, zone)?.toLocaleString(format);

/**
 * formats a date time value
 * @param {object} value - a datetime iso value
 * @param {string} format - string format expression
 * @returns {string} returns a formatted string
 */
export const toFormat = (value, format, zone) => ((value && format) ? toDateTime(value, zone)?.toFormat(format) : null);

/**
 * gets the difference in days of two values
 * @param {object} value1 - a datetime value
 * @param {object} value2 - a datetime value
 * @returns {integer} returns an integer value of number of days
 */
export const diffInDays = (value1, value2, zone) => {
  const day1 = toDateTime(value1, zone);
  const day2 = toDateTime(value2, zone);
  return Math.floor(day1.diff(day2, 'days').toObject().days);
};

export const diffInMiliSeconds = (value1, value2, zone) => {
  const day1 = toDateTime(value1, zone);
  const day2 = toDateTime(value2, zone);
  return day1.diff(day2, 'seconds') * 1;
};

/**
 * determines of the value passed is a moment object
 * @param {object} obj - a datetime value
 * @returns {boolean} returns true of false
 */
export const isMoment = (obj) => obj instanceof moment;

/**
 *  luxon datetime helper methods
 *  see above for specific params and return values
 *  localized to one timezone.
 */
export const SimDateTime = {
  timezone: () => Settings?.defaultZone.name,
  now: () => DateTime.now(),
  diffInDays: (value1, value2, zone) => diffInDays(value1, value2, zone),
  diffInMiliSeconds: (value1, value2, zone) => diffInMiliSeconds(value1, value2, zone),
  startOfDay: (value, zone) => startOfDay(value, zone),
  endOfDay: (value, zone) => endOfDay(value, zone),
  startOfWeek: (value, zone) => startOfWeek(value, zone),
  endOfWeek: (value, zone) => endOfWeek(value, zone),
  toDateTime: (value, zone) => (DateTime.isDateTime(value) ? value : toDateTime(value, zone)),
  toFormat: (value, format, zone) => toFormat(value, format, zone),
  toISODate: (value, zone) => (value ? toDateTime(value, zone).toISODate() : null),
  toLocaleString: (value, format = DateTime.DATETIME_FULL, zone) => toLocaleString(value, format, zone),
  toMoment: (value) => moment.tz(DateTime.isDateTime(value) ? value.toUTC().toISO() : value, Settings?.defaultZone.name),
  toUtcISO: (value) => toUtcISO(value),
  toAmPm: (value, zone) => (value ? (toDateTime(value, zone).toFormat('hh:mm a').split(' ')?.[1]?.toUpperCase() ?? '-') : '-'),
  // Troubleshooting Timezones
  log: (value) => {
    const datetime = value;
    console.log({
      UTC: datetime,
      SimDateTimeZone: Settings?.defaultZone.name,
      SimDateTime: toDateTime(datetime).toFormat('yyyy-MM-dd: HH:mm:ss'),
      '1: Asia/Tokyo': toDateTime(datetime, 'Asia/Tokyo').toFormat('yyyy-MM-dd: HH:mm:ss'),
      '2: Asia/Shanghai': toDateTime(datetime, 'Asia/Shanghai').toFormat('yyyy-MM-dd: HH:mm:ss'),
      '3: Europe/Paris': toDateTime(datetime, 'Europe/Paris').toFormat('yyyy-MM-dd: HH:mm:ss'),
      '4: America/New_York': toDateTime(datetime, 'America/New_York').toFormat('yyyy-MM-dd: HH:mm:ss'),
      '5: America/Chicago': toDateTime(datetime, 'America/Chicago').toFormat('yyyy-MM-dd: HH:mm:ss'),
      '6: America/Los_Angeles': toDateTime(datetime, 'America/Los_Angeles').toFormat('yyyy-MM-dd: HH:mm:ss'),
      '7: Pacific/Honolulu': toDateTime(datetime, 'Pacific/Honolulu').toFormat('yyyy-MM-dd: HH:mm:ss'),
    });
  },
  TIME_SIMPLE: DateTime.TIME_SIMPLE,
  TIME_WITH_SECONDS: DateTime.TIME_WITH_SECONDS,
  DATE_SHORT: DateTime.DATE_SHORT,
  DATE_HUGE: DateTime.DATE_HUGE,
  DATETIME_FULL: DateTime.DATETIME_FULL,
  DATETIME_SHORT: DateTime.DATETIME_SHORT,
};
