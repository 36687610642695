import { useMemo } from 'react';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import supportedLanguages from '../../../constants/supportedTranslations';
import { fetchTranslations } from '../../../axios/fetchTranslations';
import useSimStore from '../../../store/simStore';

import './LanguageDropdown.css';
import useTranslator from '../../../hooks/useTranslator';
import { LANGUAGE } from '../../../constants/storageKeys.constants';

const LanguageDropdown = () => {
  const { getMessage } = useTranslator();

  const setTranslations = useSimStore(state => state.setTranslations);
  const language = localStorage.getItem(LANGUAGE);

  const options = supportedLanguages?.map((lang) => {
    const option = {
      label: lang.name, value: lang.tag, color: '#00B8D9', isFixed: true,
    };
    return option;
  });

  const dropdownLanguageSelector = useMemo(() => {
    const defaultValue = options?.filter(lang => lang?.value === language);

    return (
      <CustomSelect
        label={getMessage('language')}
        options={options}
        onChange={async event => {
          localStorage.setItem(LANGUAGE, event.value);
          setTranslations(await fetchTranslations());
          newRelicAction('LanguageDropdown', 'changeLanguage', { language: event.value });
        }}
        defaultValue={defaultValue}
        customClass="flex-row"
        customLabelClass="profile-label"
      />
    );
  }, [language]);

  return (
    <div className="language-dropdown-container" data-testid="language-dropdown-container">
      <div className="language-dropdown" data-testid="language-dropdown">
        {dropdownLanguageSelector}
      </div>
    </div>
  );
};

export default LanguageDropdown;
