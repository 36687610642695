import axios from 'axios';

import supportedLanguages from '../constants/supportedTranslations';
import { LANGUAGE } from '../constants/storageKeys.constants';

export const fetchTranslations = async () => {
  const language = localStorage.getItem(LANGUAGE);
  let supportedLanguage = supportedLanguages.find(lang => lang.tag === language)?.bodega;
  
  if (!supportedLanguage) {
    console.log('No supported language found, defaulting to en-US');
    localStorage.setItem(LANGUAGE, 'en');
    supportedLanguage = 'en-US';
  }

  let translations = {};
  let error = null;

  try {
    const url = `https://www.nike.com/assets/i18n/sim-web/${supportedLanguage}.json`;
    const bodegaResponse = await axios.get(url);
    
    if (bodegaResponse) {
      translations = bodegaResponse.data;
    }
  } catch (err) {
    error = `Error fetching translations: ${err?.message}`;
  }

  return { translations, error };
};