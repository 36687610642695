import { useState, useMemo } from 'react';
import useSessionStorage from './useSessionStorage';

const useProductCards = () => {
  const moveEnum = {
    next: 1,
    previous: -1,
  };

  const NOT_FOUND = 'not found';
  const [currentProduct, setCurrentProduct] = useState('');
  const [getProducts, setProducts] = useSessionStorage('products', []);

  const dataIndexes = useMemo(() => getProducts() || [], [getProducts]);
  const productCodes = useMemo(() => dataIndexes.map(dataIndex => dataIndex.productCode), [dataIndexes]);

  const getProductCode = (productCode, increment) => {
    const index = productCodes.lastIndexOf(productCode);

    if (index === -1) return NOT_FOUND;
    return dataIndexes[index + increment]?.productCode ?? NOT_FOUND;
  };

  const move = (productCode, move) => {
    const nextProduct = getProductCode(productCode, move);
    setCurrentProduct(nextProduct);
  };

  return {
    moveNext: (productCode) => move(productCode, moveEnum.next),
    movePrevious: (productCode) => move(productCode, moveEnum.previous),
    setCurrentProduct,
    getProducts,
    setProducts,
    currentProduct,
    NOT_FOUND,
  };
};

export default useProductCards;
