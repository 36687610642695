import * as legacyAuth from './legacy.auth';
import * as retailAuth from './retail.auth';
import * as retailLaunchpadAuth from './launchpad.auth';
import * as iOSWrapperAuth from './iOSWrapper.auth';

import * as constants from '../constants/storageKeys.constants';
import { isProd } from '../configs/environment';

/**
 * This file is used as a facade to the different authentication methods since hooks can't use nested switch
 * statements.
 */

export const getAuthType = () => sessionStorage.getItem(constants.AUTH_TYPE) ?? constants.LEGACY;

export const login = (history, assertion) => {
  const authType = getAuthType();
  switch (authType) {
    case constants.RETAIL:
      return retailAuth.login(history);
    case constants.LAUNCHPAD:
      return retailLaunchpadAuth.login(history);
    case constants.IOS_WRAPPER:
      return iOSWrapperAuth.login();
    default:
      return legacyAuth.login(history, assertion);
  }
};

/**
 * Function provided by Auth V3 to check if the login response is present on the URL.
 * @returns {boolean} - true if the login response is present on the URL, false otherwise.
 */
export const isLoginResponsePresentOnURL = () => retailAuth.isLoginResponsePresentOnURL();

/**
 * Since all the loggedIn params are the same, except for the Auth V3 login, we can use the same function
 * for all auth types.
 */
export const isLoggedIn = () => {
  const authType = getAuthType();
  switch (authType) {
    case constants.RETAIL:
      return retailAuth.isLoggedIn();
    default:
      return legacyAuth.isLoggedIn();
  }
};

/**
 * Since all the user info is stored the same, except for the Auth V3 login, we can use the same function
 * for all auth types.
 */
export const getUserInfo = () => {
  const authType = getAuthType();
  switch (authType) {
    case constants.RETAIL:
      return retailAuth.getUserInfo();
    default:
      return legacyAuth.getUserInfo();
  }
};

export const getAccessToken = () => {
  const authType = getAuthType();
  switch (authType) {
    case constants.RETAIL:
      return retailAuth.getAccessToken();
    default:
      return legacyAuth.getAccessToken();
  }
};

export const logout = history => {
  const authType = getAuthType();
  switch (authType) {
    case constants.RETAIL:
      return retailAuth.logout(history);
    case constants.LAUNCHPAD:
      return retailLaunchpadAuth.logout();
    case constants.IOS_WRAPPER:
      return iOSWrapperAuth.logout();
    default:
      return legacyAuth.logout(history);
  }
};

export const sessionExpiresIn = () => {
  const authType = getAuthType();
  switch (authType) {
    case constants.RETAIL:
      return retailAuth.sessionExpiresIn();
    default:
      return legacyAuth.sessionExpiresIn();
  }
};

export const isAthleteAdmin = () => {
  const userInfo = getUserInfo();

  return !isProd() || userInfo?.operatorID === '99999';
};
