import { array } from 'prop-types';

import './Legend.css';

const Legend = ({ legend }) => {
  let currentIndex = 0;

  return (
    <div
      className="simweb_legend"
      data-testid="simweb_legend"
      style={legend.length < 4 ? { gridAutoFlow: 'column' } : { gridTemplateColumns: 'repeat(4, 1fr)' }}
    >
      {
        legend.map(item => (
          <div style={{ order: currentIndex++ }} className="simweb_legend_item" key={item.label}>
            <span style={{ border: `1px solid ${item.colors.start}`, backgroundColor: `${item.colors.end}` }} />
            <p>{item.label}</p>
          </div>
        ))
      }
    </div>
  );
};

Legend.propTypes = {
  legend: array.isRequired,
};

export default Legend;
