import {
  array, bool, element, object, oneOfType, string,
} from 'prop-types';

import classNames from 'classnames';

import './facet.css';

/**
 * Component that gives a name and container for a group of facets
 * @param {array} children - array of react components that will be rendered within this component
 * @param {string} name - the title of the group
 * @param {string} title - the translated title for the facet group
 * @param {boolean} hidden - if true the whole group will be hidden from view
 * @param {element} tooltip - will display this element to the right of the title
 */
const FacetGroup = ({
  children,
  name,
  title,
  hidden = true,
  tooltip = null,
}) => {
  const classes = classNames('facet-group', { 'facet-slide': !hidden }, { 'facet-hidden': hidden });
  return (
    <div className={classes} data-testid={`facet-group-${name}`}>
      <div className="facet-group-title">
        {title}
        {tooltip}
      </div>
      {children}
    </div>
  );
};

FacetGroup.propTypes = {
  children: oneOfType([array, object]).isRequired,
  title: string.isRequired,
  name: string.isRequired,
  hidden: bool,
  tooltip: element,
};

export default FacetGroup;
