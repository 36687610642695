import {
  bool, func, string, object, oneOfType,
} from 'prop-types';
import { LoadingIndicator } from '../assets/Svgs/index';

import './Button.css';

const Button = ({
  isLoading = false,
  onClick = null,
  buttonText = 'Button',
  extraClasses = '',
  disabled = false,
  type = 'button',
  dataTestId = 'sim-button',
  isSelected = false,
  darkMode = false,
  ...rest
}) => (
  <div
    className={`button ${isLoading ? 'loading' : ''} 
    ${disabled ? (darkMode ? 'disabled-darkMode' : 'disabled') : ''}
    ${isSelected && !darkMode ? 'selected' : ''} 
    ${extraClasses}`}
  >
    <button
      onClick={onClick}
      className={darkMode ? 'sim-button-darkMode' : 'sim-button'}
      onTouchStart={onClick}
      type={type}
      disabled={isLoading || disabled}
      data-testid={dataTestId}
      {...rest}
    >
      {buttonText}
    </button>
    <LoadingIndicator dataTestId="loadingIndicator-button" />
  </div>
);

Button.propTypes = {
  isLoading: bool,
  disabled: bool,
  onClick: func,
  buttonText: oneOfType([string, object]),
  extraClasses: string,
  type: string,
  dataTestId: string,
  darkMode: bool,
  isSelected: bool,
};

export default Button;
