import { WorkforceAuthorizeWebV1 } from '@nike/typescript-web-login';
import { environment, getEnvConfig } from '../configs/environment';
import { version } from '../../package.json';
import { newRelicAction } from '../utils/newRelicPageActions';
import { parseUserRoles } from '../utils/parseUserRoles';
import { STORE_ID } from '../constants/storageKeys.constants';
const { AUTH_V3_CLIENT_ID, AUTH_V3_CLIENT_SECRET, TEMP_AUTH_V3_CLIENT_ID, TEMP_AUTH_V3_CLIENT_SECRET } = getEnvConfig();

const clientIdAndSecret = `${AUTH_V3_CLIENT_ID}:${AUTH_V3_CLIENT_SECRET}`;

// Temporarily using RAP client id and secret for testing
const tempClientIdAndSecret = `${TEMP_AUTH_V3_CLIENT_ID}:${TEMP_AUTH_V3_CLIENT_SECRET}`;

const webLogin = new WorkforceAuthorizeWebV1(tempClientIdAndSecret, environment);

export const login = history => {
  const storeId = localStorage.getItem(STORE_ID);
  webLogin.authorize(storeId);

  if (webLogin.isLoggedIn()) {
    newRelicAction('simweb-login', { version, user: webLogin.getUserInfo().operatorID, loginType: 'retail' });
    history.push('/dashboard');
  }
};

export const getAccessToken = async () => {
  return webLogin.getAccessToken(clientIdAndSecret);
};

export const isLoginResponsePresentOnURL = () => webLogin.isLoginResponsePresentOnURL();

export const isLoggedIn = () => webLogin.isLoggedIn();

export const getUserInfo = () => {
  const user = webLogin.getUserInfo();
  const userRoles = parseUserRoles(user.roles);
  return {
    ...user,
    roles: userRoles,
  };
};

export const sessionExpiresIn = () => webLogin.timeInSecondsUntilSessionExpires();

export const logout = history => {
  webLogin.logout();
  history.push('/login');
};
