import { generalAxiosRequest } from '../axios/axiosFunctions';
import { baseUrl, endpoints, routerUrls } from '../axios/endpoints';
import { logErrorAction } from '../utils/newRelicPageActions';
import { setSessionValues } from '../utils/setSessionValues';
import * as constants from '../constants/storageKeys.constants';

export const login = async (history, assertion) => {
  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.LEGACY_BERM.url}`;

  try {
    const response = await generalAxiosRequest('POST', url, endpoints.LEGACY_BERM, false, null, { assertion });
    if (response) {
      setSessionValues(response.access_token);
      history?.push('/dashboard');
    }
  } catch (error) {
    logErrorAction('[Login error]', error);
    return error;
  }
};

export const logout = (history) => {
  sessionStorage.clear();
  history?.push('/login');
};

export const isLoggedIn = () => {
  const sessionExpiresOn = sessionStorage.getItem(constants.SESSION_EXPIRES_ON);
  if (!sessionExpiresOn) {
    return false;
  }
  return Number(sessionExpiresOn) > Math.floor(Date.now() / 1000);
};

export const getUserInfo = () => {
  const operatorID = sessionStorage.getItem(constants.OPERATOR_ID);
  const roles = JSON.parse(sessionStorage.getItem(constants.ROLES));
  const givenName = sessionStorage.getItem(constants.GIVEN_NAME);
  const familyName = sessionStorage.getItem(constants.FAMILY_NAME);

  if (!operatorID || !roles || !givenName || !familyName) {
    return { success: false, error: 'User info not found' };
  }

  return {
    success: true,
    operatorID,
    roles,
    givenName,
    familyName,
  };
};

export const sessionExpiresIn = () => {
  const expireStr = sessionStorage.getItem(constants.SESSION_EXPIRES_ON);
  const nowInSeconds = Math.floor(Date.now() / 1000);
  const expiration = expireStr ? Number(expireStr) : 0;
  if (expiration <= nowInSeconds) {
    return 0;
  }
  return expiration - nowInSeconds;
};

export const getAccessToken = () => {
  if (!isLoggedIn()) {
    logout();
    return { success: false, error: 'User is not logged in' };
  }

  const accessToken = sessionStorage.getItem(constants.ACCESS_TOKEN);

  if (!accessToken) {
    logout();
    return { success: false, error: 'Access token not found' };
  }
  return { success: true, token: accessToken };
};
