import { useContext } from 'react';
import {
  object, node, array, bool, number, func, string,
} from 'prop-types';
import { Card } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CPAContext } from '../../context/cpa/CPA.provider';
import ProductDetails from './ProductDetails';

import './ProductDetails.css';
// MUI is applying a default of 0 for the padding - need to override
const theme = (param) => createTheme(({
  components: {
    MUIDataTableViewCol: {
      styleOverrides: {
        root: {
          padding: '16px 24px 16px 24px !important',
        },
      },
    },
    ...param,
  },
}));
const loadingStyles = {
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
};

/**
 * A reusable card for modal/non-modal details on a give product
 * @param {object} product - the product in current view
 * @param {node} isModal - if the view is modal we add navigation to the card
 * @param {array} optionalColumns - a list of extra columns to display in the gtin table other than the default gtin, size and soh columns
 * @param {boolean} isUnitCountEnabled - get Inbound Units if true
 * @param {number} picklistIndex - the index of the picklist item to display
 * @param {function} setPicklistIndex - set the index of the picklist item
 * @param {array} selectedStyles - array of style colors to use for the picklist creation
 *  @param {boolean} isCreatingPicklist - bool indicating if the user is creating a picklist
 *  @param {function} setSelectedPicklistItems - function to set selected picklist items
 *  @param {array} picklistItems - styles selected for the picklist creation
 *  @param {function} setIsOpen -func that controls the open/closing of the picklist product modal
 *  @param {function} setFormOpen - func that controls the open/closing of the picklist form
*/
const ProductDetailCard = ({
  product = {},
  isModal = null,
  optionalColumns = [],
  isUnitCountEnabled = false,
  picklistIndex = 1,
  setPicklistIndex = () => {},
  selectedStyles = [],
  isCreatingPicklist = false,
  setSelectedPicklistItems = () => {},
  picklistItems = [],
  setIsOpen = () => {},
  setFormOpen = () => {},
  id = '',
  quantityTotal = 0,
}) => {
  const { loading, error } = useContext(CPAContext);
 
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={loading ? theme(loadingStyles) : theme()}>
        <div data-testid="productCard" key={id}>
          <Card className="productCard" elevation={4}>
            {isModal}
            <ProductDetails
              product={product} 
              loading={loading} 
              error={error}
              picklistIndex={picklistIndex} 
              setPicklistIndex={setPicklistIndex} 
              setIsOpen={setIsOpen} 
              setFormOpen={setFormOpen} 
              picklistItems={picklistItems} 
              setSelectedPicklistItems={setSelectedPicklistItems} 
              isCreatingPicklist={isCreatingPicklist} 
              optionalColumns={optionalColumns} 
              isUnitCountEnabled={isUnitCountEnabled} 
              selectedStyles={selectedStyles}
              quantityTotal={quantityTotal}
            />
          </Card>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ProductDetailCard.propTypes = {
  product: object,
  isModal: node,
  optionalColumns: array,
  isUnitCountEnabled: bool,
  picklistIndex: number,
  setPicklistIndex: func,
  selectedStyles: array,
  isCreatingPicklist: bool,
  setSelectedPicklistItems: func,
  picklistItems: array,
  setIsOpen: func,
  setFormOpen: func,
  id: string,
  quantityTotal: number,
};

export default ProductDetailCard;
