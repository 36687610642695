import { useContext } from 'react';
import { func, string } from 'prop-types';
import { DialogContent } from '@mui/material';
import { TranslationsContext } from '../../context/Translations.provider';
import Button from '../Button';

import './InformationalModal.css';

const InformationalModal = ({
  dataTestId = 'informational-modal',
  message,
  setIsOpen,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  return (
    <div data-testid={dataTestId} className="informational-modal">
      <DialogContent>
        <div className="informational-modal-content">
          <h2>{message}</h2>
        </div>
      </DialogContent>
      <div className="informational-btn">
        <Button
          extraClasses="informational-modal-okay-btn"
          onClick={() => setIsOpen(false)}
          buttonText={getMessage('okay')}
          dataTestId="informational-modal-okay-btn"
        />
      </div>
    </div>
  );
};

InformationalModal.propTypes = {
  dataTestId: string,
  message: string.isRequired,
  setIsOpen: func.isRequired,
};

export default InformationalModal;
