import { ErrorOutline } from '@mui/icons-material';
import {
  ReadyForPickup, Requested, ReadyForCarrier, Packed, Picked,
} from '../../assets/Svgs';

// Constants
export const agedOrderValues = {
  BOPIS: { key: 'BOPIS', text: 'BOPIS', isJourney: true },
  SFS: { key: 'SFS', text: 'Ship From Store', isJourney: true },
  SHIP_FROM_STORE: { key: 'SHIP_FROM_STORE', text: 'Ship From Store', isTooltip: false },
  READY_FOR_PICK_UP: { key: 'READY_FOR_PICK_UP', text: 'Ready for Pickup', isStatus: true, isTooltip: true, icon: (<ReadyForPickup />) },
  REQUESTED: { key: 'REQUESTED', text: 'Cancelled/Return', isStatus: true, isTooltip: true, icon: (<Requested />) },
  READY_FOR_CARRIER: { key: 'READY_FOR_CARRIER', isStatus: true, text: 'Ready for Carrier', isTooltip: true, icon: <ReadyForCarrier /> },
  REJECTED_BY_STORE: { key: 'REJECTED_BY_STORE', isStatus: true, text: 'Rejected by Store', isTooltip: true, icon: (<ErrorOutline />) },
  PACKED: { key: 'PACKED', text: 'Packed', isStatus: true, isTooltip: true, icon: <Packed /> },
  PICKED: { key: 'PICKED', text: 'Picked', isStatus: true, isTooltip: true, icon: <Picked /> },
  HIGH_PRIORITY: { key: 'HIGH_PRIORITY', text: 'High Priority', isBucket: true, isTooltip: true, value: '2 to 7 Days Old', className: '-high-priority', translationKey: '+7' },
  MEDIUM_PRIORITY: { key: 'MEDIUM_PRIORITY', text: 'Medium Priority', isBucket: true, isTooltip: true, value: '1 to 2 Days old', className: '-medium-priority', translationKey: '+2' },
  LOW_PRIORITY: { key: 'LOW_PRIORITY', text: 'Low Priority', isBucket: true, isTooltip: true, value: 'Less than 1 Day Old', className: '-low-priority', translationKey: '+1' },
};

const getList = (property) => Object.keys(agedOrderValues).filter(key => agedOrderValues[key]?.[property]).map(key => agedOrderValues[key]);

export const journeys = getList('isJourney');
export const tooltips = getList('isTooltip');
export const buckets = getList('isBucket');
export const statuses = getList('isStatus');
