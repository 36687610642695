import {
  useContext, useEffect, useMemo,
} from 'react';
import { isEmpty } from 'lodash';
import Button from '../../../components/Button';
import Facet from '../../../components/facets/Facet';
import FacetGroup from '../../../components/facets/FacetGroup';
import InfoTooltip from '../../../components/InfoTooltip';
import TooltipContent from './TooltipContent';

// import InboundVisibilityShippingStatusToggle from './ShippingStatusToggle';
import InboundVisibilityFacetAggregation from './InboundVisibilityFacetAggregation';
// import SearchToggle from '../search/SearchToggle';

import {
  buildFacetsData, updateFilters, sortFacetsByDate, getFacetGroupTitle,
} from './inboundVisibilityFacetsUtils';
import { newRelicAction } from '../../../utils/newRelicPageActions';

// import { InboundVisibilityContext } from '../InboundVisibility.provider';
import { InboundVisibilityContext } from '../InboundVisibility.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { tutorialContext } from '../../../components/tutorial/Tutorial.provider';

import './InboundVisibilityFacets.css';

/**
 * The faceted search for the Inbound Visibility Reports, allows multiselect
 * will update filters and rerender as each facet is selected
 */
const InboundVisibilityFacets = () => {
  const { getMessage, country } = useContext(SimWebContext);

  const {
    eddEnabled, facets, filters, isPRO, supportedFilters, setHasSelectedFacets,
    setFacets, setFilters, setPageSearchText, setSearchText, selectedEdd, setSelectedEdd,
    lastSelectedGroup, setLastSelectedGroup, eddDates,
  } = useContext(InboundVisibilityContext);
  const {
    stepIndex, startTutorial, isbackClicked, restartTutorial,
  } = useContext(tutorialContext);

  const clearFilters = () => {
    setSearchText('');
    setPageSearchText('');
    setLastSelectedGroup('');
    setFilters([]);
    setSelectedEdd(null);
  };

  const handleFacetChange = (facet, groupName) => {
    const updatedFilters = updateFilters(facet, groupName, filters, setSelectedEdd);

    const isFacetChecked = !facet.selected;
    newRelicAction('inbound-visibility-facet', { facet: groupName, item: facet.name, checked: isFacetChecked });
    setFilters(updatedFilters);
    setLastSelectedGroup(groupName);
  };

  // This is specifically to handle edd radio buttons to toggle deselect if the same one is clicked.
  const handleOnClick = (event) => {
    if (event.target.value === selectedEdd) {
      clearFilters();
    }
  };

  // tutorial magic
  useEffect(() => {
    const facetSelected = startTutorial ? document.querySelector('.facet-checkbox-wrapper input')?.checked : false;
    if (startTutorial) {
      if (stepIndex === 2 && !facetSelected) {
        const facet = document.querySelector('.facet-checkbox-wrapper .facet-label')?.innerHTML;
        handleFacetChange({ name: facet, selected: false }, 'shipDate');
      }
      if (stepIndex === 6 && isbackClicked && !facetSelected) {
        const facet = document.querySelector('.facet-checkbox-wrapper .facet-label')?.innerHTML;
        handleFacetChange({ name: facet, selected: false }, 'shipDate');
      }
       
      if ((stepIndex === 1 && isbackClicked) || (stepIndex === 7 && facetSelected && !isbackClicked) || (stepIndex === 7 && facetSelected && isbackClicked)) clearFilters();
    }
    // clear facets & reset tutorial
    if (restartTutorial) {
      clearFilters();
    }
  }, [startTutorial, stepIndex, isbackClicked, restartTutorial]);

  const facetGroupComponent = useMemo(() => {
    const facetData = buildFacetsData(facets, supportedFilters, lastSelectedGroup, eddEnabled, selectedEdd, eddDates);

    setFacets(facetData);
    const facetGroups = [...(eddEnabled ? ['edd'] : ['shipDate']), ...(eddEnabled ? ['carrier'] : []), ...(country === 'USA' ? ['deliveryNumber'] : []), 'docNumber', 'poNumber'];
    const tooltip = (
      <InfoTooltip
        content={<TooltipContent />}
        title={getMessage('aggregations').toUpperCase()}
      />
    );

    const numberSelected = ([].concat(...facetGroups.map(group => facetData[group]?.values)))
      .filter(item => item?.selected);

    setHasSelectedFacets(numberSelected?.length > 0);

    return facetGroups.map(groupName => {
      const facetComponent = facetData[groupName] && sortFacetsByDate(facetData[groupName].values, groupName)
        .map(facet => ((facet) ? (
          <Facet
            key={facet?.name}
            nameKey={facet?.name}
            name={`${groupName}.${facet?.name}`}
            selected={facet?.selected}
            handleChange={() => handleFacetChange(facet, groupName)}
            onClick={(event) => handleOnClick(event)}
            hidden={!facet?.show}
            group={groupName}
            aggregation={groupName !== 'edd' ? (
              <InboundVisibilityFacetAggregation
                poNumber={facet.aggregation.poNumber}
                carton={facet.aggregation.carton}
                unit={facet.aggregation.unit}
              />
            ) : null}
            translatedName={facet?.label}
          />
        ) : null));

      return (
        (!isEmpty(facetComponent)) ? (
          <FacetGroup
            key={groupName}
            name={`inbound-visibility-facet-group-${groupName}`}
            title={getFacetGroupTitle(facetData[groupName].label, isPRO, getMessage)}
            hidden={false}
            tooltip={facetData[groupName].showTooltip ? tooltip : null}
          >
            <div className="facets-container" data-testid={`${groupName}-facet-container`}>
              {facetComponent}
            </div>
          </FacetGroup>
        ) : null
      );
    });
  }, [supportedFilters, getMessage, selectedEdd]);

  return (
    <div>
      <div data-testid="inbound-visibility-facets" className="inbound-visibility-container">
        {/* <InboundVisibilityShippingStatusToggle
          leftLabel={getMessage('inTransit')}
          leftname="inTransit"
          rightLabel={getMessage('onOrder')}
          rightName="onOrder"
          ShippingStatusSelected={selectedShippingStatus[0]}
          setShippingStatusSelected={boundShippingStatusSelected}
          setSearchText={setSearchTextValue}
        /> */}
        {/* <div className="activeFlex">
          <SearchToggle />
        </div> */}
        {facetGroupComponent}
      </div>
      <Button
        buttonText={getMessage('clear')}
        className="clear-facets-button"
        dataTestId="inbound-visibility-clear-facets-button"
        disabled={false}
        onClick={clearFilters}
        style={{ padding: '0 24px' }}
        type="button"
      />
    </div>
  );
};

export default InboundVisibilityFacets;
