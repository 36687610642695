import { useContext } from 'react';
import { Grid2 } from '@mui/material';
import { array, string } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { SimWebContext } from '../../../context/SimWeb.provider';

const theme = createTheme(({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
  },
}));

const PrintDropZoneStyleColorTable = ({ data = [], poNumber = '' }) => {
  const { getMessage } = useContext(SimWebContext);
  const columns = [
    {
      name: 'styleColor',
      label: getMessage('styleColor'),
      options: {
        filter: false,
      },
    },
    {
      name: 'description',
      label: getMessage('description'),
      options: {
        filter: false,
      },
    },
    {
      name: 'units',
      label: getMessage('units'),
      options: {
        filter: false,
      },
    },
    {
      name: 'price',
      label: getMessage('price'),
      options: {
        filter: false,
      },
    },
  ];

  const rows = data?.map(d => ({
    styleColor: d.styleColor ?? '-',
    description: d.description ?? '-',
    units: d.unitsExpected ?? '-',
    price: d.price,
  }));

  return (
    <div data-testid={`${poNumber}-printDropZoneItemDetails`}>
      <Grid2 container spacing={2} alignItems="center" className="printDropZoneContainer">
        <Grid2 size={12}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <MUIDataTable
                data={rows}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                  filter: false,
                  print: false,
                  download: false,
                  search: false,
                  viewColumns: false,
                  elevation: 0,
                  rowsPerPage: 100,
                  rowsPerPageOptions: [15, 25, 50, 100],
                }}
                className="MUIDataTable"
              />
            </ThemeProvider>
          </StyledEngineProvider>
        </Grid2>
      </Grid2>
    </div>
  );
};

PrintDropZoneStyleColorTable.propTypes = {
  data: array,
  poNumber: string,
};

export default PrintDropZoneStyleColorTable;
