
import classNames from 'classnames';
import { bool } from 'prop-types';

const Arrow = ({ flip = false }) => {
  const classes = classNames('svg-arrow', { 'svg-arrow-flip': flip });

  return (
    <svg className={classes} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
    </svg>
  );
};

Arrow.propTypes = {
  flip: bool.isRequired,
};

export default Arrow;
