const scanButton = (onClick, disabled, label, className) => (
  <button
    onClick={onClick}
    type="button"
    className={className}
    data-testid={className}
    disabled={disabled}
  >
    {label}
  </button>
);

const okButton = (resetApprovalAction, getMessage, handleCloseModal) => scanButton(() => handleCloseModal(resetApprovalAction), false, getMessage('ok'), 'confirmation-modal-ok-button');
const noButton = (getMessage, handleCloseModal) => scanButton(() => handleCloseModal(true), false, getMessage('no'), 'confirmation-modal-no-button');
const yesButton = (newStatus, getMessage, handleOnClick) => scanButton(() => handleOnClick(newStatus), false, getMessage('yes'), 'confirmation-modal-yes-button');
const submitButton = (newStatus, disabled, getMessage, handleOnClick) => scanButton(() => handleOnClick(newStatus), disabled, getMessage('submit'), 'confirmation-modal-submit-button');
const resumeButton = (newStatus, disabled, getMessage, handleOnClick) => scanButton(() => handleOnClick(newStatus), disabled, getMessage('resume'), 'confirmation-modal-resume-button');
const closeButton = (getMessage, handleCloseModal) => scanButton(() => handleCloseModal(true), false, getMessage('close'), 'confirmation-modal-close-button');

/**
 * A helper function to get the buttons for the Confirmation Modal
 * @param {number} resumeCount the number of times the scan has been resumed
 * @param {string} action the manager approval action - SUBMITTED, SCANNING or CANCELLED
 * @param {boolean} isStatusUpdated an indicator if a scan to be updated
 * @param {object} error an error returned from the backend
 * @param {function} getMessage the function to get translated message
 * @param {function} handleCloseModal the function to close the modal
 * @param {function} handleOnClick the function to handle the button click
 */
export const getButtons = (
  resumeCount, action, isStatusUpdated, error, getMessage, handleCloseModal, handleOnClick,
) => {
  if (isStatusUpdated) return okButton(false, getMessage, handleCloseModal);
  if (error) return okButton(true, getMessage, handleCloseModal);

  // submit 2nd attempt
  if (action === 'SUBMITTED' && resumeCount > 1) {
    return (
      <>
        {submitButton('SUBMITTED', false, getMessage, handleOnClick)}
        {closeButton(getMessage, handleOnClick)}
      </>
    );
  }
  if (action === 'SUBMITTED') {
    return (
      <>
        {submitButton('SUBMITTED', false, getMessage, handleOnClick)}
        {resumeButton('SCANNING', false, getMessage, handleOnClick)}
        {closeButton(getMessage, handleCloseModal)}
      </>
    );
  }
  // resume
  if (action === 'SCANNING') {
    return (
      <>
        {yesButton('SCANNING', getMessage, handleOnClick)}
        {noButton(getMessage, handleCloseModal)}
      </>
    );
  }
  // cancel
  if (action === 'CANCELLED') {
    return (
      <>
        {yesButton('CANCELLED', getMessage, handleOnClick)}
        {noButton(getMessage, handleCloseModal)}
      </>
    );
  }

  return (
    <>
      {yesButton(action, getMessage, handleOnClick)}
      {noButton(getMessage, handleCloseModal)}
    </>
  );
};

/**
 * A helper function to get the content for the Manager Approval Modal
 * @param {string} action the manager approval action - SUBMITTED, SCANNING or CANCELLED
 * @param {object} error an error returned from the backend
 * @param {boolean} isStatusUpdated an indicator if a scan to be updated
 * @param {function} getMessage the function to get translated message
 * @param {function} formatMessage the function to get and format translated message
 */
export const getContent = (action, error, isStatusUpdated, updatedStatus, getMessage, formatMessage) => {
  if (error) return getMessage('rfidManagerApprovalErrorTryAgain');

  const formattedStatus = updatedStatus === 'SCANNING' ? 'RESUMED' : updatedStatus;
  if (isStatusUpdated) return formatMessage('messageScanStatusUpdated', { status: formattedStatus });
  if (action === 'SUBMITTED') return getMessage('messageSubmitOrResumeScan');
  if (action === 'SCANNING') return getMessage('resumeScan');
  if (action === 'CANCELLED') return getMessage('messageCancelScan');

  return '';
};

/**
 * A helper function to get the submission message for the Manager Approval Modal
 * @param {number} accuracyThreshold the accuracy threshold of the store
 * @param {string} action the manager approval action - SUBMITTED, SCANNING or CANCELLED
 * @param {boolean} isStatusUpdated an indicator if a scan to be updated
 * @param {function} formatMessage the function to get and format translated message
 */
export const getSubmissionMessage = (accuracyThreshold, action, isStatusUpdated, formatMessage) => {
  if (isStatusUpdated) return '';
  if (action === 'SUBMITTED') return formatMessage('messageSubmitScanEstimatedVariances', { accuracy: accuracyThreshold });

  return '';
};
