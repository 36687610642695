import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { updateChromeTab } from '../utils/setChromeTabTitle';
import useAuth from './useAuth';
import useSimStore from '../store/simStore';
import { generalAxiosRequest } from '../axios/axiosFunctions';
import { baseUrl, endpoints, routerUrls } from '../axios/endpoints';
import { fetchStoreConfig, fetchStoreInfo } from '../axios/fetchStoreSettings';
import { version } from '../../package.json';
import { isProd } from '../configs/environment';
import useTranslator from './useTranslator';
import { LAUNCHPAD, LOGIN_SOURCE, IOS_WRAPPER, STORE_ID, LANGUAGE } from '../constants/storageKeys.constants';
import { fetchTranslations } from '../axios/fetchTranslations';
import { messages } from '../utils/iframeMessaging';
import { isEmpty } from 'lodash';
import useNewTab from './useNewTab';
import useSetWrapperAction from './useSetWrapperAction';
/**
 * The intent of this file is to abstract away some of the logic for setting up the application.
 * This is to help with the readability of the main application file.
 *
 * It handles page refreshes, version checking, logins via wrapper or true, and other setup tasks.
 */

const useApplicationSetup = () => {
  const { isLoggedIn, getUserInfo, login } = useAuth();
  const { getMessage } = useTranslator();

  const setStoreConfig = useSimStore(state => state.setStoreConfig);
  const setStoreInfo = useSimStore(state => state.setStoreInfo);
  const setTranslations = useSimStore(state => state.setTranslations);
  const setIsAdmin = useSimStore(state => state.setIsAdmin);
  const resetStore = useSimStore(state => state.resetStore);
  const addError = useSimStore(state => state.addError);
  const setVersionIsCurrent = useSimStore(state => state.setVersionIsCurrent);
  const setStoreId = useSimStore(state => state.setStoreId);

  const isAdmin = useSimStore(state => state.isAdmin);
  const storeInfo = useSimStore(state => state.storeInfo);
  const storeConfig = useSimStore(state => state.storeConfig);
  const storeId = useSimStore(state => state.storeId);
  const location = useLocation();
  const history = useHistory();

  const loginSource = localStorage.getItem(LOGIN_SOURCE);
  const isIOSWrapper = loginSource === IOS_WRAPPER;
  const isTrue = loginSource === LAUNCHPAD;

  updateChromeTab(getMessage, storeInfo?.country, storeInfo?.storeNumber, isAdmin);
  useNewTab();

  if (isIOSWrapper) {
    useSetWrapperAction();
  }

  const callFetchStoreConfig = async () => setStoreConfig(await fetchStoreConfig());
  const callFetchStoreInfo = async () => setStoreInfo(await fetchStoreInfo());
  const callFetchTranslations = async () => setTranslations(await fetchTranslations());

  /**
   * Performs TRUE/Launchpad specific login functions.
   */
  useEffect(() => {
    const callTrueLogin = async () => {
      await login(LAUNCHPAD, history);
    };
    if (isTrue) {
      callTrueLogin();
    }
  }, []);

  /**
   * Performs IOS Wrapper specific login functions.
   */
  useEffect(() => {
    const callIOSWrapperLogin = async () => {
      await login(IOS_WRAPPER, history);
    };
    if (isIOSWrapper) {
      callIOSWrapperLogin();
      setStoreId(localStorage.getItem(STORE_ID));
    }
  }, []);

  /**
   * Unfortunately, this is specific to the wrapper to trigger an update, I'll be removing this in the future.
   * TODO: Remove when store is re-worked.
   */
  useEffect(() => {
    if (storeId && isLoggedIn()) {
      callFetchStoreInfo();
      callFetchTranslations();
    }
  }, [storeId]);

  /**
   *  Set Admin status and storeConfig when the user logs in.
   */
  useEffect(() => {
    if (isLoggedIn() && isEmpty(storeConfig)) {
      const user = getUserInfo();

      callFetchStoreConfig();
      setIsAdmin(!isProd() || user?.operatorID === '99999');
    }
  }, [location]);

  /**
   * if the user is logged in and the store id changes, call the storeConfig.
   */
  useEffect(() => {
    if (isLoggedIn() && !isEmpty(storeConfig)) {
      callFetchStoreConfig();
    }
  }, [storeInfo?.id]);

  /**
   * If user is logged out, reset the unauthed values and set the app to not rendered.
   */
  useEffect(() => {
    if (location.pathname === '/login') {
      resetStore();
    }
  }, [location]);

  /**
   * Listens for the storeId to change in local storage and calls the storeConfig and storeInfo.
   * This is used for the TRUE/Launchpad.
   */
  useEffect(() => {
    const handleStorageChange = event => {
      if (event.key === STORE_ID) {
        callFetchStoreInfo();
        callFetchStoreConfig();
      }
      if (event.key === LANGUAGE) {
        callFetchTranslations();
      }
    };
    if (isTrue) {
      window.addEventListener('storage', handleStorageChange);
    }
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isTrue]);

  /**
   * Prettifies the URL for iframe navigation from TRUE/Launchpad.
   */
  useEffect(() => {
    const pathName = location?.pathname;
    const docRef = document?.referrer?.replace(/\/$/, '') ?? '';

    if (!isTrue || docRef === '') return;

    try {
      const newurl = `${docRef}/SIMWeb${pathName}`;
      window.parent.postMessage({ name: messages.ADDRESS_BAR, newurl }, docRef);
      // if users navigates to the login page, close the iframe
      if (pathName === '/login') {
        window.parent.postMessage({ name: messages.CLOSE }, docRef);
      }
    } catch (error) {
      console.error('Error updating navigation bar', error);
    }
  }, [location]);

  /**
   * This is to check the version the UI is rendering and compare it to what the version the BFF is expecting.
   * TODO: We may be able to get rid of this soon.
   */
  useEffect(() => {
    const checkVersion = async () => {
      const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.VERSION_CHECK.url}?version=${version}`;
      generalAxiosRequest('GET', url, endpoints.VERSION_CHECK, false)
        .then(() => {
          setVersionIsCurrent(true);
        })
        .catch(error => {
          addError(error.message);
          setVersionIsCurrent(false);
        });
    };
    checkVersion();
  }, []);

  /**
   * Unregister service worker on page load and remove caches
   * TODO:  Remove when we are confident all versions previous to 1.14.2 are gone.
   */
  const unregister = () => {
    if ('serviceWorker' in navigator) {
      console.log('Unregistering service worker and deleting the caches...');
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
      });
      // delete all caches on simweb
      caches.keys().then(names => {
        for (let name of names) caches.delete(name);
      });
    }
  };

  useEffect(() => {
    unregister();
  }, []);
};

export default useApplicationSetup;
