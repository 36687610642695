import { getAggregations, getLocationAggregations } from '../utils';
import { scanTypes } from '../scanReportConstants';

/**
 * A helper function to get the scan accuracy from session data and return in translated string
 * @param {object} scanData the selected scan session data
 * @param {function} getMessage a function to get translated message
 */
export const getScanAccuracy = (scanData, getMessage) => {
  const accuracy = scanData?.aggregations?.find(_ => _.field === 'accuracy');

  if (!accuracy) return `${getMessage('scanAccuracy')}: -`;
  return `${getMessage('scanAccuracy')}: ${accuracy.value}%`;
};

/**
 * A helper function to returns the scan aggregations
 * @param {object} scanData the selected scan session data
 * @param {string} locale the language tag
 */
export const getScanAggregations = (scanData, locale) => {
  const additionalText = getAggregations(scanData?.aggregations, locale);

  return (
    additionalText.map(aggregate => (
      <span style={{ color: aggregate.color }} key={aggregate.color}>
        {aggregate.name}
      </span>
    ))
  );
};

/**
 * A helper function to get the scan category if the scan type is cycle count
 * @param {object} scanData the selected scan session data
 */
export const getScanCategory = (scanData) => {
  if (!scanTypes.isCycleCount(scanData?.scanType) || !scanData?.category) return null;

  return (
    <div className="scan-report-header-aggregations checkbox_additional-text" data-test-id="scan-report-cycle-count-categories">
      <span>
        {scanData.category[0].value}
      </span>
    </div>
  );
};

/**
 * A helper function to get the scan duration time from session data and return in translated string
 * @param {object} scanData the selected scan session data
 * @param {function} getMessage a function to get translated message
 */
export const getScanDuration = (scanData, getMessage) => {
  const duration = scanData?.scanStatus?.scanDuration;

  return `${getMessage('scanDuration')}: ${duration || '-'} ${getMessage('minutes')}`;
};

/**
 * A helper function to returns the scan aggregations by locations
 * @param {object} scanData the selected scan session data
 * @param {string} locale the language tag
 * @param {boolean} isOffsiteEnabled is offsite enabled in the store
 */
export const getScanLocationAggregations = (scanData, locale, isOffsiteEnabled) => {
  const additionalText = getLocationAggregations(scanData, locale, isOffsiteEnabled);

  return (
    additionalText.map(aggregate => (
      <span style={{ color: aggregate.color }} key={aggregate.color}>
        {aggregate.value}
      </span>
    ))
  );
};

export const getScanDateRangeString = (scanData, SimDateTime) => {
  const history = scanData?.scanStatus?.statusHistory;
  const startDate = SimDateTime.toLocaleString(history?.[0]?.date, SimDateTime.DATETIME_SHORT);
  const lastScanStatus = history?.[history.length - 1]?.status;
  const endDate = (lastScanStatus !== 'IN_REVIEW' || lastScanStatus !== 'SCANNING') ? SimDateTime.toLocaleString(history?.[history.length - 1]?.date, SimDateTime.DATETIME_SHORT) : '';

  return `${startDate} - ${endDate}`;
};
