import { useContext, useMemo, useRef, useState } from 'react';
import { array, string, bool, object, func } from 'prop-types';
import { startCase } from 'lodash';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { ErrorOutline } from '@mui/icons-material';
import { Grid2, Switch } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LoadingIndicator } from '../../../assets/Svgs';
import Legend from '../legend/Legend';
import { getLegendData, getTotalUnitsAndValue, getSubtitles, transformGraphData } from './groupedBarChart.utils';
import getFormattedPrice from '../../../utils/getFormattedPrice';

import { SimWebContext } from '../../../context/SimWeb.provider';

import './GroupedBarChartWithLegend.css';

const FONT_COLOR = 'rgb(255, 255, 255)';
const BACKGROUND_COLOR = 'rgba(0, 0, 0, 0)';
const UNCHECKED_SWITCH_COLOR = 'rgb(90, 186, 90)';

const styles = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: UNCHECKED_SWITCH_COLOR,
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: UNCHECKED_SWITCH_COLOR,
        },
      },
    },
  },
});

const GroupedBarChartWithLegend = ({
  aggregatedBy,
  data = null,
  divisions = ['apparel', 'equipment', 'footwear'],
  error = false,
  id,
  loading = false,
  toggle = null,
  varianceType,
  setVarianceType,
  setTableFilters,
  setCurrentSort,
  setToggleChanged,
}) => {
  const { currencyCode, getMessage, locale } = useContext(SimWebContext);

  const [toggleChecked, setToggleChecked] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [selectedBarLabel, setSelectedBarLabel] = useState(null);

  const chartRef = useRef();

  const options = useMemo(
    () => ({
      layout: {
        padding: 20,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            labelColor(context) {
              return {
                backgroundColor: context.dataset.backgroundColor,
                borderWidth: 0,
              };
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            color: context => {
              if (context.tick.value === 0) {
                return FONT_COLOR;
              }
              return BACKGROUND_COLOR;
            },
            font: {
              size: 14,
            },
          },
          title: {
            display: true,
            text: `# Units ${startCase(varianceType)}`,
            color: FONT_COLOR,
            padding: -10,
            font: {
              size: 14,
            },
          },
          grid: {
            drawBorder: false,
            color: context => {
              if (context.tick.value === 0) {
                return FONT_COLOR;
              }
            },
          },
        },
        x: {
          ticks: {
            color: FONT_COLOR,
            font: {
              size: 18,
            },
          },
          grid: {
            drawBorder: false,
            color: FONT_COLOR,
          },
          position: 'top',
        },
      },
    }),
    [varianceType, getMessage],
  );

  const graphData = useMemo(
    () =>
      transformGraphData(data, aggregatedBy, divisions, varianceType, selectedDivision, selectedBarLabel, getMessage),
    [data, aggregatedBy, divisions, varianceType, selectedDivision, selectedBarLabel, getMessage],
  );

  const onBarClick = event => {
    if (getElementAtEvent(chartRef.current, event).length > 0) {
      const datesetIndexNum = getElementAtEvent(chartRef.current, event)[0].datasetIndex;
      const dataPointIndex = getElementAtEvent(chartRef.current, event)[0].index;
      const datasetLabel = graphData.datasets[datesetIndexNum].label;
      const dataPointLabel = graphData.labels[dataPointIndex];
      if (
        !selectedBarLabel ||
        !selectedDivision ||
        selectedBarLabel !== datasetLabel ||
        selectedDivision !== dataPointLabel.toLowerCase()
      ) {
        setSelectedBarLabel(datasetLabel);
        setSelectedDivision(dataPointLabel.toLowerCase());
        setTableFilters([datasetLabel, dataPointLabel]);
        setCurrentSort(
          varianceType === 'extra' ? { name: 'extra', direction: 'desc' } : { name: 'missing', direction: 'desc' },
        );
      } else {
        setSelectedBarLabel(null);
        setSelectedDivision(null);
        setTableFilters([]);
        setCurrentSort({ name: 'absValueVariance', direction: 'desc' });
      }
    }
  };

  const handleSwitchToggle = event => {
    const { checked } = event.target;
    setToggleChecked(checked);
    setToggleChanged(true);

    const varianceType = checked ? 'extra' : 'missing';
    setCurrentSort(
      varianceType === 'extra' ? { name: 'extra', direction: 'desc' } : { name: 'missing', direction: 'desc' },
    );
    setVarianceType(varianceType);
  };

  const groupedBarCharts = useMemo(
    () => (
      <Bar
        data={graphData}
        options={options}
        ref={chartRef}
        onClick={onBarClick}
      />
    ),
    [graphData, options],
  );

  const legend = useMemo(() => {
    const legend = getLegendData(data, aggregatedBy, getMessage);
    return <Legend legend={legend} />;
  }, [data, aggregatedBy, getMessage]);

  const title = useMemo(
    () => (
      <>
        <Grid2
          container
          className="header-graph-table-title"
          data-testid="header-graph-table-title"
        >
          <Grid2 size={12}>
            <Grid2
              container
              style={{ justifyContent: 'space-between' }}
            >
              <span style={{ padding: '5px' }}>
                {varianceType === 'missing' ? getMessage('varianceMissing') : getMessage('varianceExtra')}
              </span>
              <Switch
                checked={toggleChecked}
                onChange={handleSwitchToggle}
              />
            </Grid2>
          </Grid2>
        </Grid2>
        <div className="header-graph-table-subtitle">
          <span>{getSubtitles(varianceType, aggregatedBy, getMessage)}</span>
        </div>
      </>
    ),
    [varianceType, aggregatedBy, getMessage],
  );

  const footers = useMemo(() => {
    const { totalUnits, totalValue } = getTotalUnitsAndValue(
      data,
      aggregatedBy,
      varianceType,
      selectedDivision,
      selectedBarLabel,
    );

    return (
      <div className="header-data-graphs flex-row">
        <div className="total-units">
          <span>{`${varianceType === 'extra' ? getMessage('totalUnitsExtra') : getMessage('totalUnitsMissing')}: `}</span>
          <span>{totalUnits}</span>
        </div>
        <div className="total-value">
          <span>{`${varianceType === 'extra' ? getMessage('totalValueExtra') : getMessage('totalValueMissing')}: `}</span>
          <span>{getFormattedPrice(totalValue, locale, currencyCode)}</span>
        </div>
      </div>
    );
  }, [data, aggregatedBy, varianceType, selectedDivision, selectedBarLabel, getMessage]);

  const chart = useMemo(() => {
    if (loading) {
      return (
        <div
          className="visual-summary-loading"
          data-testid="visual-summary-loading"
        >
          <LoadingIndicator
            width="65px"
            height="65px"
            fill="#FFFFFF"
            svgStyle={{ left: '45%' }}
          />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="visual-summary-error"
          data-testid="visual-summary-error"
        >
          <ErrorOutline
            color="error"
            fontSize="large"
          />
          <br />
          <p>{getMessage('noUnitError')}</p>
        </div>
      );
    }

    return (
      <div className="header-graph-container">
        {title}
        <div
          className="header-data-graphs"
          data-testid={`${id}-visuals`}
        >
          {groupedBarCharts}
        </div>
        <div
          className="header-data-graphs"
          data-testid={`${id}-legend`}
        >
          {legend}
        </div>
        {footers}
      </div>
    );
  }, [groupedBarCharts, id, loading, error, data, legend, aggregatedBy, varianceType, getMessage]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={styles}>
        <div
          className="groupged-bar-chart-legend-container header-visuals"
          data-testid="groupged-bar-chart-legend-container"
        >
          <div className="header-graph">
            <div className="header-graph-table">{chart}</div>
          </div>
          <div className="header-toggle">{toggle}</div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

GroupedBarChartWithLegend.propTypes = {
  aggregatedBy: string.isRequired,
  data: object,
  divisions: array,
  error: bool,
  id: string.isRequired,
  loading: bool,
  toggle: object,
  varianceType: string.isRequired,
  setVarianceType: func.isRequired,
  setTableFilters: func.isRequired,
  setCurrentSort: func.isRequired,
  setToggleChanged: func.isRequired,
};

export default GroupedBarChartWithLegend;
