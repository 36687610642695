import { useSnackbar, closeSnackbar } from 'notistack';
import { Button } from '@mui/material';
import useTranslator from '../../hooks/useTranslator';
import useSimStore from '../../store/simStore';

const ErrorQueue = () => {
  const errors = useSimStore(state => state?.errors);

  const removeErrorFromSimStore = useSimStore(state => state?.removeError);
  const { getMessage } = useTranslator();
  const { enqueueSnackbar } = useSnackbar();

  errors.map((message, index) => (
    enqueueSnackbar(message, {
      variant: 'error',
      preventDuplicate: true,
      autoHideDuration: 10000,
      hideIconVariant: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      onExited: () => removeErrorFromSimStore(index),
      action: (key) => (
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {getMessage('close')}
        </Button>
      ),
    })
  ));

};

export default ErrorQueue;
