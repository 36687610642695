import { useMemo, useState, useContext, useEffect, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { version } from '../../../../package.json';
import NavMenuItem from './NavMenuItem';
import NavFeatures from '../nav-features';
import FeatureSets from '../featureSets.json';
import Profile from '../profile/Profile';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { AdminContext } from '../../../context/Admin.provider';

import './NavMenu.css';
import useAuth from '../../../hooks/useAuth';
import useSimStore from '../../../store/simStore';

export const NavMenuLoadingItem = () => (
  <div
    className="sim-menu-item nav-menu-loading"
    data-testid="navMenu-loadingItem"
  >
    <div className="flex-row" />
  </div>
);

const NavMenu = () => {
  const { country, storeNumber, getMessage, storeConfig, storeInfo } = useContext(SimWebContext);
  const { isAdmin } = useContext(AdminContext);

  const [isSubMenuClosed, setSubMenuClosed] = useState(true);
  const [isSelected, setSelected] = useState(false);

  const { getUserInfo } = useAuth();
  const versionIsCurrent = useSimStore(state => state.versionIsCurrent);
  const location = useLocation();
  const user = getUserInfo();

  const navMenuTitle = [];
  const featureSet = storeConfig?.featureSet?.value;
  const navFeatureClone = cloneDeep(NavFeatures);

  const toggleClose = useCallback(() => {
    setSubMenuClosed(!isSubMenuClosed);
  }, [isSubMenuClosed]);

  const toggleSelected = useCallback(() => {
    setSelected(!isSelected);
  }, [isSelected]);

  // map over all of the features
  const navItems = Object.keys(navFeatureClone).map(navItem => {
    const parentFeature = navFeatureClone[navItem];
    let forceAllEnabled = false;

    if (isAdmin) {
      forceAllEnabled = true;
    }

    // if feature set does not exist or if the feature set we have is
    // less than the feature set of the feature we're mapping we return null
    if (!featureSet || (parentFeature.featureSet && FeatureSets[featureSet] < FeatureSets[parentFeature.featureSet])) {
      if (!forceAllEnabled) return null;
    }

    if (!forceAllEnabled) {
      if (parentFeature.storeConfigKey && storeConfig[parentFeature.storeConfigKey]) {
        if (storeConfig[parentFeature.storeConfigKey].value === false) {
          return null;
        }
        if (!storeConfig[parentFeature.storeConfigKey].value) {
          return null;
        }
      }
    }

    if (parentFeature.childItems) {
      parentFeature.childItems =
        parentFeature.childItems &&
        parentFeature.childItems.filter(child => {
          if (forceAllEnabled) return child;

          const childFeatureSet = child?.featureSet;
          if (!featureSet || (childFeatureSet && FeatureSets[featureSet] < FeatureSets[childFeatureSet])) {
            return null;
          }

          // if the feature has a required role set, ensure that the user has that role assigned
          if (child?.requiredRole) {
            const roles = user?.roles?.allRoles && user?.roles?.allRoles.filter(_ => _.name === child.requiredRole);
            if (!roles) return null;
          }

          // if the feature has a minimum role level set, ensure that the user has at least that level of permissions
          if (child?.minimumRoleLevel) {
            if (!user?.roles) return null;
            if (user?.roles?.maxValue && child.minimumRoleLevel > user?.roles?.maxValue) return null;
          }

          if (child?.storeConfigKey) {
            if (child?.isNavItemEnabled) return child.isNavItemEnabled(storeConfig, storeInfo, child);

            return storeConfig?.[child.storeConfigKey]?.value ? child : null;
          }

          return child;
        });

      navMenuTitle.push(getMessage(navItem));

      return (
        <NavMenuItem
          childItems={parentFeature?.childItems}
          i18nKey={getMessage(navItem)}
          toggleClose={toggleClose}
          toggleSelected={toggleSelected}
          country={country}
          key={getMessage(navItem)}
        />
      );
    }
  });

  useEffect(() => {
    const setStyle = display => {
      const subMenu = document.querySelector('.nav-bar-item');
      if (subMenu) {
        subMenu.style.display = display;
        setSubMenuClosed(display === 'none');
      }
    };

    const setFlex = () => setStyle('flex');
    const setNone = () => setStyle('none');
    const features = document.querySelector('.features');

    if (features) {
      features.addEventListener('mouseenter', setFlex);
      features.addEventListener('mouseleave', setNone);
    }

    return () => {
      if (features) {
        features.removeEventListener('mouseenter', setFlex);
        features.removeEventListener('mouseleave', setNone);
      }
    };
  }, [navItems, navFeatureClone]);

  const profile = useMemo(() => <Profile />, [user]);

  const navComponents = useMemo(() => {
    if (location.pathname === '/login') return null;

    if (featureSet && navItems) {
      return (
        <div
          className={classNames('sim-menu-container active')}
          data-testid="sim-navMenu-defaultState"
        >
          <div className="sim-menu">
            <div className="sim-menu-header-group">
              <div
                className="sim-menu-header-home-container"
                data-testid="sim-menu-header-home-container"
              >
                <Link
                  to="/dashboard"
                  data-testid="dashboard-link"
                >
                  <div
                    className="sim-menu-item header"
                    data-testid="navMenu-headerItem"
                  >
                    <span className="g72-swoosh image-swoosh" />
                    <p className="home-link-string">{getMessage('dashboard')}</p>
                    <span className={versionIsCurrent ? 'version-header' : 'version-header-error'}>{version}</span>
                  </div>
                </Link>
              </div>
              <div className="feature-wrapper">
                <div className="features">
                  <div
                    className="navItem-title-container"
                    data-testid="sim-navMenuItems-list"
                    onClick={() => {
                      toggleClose();
                      toggleSelected();
                    }}
                  >
                    {navMenuTitle?.map(title => (
                      <p
                        className="navItem-group-title"
                        data-testid="sim-navMenuItem-title"
                        key={title}
                      >
                        {title}
                      </p>
                    ))}
                  </div>
                  <div
                    className="nav-bar-item"
                    data-testid="sim-navMenuItems"
                    style={{ display: !isSubMenuClosed ? 'flex' : 'none' }}
                  >
                    {navItems}
                  </div>
                </div>
              </div>
              {profile}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames('sim-menu-container active')}
        data-testid="sim-navMenu-loading"
      >
        <div className="sim-menu">
          <div className="sim-menu-header-group">
            <div
              className="sim-menu-header-home-container"
              data-testid="headerDropdown-header-container"
            >
              <Link
                to="/dashboard"
                data-testid="dashboard-link"
              >
                <div
                  className="sim-menu-item header"
                  data-testid="navMenu-headerItem"
                >
                  <span className="g72-swoosh image-swoosh" />
                  <p className="home-link-string">{getMessage('dashboard')}</p>
                </div>
              </Link>
            </div>
            <div className="features sim-menu-null-state">
              <NavMenuLoadingItem />
              <NavMenuLoadingItem />
              <NavMenuLoadingItem />
              <NavMenuLoadingItem />
              <NavMenuLoadingItem />
            </div>
            {profile}
          </div>
        </div>
      </div>
    );
  }, [
    navItems,
    isSubMenuClosed,
    navMenuTitle,
    getMessage,
    featureSet,
    toggleSelected,
    navFeatureClone,
    storeInfo?.storeName,
    storeNumber,
  ]);

  return <>{navComponents}</>;
};

export default NavMenu;
