import { useMemo, useContext, useState, useEffect } from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import DonutChart from '../../visualSummaries/donutChart/DonutChart';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import { tutorialContext } from '../../tutorial/Tutorial.provider';
import { handleToolTip } from '../dashboardWidgetUtils/complianceWidget.utils';
import { buildGraphData } from './sizeComplianceWidget.utils';

import mockedSizeComplianceData from '../../tutorial/mockTutorialData/dashboard/size-compliance-data.json';

import './SizeComplianceWidget.css';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { DashboardContext } from '../../../containers/dashboard/Dashboard.provider';
import useSimStore from '../../../store/simStore';

/**
 * Creates a widget with data visuals for the Size Compliance
 * @param {String} customClass additional string to cusomize styles
 * @return {Node} returns a new component with the correct data
 */
const SizeComplianceWidget = ({ customClass = '' }) => {
  const { getMessage } = useContext(SimWebContext);
  const { scansLoading, data } = useContext(DashboardContext);
  const { startTutorial, activateTutorials } = useContext(tutorialContext);
  const [apparelData, setApparelData] = useState([]);
  const [footwearData, setFootwearData] = useState([]);
  const [sizeComplianceData, setComplianceData] = useState(null);
  const businessConcept = useSimStore(state => state.storeInfo.businessConcept);

  useEffect(() => {
    const sizeComplianceData = activateTutorials ? mockedSizeComplianceData : data?.sizeCompliance;
    setComplianceData(sizeComplianceData);

    const { apparelData, footwearData } = buildGraphData(sizeComplianceData, activateTutorials, getMessage);
    setApparelData(apparelData);
    setFootwearData(footwearData);
  }, [activateTutorials, data]);

  const apparelGraph = useMemo(
    () => (
      <DonutChart
        chartType="compliance"
        data={!isEmpty(sizeComplianceData) ? apparelData : []}
        title={getMessage('apparel')}
        legendEnabled
        displayCenterText
        extraClass=""
        width="170px"
        customToolTip={handleToolTip}
        stopAnimation={startTutorial}
        isDashboardWidget
      />
    ),
    [apparelData, startTutorial],
  );

  const footwearGraph = useMemo(
    () => (
      <DonutChart
        chartType="compliance"
        data={!isEmpty(sizeComplianceData) ? footwearData : []}
        title={getMessage('footwear')}
        legendEnabled
        displayCenterText
        extraClass=""
        width="170px"
        customToolTip={handleToolTip}
        stopAnimation={startTutorial}
        isDashboardWidget
      />
    ),
    [footwearData, startTutorial],
  );

  const sizeComplianceDetails = useMemo(() => {
    const noData = (
      <div
        className="sizeComplianceWidget_no-data"
        data-testid="errorContainer_emptyData"
      >
        <ErrorOutline
          color="error"
          fontSize="large"
        />
        <br />
        <p>{getMessage('noResultsSizeCompliance')}</p>
      </div>
    );

    if (!activateTutorials && scansLoading) {
      return (
        <div className="sizeComplianceWidget_card-loading">
          <LoadingIndicator
            width="45px"
            height="45px"
            fill="#FFFFFF"
          />
        </div>
      );
    }

    if (!activateTutorials && (!data || data?.error)) {
      return (
        <div
          className="sizeComplianceWidget_card-error"
          data-testid="errorContainer"
        >
          <ErrorOutline
            color="error"
            fontSize="large"
          />
          <br />
          <p>{getMessage('fatalError')}</p>
        </div>
      );
    }

    return (
      <div
        className="sizeComplianceWidget_card-details"
        data-testid="sizeComplianceWidget_data"
      >
        <div className="">
          <div className="sizeComplianceWidget_card-body">
            {sizeComplianceData?.message === 'No scan found.' ? (
              noData
            ) : (
              <div
                data-testid="sizeComplianceWidget_data_visuals"
                className="sizeComplianceWidget_data_visuals"
              >
                <div>
                  {/* beacon is an inline door - they do not have footwear on the floor */}
                  {footwearData?.length > 0 && businessConcept !== 'BEACON' ? footwearGraph : null}
                </div>
                <div>{apparelData && apparelGraph}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }, [apparelGraph, footwearGraph, scansLoading]);

  const cardContent = (
    <div className="sizeComplianceWidget_body">
      <p className="sizeComplianceWidget_title">{getMessage('size')}</p>
      {sizeComplianceDetails}
    </div>
  );

  const cardWrapper = !activateTutorials ? (
    <Link
      to="/SizeCompliance"
      className="sizeComplianceWidget_dashboard-widget"
    >
      {cardContent}
    </Link>
  ) : (
    <div className="sizeComplianceWidget_dashboard-widget">{cardContent}</div>
  );

  return (
    <div
      className={classNames('sizeComplianceWidget_dashboard', `${customClass}`, 'borderStyles')}
      data-testid="sizeComplianceWidget_dashboard"
    >
      {cardWrapper}
    </div>
  );
};

SizeComplianceWidget.propTypes = {
  customClass: string,
};

export default SizeComplianceWidget;
