import { isEmpty } from 'lodash';
import getItemInputType, { inputTypes } from '../../utils/parseItemInput';

/**
 * A helper function to build URL parameters from an object
 * @param {object} variables the object with list of gtins, styleColors or styleCodes
 */
export const buildURLParams = (variables) => {
  if (!variables || isEmpty(variables)) return '';

  const params = new URLSearchParams();

  Object.keys(variables).forEach(key => {
    if (typeof variables[key] === 'object') {
      params.append('filter', `${key}(${variables[key].join()})`);
    } else {
      params.append('filter', `${key}(${variables[key]})`);
    }
  });

  return `?${params}`;
};

/**
 * A helper function to return an object depends on the value is gtin, style-color or style, otherwise return null
 * @param {string} value the search value
 * @param {boolean} isIIWidget the indicator if the search is from Item Inquiry Widget
 */
export const setSearchValueAndType = (value, isIIWidget) => {
  let searchValue = null;
  let searchType = null;
  let itemInputType = null;

  if (!value) return { searchValue, searchType };

  if (Array.isArray(value)) {
    itemInputType = getItemInputType(value[0]);
  } else {
    itemInputType = getItemInputType(value);
  }

  const val = typeof value === 'string' ? value?.toUpperCase() : value;

  switch (itemInputType) {
    case inputTypes.GTIN:
      searchValue = val;
      searchType = 'gtins';
      break;
    case inputTypes.STYLE_COLOR:
      searchValue = val;
      searchType = 'productCodes';
      break;
    case inputTypes.STYLE:
      searchValue = !isIIWidget ? val : null;
      searchType = 'styleCodes';
      break;
    case inputTypes.NONE:
    case inputTypes.INVALID:
    default:
      searchValue = null;
      searchType = null;
  }

  return { searchValue, searchType };
};
