import { useContext } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid2, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { TranslationsContext } from '../../context/Translations.provider';

const CircularProgressWithLabel = ({
  value = 0,
  color = '#fa5400',
  size = '40px',
  dataTestId = 'circular-progress-bar',
  showArrow = true,
}) => {
  const { formatMessage } = useContext(TranslationsContext);

  const ProgressToolTip = withStyles({
    arrow: {
      color: '#292929',
    },
    tooltip: {
      backgroundColor: '#292929',
      color: '#DEDEDE',
      accentColor: '#292929',
      fontWeight: 500,
      fontSize: '12px',
      border: '1px solid black',
      padding: '5px',
    },
  })(Tooltip);

  return (

    <Grid2 container direction="row" data-testid={dataTestId}>
      <ProgressToolTip data-testid="scan-summary-progress-tooltip" title={`${formatMessage('scanProcessing', { percentage: value })}%`} placement="top" arrow={showArrow}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          width: size,
          height: size,
        }}
        >
          <div style={{ color: ((value === 100) ? '#ABD648' : color), display: 'inherit' }}>
            <CircularProgress color="inherit" variant="determinate" value={value} />
          </div>
          <div
            data-testid={`${dataTestId}-value`}
            style={{
              position: 'relative',
              left: '-28px',
              fontSize: '.55em',
            }}
          >
            {`${Math.round(value)}%`}
          </div>
        </div>
      </ProgressToolTip>
    </Grid2>
  );
};

CircularProgressWithLabel.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number,
  size: PropTypes.string,
  dataTestId: PropTypes.string,
  showArrow: PropTypes.bool,
};

export default CircularProgressWithLabel;
