import axios from 'axios';
import { baseUrl, routerUrls, endpoints } from './endpoints';
import { logErrorAction, newRelicAction } from '../utils/newRelicPageActions';
import { setDefaultHeaders, wait } from './axiosFunctions';
import useAuth from '../hooks/useAuth';

/**
 * This function checks whether or not a fiscal document already exists for a document.
 * @param {String} documentId id of the document we're checking.
 * @returns if a document link exists, return that or null otherwise.
 */
export const checkForFiscalDocumentLink = async (documentId) => {
  let result;
  const { getAccessToken } = useAuth();
  const startTime = Date.now();

  const authToken = getAccessToken().token;
  const endpoint = endpoints.FISCAL_COMPLIANCE_DOCUMENT_FETCH;

  if (authToken) {
    result = await axios
      .get(`${baseUrl(routerUrls.SIM_REPORTS)}${endpoint.url}/${documentId}`)
      .then(resp => resp?.data?.links?.documentURL)
      .catch(error => {
        logErrorAction(`[${endpoint.name} error]`, error, 'error fetching data', {
          customMessage: `[${endpoint.name} Error]: Message: ${error?.message}.`,
        });
        throw error;
      });
  }
  const duration = Date.now() - startTime;
  newRelicAction(
    'sim-backend-performance',
    {
      source: 'axios',
      queryName: `${endpoint.name} fetch: ${endpoint.url}`,
      duration,
    },
  );
  return result;
};

/**
 * Used to perform the fiscal printing functions for select EMEA stores. Will exit when job
 * status returns complete meaning item has printed.
 * @param {*} endpoint which printing function needs to be performed
 * @param {*} payload a request object with the document's id and/or address
 */
export const performFiscalPrint = async (endpoint, payload) => {
  const startTime = Date.now();
  const { getAccessToken } = useAuth();
  const authToken = getAccessToken().token;
  let jobId;
  let jobStatus;

  if (authToken) {
    setDefaultHeaders(authToken);
    const job = await axios.post(baseUrl(routerUrls.SIM_REPORTS) + endpoint.url, payload)
      .then(resp => resp?.data)
      .catch(error => {
        logErrorAction(`[${endpoint.name} error]`, error, 'error fetching data', {
          customMessage: `[${endpoint.name} Error]: Message: ${error?.message}`,
        });
        throw error;
      });
    jobId = job?.id;
    jobStatus = job?.status;

    while (jobStatus !== 'COMPLETED') {
      const jobUrl = `${baseUrl(routerUrls.SIM_REPORTS)}${endpoint.jobUrl}/${jobId}?${Math.random()}`;
      setDefaultHeaders(authToken);
      await axios.get(jobUrl)
        .then(resp => {
          jobStatus = resp?.data?.status;
        })
        .catch(error => {
          logErrorAction(`[${endpoint.name} error]`, error, 'error while waiting for job', {
            customMessage: `[${endpoint.name} Job Error]: Message: ${error?.message}.  JobId: ${jobId}`,
          });
          throw error;
        });
      await wait(1000);
    }
  }
  const duration = Date.now() - startTime;
  newRelicAction(
    'sim-backend-performance',
    {
      source: 'axios',
      queryName: `${endpoint.name} fetch: ${endpoint.url}`,
      duration,
    },
  );
};
