import {
  useContext, useState, useMemo,
} from 'react';
import {
  func, number, object,
} from 'prop-types';
import MUIDataTable from 'mui-datatables';
import CloudDownload from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { newRelicAction } from '../../utils/newRelicPageActions';
import { defaultSort } from '../../utils/sort';
import Button from '../../components/Button';
import ConfirmationModal from '../../components/confirmationModal/ConfirmationModal';
import { LoadingIndicator } from '../../assets/Svgs';
import DialogHeader from '../../components/dialogHeader/DialogHeader';
import { SimWebContext } from '../../context/SimWeb.provider';
import { createXLSXFile } from '../../utils/createXLSXFile'

import { ProductRefillContext } from './ProductRefill.provider';
import { buildDialogRowsData } from './productRefill.utils';
import { markTaskAsFilled } from './productRefill.axios';

import './productRefill.css';

/**
 * Takes in Product Refill task data and format it to be show in the dialog
 * that is shown on the Product Refill page when a task is clicked on.
 * @param {object} selectedTask - the task being shown in the dialog
 * @param {func} setIsOpen - set dialog to open or close
 * @param {func} setTaskIndex - sets the index of the task from the array of data
 * @param {number} taskIndex - the index of the task the user is viewing in the dialog
 * @param {number} parentDataRowCount - number of rows in parent table
 */
const ProductRefillTaskDialog = ({
  selectedTask = {},
  setIsOpen,
  setTaskIndex,
  taskIndex,
  parentDataRowCount = 0,
}) => {
  const {
    getMessage, athleteId, storeConfig, country, storeNumber, store,
  } = useContext(SimWebContext);
  const { isFetching, setRefetchTasks } = useContext(ProductRefillContext);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const taskService = storeConfig?.tasksVersion?.value;

  const markRefillTaskAsFilled = async () => {
    const athlete = `${country};${storeNumber};${athleteId}`;
    setIsLoading(true);
    newRelicAction('sim-mark-all-as-filled', { taskId: selectedTask.id });
    await markTaskAsFilled(selectedTask, athlete, { appId: 'simweb' }, taskService, store);
    await setRefetchTasks(true);
    setIsLoading(false);
    setIsConfirmationOpen(false);
    setIsOpen(false);
  };

  const rows = useMemo(() => buildDialogRowsData(selectedTask, taskService), [selectedTask]);

  const buildDownloadData = () => {
    return rows?.map(row => ({
      'Bin': row.bins || '-',
      'Product': row.product || '-',
      'Style Color': row.styleColor || '-',
      'Size': row.size || '-',
      'Pull': row.pull || 0,
    }));
  };

  const HeaderElements = () => (
    <>
      <IconButton
        onClick={async () => {
          const fileName = 'Item Activity Report Details';
          const newCsvData = await buildDownloadData(selectedTask, taskService)
          createXLSXFile(newCsvData, fileName);
        }}
        data-testid="itemActivity-download"
        size="large"
      >
        <CloudDownload />
      </IconButton>
    </>
  );

  const dialogTableData = useMemo(() => {
    if (isFetching) return <LoadingIndicator extraClass="ProductRefillList-task" />;

    const columns = [
      {
        name: 'bins',
        label: getMessage('bin'),
      }, {
        name: 'product',
        label: getMessage('product'),
      }, {
        name: 'styleColor',
        label: getMessage('styleColor'),
      }, {
        name: 'size',
        label: getMessage('size'),
      }, {
        name: 'pull',
        label: getMessage('pull'),
      },
    ];

    return (
      <MUIDataTable
        className="productRefillTaskDialog_table"
        data={rows}
        columns={columns}
        options={{
          selectableRows: 'none',
          rowsPerPage: 100,
          rowsPerPageOptions: [15, 25, 50, 100],
          print: true,
          downloadOptions: {
            filterOptions: {
              useDisplayedColumnsOnly: true,
              useDisplayedRowsOnly: true,
            },
          },
          download: false,
          customToolbar: () => (<HeaderElements />),
          sortOrder: {
            name: 'bins',
            direction: 'asc',
          },
          customSort: (data, dataIndex, rowIndex) => defaultSort(data, dataIndex, rowIndex),
        }}
      />
    );
  }, [rows, isConfirmationOpen, isFetching, selectedTask, taskIndex]);

  const title = useMemo(() => {
    const titleText = (selectedTask?.picklistName !== '-') ? selectedTask?.picklistName : selectedTask?.bin;
    return (
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
        <strong data-testid="product-refill-task-dialog-title">{titleText}</strong>
      </DialogTitle>
    );
  }, [selectedTask]);

  return (
    <div className="details" data-testid="product-refill-task-dialog-muiDataTable">
      <DialogHeader
        loading={isLoading}
        index={taskIndex}
        setIsOpen={setIsOpen}
        rowCount={parentDataRowCount}
        setIndex={setTaskIndex}
        nodeTitle={title}
        additionalNode={(
          <Button
            onClick={() => setIsConfirmationOpen(true)}
            isLoading={isFetching}
            buttonText={getMessage('markAllAsFilled')}
            extraClasses="mark-as-filled-button"
            data-testid="product-refill-task-dialog_markTasksFilledButton"
          />
        )}
      />
      <DialogContent>
        {dialogTableData}
      </DialogContent>
      <Dialog
        open={isConfirmationOpen}
        fullScreen={false}
        maxWidth="sm"
        fullWidth
      >
        <ConfirmationModal
          dataTestId="markAsFilled-confirmation-modal"
          isLoading={isLoading}
          onClick={markRefillTaskAsFilled}
          message={getMessage('confirmationMarkAsFilled')}
          setIsOpen={setIsConfirmationOpen}
        />
      </Dialog>
    </div>
  );
};

ProductRefillTaskDialog.propTypes = {
  selectedTask: object,
  setIsOpen: func.isRequired,
  setTaskIndex: func.isRequired,
  taskIndex: number.isRequired,
  parentDataRowCount: number,
};

export default ProductRefillTaskDialog;
