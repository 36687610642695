import { useContext, useMemo } from 'react';
import {
  arrayOf, string, func, shape,
} from 'prop-types';
import { Link } from 'react-router-dom';

import { TranslationsContext } from '../../../context/Translations.provider';
import './NavMenuItem.css';

const NavMenuItem = ({
  childItems = [],
  i18nKey = null,
  toggleClose = null,
  toggleSelected = null,
  country = null,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const childNavItem = useMemo(() => childItems && childItems.map((child) => {
    if (country && country === 'BRA') {
      if (child.storeConfigKey === 'sohEnabled') {
        return null;
      }
    }
    return (
      <Link
        to={(child && child.slug)}
        data-testid={child && child.slug}
        className="navItem-subLink"
        key={child && child.slug}
        onClick={() => { toggleClose(); toggleSelected(); }}
      >
        {child && getMessage(child.i18nKey)}
        {child && <span className={`badge badge-${child.badgeColor || 'dark'}`}>{child.badge}</span> }
      </Link>
    );
  }), [childItems, country, toggleClose, toggleSelected, getMessage]);

  return (
    <div
      data-testid="sim-menu-item"
      className="navItem-group-container"
      key={i18nKey}
    >
      <div className="flex-row">
        <div className="navItem-list" data-testid={`${i18nKey}-navItem-list`}>
          {childNavItem}
        </div>
      </div>
    </div>
  );
};

NavMenuItem.propTypes = {
  childItems: arrayOf(shape({
    i18nKey: string,
    slug: string,
    onClick: func,
    badge: string,
    badgeColor: string,
  })),
  toggleClose: func,
  toggleSelected: func,
  i18nKey: string,
  country: string,
};

export default NavMenuItem;
