/* eslint-disable no-useless-catch */
import { useContext } from 'react';
import writeXlsxFile from 'write-excel-file';
import { cloneDeep } from 'lodash';
import { getRfidTableHeader } from '../../../components/RFIDTableHeader';
import { getLocalizedNumber } from '../utils';
import getLocalizedPercent from '../../../utils/getLocalizedPercent';
import getFormattedPrice, { formatCurrency } from '../../../utils/getFormattedPrice';
import { SimDateTime } from '../../../utils/datetime';
import { fetchStoreStockData } from '../../../context/cpa/cpa.axios';
import { getSegmentedSOH } from '../../../utils/getStockOnHand';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { ScanDetailsContext } from './ScanDetails.provider';

/**
 * A helper function to get the columns for the table
 * @param {string} id - the report id
 * @param {boolean} rfidEnabled - is rfid enabled in the store
 * @param {boolean} isOffsiteEnabled - is rfid enabled in the store
 * @param {boolean} isOffsiteInactive - is offsite enabled in the store
 * @param {string} locale - the locale of the store
 * @param {string} currencyCode - the currency code of the store
 * @param {string} aggregatedBy - the aggregation type - genderAge or retailCategory
 * @param {array} tableFilters - a list of filters applied to the table
 * @param {function} getMessage - the function to get the localized message
 */
export const getColumns = (
  id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, locale, currencyCode, aggregatedBy, tableFilters, getMessage,
) => {
  const rfidColumn = getRfidTableHeader('rfidSoh', getMessage('rfidStock'), id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, false, true, [], getMessage, false, 'fullStore', true);
  const [categoryFilter, divisionFilter] = tableFilters;

  const columns = [
    {
      name: 'productCode',
      label: getMessage('styleColor'),
    },
    {
      name: 'description',
      label: getMessage('description'),
    },
    {
      name: 'division',
      label: getMessage('division'),
      options: {
        filter: false,
        ...(divisionFilter ? { filterList: [divisionFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'genderAge',
      label: getMessage('genderAge'),
      options: {
        display: 'excluded',
        filter: false,
        ...((categoryFilter && aggregatedBy === 'genderAge') ? { filterList: [categoryFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'retailCategory',
      label: getMessage('retailCategory'),
      options: {
        filter: false,
        ...((categoryFilter && aggregatedBy === 'retailCategory') ? { filterList: [categoryFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'currentPrice',
      label: getMessage('currentPrice'),
      options: {
        customBodyRender: value => (
          <span>{getFormattedPrice(value, locale, currencyCode)}</span>
        ),
      },
    },
    {
      name: 'valueVariance',
      label: getMessage('valueVariance'),
      options: {
        customBodyRender: value => {
          let className = '';
          if (value < 0) {
            className = 'scan-total-value-variance-value-negative';
          } else if (value > 0) {
            className = 'scan-total-value-variance-value-positive';
          }

          return (<span className={className} style={{ maxWidth: '100px' }}>{getFormattedPrice(value, locale, currencyCode)}</span>);
        },
      },
    },
    {
      name: 'scanned',
      label: getMessage('scanned'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'expected',
      label: getMessage('expected'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'missing',
      label: getMessage('missing'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'extra',
      label: getMessage('extra'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'accuracy',
      label: getMessage('accuracy'),
      options: {
        customBodyRender: value => getLocalizedPercent(locale, value),
      },
    },
    rfidColumn,
    {
      name: 'totalRfidSoh',
      label: 'Total RFID SOH',
      options: {
        sort: false,
        filter: false,
      },
    },
    { // use to sort by absolute value variance
      name: 'absValueVariance',
      options: {
        sort: true,
        filter: false,
        display: false,
      },
    },
  ];

  return columns;
};

/**
 * Creates headers for excel download
 * @param {*} columns columns to create headers for
 */
export const createDownloadHeaders = (columns) => {
  const headerRow = [];
  columns.map(column => headerRow.push({ value: column?.label?.toUpperCase() ?? '', fontWeight: 'bold' }));
  return headerRow;
};

/**
 * Calculates the maximum width for each column in a 2D array of data.
 *
 * @param {Array<Array<any>>} data - A 2D array where each sub-array represents a row of data.
 * @returns {Array<number>} An array of numbers representing the maximum width of each column.
 */
export const calculateColumnWidths = (data) => {
  const widths = data[0].map((_, colIndex) => Math.max(...data.map(row => (row?.[colIndex]?.value ?? '').toString().length)));
  return widths;
};

export const getTotalSohs = (data) => {
  const offSiteQuantity = data?.reduce((acc, curr) => (acc + (+curr.physicalStock?.segmentation?.offSiteQuantity || 0)), 0) || 0;
  const salesFloorQuantity = data?.reduce((acc, curr) => (acc + (+curr.physicalStock?.segmentation?.salesFloorQuantity || 0)), 0) || 0;
  const stockRoomQuantity = data?.reduce((acc, curr) => (acc + (+curr.physicalStock?.segmentation?.stockRoomQuantity || 0)), 0) || 0;
  const physicalStockQuantity = data?.reduce((acc, curr) => (acc + (+curr.physicalStock?.quantity || 0)), 0) || 0;
  const fiscalStockQuantity = data?.reduce((acc, curr) => (acc + (+curr.fiscalStock?.quantity || 0)), 0) || 0;

  return {
    fiscalStock: {
      quantity: fiscalStockQuantity,
    },
    physicalStock: {
      quantity: physicalStockQuantity,
      segmentation: {
        offSiteQuantity,
        salesFloorQuantity,
        stockRoomQuantity,
      },
    },
  };
};

export const mergeIventoryData = (inventory, stock, isOffsiteEnabled) => {
  const mapping = {
    fullStore: 'fullStore',
    salesFloor: 'salesFloor',
    stockRoom: 'stockRoom',
    offsite: 'offSite',
  };
  const newItem = cloneDeep(inventory);
  Object.keys(newItem).map(key => {
    newItem?.[key]?.map(gtinItem => {
      const gtinStockData = stock?.find(stockItem => stockItem.gtin === gtinItem.gtin);
      let quantity;
      if (key === 'fullStore') {
        quantity = getSegmentedSOH(gtinStockData?.physicalStock?.segmentation, isOffsiteEnabled) || '-';
      } else {
        quantity = gtinStockData?.physicalStock?.segmentation?.[`${mapping[key]}Quantity`] || 0;
      }
       
      gtinItem.physicalStockQuantity = quantity;
      gtinItem.fiscalStockQuantity = (key === 'fullStore') ? gtinStockData?.fiscalStock?.quantity || 0 : '-';
    });
  });

  return newItem;
};

/**
 * Adds Stock on Hand (SOH) information to the provided items.
 *
 * @param {string} storeId - The ID of the store.
 * @param {Array} items - The list of items to process.
 * @param {Array} currentPageData - The list of items currently displayed.
 * @param {boolean} isOffsiteEnabled - Flag indicating if offsite is enabled.
* @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const getUpdatedSOHData = async (storeId, items, currentPageData, isOffsiteEnabled) => {
  const processItems = async (items, storeStockData) => {
    const newItems = cloneDeep(items);

    currentPageData.forEach(row => {
      const currentItem = newItems.find(item => item.productCode === row[0]);
      const stock = storeStockData?.filter(stockItem => stockItem.productCode === currentItem.productCode);
      if (currentItem && stock?.length > 0) {
        const totalSohs = getTotalSohs(stock);
        currentItem.rfidSoh = getSegmentedSOH(totalSohs.physicalStock.segmentation, isOffsiteEnabled);
        currentItem.totalRfidSoh = totalSohs.physicalStock.quantity || 0;
        currentItem.inventory = mergeIventoryData(currentItem.inventory, stock, isOffsiteEnabled);
      }
    });

    return newItems;
  };

  try {
    const productCodes = currentPageData.map(item => item?.[0] || '');
    const storeStockData = await fetchStoreStockData(storeId, productCodes, true);
    const filterItems = await processItems(items, storeStockData);
    return filterItems;
  } catch (error) {
    throw error;
  }
};

export const getScanAggregationsByFilter = (items, tableFilters, aggregatedBy) => {
  if (!items || items.length === 0) return {};

  const [categoryFilter, divisionFilter] = tableFilters;
  const filteredItems = items.filter(item => item?.division === divisionFilter && item?.[aggregatedBy] === categoryFilter);

  return {
    scanned: filteredItems.reduce((acc, curr) => acc + curr?.scanned, 0),
    expected: filteredItems.reduce((acc, curr) => acc + curr?.expected, 0),
    salesFloor: filteredItems.reduce((acc, curr) => acc + curr?.inventory?.salesFloor.reduce((acc, curr) => acc + curr?.scanned, 0), 0),
    stockRoom: filteredItems.reduce((acc, curr) => acc + curr?.inventory?.stockRoom.reduce((acc, curr) => acc + curr?.scanned, 0), 0),
    offsite: filteredItems.reduce((acc, curr) => acc + curr?.inventory?.offsite.reduce((acc, curr) => acc + curr?.scanned, 0), 0),
  };
};

export const onRowClick = (rowData, items, setScanProductDetailData, setIsOpened, setCurrentProduct) => {
  const productCode = rowData?.[0];
  if (!productCode) return;

  if (!items?.length) return;

  const productDetails = items?.find(item => item.productCode === productCode);
  setScanProductDetailData(productDetails);
  setCurrentProduct(productCode);
  setIsOpened(true);
};

export const onTableChange = async (action, tableState, storeId, items, setSOHLoading, setScanItems, isOffsiteEnabled, setCurrentSort, setProducts, getPage, setPage, getDisplayedRowCount, setDisplayedRowCount, getToggleChanged, setToggleChanged) => {
  const {
    page, rowsPerPage, displayData, sortOrder,
  } = tableState;

  setCurrentSort(sortOrder);

  // should pull 2 pages worth of data
  const numberOfRowsToPull = rowsPerPage * 2;
  const startIndex = page * numberOfRowsToPull;
  const endIndex = ((page + 1) * (numberOfRowsToPull)) - 1;

  // used to determine if something has changed. using updateProps will cause an infinite loop
  const hasDisplayedDataChanged = displayData.length !== parseInt(getDisplayedRowCount(), 10)
    || (['missing', 'extra'].includes(sortOrder.name) && getToggleChanged());

  const interceptActions = ['search', 'sort', 'changePage', 'changeRowsPerPage'];
  if (interceptActions.includes(action) || hasDisplayedDataChanged) {
    setSOHLoading(true);
    setToggleChanged(false);

    const currentPage = displayData.slice(startIndex, endIndex);
    const currentPageData = currentPage.map(row => row.data);

    // Extract dataIndexes for product card navigation
    const products = currentPage.map(row => ({ index: row.index, productCode: row.data[0] }));
    setProducts(products);

    await getUpdatedSOHData(storeId, items, currentPageData, isOffsiteEnabled)
      .then(updatedItems => {
        setScanItems(updatedItems);
      })
      .catch(error => {
         
        console.error('Error updating SOH:', error);
      })
      .finally(() => {
        setDisplayedRowCount(tableState.displayData.length);
        setPage(page);
        setSOHLoading(false);
      });
  }
};

export const onDownload = (_buildHead, _buildBody, newColumns, newItems, locale, currencyCode) => {
  try {
    const valueVarianceIndex = newColumns.findIndex(column => column.name === 'valueVariance');
    const currentPriceIndex = newColumns.findIndex(column => column.name === 'currentPrice');

    newItems.sort((a, b) => {
      const valueA = Math.abs(parseFloat(a.data[valueVarianceIndex]) || 0);
      const valueB = Math.abs(parseFloat(b.data[valueVarianceIndex]) || 0);
      return valueB - valueA;
    });

    const dataRows = newItems.map(row => row.data.map((value, index) => {
      let currentValue = value;

      if (index === valueVarianceIndex || index === currentPriceIndex) {
        currentValue = formatCurrency(value ? parseFloat(value) : 0, locale, currencyCode);
      }

      return { value: currentValue?.toString() ?? '', type: String };
    }));

    const headerRow = createDownloadHeaders(newColumns);
    const columnWidths = calculateColumnWidths([...[headerRow], ...dataRows]);
    const columns = columnWidths.map(width => ({ width: width + 2 }));
    writeXlsxFile([headerRow, ...dataRows], { columns, fileName: `ScanReport-${SimDateTime.toUtcISO()}.xlsx` });
  } catch (error) {
     
    console.error(error);
  }

  return false; // cancels default datatable download logic
};

/**
 * Custom hook to generate table options for a data table.
 *
 * @param {Array} items - Array of items to be displayed in the table.
 * @param {Function} setScanItems - Function to set scan items.
 * @param {Function} setSOHLoading - Function to set the state of SOH loading.
 * @param {Function} setScanProductDetailData - Function to set scan product detail data.
 * @param {Function} setIsOpened - Function to set the state of whether a modal or detail view is opened.
 * @returns {Object} Table options for configuring the data table.
 */
const useTableOptions = (setScanProductDetailData, setIsOpened, setCurrentProduct, setProducts) => {
  const {
    currencyCode, getMessage, isOffsiteEnabled, isPrintingEnabled, locale, storeId,
  } = useContext(SimWebContext);

  const {
    items, setScanItems, setSOHLoading,
    getCurrentSort, setCurrentSort,
    getPage, setPage,
    getDisplayedRowCount, setDisplayedRowCount,
    getToggleChanged, setToggleChanged,
  } = useContext(ScanDetailsContext);

  return {
    selectableRows: 'none',
    print: isPrintingEnabled,
    responsive: 'scrollFullHeight',
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    filter: true,
    download: true,
    onRowClick: (rowData) => onRowClick(rowData, items, setScanProductDetailData, setIsOpened, setCurrentProduct),
    onDownload: (_buildHead, _buildBody, newColumns, newItems) => onDownload(_buildHead, _buildBody, newColumns, newItems, locale, currencyCode),
    onTableChange: (action, tableState) => onTableChange(action, tableState, storeId, items, setSOHLoading, setScanItems, isOffsiteEnabled, setCurrentSort,
      setProducts, getPage, setPage, getDisplayedRowCount, setDisplayedRowCount, getToggleChanged, setToggleChanged),
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
    },
    sortOrder: getCurrentSort(),
    fixedHeader: true,
    tableBodyMaxHeight: '625px',
  };
};

export default useTableOptions;
