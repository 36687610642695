import {
  createContext, useState, useContext, useEffect,
} from 'react';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { node, object } from 'prop-types';

import { SimWebContext } from '../../context/SimWeb.provider';
import { routerUrls, endpoints } from '../../axios/endpoints';
import { createAndMonitorJob } from '../../axios/jobPollAndDataFetchAxiosFunctions';
import { getASNTrackingReport } from './asnTracking.axios';
import { getLastUpdatedDate } from './asnTracking.utils';
import { SimDateTime } from '../../utils/datetime';

export const ASNTrackingContext = createContext({});

const ASNTrackingProvider = ({ children = {}, mockedValue = null }) => {
  const { locale, store } = useContext(SimWebContext);
  const { Provider } = ASNTrackingContext;

  const asnTrackingInitialStates = {
    startDateState: SimDateTime.startOfDay(),
    endDateState: SimDateTime.endOfDay(),
  };

  const [jobId, setJobId] = useState(null);
  const [jobFetchError, setJobFetchError] = useState(null);
  const [fetchingJobId, setFetchingJobId] = useState(false);
  const [dateFetched, setDateFetched] = useState(null);
  const [rows, setRows] = useState([]);
  const [fetchByNewDates, setFetchByNewDates] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [asnTrackingStates, setAsnTrackingStates] = useState(asnTrackingInitialStates);

  const { startDateState, endDateState } = asnTrackingStates;

  const onGoClick = (options, refresh) => {
    const { startDate: currentStartDate, endDate: currentEndDate } = options;

    if (refresh) {
      setAsnTrackingStates(asnTrackingInitialStates); // reseting dates
      setRefresh(false);
    } else {
      setAsnTrackingStates({
        startDateState: DateTime.fromISO(currentStartDate),
        endDateState: DateTime.fromISO(currentEndDate),
      });
    }
    setFetchByNewDates(true);
  };

  const fetchJobId = async (store, startDate, endDate, locale) => {
    const detailsPayload = {
      start: startDate,
      end: endDate,
      store,
    };

    setJobId(null);
    setFetchingJobId(true);

    try {
      const jobId = await createAndMonitorJob(routerUrls.DELEDGE, endpoints.ASN_TRACKING, detailsPayload);
      setJobId(jobId);
      const lastUpdatedDate = getLastUpdatedDate(locale);
      setDateFetched(lastUpdatedDate);
    } catch (error) {
      setDateFetched(null);
      setJobFetchError(error);
    }

    setFetchingJobId(false);
  };

  const fetchReport = async () => {
    setLoading(true);

    try {
      const response = await getASNTrackingReport(store, jobId);
      setData(response);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!isEmpty(startDateState) && !isEmpty(endDateState) && store && fetchByNewDates) {
      fetchJobId(store, startDateState, endDateState, locale);
      setFetchByNewDates(false);
    }
  }, [startDateState, endDateState, store, fetchByNewDates, locale]);

  useEffect(() => {
    if (store && jobId) fetchReport();
  }, [store, jobId]);

  useEffect(() => {
    if (refresh) onGoClick(asnTrackingInitialStates, refresh);
  }, [refresh]);

  useEffect(() => {
    if (data?.details) setRows(data?.details);
  }, [data?.details]);

  return (
    <Provider value={mockedValue ?? {
      dateFetched,
      rows,
      setRows,
      loading,
      error,
      refetch: fetchReport,
      jobId,
      fetchingJobId,
      jobFetchError,
      refresh,
      setRefresh,
      onGoClick,
      setAsnTrackingStates,
      startDateState,
      endDateState,
      storeNumber: data?.store,
    }}
    >
      {children}
    </Provider>
  );
};

ASNTrackingProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default ASNTrackingProvider;
