import { useRef, useContext } from 'react';
import {
  Dialog, DialogActions, DialogContent, Grid2,
} from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { array, bool, func } from 'prop-types';
import ReactToPrint from 'react-to-print';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import PrintDropZoneDialogContainer from './PrintDropZoneDialogContainer';
import Button from '../../../components/Button';

import { SimWebContext } from '../../../context/SimWeb.provider';

import './PrintDropZoneStyles.css';

const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    @page {
      size: landscape !important;
    }
    .pagebreak {
      page-break-before: always !important;
    }
  }
`;

const PrintDropZoneDialog = ({
  printDropZoneData = [],
  mergeOrdersBasedOnStyleColors,
  printDialogOpen,
  setPrintDialogOpen,
}) => {

  const printRef = useRef();

  const { getMessage } = useContext(SimWebContext);

  const theme = createTheme(({
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            overflowY: 'hidden',
          },
        },
      },
    },
  }));

  return (
    <div data-testid="printDropZoneDialog-container">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Dialog
            open={printDialogOpen}
            onClose={() => setPrintDialogOpen(false)}
            maxWidth="md"
            fullWidth
            data-testid="printDropZoneDialog"
          >
            <DialogContent style={{ marginTop: 30, position: 'inherit' }} ref={printRef}>
              {printDropZoneData?.map((item, index) => (
                <Grid2
                  key={index}
                  style={{ pageBreakAfter: 'always' }}
                >
                  <PrintDropZoneDialogContainer
                    item={item}
                    mergeOrdersBasedOnStyleColors={mergeOrdersBasedOnStyleColors}
                  />
                </Grid2>
              ))}
            </DialogContent>
            <DialogActions id="printDropZoneDialog-footer">
              <ReactToPrint
                trigger={() => (
                  <Button
                    id="sim-printDropZoneButton"
                    buttonText={getMessage('print')}
                  />
                )}
                content={() => printRef.current}
                onAfterPrint={() => {
                  newRelicAction('inbound-visibility-dropzone-printed', { isPrintModalEnabled: true });
                  setPrintDialogOpen(false);
                }}
                pageStyle={pageStyle}
              />
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

PrintDropZoneDialog.propTypes = {
  printDropZoneData: array,
  mergeOrdersBasedOnStyleColors: func.isRequired,
  setPrintDialogOpen: func.isRequired,
  printDialogOpen: bool.isRequired,
};

export default PrintDropZoneDialog;
