import { createContext } from 'react';
import { node, object } from 'prop-types';
import { isEmpty } from 'lodash';
import useSimStore from '../store/simStore';

export const StoreConfigContext = createContext({});

const StoreConfigProvider = ({ children = {}, mockedValue = null }) => {
  const { Provider } = StoreConfigContext;
  const storeConfig = useSimStore(state => state.storeConfig);

  return (
    <Provider
      value={mockedValue ?? {
        configRetrieved: !isEmpty(storeConfig),
        storeConfig,
      }}
    >
      {children}
    </Provider>
  );
};

StoreConfigProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default StoreConfigProvider;
