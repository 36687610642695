import { useContext } from 'react';
import { DashboardContext } from '../Dashboard.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { StyleComplianceWidget } from '../../../components/dashboardWidgets';
import { useTutorial } from '../../../components/tutorial/useTutorial';
import { tutorialContext } from '../../../components/tutorial/Tutorial.provider';

const StyleCompliance = () => {
  const { scansLoading, data } = useContext(DashboardContext);
  const { storeConfig } = useContext(SimWebContext);
  const { activateTutorials } = useContext(tutorialContext);
  const { tutorial } = useTutorial('styleAndSize');

  const rfidStyleComplianceWidgetEnabled = storeConfig?.rfidstyleComplianceWidget?.value;

  const tutorialDiv = (
    <div className="dashboard_tutorial_button_placeholder">
      <div style={{ paddingLeft: '25px', paddingBottom: '10px', color: 'white' }}>
        {tutorial}
      </div>
    </div>
  );

  return (rfidStyleComplianceWidgetEnabled
    && (
      <div>
        {activateTutorials && tutorialDiv}
        <div className="sizeComplianceWidget_dashboard_container">
          <StyleComplianceWidget data={data?.styleCompliance} loading={scansLoading} />
        </div>
      </div>
    )
  );
};

export default StyleCompliance;
