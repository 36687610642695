import * as auth from '../auth';
import { AUTH_TYPE } from '../constants/storageKeys.constants';

const useAuth = () => {
  const login = (authType, history, assertion) => {
    sessionStorage.setItem(AUTH_TYPE, authType);
    return auth.login(history, assertion);
  };

  const isLoginResponsePresentOnURL = () => auth.isLoginResponsePresentOnURL();
  const isLoggedIn = () => auth.isLoggedIn();
  const logout = (history) => auth.logout(history);
  const getUserInfo = () => auth.getUserInfo();
  const getAccessToken = () => auth.getAccessToken();
  const sessionExpiresIn = () => auth.sessionExpiresIn();
  const isAthleteAdmin = () => auth.isAthleteAdmin();

  return {
    isLoginResponsePresentOnURL,
    isLoggedIn,
    login,
    logout,
    getUserInfo,
    getAccessToken,
    sessionExpiresIn,
    isAthleteAdmin,
  };
};

export default useAuth;
