import {
  createContext, useContext, useState, useEffect,
} from 'react';
import { object, node } from 'prop-types';
import { createTheme } from '@mui/material/styles';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { getRfidTableHeader } from '../../../components/RFIDTableHeader';
import {
  getSOH, getTotalSOH, getUpdatedSOHData, fetchDotComAvailability,
} from '../inboundVisibility.utils';

import { tutorialContext } from '../../../components/tutorial/Tutorial.provider';
import { InboundVisibilityContext } from '../InboundVisibility.provider';
import { InboundVisibilityTableContext } from '../InboundVisiblityTable.provider';

export const PurchaseOrderDialogContext = createContext({});

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: '40px',
        },
        root: {
          height: '40px',
          width: '30px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 3,
        },
        body: {
          padding: 3,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          zIndex: 'auto',
          position: 'static',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 3,
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: 3,
        },
      },
    },
  },
});

const InboundVisibilityPurchaseOrderDialogProvider = ({ children = {}, mockedValue = null }) => {
  const { Provider } = PurchaseOrderDialogContext;
  const {
    isOffsiteEnabled, isOffsiteInactive, storeId, getMessage,
  } = useContext(SimWebContext);
  const { startTutorial, isbackClicked } = useContext(tutorialContext);
  const {
    dialogData, rfidEnabled, webCarrierAndTrackingID, merchGroup, isPro,
  } = useContext(InboundVisibilityContext);
  const { docProNumber } = useContext(InboundVisibilityTableContext);

  const [index, setIndex] = useState(0);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState(null);
  const [dataWithDotComAvailability, setDotComAvailibilityData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(!data);

  useEffect(() => {
    setData(null);
    setError(null);

    const fetchData = async () => {
      try {
        let updatedSOHData = null;
        let updatedWithDotCom = null;

        if (merchGroup && dialogData) {
          updatedSOHData = await getUpdatedSOHData(merchGroup, dialogData, index, setLoading, storeId, rfidEnabled, isOffsiteEnabled);
          updatedWithDotCom = await fetchDotComAvailability(dialogData, index, merchGroup, setIndex); // seperate logic so data can render when its ready
          setData(updatedSOHData);
          if (updatedWithDotCom) setDotComAvailibilityData(updatedWithDotCom);
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [merchGroup, dialogData, index]);

  const sohColumn = rfidEnabled
    ? getRfidTableHeader('soh', getMessage('rfidSoh'), 'inbound-visibility-purchase-order', rfidEnabled, isOffsiteEnabled, isOffsiteInactive)
    : { name: 'soh', label: getMessage('soh') };

  const columns = [
    {
      name: 'cartonId',
      label: getMessage('cartonID'),
    },
    {
      name: 'bin',
      label: getMessage('bin'),
    },
    {
      name: 'styleColor',
      label: getMessage('style-color'),
    },
    {
      name: 'category',
      label: getMessage('category'),
    },
    {
      name: 'gender',
      label: getMessage('gender'),
    },
    {
      name: 'description',
      label: getMessage('description'),
    },
    {
      name: 'size',
      label: getMessage('size'),
    },
    {
      name: 'unitsExpected',
      label: getMessage('unitsExpected'),
    },
    sohColumn,
    {
      name: 'totalSOH',
      label: (rfidEnabled) ? 'Total RFID SOH' : 'Total SOH',
    },
    {
      name: 'price',
      label: getMessage('price'),
    },
    // TODO Temporarily disabled: GOLINV-10756
    // {
    //   name: 'lirp',
    //   label: 'LI/RP',
    // },
    {
      name: 'available',
      label: 'Nike.com',
    },
    {
      name: 'fohSOH',
      label: 'FOH SOH',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'bohSOH',
      label: 'BOH SOH',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'offsiteSOH',
      label: 'Offsite SOH',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'color',
      label: getMessage('color'),
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'style',
      label: getMessage('style'),
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'totalUnitsExpected',
      label: getMessage('totalUnitsExpected'),
      options: {
        display: 'excluded',
      },
    },
  ];

  const rows = ((startTutorial && isbackClicked) ? dataWithDotComAvailability : dialogData)?.map(_ => ({
    available: _?.available || 'N/A',
    poNumber: _?.poNumber || '-',
    cartonId: _?.cartonNumber || '-',
    styleColor: _?.styleColor || '-',
    description: _?.description || '-',
    size: _?.size || '-',
    unitsExpected: _?.unitsExpected || '-',
    gender: _?.gender || '-',
    soh: getSOH(rfidEnabled, isOffsiteEnabled, _?.soh),
    totalSOH: getTotalSOH(rfidEnabled, isOffsiteEnabled, _?.soh),
    price: `${_?.price}` || '-',
    bin: _?.bin?.length ? _?.bin?.join(', ') : '-',
    lirp: _?.lirp || '-',
    category: _?.category || '-',
  }));

  useEffect(() => {
    setTitle(webCarrierAndTrackingID ? `Carrier Code: ${docProNumber?.split('-')[0] || '-'} - Tracking Number: ${docProNumber?.split('-')[1] || '-'}`
      : `${isPro ? 'Pro Number' : 'Doc Number'}: ${docProNumber}`);
  }, [docProNumber]);

  return (
    <Provider
      value={mockedValue ?? {
        columns,
        dataWithDotComAvailability,
        error,
        index,
        loading,
        rows,
        theme,
        title,
        setIndex,
      }}
    >
      {children}
    </Provider>
  );
};

InboundVisibilityPurchaseOrderDialogProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default InboundVisibilityPurchaseOrderDialogProvider;
