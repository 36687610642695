import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import {
  arrayOf,
  bool,
  func, object, oneOfType, shape, string, number,
} from 'prop-types';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import Button from '../Button';
import { LoadingIndicator } from '../../assets/Svgs/index';
import { SimWebContext } from '../../context/SimWeb.provider';

import './DateRangeWithOptions.css';

const DateRangeWithOptions = ({
  start = undefined,
  end = undefined,
  options = null,
  optionsLabel = null,
  extraClassForLoadingIcon = '',
  disabled = false,
  onClick = null,
  onDateChange = null,
  isOutsideRange = null,
  showLoadingIcon = false,
  tooManyDaysSelected = false,
  maxSearchableDays = null,
}) => {
  const {
    getMessage, formatMessage, locale, SimDateTime,
  } = useContext(SimWebContext);
  const [startDate, setStartDate] = useState(SimDateTime.toDateTime(start));
  const [endDate, setEndDate] = useState(SimDateTime.toDateTime(end));
  const [selectedOption, setSelectedOption] = useState(options?.[0]?.value);

  const localeShort = locale?.split('-')[0] || 'en';

   
  const shouldButtonDisable = useCallback(() => {
    return isOutsideRange(startDate) || isOutsideRange(endDate) || (SimDateTime.toDateTime(startDate) - SimDateTime.toDateTime(endDate)) > 0;
  }, [startDate, endDate]);

  const onDatesChanging = (value, type) => {
    const startDateDay = type === 'startDate' ? value : startDate;
    const endDateDay = type === 'endDate' ? value : endDate;

    setStartDate(startDateDay);
    setEndDate(endDateDay);

    if (typeof onDateChange === 'function') {
      onDateChange({
        startDate: startDateDay,
        endDate: endDateDay,
        selectedOption,
      });
    }
  };

  const onGoClick = () => {
    if (typeof onClick !== 'function') return;

    onClick({
      startDate,
      endDate,
      selectedOption,
    });
  };

  const onSelectChange = (event) => {
    setSelectedOption(event?.target?.value);
  };

  const datePicker = useMemo(() => (
    <div className="date-pickers-container" data-testid="date-pickers-container">
      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={localeShort}>
        <div data-testid="start-date-picker">
          <DatePicker
            className="start-date-picker"
            disabled={disabled}
            label={getMessage('startDate')}
            value={startDate}
            onChange={(newValue) => onDatesChanging(newValue, 'startDate')}
            shouldDisableDate={date => (typeof isOutsideRange === 'function' && isOutsideRange(date, 'startDate')) || false}
          />
        </div>
        <div data-testid="end-date-picker">
          <DatePicker
            className="end-date-picker"
            disabled={disabled}
            label={getMessage('endDate')}
            value={endDate}
            onChange={(newValue) => onDatesChanging(newValue, 'endDate')}
            shouldDisableDate={date => (typeof isOutsideRange === 'function' && isOutsideRange(date, 'endDate')) || false}
          />
        </div>
      </LocalizationProvider>
    </div>
  ), [startDate, endDate, getMessage, disabled]);

  return (
    <div className="date-range-container">
      <div className="date-range-options-container">
        {options?.length ? (
          <div className="options">
            <div className="options-label label">
              {optionsLabel}
            </div>
            <svg>
              <polygon points="0,0 4,7 8,0" />
            </svg>
            <select disabled={disabled} onChange={onSelectChange} className="date-range-options-select" data-testid="date-range-select">
              {options.map(opt => (
                <option value={opt.value} key={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        {datePicker}
        {typeof onClick === 'function'
          && (
            <Button
              buttonText={getMessage('go')}
              onClick={e => onGoClick(e)}
              extraClasses="date-range-submit-button"
              dataTestId="date-range-submit-button"
              disabled={disabled || (typeof isOutsideRange === 'function' && shouldButtonDisable())}
            />
          )}
        {showLoadingIcon && <LoadingIndicator width="45px" height="45px" extraClass={extraClassForLoadingIcon} />}
      </div>
      {(typeof isOutsideRange === 'function' && shouldButtonDisable())
        && (
          <p className="date-range-options-warning-message">{tooManyDaysSelected ? formatMessage('tooManyDaysSelected', { numberOfDays: maxSearchableDays }) : getMessage('dateOutsideRangeWarningMessage')}</p>
        )}
    </div>
  );
};

DateRangeWithOptions.propTypes = {
  disabled: bool,
  end: oneOfType([
    string,
    object,
  ]),
  extraClassForLoadingIcon: string,
  isOutsideRange: func,
  tooManyDaysSelected: bool,
  onClick: func,
  onDateChange: func,
  options: arrayOf(shape({
    label: string.isRequired,
    value: string.isRequired,
  })),
  optionsLabel: string,
  showLoadingIcon: bool,
  start: oneOfType([
    string,
    object,
  ]),
  maxSearchableDays: number,
};

export default DateRangeWithOptions;
