import { useContext } from 'react';
import {
  string,
  number,
  arrayOf,
} from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import noFootwearImage from '../assets/images/noFootwear.png';
import noApparelImage from '../assets/images/noApparel.png';
import noEquipmentImage from '../assets/images/noEquipment.png';

import { TranslationsContext } from '../context/Translations.provider';

import './ScanProductImageCard.css';

const ScanProductImageCard = ({
  gender = null,
  division = null,
  category = null,
  binInfo = null,
  price = null,
  gtins = 0,
  keyProp = null,
  image = null,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  const bins = (binInfo && binInfo.length) ? binInfo.map(bin => <p key={bin}>{bin}</p>) : getMessage('binsNa');
  const largeGtinTotal = gtins >= 8;
  let noProductImage = noFootwearImage; // default to footwear image, in case of product info or division is missing

  if (division === 'Apparel' && image === '') {
    noProductImage = noApparelImage;
  }

  if (division === 'Footwear' && image === '') {
    noProductImage = noFootwearImage;
  }

  if (division === 'Equipment' && image === '') {
    noProductImage = noEquipmentImage;
  }

  return (
    <div key={keyProp} className="product-image-card-container">
      <Card style={{ width: largeGtinTotal ? '200px' : '300px' }}>
        <CardContent
          className="product-image-card"
          style={{
            flexDirection: largeGtinTotal ? 'column' : 'row',
            marginRight: largeGtinTotal ? '0' : '20px',
            marginTop: largeGtinTotal ? '20px' : '0',
          }}
        >
          <img
            src={image === '' ? noProductImage : image}
            alt={keyProp}
            className="product-image-card-image"
            style={{
              margin: largeGtinTotal ? '0' : '0 20px',
              maxWidth: largeGtinTotal ? '80%' : '50%',
              maxHeight: largeGtinTotal ? '' : '50%',
              padding: largeGtinTotal ? '0' : '10px',
            }}
          />
          <div className="product-image-card-text-container" style={{ textAlign: largeGtinTotal ? 'center' : 'left' }}>
            <p>{!gender ? '' : gender}</p>
            <p>{!division ? '' : division}</p>
            <p>{!category ? '' : category}</p>
            <div className="variance-product-bins">{bins}</div>
            <p><strong>{price ? `${price}` : getMessage('noPrice')}</strong></p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

ScanProductImageCard.propTypes = {
  keyProp: string,
  gender: string,
  division: string,
  category: string,
  binInfo: arrayOf(string),
  price: string,
  gtins: number,
  image: string,
};

export default ScanProductImageCard;
