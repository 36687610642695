import { useMemo } from 'react';
import { func, object, string } from 'prop-types';
import {
  Table, TableHead, TableContainer, Paper, TableRow, TableCell, TableBody,
} from '@mui/material';
import getFormattedPrice from '../../../utils/getFormattedPrice';

import './ExpandableRows.css';

const ExpandableRows = ({
  data = {},
  locale = 'en-US',
  currencyCode = 'USD',
  getMessage,
}) => {
  const tableHeaders = useMemo(() => (
    <TableHead>
      <TableRow>
        <TableCell>{getMessage('type')}</TableCell>
        <TableCell>{`${getMessage('style')}\xA0\xA0\xA0${getMessage('color')}`}</TableCell>
        <TableCell className="left wide">{getMessage('class')}</TableCell>
        <TableCell className="left wide">{getMessage('description')}</TableCell>
        <TableCell className="left tight">{getMessage('size')}</TableCell>
        <TableCell>{getMessage('upc')}</TableCell>
        <TableCell>{getMessage('units')}</TableCell>
        <TableCell>{getMessage('totalPrice')}</TableCell>
      </TableRow>
    </TableHead>
  ), [getMessage]);

  const tableRows = useMemo(() => {
    const rows = [];

    if (data && data.tableData) {
      data.tableData?.map((rowData, index) => {
        rows.push(
          <TableRow key={`${rowData.key}-${index}`}>
            <TableCell>{rowData.type}</TableCell>
            <TableCell>{rowData.styleColor}</TableCell>
            <TableCell className="left wide">{rowData.class}</TableCell>
            <TableCell className="left wide">{rowData.description}</TableCell>
            <TableCell className="left tight">{rowData.size}</TableCell>
            <TableCell>{rowData.upc}</TableCell>
            <TableCell
              className={`${rowData.units < 0 ? 'red' : ''}`}
              data-testid={`${rowData.key}-${index}-units`}
            >
              {Math.abs(rowData.units)}
            </TableCell>
            <TableCell
              className={`price ${rowData.price < 0 ? 'red' : ''}`}
              data-testid={`${rowData.key}-${index}-price`}
            >
              {getFormattedPrice(rowData.price, locale, currencyCode)}
            </TableCell>
          </TableRow>,
        );
      });

      rows.push(
        <TableRow key={`${data?.adjustmentId}-total`} className="total-row">
          <TableCell className="total">{getMessage('total')}</TableCell>
          <TableCell colSpan={5} />
          <TableCell
            className={`${data.totalUnits < 0 ? 'red' : 'green'}`}
            data-testid={`${data?.adjustmentId}-totalUnits`}
          >
            <strong>{Math.abs(data.totalUnits)}</strong>
          </TableCell>
          <TableCell
            className={`price ${data.totalPrice < 0 ? 'red' : 'green'}`}
            data-testid={`${data?.adjustmentId}-totalPrice`}
          >
            <strong>{getFormattedPrice(data.totalPrice, locale, currencyCode)}</strong>
          </TableCell>
        </TableRow>,
      );
    }

    return rows;
  });

  return (
    <div className="adjustments-dropdown-table dropdown-table-detail" data-testid="adjustments-dropdown-table">
      <TableContainer component={Paper}>
        <Table>
          {tableHeaders}
          <TableBody>
            {tableRows}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

ExpandableRows.propTypes = {
  data: object,
  locale: string,
  currencyCode: string,
  getMessage: func.isRequired,
};

export default ExpandableRows;
