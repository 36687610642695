import {
  useEffect, useState, createContext, useContext,
} from 'react';

import { node, object } from 'prop-types';
import { SimWebContext } from '../../context/SimWeb.provider';
import { getTasksByAthlete, getAthleteIdFromString } from './storePerformance.utils';
import { fetchStorePerformanceData } from './storePerformance.axios';
import { isOutsideDateRange } from '../../utils/isOutsideDateRange';

export const StorePerformanceContext = createContext({});

/**
  * An StorePerformance Provider that handles StorePerformance data
  *
  * @param {node} children - data that we iterate on and create data visuals
  * @param {object} mockedValue - mock values for testing
*/
const StorePerformanceProvider = ({ children = {}, mockedValue = null }) => {
  const { Provider } = StorePerformanceContext;
  const { storeId, appId, storeConfig, SimDateTime } = useContext(SimWebContext);

  const [storePerformance, setStorePerformance] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(SimDateTime.startOfDay());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay());

  const taskService = storeConfig?.tasksVersion?.value;
  const isOutsideRange = date => isOutsideDateRange(date, SimDateTime.startOfDay().minus({ months: 1 }), SimDateTime.endOfDay());

  useEffect(() => {
    const fetchStorePerformance = async () => {
      setStorePerformance([]);
      setIsFetching(true);
      setError(null);

      let fetchedPerformance = [];

      try {
        fetchedPerformance = await fetchStorePerformanceData(storeId, startDate, endDate, appId, taskService);
        if (fetchedPerformance?.length > 0) {
          const athleteList = [...new Set(fetchedPerformance.map(task => getAthleteIdFromString(task?.assignedAthleteId)))];
          const sortedAthletePerformanceData = athleteList?.map(athlete => ({
            athleteId: athlete,
            tasks: getTasksByAthlete(fetchedPerformance, athlete, taskService),
          }));
          setStorePerformance(sortedAthletePerformanceData);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setIsFetching(false);
      }
    };

    if (storeId && startDate && endDate) {
      fetchStorePerformance();
    }
  }, [storeId, startDate, endDate]);

  const onGoClick = (options) => {
    const { startDate, endDate } = options;
    setStartDate(SimDateTime.toDateTime(startDate));
    setEndDate(SimDateTime.toDateTime(endDate));
  };

  return (
    <Provider
      value={mockedValue ?? {
        storePerformance,
        startDate,
        endDate,
        error,
        isFetching,
        isOutsideRange,
        onGoClick,
      }}
    >
      {children}
    </Provider>
  );
};

StorePerformanceProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default StorePerformanceProvider;
