import {
  useState, createContext, useMemo, useEffect,
} from 'react';
import { object, node } from 'prop-types';
import {
  NotFoundException, BrowserMultiFormatReader, BarcodeFormat, DecodeHintType,
} from '@zxing/library';

export const BarcodeScannerContext = createContext({});
export const formats = [
  BarcodeFormat.CODE_128,
  BarcodeFormat.UPC_A,
];

/**
  * A Login Provider that handles login tokens and properites
  * @param {node} children - data that we iterate
  * @param {object}  - mockedValue context object
*/
const BarcodeScannerProvider = ({ children = null, mockedValue = null }) => {
  const { Provider } = BarcodeScannerContext;

  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [scanList, setScanList] = useState([]);
  const [code, setCode] = useState('');
  const [selectedSource, setSelectedSource] = useState(null);
  const [barcodeReader, setBarcodeReader] = useState(null);

  const setupDevices = (videoInputDevices, sourceSelect) => {
    setSelectedSource(sourceSelect);
    setSelectedDeviceId(videoInputDevices?.[0]?.deviceId);

    if (videoInputDevices?.length > 0) {
      setVideoInputDevices(videoInputDevices);
    }
  };

  const decodeContinuously = (multiScanEnabled, selectedDeviceId, updateSearchParam, setIsBarcodeOpen) => {
    barcodeReader?.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
      if (result) {
        setCode(result.text);
        if (!multiScanEnabled) {
          updateSearchParam(result.text);
          setIsBarcodeOpen(false);
        }
      }
      if (err && !(err instanceof NotFoundException)) {
        document.getElementById('result').textContent = err;
      }
    });
  };

  useEffect(() => {
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    const barcodeReader = new BrowserMultiFormatReader(hints);
    setBarcodeReader(barcodeReader);

    return () => {
      barcodeReader?.reset();
    };
  }, []);

  const contextValue = useMemo(() => ({
    barcodeReader,
    videoInputDevices,
    selectedDeviceId,
    scanList,
    code,
    selectedSource,
    formats,
    setupDevices,
    decodeContinuously,
    setScanList,
    setSelectedDeviceId,
  }), [barcodeReader, videoInputDevices, selectedDeviceId, scanList, code, selectedSource]);

  return (
    <Provider value={mockedValue ?? contextValue}>
      {children}
    </Provider>
  );
};

BarcodeScannerProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default BarcodeScannerProvider;
