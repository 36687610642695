import {
  useState, useContext, useRef, useMemo, useEffect,
} from 'react';
import { object, string } from 'prop-types';
import { withStyles } from '@mui/styles';
import { Dialog, TextField } from '@mui/material';
import getItemInputType, { inputTypes } from '../../utils/parseItemInput';
import Button from '../../components/Button';
import useAuthorizedToView from '../../hooks/useAuthorizedToView';
import ProductDetailContainer from '../../components/productDetails/ProductDetailContainer';
import ShoeboxFragment from '../../components/ShoeboxFragment';
import BarcodeScannerButton from '../../components/barcodeScanner/BarcodeScannerButton';
import BarcodeScanner from '../../components/barcodeScanner/BarcodeScanner';
import Tutorial from '../../components/tutorial/Tutorial';

import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../../components/tutorial/Tutorial.provider';
import { CPAContext } from '../../context/cpa/CPA.provider';

import './ItemInquiryPage.css';

const styles = ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: '8px',
    marginRight: '8px',
    '& .Mui-focused legend': {
      minWidth: '135px',
    },
  },
  menu: {
    width: 200,
  },
});

const ItemInquiryPage = ({ classes, itemInquirySearchValue = '' }) => {
  const inputRef = useRef();
  const { getProducts } = useContext(CPAContext);
  const { isMobile, getMessage, storeConfig } = useContext(SimWebContext);
  const { stepIndex, startTutorial, handleStartTutorial } = useContext(tutorialContext);

  const title = getMessage('itemInquiry');
  useAuthorizedToView(title, 'isItemInquiryEnabled');

  const [searchParam, setSearchParam] = useState('');
  const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const isBarcodeScannerEnabled = storeConfig?.isItemInquiryBarcodeScannerEnabled?.value;

  const onGoClick = () => {
    const value = inputRef?.current?.value || itemInquirySearchValue;
    if (value) {
      setSearchParam(value);
      inputRef.current.blur();
      if (!startTutorial) getProducts(value);
    }
  };

  const updateSearchParam = (val) => {
    if (val !== '') {
      setIsBarcodeOpen(false);
      setSearchValue(val);
      onGoClick();
    }
  };

  useEffect(() => {
    if (itemInquirySearchValue) {
      setSearchValue(itemInquirySearchValue);
      onGoClick();
    }
  }, [itemInquirySearchValue]);

  const onEnterPressed = (e) => {
    if (e.charCode === 13) {
      onGoClick();
    }
  };

  const buttonElement = useMemo(() => (
    <Button
      data-testid="inquiryGoButton"
      buttonText={getMessage('go')}
      onClick={onGoClick}
    />
  ), [itemInquirySearchValue, inputRef]);

  const getResultsFragment = useMemo(() => {
    const itemInputType = getItemInputType(searchParam);
    if (stepIndex === 2) onGoClick(); // for tutorial
    if (!startTutorial && stepIndex === 0 && (searchValue === '' || searchValue !== searchParam)) {
      setSearchParam(''); // clear search val after tutorial
    }

    switch (itemInputType) {
      case inputTypes.GTIN:
      case inputTypes.STYLE_COLOR:
      case inputTypes.STYLE:
        return (
          <ProductDetailContainer styleColor={searchParam} optionalColumns={['dotcomAvailability']} />
        );
      case inputTypes.NONE:
      case inputTypes.INVALID:
      default:
        return <ShoeboxFragment label={getMessage('searchStyleColorUpc')} />;
    }
  }, [searchParam, startTutorial, stepIndex, searchValue]);

  return (
    <div className="item-inquiry-page flex-column">
      <div className="header flex-row">
        <div className="title-block">
          <div className="feature-title">{title}</div>
          <Tutorial setTutorialState={handleStartTutorial} />
        </div>
        <div className="form flex-row">
          <TextField
            inputRef={inputRef}
            data-testid="styleInput"
            label={getMessage('styleColorOrUpc')}
            className={classes.textField}
            margin="normal"
            autoFocus
            fullWidth
            onKeyPress={e => onEnterPressed(e)}
            onChange={e => {
              setSearchValue(e?.target?.value);
            }}
            value={startTutorial ? 'DR1924-394' : searchValue}
            style={{
              marginRight: '20px',
            }}
          />
          {buttonElement}
          <div />
          {isBarcodeScannerEnabled && <BarcodeScannerButton setIsBarcodeOpen={setIsBarcodeOpen} />}
        </div>
      </div>
      <div className="content flex-column">
        <div className="results flex-column">{getResultsFragment}</div>
      </div>
      {isBarcodeScannerEnabled && (
        <Dialog
          data-testid="inquiryWidgetbarcodeScanner"
          open={isBarcodeOpen}
          onClose={() => {
            setIsBarcodeOpen(false);
            setSearchValue('');
          }}
          fullScreen={isMobile}
          maxWidth="lg"
        >
          <BarcodeScanner
            updateSearchParam={updateSearchParam}
            setIsBarcodeOpen={setIsBarcodeOpen}
          />
        </Dialog>
      )}
    </div>
  );
};

ItemInquiryPage.propTypes = {
  classes: object.isRequired,
  itemInquirySearchValue: string,
};

export default (withStyles(styles)(ItemInquiryPage));
