// Local Storage Keys
export const COUNTRY = 'COUNTRY';
export const STORE_NUMBER = 'STORE_NUMBER';
export const STORE_ID = 'STORE_ID';
export const LANGUAGE = 'LANGUAGE';

// Session Storage Keys
export const AUTH_TYPE = 'AUTH_TYPE';
export const SERVER_REGION = 'SERVER_REGION';
export const APP_ID = 'APP_ID';
export const LOGIN_SOURCE = 'LOGIN_SOURCE';
export const CREDENTIALS_SHARING = 'CREDENTIALS_SHARING';

// Session Storage Source Types
export const IOS = 'IOS';
export const TRUE = 'TRUE';
export const IOS_BROWSER = 'IOS_BROWSER';
export const BROWSER = 'BROWSER';

// Session Storage Keys for AUTH types
export const LEGACY = 'LEGACY';
export const RETAIL = 'RETAIL';
export const LAUNCHPAD = 'LAUNCHPAD';
export const IOS_WRAPPER = 'IOS_WRAPPER';

// Session Storage Keys for TRUE/Retail Launchpad V2
export const TRUE_PARENT_URL = 'TRUE_PARENT_URL';

// Session Storage Auth Keys
export const OPERATOR_ID = 'OPERATOR_ID';
export const ROLES = 'ROLES';
export const GIVEN_NAME = 'GIVEN_NAME';
export const FAMILY_NAME = 'FAMILY_NAME';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const SESSION_EXPIRES_ON = 'SESSION_EXPIRES_ON';
export const IS_ADMIN = 'IS_ADMIN';

// Session Storage for device size
export const DEVICE_SIZE = 'DEVICE_SIZE';
export const SM = 'SM';
export const LG = 'LG';
export const SHOW_TOAST = 'SHOW_TOAST';
