import sortBy from 'lodash/sortBy';;

export const fohRolesEnum = {
  GeneralFOH: 10,
  Cashier: 20,
  CustomerService: 30,
  Admin: 40,
  AssistantManagerFOH: 50,
  ManagerFOH: 60,
  SupportFOH: 99,
}

export const bohRolesEnum = {
  GeneralBOH: 10,
  ShippingReceivingAssociate: 20,
  InventoryAdjuster: 30,
  KeyHolder: 40,
  AssistantManagerBOH: 50,
  ManagerBOH: 60,
  SupportBOH: 99,
}

const mapRolesToEnum = (roles, enumerator) => {
  const unsorted = roles.map(roleStr => {
    const roleVal = enumerator[roleStr] || null;

    if (!roleVal) {
      return null;
    }

    return { name: roleStr, value: roleVal };
  }).filter(Boolean);

  return sortBy(unsorted, ['value']);
};

export const parseUserRoles = (roles) => {
  if (!roles || !roles.map || typeof roles !== 'object') { return null; }
  const fohRoles = mapRolesToEnum(roles, fohRolesEnum);
  const bohRoles = mapRolesToEnum(roles, bohRolesEnum);
  const highestBOHRole = bohRoles.slice(-1)[0];
  const highestFOHRole = fohRoles.slice(-1)[0];
  const bohMaxValue = highestBOHRole ? highestBOHRole.value : 0;
  const fohMaxValue = highestFOHRole ? highestFOHRole.value : 0;
  const allRoles = [...fohRoles, ...bohRoles];

  return {
    bohRoles,
    fohRoles,
    bohMaxValue,
    fohMaxValue,
    allRoles,
    maxValue: Math.max(bohMaxValue, fohMaxValue),
  };
};
