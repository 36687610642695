import {
  useContext, useMemo, useState,
} from 'react';
import { Dialog } from '@mui/material';
import ProductRefillHeader from './ProductRefillHeader';
import ProductRefillList from './ProductRefillList';
import ConfirmationModal from '../../components/confirmationModal/ConfirmationModal';

import { SimWebContext } from '../../context/SimWeb.provider';
import { ProductRefillContext } from './ProductRefill.provider';
import { markAllTasksAsFilled } from './productRefill.axios';
import useAuth from '../../hooks/useAuth';

export const ProductRefillPage = () => {
  const {
    getMessage, storeConfig, country, storeNumber, store,
  } = useContext(SimWebContext);
  const { tasks, setRefetchTasks } = useContext(ProductRefillContext);
  const { getUserInfo } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const taskService = storeConfig?.tasksVersion?.value;
  const user = getUserInfo();
  const submitAllTasksAsFilled = () => {
    const athlete = `${country};${storeNumber};${user.operatorID}`;

    setIsLoading(true);
    markAllTasksAsFilled(tasks, athlete, { appId: 'simweb' }, taskService, store);
    setRefetchTasks(true);
    setIsLoading(false);
    setIsOpen(false);
  };

  const productHeader = useMemo(() => (
    <ProductRefillHeader
      setOpenModal={setIsOpen}
      disableMarkAllAsFilled={user?.roles?.maxValue < 40}
    />
  ), [user?.roles]);

  return (
    <div className="product-refill" data-testid="productRefill">
      {productHeader}
      <ProductRefillList />
      <Dialog
        open={isOpen}
        fullScreen={false}
        maxWidth="sm"
        fullWidth
      >
        <ConfirmationModal
          dataTestId="markAllAsFilled-confirmation-modal"
          isLoading={isLoading}
          onClick={submitAllTasksAsFilled}
          message={getMessage('confirmationMarkAllAsFilled')}
          setIsOpen={setIsOpen}
        />
      </Dialog>
    </div>
  );
};

export default ProductRefillPage;
