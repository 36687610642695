export const createStep = (props) => {
  const {
    stepId, title, content, target, spotlightClicks, spotlightPadding, addZindex, placement, config,
  } = props;

  const stepdef = {
    step: stepId,
    title,
    content,
    target,
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    placement: placement ?? 'auto',
    event: 'click',
    spotlightClicks: spotlightClicks ?? true,
    spotlightPadding: spotlightPadding ?? 0,
    config: config ?? false,
  };

  return !addZindex ? stepdef : {
    ...stepdef,
    styles: {
      overlay: {
        zIndex: 20001,
      },
      options: {
        zIndex: 20001,
      },
    },
  };
};
