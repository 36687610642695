import { isEmpty } from 'lodash';

/**
 * A helper function to get the translated string for store area dropdown options
 * @param {object} options the storeArea destination dropdown options
 * @param {function} getMessage translations helper func
 */
export const getTranslatedStoreAreaOptions = (options, getMessage) => {
  if (isEmpty(options)) return [];

  return options.map(option => {
    if (option.label === 'STOCKROOM') return { ...option, label: getMessage('stockRoom') };
    if (option.label === 'OFFSITE') return { ...option, label: getMessage('offsite') };
    return option;
  });
};
