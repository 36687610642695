import  {
  useState, createContext, useMemo, useContext, useCallback, useEffect,
} from 'react';
import { node, object } from 'prop-types';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { stepMap } from './stepConstants';
import { newRelicAction } from '../../utils/newRelicPageActions';

import { StoreConfigContext } from '../../context/StoreConfig.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import { AdminContext } from '../../context/Admin.provider';
import { TranslationsContext } from '../../context/Translations.provider';
import { fetchTranslations } from '../../axios/fetchTranslations';
import Tutorial from './Tutorial';
import { isEmpty } from 'lodash';

import customActions from './customActions';

export const tutorialContext = createContext({});

/**
  * @param {node} children - data that we iterate on and create data visuals
  * @param {object} mockedValue - mock values for testing
*/
const TutorialProvider = ({ children = {}, mockedValue = null }) => {
  const screenSize = sessionStorage.getItem('deviceSize') === 'sm';

  const { Provider } = tutorialContext;

  const { storeConfig } = useContext(StoreConfigContext);
  const { isAdmin } = useContext(AdminContext);
  const { storeNumber, country, isTutorialEnabled, activateTutorials } = useContext(SimWebContext);
  const { getMessage, translations } = useContext(TranslationsContext);

  const [stepIndex, setStepIndex] = useState(0);
  const [startTutorial, setTutorialState] = useState(false);
  const [loadingTutorial, setTutorialLoading] = useState(false);
  const [isbackClicked, setBackWasClicked] = useState(false);
  const [restartTutorial, setTutorialRestart] = useState(false);
  const [translatedSteps, setTranslatedSteps] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [durationTime, setDurationTime] = useState(null);
  const [dashboardWidget, setDashboardWidget] = useState(null);
  const [page, setPage] = useState(window?.location?.href?.split('/')?.[3]);
  const [customSteps, setCustomSteps] = useState(null); // New state if customizing steps further
  
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const isDropZonesEnabled = storeConfig?.isDropzonesEnabled?.value;
  const isSizeComplianceRefillsEnabled = storeConfig?.isSizeComplianceRefillsEnabled?.value;
  const isStyleManagementTutorialEnabled = isAdmin || storeConfig?.isStyleManagementTutorialEnabled?.value;

  const setTimeOutOnTutorial = (step, time) => {
    setTutorialLoading(true);
    setTutorialState(false);

    setTimeout(() => {
      setTutorialLoading(false);
      setTutorialState(true);
      if (step && startTutorial) setStepIndex(step); // TODO - find a solution for this
    }, time ?? 200);
  };

  const handleJoyrideCallback = useCallback((data) => {
    try {
      const {
        action, status, type, index,
      } = data;

       // Restart the tutorial if the page changes unless were looking 
       // at the scan report where we have routing in place
      if (page !== window?.location?.href?.split('/')?.[3] && page !== 'scanSummary') {
        setPage(window?.location?.href?.split('/')?.[3]);
        setStepIndex(0);
        setTutorialState(false);
        setTutorialRestart(true);
        return;
      }

      // Execute custom actions for specific reports and steps - this allows the tool tip to 
      // be placed in the correct position
      if (customActions[page] && customActions[page][stepIndex]) {
        if (page === 'inboundVisibility') {
          customActions[page][stepIndex](isbackClicked, isDropZonesEnabled);
        } else {
          customActions[page][stepIndex](isbackClicked);
        }
      }
  
      if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
        // Need to set our running state to false, so we can restart if we click start again.
        setStepIndex(0);
        setTutorialState(true);
      }

      if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
        setStepIndex(stepIndex + (action === ACTIONS.PREV ? -1 : 1));
        const timeDiff = new Date().getTime() - startTime;
        setDurationTime(Math.floor(timeDiff / 1000 % 60));
      }

      if (([EVENTS.TOUR_END]).includes(type)) {
        setTutorialRestart(true);
        setStepIndex(0);
        setTutorialState(false);
        // This is added specifically since when restarting the tutorial it 
        // will always have restart as true causing issues placing the tooltip 
        // for the first step due to the delay
        setTimeout(() => {
          setTutorialRestart(false);
        }, 1000);
      }

      if (([EVENTS.TOUR_STATUS]).includes(type) && startTutorial) {
        setTutorialRestart(false);
      }

      if ( (([EVENTS.STEP_AFTER]).includes(type) && ([ACTIONS.PREV]).includes(action))) {
        setBackWasClicked(true);
      }

      if (([EVENTS.STEP_AFTER]).includes(type) && ([ACTIONS.NEXT]).includes(action) && isbackClicked) {
        setBackWasClicked(false);
      }

      if (([EVENTS.TARGET_NOT_FOUND]).includes(type)) {
        setTimeOutOnTutorial(index); // wait for mounting
      }
      
    } catch (error) {
      console.error('Error in Joyride callback:', error);
    }
  }, [restartTutorial, isbackClicked, stepIndex, startTutorial, activateTutorials, startTime, page, isDropZonesEnabled]);

  const steps = useMemo(() => {
    if (customSteps) return customSteps; // Use custom steps if provided
    if (!page || !stepMap?.get(page)) return [];

    const getFilteredSteps = page && stepMap?.get(page)?.getFilteredSteps;

    if (translatedSteps) {
      if (!getFilteredSteps) return translatedSteps;

      const pageHandlers = {
        adjustments: () => {
          const printOptions = (country === 'RUS' || country === 'GRC') ?? false;
          return getFilteredSteps(translatedSteps, printOptions);
        },
        dashboard: () => getFilteredSteps(dashboardWidget, translatedSteps, rfidEnabled),
        itemInquiry: () => getFilteredSteps(screenSize, rfidEnabled, translatedSteps),
        inboundVisibility: () => getFilteredSteps(isDropZonesEnabled, translatedSteps),
        scanSummary: () => getFilteredSteps(translatedSteps),
        scan: () => getFilteredSteps(translatedSteps),
        styleManagement: () => getFilteredSteps(rfidEnabled, translatedSteps),
        sizeCompliance: () => getFilteredSteps(isSizeComplianceRefillsEnabled, translatedSteps),
        transfers: () => getFilteredSteps(country, translatedSteps),
        priceChanges: () => getFilteredSteps(true, setCustomSteps, translatedSteps),
      };
      
      return pageHandlers[page] ? pageHandlers[page]() : translatedSteps;
    }
  }, [sessionStorage.getItem('deviceSize'), stepMap, rfidEnabled, isDropZonesEnabled, page, translatedSteps, dashboardWidget, isSizeComplianceRefillsEnabled]);

  const handleStartTutorial = () => {
    setTutorialState(!startTutorial);
  };

  // // adding translations to steps
  useEffect(() => {
    // Fetch translations if they are not available
    const translateStrings = () => {
      const newArr = [];
      const steps = page && stepMap?.get(page)?.steps;
      const clone = steps && [...steps];
      clone?.map(step => {
        const newStep = { ...step };
        newStep.content = getMessage(step?.content);
        newStep.title = getMessage(step?.title);
        if (typeof newStep.target === 'function') {
          newStep.target = newStep.target();
        }
        return newArr.push(newStep);
      });
      setTranslatedSteps(newArr);
    };

    if (!isEmpty(translations) && page) {
      translateStrings();
    }

    if (isEmpty(translations) && page) {
      fetchTranslations();
    }
  }, [getMessage, page, translations, stepMap]);

  useEffect(() => {
    const actions = page && stepMap?.get(page)?.actions;
    if (actions) actions(startTutorial, stepIndex, restartTutorial, isbackClicked, setStepIndex);
  }, [stepIndex, startTutorial, isbackClicked, restartTutorial, stepMap, page]);

  useEffect(() => {
    if ((activateTutorials || isStyleManagementTutorialEnabled) && startTutorial) {
      setStartTime(new Date().getTime());
      newRelicAction(
        'sim-report-tutorial',
        {
          report: page,
          screenSize: screenSize ? 'small' : 'large',
          duration: durationTime,
          step: steps?.[stepIndex]?.content,
          stepIndex,
          store: `${country}:${storeNumber}`,
        },
      );
    }
  }, [activateTutorials, isStyleManagementTutorialEnabled, stepIndex, screenSize, steps, startTutorial, durationTime, page, storeNumber, country]);

  return (
    <Provider
      value={mockedValue ?? {
        activateTutorials,
        setCustomSteps,
        stepIndex,
        setStepIndex,
        handleJoyrideCallback,
        steps,
        startTutorial,
        handleStartTutorial,
        loadingTutorial,
        setTutorialLoading,
        isbackClicked,
        setBackWasClicked,
        isTutorialEnabled,
        setTimeOutOnTutorial,
        setTutorialRestart,
        restartTutorial,
        tutorialReport: page,
        page,
        setPage,
        dashboardWidget,
        setDashboardWidget,
        isStyleManagementTutorialEnabled,
        tutorial: (isTutorialEnabled, activateTutorials) && <Tutorial setTutorialState={handleStartTutorial} activateTutorials={activateTutorials} />,
        getTutorial: (disabled, widgetName) => isTutorialEnabled && <Tutorial setTutorialState={handleStartTutorial} disabled={disabled} widgetName={widgetName} />,
      }}
    >
      {children}
    </Provider>
  );
};

TutorialProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default TutorialProvider;
