import { useContext } from 'react';
import { TranslationsContext } from '../../../context/Translations.provider';

/**
 * The Tooltip content component for Inbound Visibility's facet aggregation
 */
const TooltipContent = () => {
  const { getMessage } = useContext(TranslationsContext);

  return (
    <div data-testid="inbound-visibility-tooltip-content">
      <p className="tooltip-content-po-numbers">
        {`${getMessage('purchaseOrdersDescription')}`}
      </p>
      <p className="tooltip-content-cartons">
        {`${getMessage('cartonsDescription')}`}
      </p>
      <p className="tooltip-content-units">
        {`${getMessage('unitsDescription')}`}
      </p>
    </div>
  );
};

export default TooltipContent;
