import { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

import { SimWebContext } from '../../context/SimWeb.provider';
import { AdminContext } from '../../context/Admin.provider';

const SeamlessLoginNotification = () => {
  const {
    getMessage, storeName, country, storeNumber,
  } = useContext(SimWebContext);
  const { isAdmin } = useContext(AdminContext);

  const showToast = sessionStorage.getItem('showToast')?.toLocaleLowerCase() === 'true';
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeToast = (snackbarId) => {
    sessionStorage.setItem('showToast', false);
    closeSnackbar(snackbarId);
  };

  const action = snackbarId => (
    <div>
      <Button
        data-testid="seamless-login-notification-close-button"
        color="inherit"
        size="small"
        onClick={() => closeToast(snackbarId)}
      >
        {getMessage('close')}
      </Button>
    </div>
  );

  if (isAdmin && showToast && storeName) {
    enqueueSnackbar(`You are now logged into ${country}-${storeNumber}, ${storeName}.`, { variant: 'warning', persist: true, action });
  }
};

export default SeamlessLoginNotification;
