import { useMemo, useContext } from 'react';
import {
  array, bool, func, object, shape, string, oneOfType,
} from 'prop-types';
import VisualSummariesContainer from '../../components/visualSummaries/VisualSummariesContainer';
import SummaryWidget from '../../components/visualSummaries/summaryWidget/SummaryWidget';
import InfoTooltip from '../../components/InfoTooltip';
import DatePickerComponent from './components/BaseDatePicker';
import ReportDetails from './components/ReportDetails';
import PageContent from './components/BasePageContent';
import useAuthorizedToView from '../../hooks/useAuthorizedToView';
import BaseViewButtons from './buttons/BaseViewButtons';

import { SimWebContext } from '../../context/SimWeb.provider';
import { useTutorial } from '../../components/tutorial/useTutorial';

import './BaseViewPage.css';
import { TranslationsContext } from '../../context/Translations.provider';

/**
  * BasePageView this base view has the structure to build most of the reports needed
  * @param {object} buttons object containing configs(a list of button configs), setButtonfilters functionand an optional customButtonSelected function
  * @param {object} details object containing MUI Table options, tableTitle, rows, columns, theme
  * @param {object} dialog datarow dialog modal
  * @param {object} dropdown object containing dropdown options and handleDropdownChange function
  * @param {object} datepicker object containing startDate and endDate states and handleDateChange function
  * @param {object} error object defining the current error state
  * @param {boolean} hasData function returns a boolean indicating if there is data
  * @param {string} id the name of the pageView, ie, styleManagement
  * @param {boolean} loading is the data still loading for the page
  * @param {object} tabs object containing MUI Tabs
  * @param {string} title bodega sting title
  * @param {object} visuals object containg chart and legend objects
  * @param {object} summary object containing title and items for the Summary widget
  */
const BaseViewPage = ({
  id,
  title = null,
  pageInfo = null,
  loading = false,
  dropdown = null,
  datepicker = null,
  visuals = null,
  details = null,
  error = null,
  dialog = null,
  buttons = null,
  summary = null,
  tabs = null,
  pageContents = null,
  customSummaryContainer = null,
  storeConfigValue = null,
  minRole = null,
}) => {
  useAuthorizedToView(title, storeConfigValue, minRole);
  const { locale } = useContext(SimWebContext);
  const { getMessage, translationsFetched } = useContext(TranslationsContext);
  const { getTutorial } = useTutorial(id);

  const tutorial = useMemo(() => getTutorial(!details?.rows?.length || loading), [details, loading]);

  const summaryWidget = useMemo(() => {
    if (!loading && summary) {
      const {
        objects, title, subMessage, isLive,
      } = summary;
      return (
        <div className={`${id}-totals-table`}>
          <SummaryWidget
            loading={loading}
            data={objects}
            locale={locale}
            title={title}
            subMessage={subMessage}
            isLive={isLive}
          />
        </div>
      );
    }

    return null;
  }, [loading, summary]);

  return translationsFetched ? (
    <div data-testid={id} className="report">
      <div className="title-block flex-row">
        <div className="feature-title">{title && getMessage(title)}</div>
        {pageInfo && (
          <div data-testid={`${id}-tooltip`} style={{ position: 'relative', paddingLeft: '10px', top: '-10px' }}>
            <InfoTooltip
              title={pageInfo?.title}
              content={pageInfo?.content}
              disableFocusListener
              disableTouchListener
              placement={pageInfo?.placement || 'bottom'}
            />
          </div>
        )}
        {dropdown && dropdown}
        <DatePickerComponent id={id} loading={loading} datepicker={datepicker} />
      </div>
      {tutorial}
      {tabs}
      <div className="header" data-testid={`${id}-header`}>
        {buttons && (
          <div className="header-left">
            <BaseViewButtons
              buttons={buttons}
              id={id}
              isLoading={loading}
            />
          </div>
        )}
        {customSummaryContainer ?? <VisualSummariesContainer chart={visuals} summaryWidget={summaryWidget} />}
      </div>
      <br />
      {pageContents ?? <PageContent id={id} loading={loading} error={error} reportDetails={<ReportDetails id={id} details={details} getMessage={getMessage} dialog={dialog} />} />}
    </div>
  ) : null;
};

BaseViewPage.propTypes = {
  id: string.isRequired,
  title: string,
  pageInfo: shape({
    title: string,
    content: object,
  }),
  loading: bool,
  error: object,
  buttons: shape({
    configs: array,
    customButtonSelected: func,
    setButtonFilters: func,
    info: shape({
      title: string,
      content: object,
      placement: string,
    }),
  }),
  visuals: shape({
    chart: object,
    legend: object,
  }),
  summary: shape({
    objects: array,
    title: string,
  }),
  customSummaryContainer: object,
  details: shape({
    title: oneOfType([
      string,
      object,
    ]),
    options: object,
    columns: array,
    theme: object,
  }),
  dropdown: shape({
    options: array,
    handleDropdownChange: func,
  }),
  datepicker: shape({
    startDate: oneOfType([
      string,
      object,
    ]),
    endDate: oneOfType([
      string,
      object,
    ]),
    handleDateChange: func,
  }),
  dialog: object,
  tabs: object,
  pageContents: object,
  storeConfigValue: string,
  minRole: string,
};

export default BaseViewPage;
