
import {
  string, number, func, oneOfType,
} from 'prop-types';

import './svgs.css';

/**
   * An SVG used in the Scan Report to help display the status of a
   * scan to the  users in the summary table
   * @param {string} className css styling string
   * @param {string | number} height - height of the SVG
   * @param {string | number} width - width of the SVG
   * @param {func} onClick - on click action
   */
export const Cancel = ({
  className = 'align-svg',
  height = '30px',
  width = '30px',
  onClick = null,
}) => {
  const svg = (
    <svg
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <g transform="translate(-5.0, 0.0)" fill="#333333">
        <g transform="translate(4.0, 0.0)">
          <polygon points="7.719 4.964 12.692 0.017 14.389 1.715 9.412 6.666 14.354 11.634 12.657 13.331 6.017 6.657 7.715 4.960" />
          <polygon points="7.612 4.964 7.616 4.960 9.313 6.657 2.674 13.331 0.977 11.634 5.919 6.666 0.942 1.715 2.639 0.017" />
        </g>
      </g>
    </svg>

  );

  return (
    <div className={className} onClick={onClick}>
      {svg}
    </div>
  );
};

export default Cancel;

Cancel.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  className: string,
  onClick: func,
};
