import { useHistory } from 'react-router-dom';
import useAuth from './useAuth';
import useSimStore from '../store/simStore';
import useTranslator from './useTranslator';

/**
 * Verifies whether or not a user has access to a page based on store configuration and their role.
 *
 * @param {string} title - The title of the component.
 * @param {string} storeConfigValue - The value used for store configuration.
 * @param {number} [minRole=0] - The minimum role required to access the page.
 * @returns {void} - redirects to the dashboard if unauthorized and shows an error message.
 */
const useAuthorizedToView = (title, storeConfigValue, minRole = 0) => {
  const isAdmin = useSimStore(state => state.isAdmin);

  if (isAdmin) return;

  const storeConfig = useSimStore(state => state.storeConfig);
  const history = useHistory();
  const addError = useSimStore(state => state.addError);
  const { formatMessage } = useTranslator();
  const { getUserInfo } = useAuth();

  const nikeStoreRoles = getUserInfo()?.roles;
  const isAuthorized = storeConfig?.[storeConfigValue]?.value && nikeStoreRoles?.maxValue >= minRole;

  if (!isAuthorized) {
    if (minRole) {
      addError(` Roles ${minRole} and above are required to view this page.`);
    } else {
      addError(formatMessage('unauthorizedToViewErrorMessage', { page: title }));
    }
    history.push('/dashboard');
  }
};

export default useAuthorizedToView;
