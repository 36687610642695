import { createContext, useMemo } from 'react';
import { node, object } from 'prop-types';
import geoMap from '../constants/geo-map.json';
import { newRelicAttribute } from '../utils/newRelicPageActions';
import useSimStore from '../store/simStore';
import { isEmpty } from 'lodash';

export const StoreViewContext = createContext({});

const StoreViewProvider = ({ children, mockedValue }) => {
  const { Provider } = StoreViewContext;

  const storeInfo = useSimStore(state => state.storeInfo);

  // used for new relic logging
  useMemo(() => {
    if (storeInfo) {
      newRelicAttribute('store-concept', storeInfo?.businessConcept);
      newRelicAttribute('store-name', storeInfo?.name);
      newRelicAttribute('store-number', storeInfo?.storeNumber);
      newRelicAttribute('store-city', storeInfo?.address?.city);
      newRelicAttribute('store-country', storeInfo?.address?.country);
      newRelicAttribute('store-region', storeInfo?.region);
      newRelicAttribute('store-district', storeInfo?.district?.code);
      newRelicAttribute('store-country-number', `${storeInfo?.address?.country}-${storeInfo?.storeNumber}`);
      newRelicAttribute('geo', geoMap[storeInfo?.region]);
    }
  }, [storeInfo]);

  return (
    <Provider
      value={
        mockedValue ?? {
          flag: storeInfo?.flag,
          store: storeInfo?.countryStoreNumber,
          country: storeInfo?.country,
          storeId: storeInfo?.id,
          storeNumber: storeInfo?.storeNumber,
          storeInfo,
          isStoreInfoLoaded: !isEmpty(storeInfo),
        }
      }
    >
      {children}
    </Provider>
  );
};

StoreViewProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default StoreViewProvider;
