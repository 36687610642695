import {
  string, number, bool, func,
} from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';

import './ProgressBar.css';

const ProgressBar = ({
  bgcolor = '#fa5400',
  completed = 0,
  dataTestId = 'progress-bar',
  showArrow = true,
  getMessage,
  formatMessage,
}) => {
  const containerStyles = {
    height: 'auto',
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 10,
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: (completed === 100) ? '#ABD648' : bgcolor,
    borderRadius: 10,
    textAlign: 'right',
    transition: 'width 1s ease-in-out',
  };

  const labelStyles = {
    padding: 5,
    color: 'var(--green-success)',
    fontWeight: 'bold',
    textAlign: 'center',
  };

  const ProgressToolTip = withStyles({
    arrow: {
      color: '#292929',
    },
    tooltip: {
      backgroundColor: '#292929',
      color: '#DEDEDE',
      accentColor: '#292929',
      fontWeight: 500,
      fontSize: '12px',
      border: '1px solid black',
      padding: '10px',
    },
  })(Tooltip);

  const toolTipTitle = `${formatMessage('scanProcessing', { percentage: completed })}% completed`;

  return (
    <div style={containerStyles} data-testid={dataTestId}>
      <ProgressToolTip className="scan-progress-tool-tip" data-testid="scan-progress-tooltip" title={toolTipTitle} placement="top" arrow={showArrow}>
        <div data-testid="scan-progress-bar" style={fillerStyles} />
        <h4 data-testid={`${dataTestId}-completed`} style={labelStyles}>
          {`${getMessage('scanInProgress').toUpperCase()}`}
        </h4>
      </ProgressToolTip>
    </div>
  );
};

ProgressBar.propTypes = {
  bgcolor: string,
  completed: number,
  dataTestId: string,
  showArrow: bool,
  getMessage: func.isRequired,
  formatMessage: func.isRequired,
};

export default ProgressBar;
