import jwtDecode from 'jwt-decode';
import { parseUserRoles } from './parseUserRoles';
import * as constants from '../constants/storageKeys.constants';
import { version } from '../../package.json';
import { logErrorAction, newRelicAction } from './newRelicPageActions';

/**
 * Decodes an access token to set user session values.
 * @param {string} token - access token returned from auth services
 * @param {boolean} setStoreValues - flag to set store values (should be for wrapper and launchpad only)
 */
export const setSessionValues = (token, setStoreValues) => {
  if (!token) return;

  const jwt = jwtDecode(token);
  const parsedRoles = parseUserRoles(jwt.nike_storeRoles);
  const operatorId = jwt.prn.split(';')[2];
  const loginSource = localStorage.getItem(constants.LOGIN_SOURCE);

  if (parsedRoles.maxValue < 20) {
    logErrorAction('simweb-unauthorized-user', { user: operatorId });
    throw new Error('unauthorizedError');
  }

  sessionStorage.setItem(constants.OPERATOR_ID, operatorId);
  sessionStorage.setItem(constants.ROLES, JSON.stringify(parsedRoles));
  sessionStorage.setItem(constants.GIVEN_NAME, jwt.given_name);
  sessionStorage.setItem(constants.FAMILY_NAME, jwt.family_name);

  sessionStorage.setItem(constants.ACCESS_TOKEN, token);
  sessionStorage.setItem(constants.SESSION_EXPIRES_ON, jwt.exp);

  if (setStoreValues) {
    const storeId = jwt.nike_storeId;
  
    localStorage.setItem(constants.COUNTRY, jwt.nike_country);
    localStorage.setItem(constants.STORE_NUMBER, jwt.nike_store);
    localStorage.setItem(constants.STORE_ID, storeId);
    
    // used by launchpad to see if the storeId has changed.
    window.dispatchEvent(new StorageEvent('storage', { key: constants.STORE_ID, storeId }));
  }

  // for anything not using a v1 token, the appId needs to match the jwt sub.
  if (loginSource === constants.IOS_WRAPPER || loginSource === constants.LAUNCHPAD) {
    sessionStorage.setItem(constants.APP_ID, jwt.sub)
  }

  newRelicAction('simweb-login', { version, user: operatorId, loginType: loginSource });
};
