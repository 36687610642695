import { string } from 'prop-types';

import './LoadingAnnimation.css';

/**
   * A loading component used in the Scan Report to help display the
   * Scanning status of scan to the users in the summary table
   * @param {string} label - optional value that can be rendered with the SVG
   */
 
export const LoadingAnnimation = ({
  label = null,
}) => {
  const loadingIndicator = (
    <div className="dot-flashing" data-testid="loading-annimation" data-title="dot-flashing" />
  );

  return label ? (
    <div className="loading-flex" data-testid="loading-annimation-with-label">
      {loadingIndicator}
      {` ${label}`}
    </div>
  ) : loadingIndicator;
};

LoadingAnnimation.propTypes = {
  label: string,
};

export default LoadingAnnimation;
