
import { string } from 'prop-types';

export const PriceTag = ({ extraClasses = '' }) => (
  <div className={`${extraClasses}`}>
    <svg aria-hidden="false" focusable="false" viewBox="0 0 24 24" role="img" width="24px" height="24px" fill="none" id="Tag-m-icon" xmlns="http://www.w3.org/2000/svg">
      <path stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5" d="M3.75 3.75h7.42l9.93 9.93c.86.86.86 2.25 0 3.11l-4.31 4.31c-.86.86-2.25.86-3.11 0l-9.93-9.93V3.75z" />
      <path stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5" d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" />
    </svg>
  </div>
);

PriceTag.propTypes = {
  extraClasses: string,
};
