import { camelCase, isEmpty } from 'lodash';
import {
  inboundVisibilityfilters, apparelDivisions, footwearDivisions, equipmentDivisions,
} from '../inboundVisibility.contants';
import { getCssColorString } from '../../../utils/getCssColorString';
/**
  * Helper function to create filters by division type
  * @param {array} divisionTypes - divisionTypes to filter the data
  * @param {object} filterOn - additonal filter ex- genderAge/retailCategory
*/
export const filterDivision = (divisionType, filterOn) => [{
  name: 'division',
  operator: 'EQUALS',
  values: [...divisionType],
}, filterOn,
];

/**
 * Returns filters for each divisions
 */
export const defaultFilters = {
  apparelFilters: filterDivision(apparelDivisions, inboundVisibilityfilters.genderAge),
  footwearFilters: filterDivision(footwearDivisions, inboundVisibilityfilters.genderAge),
  equipmentFilters: filterDivision(equipmentDivisions, inboundVisibilityfilters.genderAge),
};

/**
  * Creates a unique donut chart for a particular division
  * @param {object} data - data returned to iterate and formulate the graph
  * @param {array} divisionTypes - divisionTypes to filter the data
  * @param {string} title - unique title for the graph = 'Apparel'
  * @param {string} filterByName - value we are filtering on - ex) genderAge/retailCategory
  * @param {func} getMessage - translations helper func
*/
export const getGraphData = (data, divisionTypes, title, filterByName, getMessage) => {
  if (!data) return { title, totalUnitCount: 0, divisions: [], colors: [] };

  const shipmentDetails = data?.supportedFilters;
  const divisionDetails = shipmentDetails?.filter(details => details.name === 'division')[0]?.values;
  const aggregatedDetails = shipmentDetails?.filter(details => details.name === filterByName)[0]?.values;

  const totalUnitCount = divisionDetails?.filter(division => divisionTypes.includes(division.value))[0]?.aggregations?.filter(agg => agg.field === 'unitCount')[0]?.value;
  const divisonGradientColors = [];
  const divisionArray = [];

   
  aggregatedDetails && aggregatedDetails.map((agg) => {
    const unitCountValue = agg.aggregations?.filter(a => a.field === 'unitCount')[0]?.value;
    const cssColor = getCssColorString(agg?.value);

    const color = {
      start: getComputedStyle(document.documentElement).getPropertyValue(`--${filterByName}_${cssColor}`),
      end: getComputedStyle(document.documentElement).getPropertyValue(`--${filterByName}_${cssColor}2`),
    };
    const aggObj = {
      value: unitCountValue,
      label: getMessage(camelCase(agg.value)),
      colors: color,
      total: totalUnitCount,
    };

    divisonGradientColors.push(color);
    divisionArray.push(aggObj);
    return divisionArray;
  });

  return { title, totalUnitCount, divisions: divisionArray, colors: divisonGradientColors };
};

/**
 * Get the sum of the count by provided field name
 * @param {object} shipDateValues the shipDate supportedFilters object
 * @param {string} field the field name of the value to get - ex. unitCount, cartonCount and poNumberCount
 */
export const getSumValues = (shipDateValues, field) => {
  if (isEmpty(shipDateValues) || !field) return 0;

  const values = shipDateValues?.values?.map(value => value?.aggregations?.find(item => item.field === field)?.value ?? 0);
  return values && values.length > 0 ? values?.reduce((a, b) => a + b) : 0;
};

/**
 * Get the unit, carton and poNumber count object
 * @param {object} shipDateValues the shipDate supportedFilters object
 * @param {function} getMessage translation helper function
 */
export const getCountsObject = (shipDateValues, getMessage) => {
  const aggregatedData = { unitCount: 0, cartonCount: 0, poNumberCount: 0 };

  if (!isEmpty(shipDateValues)) {
    aggregatedData.unitCount = getSumValues(shipDateValues, 'unitCount');
    aggregatedData.cartonCount = getSumValues(shipDateValues, 'cartonCount');
    aggregatedData.poNumberCount = getSumValues(shipDateValues, 'poNumberCount');
  }

  return [
    {
      label: getMessage('totalPos'),
      name: 'totalPos',
      value: aggregatedData?.poNumberCount,
    },
    {
      label: getMessage('totalCartons'),
      name: 'totalCartons',
      value: aggregatedData?.cartonCount,
    },
    {
      label: getMessage('totalUnits'),
      name: 'totalUnits',
      value: aggregatedData?.unitCount,
    },
  ];
};
