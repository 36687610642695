import { useMemo } from 'react';
import {
  bool, func, number, object, string,
} from 'prop-types';
import { useTutorial } from '../../../../../components/tutorial/useTutorial';
import ProgressBar from '../../../../../components/progressBar/ProgressBarV2';
import { scanStatuses } from '../../../scanReportConstants';

import './ManagerApproval.css';

const ManagerApproval = ({
  accuracySubmissionThreshold = 0,
  approvalAction = '',
  data = null,
  formatMessage,
  getMessage,
  isScanNotProccessing = false,
  nikeStoreRoles = {},
  scanProgress,
  setApprovalAction,
  setOpenModal,
}) => {
  const {
    aggregations, resumeCount, status,
  } = data;

  const { startTutorial, stepIndex, isbackClicked } = useTutorial('scanReport');
  const accuracy = aggregations?.accuracy;
  const disableSubmitButton = resumeCount === 2 && accuracy < accuracySubmissionThreshold;
  const scanStatusLabel = scanStatuses.getTranslatedLabel(status, getMessage);
  const showButtons = nikeStoreRoles?.maxValue >= 40 && (isScanNotProccessing || (status === 'IN_REVIEW' && scanProgress >= 100));

  const onClick = (action) => {
    setApprovalAction(action);
    setOpenModal(true);
  };

  const progressBar = useMemo(() => {
    if ((status !== 'IN_REVIEW' && status !== 'SCANNING') || (status === 'IN_REVIEW' && scanProgress >= 100)) return null;

    return (
      <div className="scan-report-progressbar" data-testid="scan-report-progressbar">
        <ProgressBar completed={scanProgress} getMessage={getMessage} formatMessage={formatMessage} />
      </div>
    );
  }, [scanProgress, status]);

  const submitButton = useMemo(() => (
    <div title={disableSubmitButton ? getMessage('managerApprovalButtonSubmissionDisabledMessage') : null}>
      <button
        onClick={() => onClick('SUBMITTED')}
        type="button"
        className="scan-report-submit-button"
        disabled={disableSubmitButton || (status === 'IN_REVIEW' && scanProgress < 100)}
        data-testid="scan-report-submit-button"
      >
        {getMessage('submit')}
      </button>
    </div>
  ), [disableSubmitButton, status, scanProgress, approvalAction, getMessage]);

  const resumeButton = useMemo(() => (
    <button
      onClick={() => onClick('SCANNING')}
      type="button"
      className="scan-report-resume-button"
      disabled={resumeCount === 2}
      data-testid="scan-report-resume-button"
    >
      {getMessage('resume')}
    </button>
  ), [approvalAction, resumeCount, getMessage]);

  const cancelButton = useMemo(() => (
    <button
      onClick={() => onClick('CANCELLED')}
      type="button"
      className="scan-report-cancel-button"
      data-testid="scan-report-cancel-button"
    >
      {getMessage('cancel')}
    </button>
  ), [approvalAction, getMessage]);

  const buttons = useMemo(() => (
    showButtons ? (
      <>
        <div className="manager-approval-buttons-wrapper" data-testid="manager-approval-buttons-wrapper">
          {submitButton}
          {resumeButton}
          {cancelButton}
        </div>
      </>
    ) : null
  ), [resumeButton, submitButton, cancelButton, getMessage, showButtons]);

  const statusLabel = useMemo(() => {
    const label = status !== 'IN_REVIEW' ? scanStatusLabel : 'ready for manager approval'.toUpperCase();
    return (
      <div className="manager-approval-status" style={{ backgroundColor: scanStatuses.getColor(status) }} data-testid="manager-approval-status-label">
        <h4>{label}</h4>
      </div>
    );
  }, [status, scanStatusLabel]);

  const managerApprovalUI = useMemo(() => {
    if (startTutorial) {
      if (stepIndex === 6) {
        onClick('SCANNING');
      }
      if (stepIndex === 7) {
        onClick('CANCELLED');
      }
      if (stepIndex === 8) {
        onClick('SUBMITTED');
        if (isbackClicked) return statusLabel;
      }
      if (stepIndex >= 4) return statusLabel;
    } else {
      return progressBar ?? statusLabel;
    }
    return null;
  }, [stepIndex, startTutorial, isbackClicked]);

  const widget = useMemo(() => (
    <>
      {managerApprovalUI}
      {buttons ?? null}
    </>
  ), [getMessage, scanStatusLabel, buttons, progressBar]);

  return (
    <div className="manager-approval-container" data-testid="manager-approval-widget-container">
      {widget}
    </div>
  );
};

ManagerApproval.propTypes = {
  accuracySubmissionThreshold: number,
  approvalAction: string,
  data: object,
  formatMessage: func.isRequired,
  getMessage: func.isRequired,
  isScanNotProccessing: bool,
  nikeStoreRoles: object,
  scanProgress: number.isRequired,
  setApprovalAction: func.isRequired,
  setOpenModal: func.isRequired,
};

export default ManagerApproval;
