import { useContext } from 'react';
import {
  FormControl, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';

import { AdminContext } from '../../../../context/Admin.provider';

/**
 * Renders a group of radio buttons to switch between admin and store views.
 *
 * @component
 * @returns {JSX.Element} The rendered ViewAsGroup component.
 */
const ViewAsGroup = () => {
  const { isAdmin, setIsAdmin } = useContext(AdminContext);

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="view-as-admin-label"
        name="row-radio-buttons-group"
        value={isAdmin ? 'admin' : 'store'}
        onChange={(e) => {
          setIsAdmin(e?.target?.value === 'admin');
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '15px' }}>
          <FormControlLabel value="admin" control={<Radio size="small" />} label="Admin" />
          <FormControlLabel value="store" control={<Radio size="small" />} label="Store" />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default ViewAsGroup;
