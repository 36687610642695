import { useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, Switch } from '@mui/material';
import { closeSnackbar } from 'notistack';
import LanguageDropdown from '../languageDropdown/LanguageDropdown';
import { User, Settings } from '../../../assets/Svgs';

import { navigationBadge, environment } from '../../../configs/environment';
import Button from '../../../components/Button';
import ViewAsGroup from './components/ViewAs';
import { changeFavicon } from '../../../utils/setChromeTabTitle';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { AdminContext } from '../../../context/Admin.provider';
import { StoreViewContext } from '../../../context/StoreView.provider';

import favIcon from '../../../assets/images/favicon-small.png';

import './Profile.css';
import useAuth from '../../../hooks/useAuth';
import StoreAndCountryDropdown from '../../../components/customSelect/StoreAndCountryDropdown';
import useTranslator from '../../../hooks/useTranslator';
import useSimStore from '../../../store/simStore';

const Profile = () => {
  const history = useHistory();
  const { isAdmin } = useContext(AdminContext);
  const { isIOS, isTrue, isTutorialEnabled, activateTutorials, setActivateTutorials } = useContext(SimWebContext);
  const { storeInfo, flag } = useContext(StoreViewContext);
  const { getMessage } = useTranslator();
  const translations = useSimStore(state => state.translations);

  const { logout, getUserInfo, isAthleteAdmin } = useAuth();

  const handleStartTutorial = () => {
    setActivateTutorials(!activateTutorials);
    sessionStorage.setItem('isTutorialActivated', !activateTutorials);
  };

  const user = getUserInfo();
  const storeConcept = storeInfo?.businessConcept?.toLowerCase() || '-';
  const storeFacilityType = storeInfo?.facilityType?.toLowerCase() || '-';
  const storeCountryNumber = `: ${storeInfo?.address?.country}-${storeInfo?.storeNumber}`;

  const adminSettings = useMemo(() => environment === 'test' && user?.roles?.maxValue >= 40 && (
    <div className="admin-settings" data-testid="admin-settings">
      <div className="admin-hover-styles" />
      <button
        data-testid="admin-settings-button"
        type="button"
        onClick={() => history.push('/admin')}
        className="header-admin-settings"
        aria-label="admin settings button"
      >
        <Settings stroke="white" />
      </button>
    </div>
  ), []);

  const tutorial = useMemo(() => isTutorialEnabled && (
    <div data-testid="reportTutorial" style={{ marginTop: '10px' }}>
      <Tooltip title={getMessage('activateReportTutorials')}>
        <Switch
          checked={activateTutorials}
          onChange={handleStartTutorial}
          name="reportTutorial"
          color="primary"
        />
      </Tooltip>
    </div>
    ), [activateTutorials, isTutorialEnabled]);

  const profileDropdown = useMemo(() => {
    changeFavicon(isAdmin ? flag : favIcon);

    return (
      <div className="accountMenu-dropdown-content" id="accountMenu-dropdown-list" data-testid="accountMenu-dropdown">
        <div style={{ paddingRight: '25px' }}>
          <ul>
            <li className="navDropdownItem-label" key={getMessage('profile')}>
              {getMessage('profile')}
              <img src={flag} alt="based on the language used for as the default in store" height="100%" />
            </li>
            <p>
              <span style={{ fontWeight: '700', fontSize: '14px' }}>{storeInfo?.name}</span>
              <span style={{ fontWeight: '700', fontSize: '14px' }}>{storeCountryNumber}</span>
            </p>
            <p>
              <span style={{ fontWeight: '700' }}>{'User: '}</span>
              <span data-testid="profile-user-info" style={{ fontWeight: '700' }}>{user.operatorID}</span>
            </p>
            <p>
              <span data-testid="showAppVersion">
                <span style={{ fontWeight: '700' }}>{'Environment: '}</span>
                <span className="badge badge-dark">{`${navigationBadge}`}</span>
              </span>
            </p>
            <p>
              <span data-testid="timezone" style={{ fontWeight: '700' }}>{`${getMessage('timezone') }: `}</span>
              <span>{storeInfo?.timezone}</span>
            </p>
            <p>
              <span data-testid="facilityType" style={{ fontWeight: '700' }}>{`${getMessage('facilityType') }: `}</span>
              <span>{storeFacilityType}</span>
            </p>
            <p style={{ marginBottom: '3px' }}>
              <span data-testid="businessConcept" style={{ fontWeight: '700' }}>{`${getMessage('businessConcept') }: `}</span>
              <span>{storeConcept}</span>
            </p>
            <li>
              <LanguageDropdown customClass="flex-row" />
            </li>
            { isAthleteAdmin() && !isIOS && !isTrue && (
              <>
                <li>
                  <span data-testid="view-as-admin-label" style={{ fontWeight: '700' }}>{ 'View As: ' }</span>
                  <ViewAsGroup />
                </li>
                <li>
                  <div data-testid="login-country-store">
                      <StoreAndCountryDropdown />
                  </div>
                </li>
              </>
            )}
            <li>
              <div>
                <Button
                  data-testid="profile-logout"
                  type="submit"
                  buttonText={getMessage('signout')}
                  onClick={() => {
                    sessionStorage.setItem('showToast', false);
                    closeSnackbar();
                    logout(history);
                  }}
                  style={{ width: '300px' }}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }, [isAdmin, storeInfo, translations]);

  return (
    <div className="header-profile-container" data-testid="header-profile-container">
      <div>
        {tutorial}
      </div>
      <div>
        <img src={flag} alt="based on the language used for as the default in store" height="100%" />
      </div>
      {adminSettings}
      <div className="header-dropdown" data-testid="header-dropdown">
        <div className="user-hover-styles" />
        <User
          className="profile-icon pr12-sm"
          testId="header-profile-tab"
          fill="white"
        />
        {profileDropdown}
      </div>
    </div>
  );
};

export default Profile;
