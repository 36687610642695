import { useContext, useMemo } from 'react';
import { array, bool, object, string } from 'prop-types';

import MUIDataTable from 'mui-datatables';
import { sortDetailTableData, mergeSOHByGtin } from './scanReportProducts.utils';
import { sortSizes, defaultSort } from '../../../../utils/sort';

import { getRfidTableHeader } from '../../../../components/RFIDTableHeader';
import getStockOnHand from '../../../../utils/getStockOnHand';

import { TranslationsContext } from '../../../../context/Translations.provider';

/**
   * This component handles the scan data populated with the correct values in the data table
   * @param {object} scan the scan data object
   * @param {string} scanArea the scan location - salesFloor, stockRoom and offsite
   * @param {string} scanType the type of scan
   *        - FULL_STORE_SCAN, SALES_FLOOR_SCAN, OFFSITE_SCAN, FILTERED_FULL_STORE_SCAN and STOCK_ROOM_SCAN
   * @param {boolean} iPad the value that handles the rowsPerPage
   * @param {object} sortBy the value that by which we are sorting the data
   * @return A data table with the gtin/upc data
*/
const ScanReportProductDetailTable = ({
  scan,
  scanArea,
  scanType,
  iPad,
  sortBy,
  sizes = null,
  rfidEnabled,
  isOffsiteEnabled,
  isOffsiteInactive,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  let sortedScans = [];

  if (scan && scan[scanArea] && sortBy) {
    sortedScans = sortDetailTableData(
      mergeSOHByGtin(scan[scanArea], sizes, isOffsiteEnabled, getStockOnHand), sortBy.value, sortBy.order,
    );
  }

  const rfidColumn = getRfidTableHeader('quantity', getMessage('rfidStock'), 'productCard-summary', rfidEnabled, isOffsiteEnabled, isOffsiteInactive, !rfidEnabled);

  const columns = [
    {
      name: 'gtin',
      label: getMessage('upc'),
    },
    {
      name: 'size',
      label: getMessage('size'),
    },
    {
      name: 'scanned',
      label: getMessage('scanned'),
    },
    {
      name: 'expected',
      label: getMessage('expected'),
    },
    {
      name: 'missing',
      label: scanArea === 'allGtins' && scanType !== 'SALES_FLOOR_SCAN' ? getMessage('missing') : getMessage('lost'),
    },
    {
      name: 'extra',
      label: scanArea === 'allGtins' && scanType !== 'SALES_FLOOR_SCAN' ? getMessage('extra') : getMessage('found'),
    },
    {
      name: 'accuracy',
      label: getMessage('accuracy'),
    },
    rfidColumn,
  ];

  const datatable = useMemo(() => (
    <MUIDataTable
      data={sortedScans}
      columns={columns}
      options={{
        selectableRows: 'none',
        rowsPerPage: iPad ? 4 : 8,
        rowsPerPageOptions: iPad ? [4, 8, 12] : [8, 12, 16],
        filter: false,
        print: false,
        download: false,
        search: false,
        viewColumns: false,
        elevation: 0,
        customSort: (data, dataIndex, rowIndex) => {
          if (dataIndex === 1) {
            return data && sortSizes(data, rowIndex);
          }
          return defaultSort(data, dataIndex, rowIndex);
        },
      }}
    />
  ), [sortedScans, columns, iPad, scanArea]);

  return (
    <div data-testid="scanProductDetailTable">
      {datatable}
    </div>
  );
};

ScanReportProductDetailTable.propTypes = {
  scan: object.isRequired,
  scanArea: string.isRequired,
  scanType: string.isRequired,
  iPad: bool.isRequired,
  sortBy: object.isRequired,
  sizes: array,
  rfidEnabled: bool.isRequired,
  isOffsiteEnabled: bool.isRequired,
  isOffsiteInactive: bool.isRequired,
};

export default ScanReportProductDetailTable;
