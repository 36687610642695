import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { logErrorAction } from '../../utils/newRelicPageActions';

/**
   * A util that creates the bin audit data with pass/fail, calculations, audit #'s, score etc...
   * @param {string} store - the store ex: USA-128
   * @param {object} startDate - the start date the use has selected in the date picker
   * @param {object} endDate - the end date the use has selected in the date picker
   * @param {boolean} useAuditV2 - whether to use the audit v2 flow or not
*/
export const fetchBinAuditsData = async (store, startDate, endDate, useAuditV2 = false) => {
  if (!store || !startDate || !endDate) return null;

  try {
    const filters = `?filter=store(${store})&filter=creationDateBeforeEq(${endDate.toJSDate().toISOString()})&filter=creationDateAfterEq(${startDate.toJSDate().toISOString()})`;
    const binAuditsEndpoint = useAuditV2 ? endpoints.BIN_AUDITS_V2 : endpoints.BIN_AUDITS;

    const url = `${baseUrl(routerUrls.DELEDGE)}${binAuditsEndpoint.url}${filters}`;

    const binAuditsData = await generalAxiosRequest('GET', url, binAuditsEndpoint, true);

    return binAuditsData?.objects;
  } catch (error) {
    logErrorAction('BinAuditsError', error, 'error retrieving data', {
      message: error?.message, store, startDate, endDate,
    });
    throw error;
  }
};
