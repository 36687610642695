import { useContext } from 'react';
import { ReceivingLogWidget } from '../../../components/dashboardWidgets';
import ReceivingLogProvider from '../../receivingLog/ReceivingLog.provider';
import { StoreViewContext } from '../../../context/StoreView.provider';

const ReceivingLog = () => {
  const { store } = useContext(StoreViewContext);


  return (
  <ReceivingLogProvider fromDashboard>
    <ReceivingLogWidget store={store} />
  </ReceivingLogProvider>
);
};

export default ReceivingLog;
