import {
  bool, func, number, string, oneOfType, array,
} from 'prop-types';
import classNames from 'classnames';

import './Toggle.css';

/**
 * A Toggle component, the unselected side will be faded
 * allows for an optional number quantity on each side
 * @param {String} leftLabel - the string to be shown for the left side of the toggle
 * @param {Number || String} leftQuantity - optional number that can be shown with the left label
 * @param {String} leftName - the string value to be used for the left side of the toggle
 * @param {String} rightLabel  - the string to be shown for the right side of the toggle
 * @param {Number || String} rightQuantity - optional number that can be shown with the right label
 * @param {String} rightName - the string value to be used for the right side of the toggle
 * @param {String} selected - string that should match either label and will cause that label to be selected
 * @param {Function} setSelected - function that should change the selected value
 * @param {Boolean} toggleByLabel - value that handling adding an additional class on the element
 * @param {string} toggleDirection - value that handles the direction of the toggle
 * @param {Boolean} hasSeveralToggles - if there are more than 2 toggle labels
 * @param {Array} toggleLabelsArray - array of toggle labels if hasSeveralToggles is true
 * @param {bool} darkMode - changes the color to support dark mode on dashboard
 */
const Toggle = ({
  leftLabel = '',
  leftQuantity = '',
  leftName = '',
  rightLabel = '',
  rightQuantity = '',
  rightName = '',
  selected = '',
  setSelected,
  toggleByLabel = true,
  toggleDirection = 'horizontal',
  hasSeveralToggles = false,
  toggleLabelsArray = [],
  darkMode = false,
}) => {
  const handleChange = (event, selectedValue) => {
    setSelected(selectedValue);
    event.preventDefault();
  };
  // TODO: need to clean this comp up
  const toggleHorizonalElement = (
    <div className="toggle" data-testid="toggle-horizontal">
      <div
        className={classNames('toggle-label', { 'toggle-selected': toggleByLabel ? leftName === selected : selected }, { 'darkMode-toggle': darkMode && leftName === selected })}
        onClick={(event) => { handleChange(event, leftName); }}
        data-testid={`toggle-${leftName}`}
      >
        {`${leftLabel} ${leftQuantity}`}
      </div>
      <div
        className={classNames('toggle-label', { 'toggle-selected': toggleByLabel ? rightName === selected : !selected }, { 'darkMode-toggle': darkMode && rightName === selected })}
        onClick={(event) => { handleChange(event, rightName); }}
        data-testid={`toggle-${rightName}`}
      >
        {`${rightLabel} ${rightQuantity}`}
      </div>
    </div>
  );
  const toggleVerticalElement = hasSeveralToggles ? (
    <div className="toggle-vertical" data-testid="toggle-vertical">
      {toggleLabelsArray.map(label => (
        <div
          className={classNames('toggle-vertical-label', { 'toggle-vertical-selected': toggleByLabel ? label.name === selected : selected })}
          onClick={(event) => { handleChange(event, label.name); }}
          key={`toggle-vertical-${label.name}`}
          data-testid={`toggle-${label.name}`}
        >
          {`${label.label}`}
        </div>
      ))}
    </div>
  ) : (
    <div className="toggle-vertical" data-testid="toggle-vertical">
      <div
        className={classNames('toggle-vertical-label', { 'toggle-vertical-selected': toggleByLabel ? leftName === selected : selected })}
        onClick={(event) => { handleChange(event, leftName); }}
        data-testid={`toggle-${leftName}`}
      >
        {`${leftLabel} ${leftQuantity}`}
      </div>
      <div
        className={classNames('toggle-vertical-label', { 'toggle-vertical-selected': toggleByLabel ? rightName === selected : !selected })}
        onClick={(event) => { handleChange(event, rightName); }}
        data-testid={`toggle-${rightName}`}
      >
        {`${rightLabel} ${rightQuantity}`}
      </div>
    </div>
  );

  return toggleDirection === 'horizontal' ? toggleHorizonalElement : toggleVerticalElement;
};

Toggle.propTypes = {
  leftLabel: string,
  leftQuantity: oneOfType([string, number]),
  leftName: string,
  rightLabel: string,
  rightQuantity: oneOfType([string, number]),
  rightName: string,
  selected: oneOfType([string, bool]),
  setSelected: func.isRequired,
  toggleByLabel: bool,
  toggleDirection: string,
  hasSeveralToggles: bool,
  toggleLabelsArray: array,
  darkMode: bool,
};

export default Toggle;
