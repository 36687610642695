import { useState, useMemo } from 'react';
import {
  string,
  number,
  oneOfType,
} from 'prop-types';

import './svgs.css';

 
export const Info = ({
  width = '20',
  height = '20',
  fill = 'black',
}) => {
  const [isHovered, toggleHover] = useState(false);

  const handleHover = () => {
    toggleHover(prevState => !prevState);
  };

  const body = useMemo(() => (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className="info_svg"
      fill={fill}
      height={height}
      width={width}
      onMouseEnter={() => handleHover()}
      onMouseLeave={() => handleHover()}
    >
      {isHovered
        ? <path className="info_svg_hover" d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 106.667969c11.777344 0 21.332031 9.554687 21.332031 21.332031s-9.554687 21.332031-21.332031 21.332031-21.332031-9.554687-21.332031-21.332031 9.554687-21.332031 21.332031-21.332031zm48 298.664062h-96c-11.796875 0-21.332031-9.554687-21.332031-21.332031s9.535156-21.332031 21.332031-21.332031h26.667969v-128h-16c-11.796875 0-21.335938-9.558594-21.335938-21.335938 0-11.773437 9.539063-21.332031 21.335938-21.332031h37.332031c11.796875 0 21.332031 9.558594 21.332031 21.332031v149.335938h26.667969c11.796875 0 21.332031 9.554687 21.332031 21.332031s-9.535156 21.332031-21.332031 21.332031zm0 0" />
        : (
          <>
            <path className="info_svg_default" d="m277.332031 128c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0" />
            <path d="m256 405.332031c-8.832031 0-16-7.167969-16-16v-165.332031h-21.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h37.332031c8.832031 0 16 7.167969 16 16v181.332031c0 8.832031-7.167969 16-16 16zm0 0" />
            <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0" />
            <path d="m304 405.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
          </>
        )}
    </svg>
  ), [isHovered]);

  return body;
};

Info.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  fill: string,
};
