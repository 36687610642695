export const inputTypes = {
  STYLE: 'STYLE',
  STYLE_COLOR: 'STYLE_COLOR',
  GTIN: 'GTIN',
  INVALID: 'INVALID',
  NONE: 'NONE',
};

const getItemInputType = (input) => {
  if (!input?.trim()?.length) {
    return inputTypes.NONE;
  }
  if (/^[0-9]{10,}$/g.test(input)) {
    return inputTypes.GTIN;
  }
  if (input.includes('-')) {
    return inputTypes.STYLE_COLOR;
  }
  return inputTypes.STYLE;
};

export default getItemInputType;