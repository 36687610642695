import { useContext, useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import Joyride from 'react-joyride';
import Switch from '@mui/material/Switch';
import { isEmpty } from 'lodash';
import ErrorBoundary from '../ErrorBoundary';
import { LoadingIndicator } from '../../assets/Svgs/index';
import { SimWebContext } from '../../context/SimWeb.provider';
import TutorialProvider, { tutorialContext } from './Tutorial.provider';
/**
 * This component is used as a step by step guide on how to use features/reports
 * @param {function} setTutorialState - sets the bool to trigger activating the tutorial
 * @param {bool} disabled - disable the tutorial if no data is present
 * @param {string} widgetName - widget name for tutorial on specific widget
 */
const Tutorial = ({
  setTutorialState = null,
  disabled = false,
  widgetName = null,
}) => {
  const { getMessage, activateTutorials } = useContext(SimWebContext);
  const {
    handleJoyrideCallback,
    steps,
    loadingTutorial,
    dashboardWidget,
    isStyleManagementTutorialEnabled,
    page,
    setPage,
    stepIndex,
    setStepIndex,
    startTutorial,
    setDashboardWidget,
    setTutorialState: onToggleChange,
  } = useContext(tutorialContext);

  useEffect(() => {
    if (widgetName) {
      setDashboardWidget(widgetName);
    }
  }, [widgetName]);

  useEffect(() => setPage && setPage(window.location.href.split('/')[3]), [window?.location]);

  // reposition joyride spotlight to target component after scrolling the screen
  useEffect(() => {
    const { target } = steps?.[stepIndex] || {};
    if (dashboardWidget && target) {
      const targetEl = document.querySelector(target);
      if (targetEl) {
        window.setTimeout(() => {
          const spotlight = document.querySelector('.react-joyride__spotlight');
          if (spotlight) {
            const spotlightTop = spotlight.offsetTop;
            const targetTop = targetEl.offsetTop;
            const diff = spotlightTop > targetTop
              ? spotlightTop - targetTop
              : targetTop - spotlightTop;
            if (diff > 1) {
              spotlight.style.transform = `translateY(${diff}px)`;
            }
          }
        }, 0);
      }
    }
  }, [stepIndex, startTutorial]);

  const enableTutorialForPilot = isStyleManagementTutorialEnabled && page === 'styleManagement';
  const translatedActions = {
    back: getMessage('back'), close: getMessage('close'), last: getMessage('last'), next: getMessage('next'), skip: getMessage('skip'),
  };

  const comingSoon = 'Tutorials coming soon!';
  const disableTutorial = 'The tutorial is disabled due to no data present for this report';

  if (isEmpty(steps) && activateTutorials) {
    return <p className="tutorial-disabled" data-testid="tutorial-not-implemented">{comingSoon}</p>;
  }

  const handleStartTutorial = () => {
    onToggleChange(!startTutorial);
  };

  return (
    <TutorialProvider>
      <ErrorBoundary>
        <div data-testid="custom-tutorial" className="tutorial-joyride-wrapper">
          {(activateTutorials || enableTutorialForPilot) && (
            <div data-testid={`${page}-tutorial-container`}>
              <Joyride
                callback={handleJoyrideCallback}
                continuous
                run={startTutorial}
                scrollToSteps
                setStepIndex={setStepIndex}
                showProgress
                showSkipButton
                stepIndex={stepIndex}
                steps={steps}
                disableScrolling
                locale={translatedActions}
                styles={{
                  options: {
                    primaryColor: '#000',
                    zIndex: 2000,
                  },
                }}
              />
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <label htmlFor="reportTutorial" className={(loadingTutorial || disabled) && 'tutorial-disabled'}>{getMessage('tutorial')}</label>
                <Switch
                  checked={startTutorial}
                  onChange={setTutorialState ?? handleStartTutorial}
                  name="reportTutorial"
                  color="primary"
                  data-testid="custom-tutorial-switch"
                  disabled={disabled}
                />
                {loadingTutorial && (
                  <LoadingIndicator
                    data-testid="loading-tutorial"
                    width="40px"
                    height="40px"
                    style={{ margin: 0 }}
                    svgStyle={{
                      position: 'static', top: 0, left: 0, width: 'auto',
                    }}
                  />
                )}
              </div>
              {disabled && <p className="tutorial-disabled" data-testid="disabledTutorial">{disableTutorial}</p>}
            </div>
          )}
        </div>
      </ErrorBoundary>
    </TutorialProvider>
  );
};

Tutorial.propTypes = {
  disabled: bool,
  setTutorialState: func,
  widgetName: string,

};

export default Tutorial;
