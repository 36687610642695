
// This object is used in the tutorial callback and helps with events during
// tutorial steps to apply tooltip correctly on highlighted elements
const customActions = {
  inboundVisibility: {
    9: (isbackClicked) => {
      const allShipDatesSelected = document.querySelector('table input')?.checked;
      const listOfCheckboxes = document.querySelectorAll('table td input');
      if (isbackClicked) {
        listOfCheckboxes?.[0]?.click();
        setTimeout(() => {
          document.querySelector('table input')?.click();
        }, 200);
      } else if (!allShipDatesSelected && !isbackClicked) {
        const checkbox = document.querySelector('table input');
        if (checkbox && !checkbox.checked && !allShipDatesSelected && !isbackClicked) {
          checkbox.click(); // select all
        }
      }
    },
    10: (isbackClicked) => {
      const allShipDatesSelected = document.querySelector('table input')?.checked;
      const listOfCheckboxes = document.querySelectorAll('table td input');
     
      if (allShipDatesSelected) {
        document.querySelector('table input')?.click(); // unselect all
      }
      if (!listOfCheckboxes[0].checked) listOfCheckboxes[0].click();
      if (isbackClicked) {
        if (listOfCheckboxes?.[1]?.checked) {
          listOfCheckboxes[1].click();
        }
      }
    },
    11: () => {
      const listOfCheckboxes = document.querySelectorAll('table td input');
      if (!listOfCheckboxes[1].checked) {
        listOfCheckboxes[1].click();
      }
    },
    13: (isbackClicked, isDropZonesEnabled) => {
      if (!isDropZonesEnabled) {
        const listOfCheckboxes = document.querySelectorAll('table td input');
        if (listOfCheckboxes[0]?.checked) {
          listOfCheckboxes[0].click();
          listOfCheckboxes[1].click();
        }
      }
   
    },
  },
  styleManagement: {
    8: (isbackClicked) => {
      const listOfCheckboxes = document.querySelectorAll('table td input');
      const checkbox = listOfCheckboxes?.[0];
      if (isbackClicked) { 
        if (checkbox.checked) listOfCheckboxes?.[0].click();
      }
    },
    9: () => {
      const listOfCheckboxes = document.querySelectorAll('table td input');
      const checkbox = listOfCheckboxes?.[0];

      if (!checkbox.checked) checkbox.click();
    },
  },
  // Add more reports if needed
};

export default customActions;