import { object } from 'prop-types';

import './VisualSummaries.css';

const VisualSummariesContainer = ({ chart = null, summaryWidget = null }) => (
  <>
    {(chart || summaryWidget) && (
      <div
        data-testid="visual-summaries-container"
        className="visual-summaries-container header-right"
      >
        {summaryWidget && (
          <div className="visual-summaries-summary-widget">
            {summaryWidget}
          </div>
        )}
        {chart && (
          <div className="visual-summaries-donut-chart">
            {chart}
          </div>
        )}
      </div>
    )}
  </>
);

VisualSummariesContainer.propTypes = {
  chart: object,
  summaryWidget: object,
};

export default VisualSummariesContainer;
