import { string } from 'prop-types';
 
export const ClothesOnHanger = ({ extraClasses = '' }) => (
  <div className={`${extraClasses}`}>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path fill="currentColor" d="M1.101 9.421 8.801 6.084c.129.432 .345.773 .601 1.049l2.05-1.248V5.035c0-.303.245-.548.548-.548.616 0 1.116-.501 1.116-1.116 0-.615-.501-1.116-1.116-1.116-.615 0-1.116.501-1.116 1.116 0 .303-.245.548-.548.548-.303 0-.548-.245-.548-.548 0-1.22.993-2.212 2.212-2.212 1.22 0 2.212.992 2.212 2.212 0 1.031-.708 1.899-1.664 2.144v.371L14.598 7.132c.256-.276.471-.617.601-1.049L22.899 9.421l-1.669 4.555-4.085-1.53.069 10.395h-5.215-5.215l.069-10.395-4.085 1.53L1.101 9.421zM6.47 11.42l1.491-.558-.01 1.592-.062 9.292h4.112H16.112l-.062-9.292-.011-1.592 1.491.558 3.051 1.143.933-2.547-5.8-2.513c-.052.073-.107.143-.162.211-.251.306-.54.559-.848.767-.901.61-1.959.836-2.675.855h-.03l-.03-.001c-.085-.002-.857-.032-1.729-.369-.345-.133-.653-.303-.936-.493-.328-.22-.61-.476-.848-.767-.057-.069-.121-.133-.172-.206l-5.8 2.514.933 2.547L6.47 11.42zM12 6.834l-1.634.994c.822.389 1.634.411 1.634.411s.812-.022 1.634-.411L12 6.834z" />
    </svg>
  </div>
);

ClothesOnHanger.propTypes = {
  extraClasses: string,
};
