/**
 * A helper function to returns a string with language sensitive representation of a given number
 * @param {string} locale the language tag
 * @param {number} numberValue the number to localize
 */
export const getLocalizedNumber = (locale, numberValue) => {
  if (numberValue === null || numberValue === undefined) return '-';
  if (numberValue === '-') return numberValue;
  return numberValue.toLocaleString(locale);
};
