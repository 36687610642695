import { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslationsContext } from '../../context/Translations.provider';

const NoContentMessage = ({
  isOnlyOneDay = false,
  className = 'no-content-message',
  noDateRange = false,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  return (
    <div>
      <p className={className} data-testid="no-content-message">
        {
          !noDateRange && (isOnlyOneDay
            ? getMessage('noDataDate')
            : getMessage('noDataDateRange')
          )
        }
        {noDateRange && getMessage('noData')}
      </p>
    </div>
  );
};

NoContentMessage.propTypes = {
  isOnlyOneDay: PropTypes.bool,
  noDateRange: PropTypes.bool,
  className: PropTypes.string,
};

export default NoContentMessage;
