import { generalAxiosRequest } from './axiosFunctions';
import { baseUrl, endpoints, routerUrls } from './endpoints';
import getLanguageFlag from '../utils/getLanguageFlag';
import regionMap from '../constants/region-map.json';
import { BROWSER, COUNTRY, LANGUAGE, LOGIN_SOURCE, SERVER_REGION, STORE_ID, STORE_NUMBER } from '../constants/storageKeys.constants';

export const getCurrencySymbol = (locale = 'en-US', currencyCode = 'USD') => {
  const nullPrice = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).format(0);

  const currencySymbol = nullPrice.replace(/[\d,.\s]/g, '');
  return currencySymbol;
};

const headers = { 'nike-api-caller-id': 'com.nike:retail.sim' };

/**
 * Fetches the store information for the current store.
 * @returns {{storeInfo, error}} - storeInfo and error, if applicable
 */
export const fetchStoreInfo = async () => {
  const storeId = localStorage.getItem(STORE_ID);
  const loginSource = localStorage.getItem(LOGIN_SOURCE);

  let storeInfo = {};
  let enrichedStoreInfo = {};
  let error = null;

  const url = `${baseUrl(routerUrls.NIKE_API)}${endpoints.STORE_VIEWS.url}/${storeId}`;
  if (storeId) {
    try {
      storeInfo = await generalAxiosRequest('GET', url, endpoints.STORE_VIEWS, false, headers);
    } catch (err) {
      error = `Error fetching store info: ${err?.message}`;
    }

    const country = storeInfo?.address?.country;

    enrichedStoreInfo = {
      ...storeInfo,
      isNorthAmerica: !storeInfo?.region ? true : storeInfo?.region === 'NORTH_AMERICA',
      isEMEA: storeInfo?.region === 'EUROPE_MIDDLE_EAST_AFRICA',
      isGreaterChina: storeInfo?.region === 'GREATER_CHINA',
      isAPLA: storeInfo?.region === 'ASIA_PACIFIC_LATIN_AMERICA',
      serverRegion: regionMap[country] || 'us-east-1',
      countryStoreNumber: `${country}-${storeInfo.storeNumber}`,
      country: country,
      merchGroup: storeInfo?.address?.iso2Country,
      flag: getLanguageFlag(country),
      currencyCode: storeInfo?.currencies?.[0],
      currencySymbol: getCurrencySymbol(storeInfo?.locale, storeInfo?.currencyCode),
    };
  
    if (loginSource !== BROWSER) {
      localStorage.setItem(LANGUAGE, storeInfo?.defaultLanguage);
      window.dispatchEvent(new StorageEvent('storage', { key: LANGUAGE, language: storeInfo?.defaultLanguage }));
    }
    localStorage.setItem(SERVER_REGION, enrichedStoreInfo.serverRegion);
  }
  
  return { storeInfo: enrichedStoreInfo, error };
};

/**
 * Fetches the configuration values for the current store.
 * @returns {{storeConfig, error}} - storeConfig and error, if applicable
 */
export const fetchStoreConfig = async () => {
  const country = localStorage.getItem(COUNTRY);
  const storeNumber = localStorage.getItem(STORE_NUMBER);

  let storeConfig = {};
  let error = null;

  const url = `${baseUrl(routerUrls.DELEDGE)}${endpoints.STORE_CONFIG.url}/${country}-${storeNumber}`;

  try {
    const response = await generalAxiosRequest('GET', url, endpoints.STORE_CONFIG);

    response?.objects?.map(configValue => {
      const splitKey = configValue?.key?.split('.');
      const groupName = splitKey[0];

      if (groupName === 'sim') {
        const keyName = splitKey.slice(1).join('.');
        if (!storeConfig[keyName]) {
          storeConfig[keyName] = {};
        }
        storeConfig[keyName] = {
          value: configValue.value,
          scope: configValue.scopeType,
        };
      }
    });
  } catch (err) {
    error = `Error fetching store config: ${err?.message}`;
  }

  return { storeConfig, error };
};

/**
 * Fetches all OPEN, UNOPENED, and CLOSED stores.
 *
 * @returns {{
 *  storesList: [{
 *    id: string,
 *    storeNumber: string,
 *    defaultLanguage: string,
 *    locale: string,
 *    address: { country: string}
 *  }],
 *  error: String}}
 */
export const fetchAllStores = async () => {
  let stores = [];
  let error = null;

  const statuses = ['OPEN', 'UNOPENED', 'CLOSED'];
  const filterStatus = statuses.map(status => `storeStatus==${status}`).join(' or ');
  const fields = 'id,storeNumber,defaultLanguage,locale,address.country';

  try {
    let anchor = null;

    let url = `${baseUrl(routerUrls.NIKE_API)}${endpoints.STORE_VIEWS.url}?search=(${filterStatus})&fields=${fields}&count=3000`;
    do {
      if (anchor) {
        url = `${baseUrl(routerUrls.NIKE_API)}/${anchor}`;
      }
      const response = await generalAxiosRequest('GET', url, endpoints.STORE_VIEWS, false, headers);

      if (response?.objects?.length > 0) {
        anchor = response?.pages?.next;
        stores.push(...response.objects);
      }
    } while (anchor);
  } catch (err) {
    error = `Error fetching stores: ${err?.message}`;
  }

  // Sort list by country alphabetically and then by store number
  const sortedStoreList = stores.sort(
    (a, b) =>
      (a?.address?.country ?? '').localeCompare(b?.address?.country ?? '') ||
      a.storeNumber.localeCompare(b.storeNumber, 'en', { numeric: true }),
  );

  return { storesList: sortedStoreList, error };
};
