export const defaultNoValueSort = (a, b, index, direction) => {
  if (a?.data?.[index] === '-' && b?.data?.[index] === '-') return 0;
  if (a?.data?.[index] === '-' || b?.data?.[index] === '-') {
    if (direction === 'asc') return (a?.data?.[index] !== '-' && b?.data?.[index] === '-') ? -1 : 1;
    return (a?.data?.[index] === '-' && b?.data?.[index] !== '-') ? 1 : -1;
  }
};

export const defaultSort = (data, index, direction) => (
  data && data.sort((a, b) => {
    const sortValue = defaultNoValueSort(a, b, index, direction);
    if (sortValue !== undefined) return sortValue;

    return direction === 'desc' ? (a.data[index] < b.data[index] ? 1 : -1) : (b.data[index] > a.data[index] ? -1 : 1);
  })
);
