import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { isLocalHost, isProd } from '../configs/environment';
import { LANGUAGE } from '../constants/storageKeys.constants';
import _ from 'lodash';

export const parseBoolean = value => {
  return _.toLower(value) === 'true' || value === '1';
};

/**
 * Defines a Zustand store with various state properties and their corresponding setter functions.
 */

// TODO: Prune session storage and make sure we're only storing what we need: login source
const simStore = set => ({
  isAdmin: false,
  storeId: '', // adding this here specifically for the wrapper to grab onto
  versionIsCurrent: true,
  translations: {},
  storeInfo: {},
  storeConfig: {},
  language: '',
  activateTutorials: parseBoolean(sessionStorage.getItem('isTutorialActivated')) || false,
  errors: [],
  setIsAdmin: isAdmin => set(() => ({ isAdmin }), undefined, 'sim/setIsAdmin'),
  setStoreId: storeId => set(() => ({ storeId }), undefined, 'sim/setStoreId'), // adding this here specifically for the wrapper to grab onto
  setVersionIsCurrent: versionIsCurrent => set(() => ({ versionIsCurrent }), undefined, 'sim/setVersionIsCurrent'),
  setTranslations: data =>
    set(
      state => ({
        translations: data.translations,
        language: localStorage.getItem(LANGUAGE), // This is so we can trigger updates
        // only add to the error state if there is an error and if the error is not already in state.
        ...(data.error && !state.errors.includes(data.error) && { errors: [...state.errors, data.error] }),
      }),
      undefined,
      'sim/setTranslations',
    ),
  setActiveTutorials: activateTutorials => set(() => ({ activateTutorials }), undefined, 'sim/setActiveTutorials'),
  setStoreInfo: data =>
    set(
      state => ({
        storeInfo: data.storeInfo,
        ...(data.error && !state.errors.includes(data.error) && { errors: [...state.errors, data.error] }),
      }),
      undefined,
      'sim/setStoreInfo',
    ),
  setStoreConfig: data =>
    set(
      state => ({
        storeConfig: data.storeConfig,
        ...(data.error && !state.errors.includes(data.error) && { errors: [...state.errors, data.error] }),
      }),
      undefined,
      'sim/setStoreConfig',
    ),
  addError: error =>
    set(
      state => ({
        ...(!state.errors.includes(error) && { errors: [...state.errors, error] }),
      }),
      undefined,
      'sim/setError',
    ),
  removeError: index =>
    set(state => ({ errors: state.errors.filter((_, i) => i !== index) }), undefined, 'sim/removeError'),
  resetStore: () =>
    set(
      () => ({
        isAdmin: false,
        storeInfo: {},
        storeConfig: {},
        errors: [],
        activateTutorials: false,
        versionIsCurrent: true,
      }),
      undefined,
      'sim/resetStore',
    ),
});

/**
 * Add redux devtools in development or localhost and persist the store in session storage for everything
 */
const wrappedSimStore = devtools(
  persist(simStore, {
    name: 'sim-store',
    storage: createJSONStorage(() => sessionStorage),
    merge: (persistedState, currentState) => ({ ...currentState, ...persistedState }),
  }),
  { enabled: isLocalHost() || !isProd() },
);

const useSimStore = create(wrappedSimStore);

export default useSimStore;
