import { messages, getTargetOrigin } from '../utils/iframeMessaging';
import * as constants from '../constants/storageKeys.constants';
import { setSessionValues } from '../utils/setSessionValues';

export const logout = () => {
  const docRef = document.referrer.replace(/\/$/, '');

  window.parent.postMessage({ name: messages.CLOSE }, docRef);
  sessionStorage.clear();
};

export const login = async history => {
  try {
    const docRef = document?.referrer?.replace(/\/$/, '') ?? '';
    const targetOrigins = getTargetOrigin();

    if (!targetOrigins.includes(docRef)) return;

    window.parent.postMessage({ name: messages.REQUEST_ACCESS_TOKEN }, docRef);
    window.parent.postMessage({ name: messages.SET_TITLE, title: 'Store Inventory Management' }, docRef);
    window.parent.postMessage({ name: messages.GET_URL }, docRef);

    window.onmessage = e => {;
      // This extra check is to handle TRUEv1 because in prod it's still sending a string.
      if (e.data.token && typeof e.data.token === 'object') {
        setSessionValues(e.data.token.accessToken, true);
      }
      if (e.data.token && typeof e.data.token === 'string') {
        setSessionValues(e.data.token, true);
      }
      if (e.data.url) {
        sessionStorage.setItem(constants.TRUE_PARENT_URL, e.data.url);
        if (e.data.url.includes('selfCheckoutDevice')) {
          history.push('/selfCheckoutDeviceAssignment');
        } else {
          history.push('/dashboard');
        }
      }
    };
  } catch (error) {
    console.error('Error setting tokens', error);
    logout();
  }
};
