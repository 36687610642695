
import { string, number, oneOfType } from 'prop-types';

import './svgs.css';

/**
   * An SVG used in navbar for admin page
   * scan to the  users in the summary table
   * @param {string} stroke - adjustable color of SVG
   * @param {string | number} height - height of the SVG
   * @param {string | number} width - width of the SVG
   */
 
export const Settings = ({
  stroke = '#000000',
  height = '22px',
  width = '22px',
}) => (
  <svg width={width} height={height} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" className="settings-gear">
    <path fillRule="evenodd" clipRule="evenodd" d="M27.4951 17.2499C29.5671 20.8359 28.3371 25.4239 24.7491 27.4959C21.1631 29.5659 16.5771 28.3359 14.5051 24.7499C12.4331 21.1639 13.6631 16.5759 17.2491 14.5039C20.8371 12.4339 25.4231 13.6639 27.4951 17.2499Z" stroke={stroke} strokeWidth="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.4568 8.71473H36.6588L39.4688 13.5827L36.3688 18.9527C35.5648 20.3447 35.5648 22.0607 36.3688 23.4527L39.2348 28.4167L36.4228 33.2847H30.6908C29.0828 33.2847 27.5968 34.1427 26.7928 35.5347L23.9268 40.5007H18.3068L15.4408 35.5347C14.6368 34.1427 13.1508 33.2847 11.5428 33.2847H5.34076L2.53076 28.4187L5.63076 23.0467C6.43476 21.6547 6.43476 19.9387 5.63076 18.5467L2.76476 13.5827L5.57676 8.71473H11.3088C12.9168 8.71473 14.4028 7.85673 15.2068 6.46473L18.0728 1.50073H23.6928L26.5588 6.46473C27.3628 7.85673 28.8488 8.71473 30.4568 8.71473Z" stroke={stroke} strokeWidth="3" />
  </svg>
);

Settings.propTypes = {
  stroke: string,
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
};
