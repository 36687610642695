import { useContext } from 'react';
import { SizeComplianceWidget } from '../../../components/dashboardWidgets';
import { StoreConfigContext } from '../../../context/StoreConfig.provider';

const SizeCompliance = () => {
  const { storeConfig } = useContext(StoreConfigContext)

  const rfidSizeComplianceWidgetEnabled = storeConfig?.sizeComplianceEnabled?.value;

  return (rfidSizeComplianceWidgetEnabled
      && (
        <div className="sizeComplianceWidget_dashboard_container">
          <SizeComplianceWidget />
        </div>
      )
  );
};

export default SizeCompliance;
