import { useContext } from 'react';
import { object, string } from 'prop-types';
import { TableCell, TableRow } from '@mui/material';
import { TranslationsContext } from '../../context/Translations.provider';
import getFormattedPrice from '../../utils/getFormattedPrice';

const TransfersTableDetailItem = ({
  currencyCode = 'USD',
  data,
  locale = 'en-US',
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const rows = [];

  data?.cartons?.forEach((carton) => {
    let firstCartonRow = true;
    let totalItems = 0;
    let totalPrice = 0;
    carton?.styleColors?.forEach((styleColor) => {
      let firstStyleColorRow = true;
      styleColor?.items.forEach((item) => {
        rows.push(
          <TableRow
            key={`${item.gtin}-${styleColor.styleColor}-${carton.cartonNumber}`}
            data-testid="transfers-carton-details-table-row"
            className="nested-rows"
          >
            <TableCell className={!firstCartonRow ? 'no-border' : ''}>
              {firstCartonRow ? carton.cartonNumber : null}
            </TableCell>
            <TableCell className={!firstStyleColorRow ? 'no-border' : ''}>
              {firstStyleColorRow ? styleColor.styleColor : null}
            </TableCell>
            <TableCell className={!firstStyleColorRow ? 'no-border' : ''}>
              {firstStyleColorRow ? styleColor.class : null}
            </TableCell>
            <TableCell className={!firstStyleColorRow ? 'no-border' : ''}>
              {firstStyleColorRow ? styleColor.description : null}
            </TableCell>
            <TableCell>
              {item.size}
            </TableCell>
            <TableCell>
              {item.gtin}
            </TableCell>
            <TableCell>
              {item.quantity}
            </TableCell>
            <TableCell>
              {getFormattedPrice(item.price, locale, currencyCode)}
            </TableCell>
          </TableRow>,
        );

        totalItems += item.quantity;
        totalPrice += item.price;

        firstCartonRow = false;
        firstStyleColorRow = false;
      });
    });

    rows.push(
      <TableRow className="bottom-row" key={`${carton.cartonNumber}-totals`}>
        <TableCell className="bold">
          {getMessage('totals')}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell className="bold">
          {totalItems}
        </TableCell>
        <TableCell className="bold">
          {getFormattedPrice(totalPrice, locale, currencyCode)}
        </TableCell>
      </TableRow>,
    );
  });

  return rows;
};

TransfersTableDetailItem.propTypes = {
  currencyCode: string,
  data: object.isRequired,
  locale: string,
};

export default TransfersTableDetailItem;
