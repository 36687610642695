import { useContext } from 'react';
import ItemInquiryWidget from './ItemInquiryWidget';
import CPAProvider from '../../../context/cpa/CPA.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';

const ItemInquiryWidgetContainer = () => {
  const { storeConfig } = useContext(SimWebContext);
  const isItemInquiryEnabled = storeConfig?.isItemInquiryEnabled?.value;

  return isItemInquiryEnabled && (
    <CPAProvider>
      <ItemInquiryWidget />
    </CPAProvider>
  );
};

export default ItemInquiryWidgetContainer;
