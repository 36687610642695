import {
  useContext, useMemo,
} from 'react';
import { camelCase } from 'lodash';

import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import { LoadingIndicator } from '../../assets/Svgs/index';
import Options from '../../components/Options';
import TransfersTable from './TransfersTable';
import ErrorAlert from '../../components/alerts/Error';

import { SimWebContext } from '../../context/SimWeb.provider';
import { TransfersContext } from './Transfers.provider';
import { useTutorial } from '../../components/tutorial/useTutorial';

import './TransfersPage.css';

const TransfersPage = () => {
  const { getMessage, isIOS } = useContext(SimWebContext);
  const {
    error,
    isFetchingJobId,
    isOutsideRange,
    onFilterChange,
    onGoClick,
    onPrintOptionChange,
    onTransferFilterChange,
    printOptions,
    startDate,
    endDate,
  } = useContext(TransfersContext);

  const { tutorial } = useTutorial('transfers', isFetchingJobId);

  const filterOptions = useMemo(() => [
    { label: getMessage('all'), value: '' },
    { label: getMessage('complete'), value: 'assembled' },
    { label: getMessage('open'), value: 'unknown' },
  ], [getMessage]);

  const transferOptions = useMemo(() => [
    { label: getMessage('all'), value: 'all' },
    { label: getMessage('store'), value: 'store' },
    { label: getMessage('vendor'), value: 'vendor' },
    { label: getMessage('warehouse'), value: 'warehouse' },
  ], [getMessage]);

  const getPrintingOptions = () => {
    if (!printOptions) return null;

    return (
      <div className="print-option-block" data-testid="print-option-block">
        <Options
          onOptionChange={onPrintOptionChange}
          options={printOptions.map(opt => ({
            label: getMessage(camelCase(opt)),
            value: opt,
          }))}
          optionsLabel={getMessage('document')}
        />
      </div>
    );
  };

  return (
    <div className="transfers-page" data-testid="transfers-page">
      <div className="header">
        <div className="title-block flex-row">
          <div className="feature-title">{getMessage('transfers')}</div>
          <div className="page-controls flex-row" data-testid="transfers-page-options">
            <div className="flex-row left-side">
              <div data-testid="transfers-filter-dropdown">
                <Options
                  onOptionChange={onFilterChange}
                  options={filterOptions}
                  optionsLabel={getMessage('filter')}
                />
              </div>
              <div data-testid="transfers-type-dropdown">
                <Options
                  onOptionChange={onTransferFilterChange}
                  options={transferOptions}
                  optionsLabel={getMessage('transfers')}
                />
              </div>
              <div className="date-picker-block">
                <DateRangeWithOptions
                  optionsLabel="View"
                  start={startDate.toISO()}
                  end={endDate.toISO()}
                  onClick={onGoClick}
                  disabled={isFetchingJobId}
                  isOutsideRange={isOutsideRange}
                />
              </div>
            </div>
            {!isIOS && getPrintingOptions()}
          </div>
        </div>
        {tutorial}
      </div>
      {isFetchingJobId && <LoadingIndicator dataTestId="transfer-page-loading-indicator" />}
      {!isFetchingJobId && error
        && (
          <ErrorAlert
            errorObject={error.message}
            apiName="Transfers Page API"
            pageCode=""
          />
        )}
      {!isFetchingJobId && (
        <TransfersTable />
      )}
    </div>
  );
};

export default TransfersPage;
