import { useContext } from 'react';
import DeviceAssignmentAdapter from './deviceAssignment.axios';
import AthletesDevices from './AthletesDevices';
import { SimWebContext } from '../../context/SimWeb.provider';
import useAuthorizedToView from '../../hooks/useAuthorizedToView';
import useAuth from '../../hooks/useAuth';

const daAdapter = new DeviceAssignmentAdapter();

const SelfCheckoutDeviceAssignment = () => {
  const {
    country, storeId, storeName, storeNumber, getMessage,
  } = useContext(SimWebContext);

  const title = getMessage('selfCheckoutTitle');
  useAuthorizedToView(title, 'selfCheckoutEnabled', 40);
  const { getAccessToken } = useAuth();
  const tokenResponse = getAccessToken();

  return (
    <div className="flex-column" data-testid="selfcheckoutdeviceassignment">
      <div>
        <p className="feature-title">{title}</p>
        <AthletesDevices
          auth={tokenResponse.token}
          country={country}
          daAdapter={daAdapter}
          storeId={storeId}
          storeName={storeName}
          storeNumber={storeNumber}
        />
      </div>
    </div>
  );
};

export default SelfCheckoutDeviceAssignment;
