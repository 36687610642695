import { useState, useEffect } from 'react';
import { func, string, object } from 'prop-types';
import {
  TextField,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import './Search.css';

const theme = createTheme(({
  palette: {
    primary: {
      main: '#000000',
    },
  },
}));

const NewSearch = ({
  searchLabel = '',
  onSearchChange = null,
  onEnterSearch = null,
  reference = null,
}) => {
  const [searchedValue, setSearchedValue] = useState('');

  const onEnterPressed = (event) => {
    if (event.charCode !== 13) {
      return;
    }

    if (typeof onEnterSearch !== 'function') {
      event.target.blur();
      return;
    }

    onEnterSearch(searchedValue);
    event.target.blur();
  };

  useEffect(() => {
    if (onSearchChange && typeof onSearchChange === 'function') {
      onSearchChange(searchedValue);
    }
  }, [searchedValue, onSearchChange]);

  return (
    <div className="search-container" data-testid="search-container">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <TextField
            inputRef={reference}
            placeholder={searchLabel}
            variant="standard"
            InputProps={{
              endAdornment: <SearchIcon color="disabled" />,
            }}
            onChange={e => setSearchedValue(e.target.value)}
            value={searchedValue}
            onKeyPress={e => onEnterPressed(e)}
            data-testid="search-input"
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

NewSearch.propTypes = {
  onSearchChange: func,
  searchLabel: string,
  reference: object,
  onEnterSearch: func,
};

export default NewSearch;
