import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { node, object } from 'prop-types';

import { fetchOOSAuditsData } from './oosAudits.axios';
import { isOutsideDateRange } from '../../utils/isOutsideDateRange';
import { buildOOSAuditsTableData, setOOSAuditTutorialSteps } from './oosAudits.utils';
import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../../components/tutorial/Tutorial.provider';

export const oosAuditContext = createContext({});

const OOSAuditProvider = ({ children = {}, mockedValue = null }) => {
  const { store, getMessage, SimDateTime, storeConfig } = useContext(SimWebContext);
  const {
    startTutorial, stepIndex, isbackClicked, setStepIndex,
  } = useContext(tutorialContext);

  const { Provider } = oosAuditContext;

  const [data, setData] = useState(null);
  const [dialogData, setDialogData] = useState([]);
  const [error, setError] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(SimDateTime.startOfDay());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay());

  const useAuditV2 = storeConfig?.newAuditFlowEnabled?.value;

  useEffect(() => {
    setOOSAuditTutorialSteps(startTutorial, stepIndex, isbackClicked, setStepIndex, isOpen, setOpen);
  }, [startTutorial, stepIndex, isbackClicked, isOpen, setOpen]);

  useEffect(() => {
    const getOOSAuditsData = async () => {
      setData(null);
      setError(null);
      setLoading(true);

      try {
        const oosAuditsData = await fetchOOSAuditsData(store, startDate.toISO(), endDate.toISO(), useAuditV2);
        const preparedData = buildOOSAuditsTableData(oosAuditsData);

        setData(preparedData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    if (store && startDate && endDate) getOOSAuditsData();
  }, [store, startDate, endDate]);

  const refreshData = (options) => {
    const { startDate: currentStartDate, endDate: currentEndDate } = options;
    setStartDate(SimDateTime.toDateTime(currentStartDate));
    setEndDate(SimDateTime.toDateTime(currentEndDate));
  };

  const onGoClick = (options) => {
    refreshData(options);
  };

  const maxDate = SimDateTime.endOfDay();
  const isOutsideRange = date => isOutsideDateRange(date, null, maxDate);

  return (
    <Provider
      value={mockedValue ?? {
        data,
        dialogData,
        endDate,
        error,
        isOpen,
        loading,
        startDate,
        getMessage,
        isOutsideRange,
        onGoClick,
        setDialogData,
        setEndDate,
        setOpen,
        setStartDate,
      }}
    >
      {children}
    </Provider>
  );
};

OOSAuditProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default OOSAuditProvider;
