import { get } from "lodash";

/**
   * A function determines whether a value is NaN or not.
   * @param {string | number} val the accuracy total we're getting from the service
*/
export const parseAsInt = value => parseInt(value, 10) || 0;
export const getSum = (value) => Number(value?.split('/').reduce((total, num) => parseAsInt(total) + parseAsInt(num)));

export const getNumber = (data, currencyCode = '') => {
  if (typeof data === 'string') {
    const value = data.trim().replace(currencyCode, '');
    if (value.trim() === '-') return -1;
    if (value.includes('/')) return getSum(value);
    return Number(value);
  }
  return Number(data);
};

/**
 * Sorting salesfloor data - this is used to set the row data accurately for the modals
 * @param {array} data - array of data in the mui table
 * @param {string} path - the path to the field or data column
 * @param {string} order - order to sort by: desc/asc
 * @param {func} getData - lambda for getting data to be sorted from object
 * @param {string} currencyCode - curreny code to be removed from string
 */
const sortingByNumber = (data, path, order, getData, currencyCode) => {
  if (!data) return [];

  const sortedValue = [...data];
  sortedValue.sort((a, b) => {
    const aValue = getNumber(getData(a, path), currencyCode);
    const bValue = getNumber(getData(b, path), currencyCode);

    if (order === 'asc') return aValue - bValue;
    if (order === 'desc') return bValue - aValue;
    return 0;
  });
  return sortedValue;
};

/**
   * Sorting salesfloor data - this is used to set the row data accurately for the modals
   * @param {array} data - array of data in the mui table
   * @param {string} dataIndex - the index we want to check on
   * @param {string} order - order to sort by: desc/asc
   * @param {string} currencyCode - curreny code to be removed from string
   */
export const sortByNumber = (data, dataIndex, order, currencyCode) => {
  const getData = (item, dataIndex) => item?.[dataIndex];
  return sortingByNumber(data, dataIndex, order, getData, currencyCode);
};

/**
   * Sorting salesfloor data - this is used to set the row data accurately for the modals
   * @param {array} data - array of data in the mui table
   * @param {string} path - the path to the field or data column
   * @param {string} order - order to sort by: desc/asc
   * @param {string} currencyCode - curreny code to be removed from string
   */
export const sortByNumberByData = (data, path, order, currencyCode) => {
  const getData = (item, path) => get(item, path);
  return sortingByNumber(data, path, order, getData, currencyCode);
};

/**
 * Sorts by stock on hand with a default index value of 3
 * @param {*} data - array of data in the mui table
 * @param {*} order - order to sort by: desc/asc
 * @param {*} dataIndex - the index we want to check on 
 * @returns sorted array
 */
export const sortByStockOnHand = (data, order, dataIndex = 3) => sortByNumberByData(data, `data[${dataIndex}]`, order)