import {
  bool, func, string, object, oneOfType,
} from 'prop-types';
import classNames from 'classnames';
import { LoadingIndicator } from '../assets/Svgs/index';
import { svg as chevronSvg } from '../assets/Svgs/Chevron';
import './Button.css';

const ChevronButton = ({
  isLoading = false,
  shape = 'oval',
  onClick = null,
  buttonText = '',
  extraClasses = '',
  disabled = false,
  type = 'button',
  dataTestId = 'sim-button',
  chevron = null,
  darkMode = false,
  ...rest
}) => (
  <div
    className={`button ${isLoading ? 'loading' : ''} ${
      disabled ? 'disabled' : ''
    } ${extraClasses}`}
  >
    <button
      className={classNames('sim-button', shape === 'circle' ? 'circleButton' : '', darkMode ? 'sim-button-darkMode' : '')}
      onClick={onClick}
      type={type}
      disabled={isLoading || disabled}
      data-testid={dataTestId}
      {...rest}
    >
      {chevron && chevron === 'left' ? chevronSvg() : ''}
      {buttonText}
      {chevron && chevron === 'right' ? chevronSvg(chevron) : ''}
    </button>
    <LoadingIndicator dataTestId="loadingIndicator-button" />
  </div>
);

ChevronButton.propTypes = {
  isLoading: bool,
  disabled: bool,
  onClick: func,
  buttonText: oneOfType([string, object]),
  extraClasses: string,
  type: string,
  dataTestId: string,
  chevron: string,
  shape: string,
  darkMode: bool,
};

export default ChevronButton;
